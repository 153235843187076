import React, { useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'
import { vouchersService } from 'ziphy-web-shared/basic/api'
import { isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers'
import { useLoader, useUpdateCallback } from 'ziphy-web-shared/basic/hooks'
import { useForm } from 'ziphy-web-shared/basic/lib/forms'
import { Input } from 'ziphy-web-shared/basic/lib/forms/fields'
import { BubbleLoader } from 'ziphy-web-shared/basic/lib/loaders'
import { ProgressRing } from 'ziphy-web-shared/basic/lib/loaders'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import { $booking } from '@store'

import VoucherCard from '../voucherCard/VoucherCard'
import styles from './SelectVoucher.module.scss'

const SelectVoucher = ({ voucher = null, onChange }) => {
  const { t } = useTranslation()

  const availablePracticeIds = useMemo(() => {
    return $booking.hasVoucherOrCode
      ? [$booking.practiceId]
      : $booking.getFilteredPractices(false).map((x) => x.id)
  }, [$booking.hasVoucherOrCode, $booking.practiceId])

  const [loadedData, setLoadedData] = useState(null)
  const [submitted, setSubmitted] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  // Load init data
  const { isLoading: isInitLoading } = useLoader(async () => {
    if (voucher && !submitted) {
      const response = await vouchersService.search({
        id: voucher.id,
        practiceIds: availablePracticeIds,
      })

      setLoadedData({
        vouchers: response.prepared?.vouchers.items || [],
        practices: response.prepared?.practices || [],
        practiceServices: response.prepared?.practiceServices || [],
      })
      setSubmitted(voucher)
    }
  }, [voucher, availablePracticeIds])

  // Search and select
  const { form, useFormOnChange } = useForm({ searchString: { value: '' } })
  const { searchString } = form.fields
  const [selected, setSelected] = useState(null)

  const updateCallback = useUpdateCallback(async () => {
    const code = _.trim(searchString.value)
    if (isAbsoluteEmpty(code)) {
      setIsLoading(false)
      return handleClear()
    }

    const response = await vouchersService.search({
      code,
      practiceIds: availablePracticeIds,
    })

    handleClear()
    setLoadedData({
      vouchers: response.prepared?.vouchers.items || [],
      practices: response.prepared?.practices || [],
      practiceServices: response.prepared?.practiceServices || [],
    })
    setIsLoading(false)
  })

  useEffect(() => {
    if (searchString.value) {
      setIsLoading(true)
    }
  }, [searchString.value])

  useFormOnChange(updateCallback, [searchString.value])

  function handleClear() {
    setLoadedData(null)
    setSelected(null)
  }

  // clear after code changing
  useEffect(() => {
    handleClear()
    searchString.onChange('')
  }, [availablePracticeIds])

  // View
  const preparedList = useMemo(() => {
    const list = _.map(loadedData?.vouchers, (voucher) => {
      const practiceService = loadedData.practiceServices.find(
        (x) => x.id === voucher.practiceServiceId,
      )
      const practice = loadedData.practices.find((x) => x.id === voucher.practiceId)
      const service = $booking.$services.getServiceById(practiceService.serviceId)

      if (!service) {
        return null
      }

      return { voucher, practice, practiceService, service }
    }).filter((item) => item)

    return _.orderBy(list, 'practice.name')
  }, [loadedData?.vouchers])

  const preparedSubmittedItem = useMemo(() => {
    return preparedList.find((x) => x.voucher.id === submitted?.id)
  }, [submitted?.id])

  // Submit
  function handleChooseAnother() {
    searchString.onChange(preparedSubmittedItem.voucher.code)
    setSubmitted(null)
    onChange({ voucher: null, serviceId: null })
  }

  function handleSubmit() {
    setSubmitted(selected)
    const practiceService = loadedData.practiceServices.find(
      (x) => x.id === selected.practiceServiceId,
    )
    onChange({ voucher: selected, serviceId: practiceService?.serviceId })
    setSelected(null)
  }

  return (
    <div className="mt-30">
      <div className="title-sm">
        {t('label.voucher')}
        {preparedSubmittedItem && (
          <span className="text-muted"> {preparedSubmittedItem.voucher.code}</span>
        )}
      </div>
      {isInitLoading ? (
        <div className="box-body box-body--padding text-center">
          <ProgressRing progress={25} spin={true} />
        </div>
      ) : (
        <>
          {preparedSubmittedItem ? (
            <VoucherCard
              title={preparedSubmittedItem.service.name}
              subTitle={preparedSubmittedItem.practice.name}
              description={preparedSubmittedItem.voucher.description}
              price={{
                amount: preparedSubmittedItem.voucher.price,
                currency: preparedSubmittedItem.voucher.currency,
              }}
              buttonLabel={t('book.vouchers.btn.choose_another_voucher')}
              onButtonClick={handleChooseAnother}
            />
          ) : (
            <>
              <div className="mb-10">{t('book.vouchers.voucher_subtitle')}</div>
              <Input
                field={searchString}
                label={t('label.voucher')}
                placeholder={t('label.enter_the_voucher_here')}
                mode="lg"
                clear={true}
                onClear={handleClear}
              />
              {isLoading ? (
                <div className={styles.loader}>
                  <BubbleLoader />
                </div>
              ) : !preparedList.length && searchString.value ? (
                <div className={styles.notFound}>{t('ps.errors.no_results')}</div>
              ) : (
                preparedList.map((item) => (
                  <VoucherCard
                    key={item.voucher.id}
                    className="mt-10"
                    title={item.service.name}
                    subTitle={item.practice.name}
                    description={item.voucher.description}
                    price={{ amount: item.voucher.price, currency: item.voucher.currency }}
                    isCheckbox={true}
                    isChecked={selected?.id === item.voucher.id}
                    onClick={() => setSelected(item.voucher)}
                  />
                ))
              )}
              {selected && (
                <ButtonLegacy mode={['primary', 'block']} className="mt-10" action={handleSubmit}>
                  {t('btn.submit')}
                </ButtonLegacy>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default observer(SelectVoucher)
