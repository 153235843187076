import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
import { $layouts } from 'ziphy-web-shared/basic/utils/layouts';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import { $notices } from '@store';
import Arrow from '@assets/svg/Arrow';
import NotificationsListItem from '../notificationsListItem/NotificationsListItem';
import styles from './NotificationsGroup.module.scss';
const NotificationsGroup = ({ data }) => {
    var _a, _b;
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [first, ...other] = data.items;
    const notViewedIds = (_a = data.items) === null || _a === void 0 ? void 0 : _a.filter((x) => !x.viewed).map((x) => x.id);
    const apptLink = (_b = first === null || first === void 0 ? void 0 : first.actions) === null || _b === void 0 ? void 0 : _b.find((x) => x.type === 'view_appointment');
    return (_jsxs("div", Object.assign({ className: classNames(styles.group, isOpen && styles.groupOpen) }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsxs("div", Object.assign({ className: styles.title }, { children: [data.hasActual && (_jsx("div", { className: classNames(styles.redMark, isOpen && styles.redMarkSecondary) })), data.appointmentId && apptLink ? (_jsxs(ButtonLegacy, Object.assign({ mode: ['anchor-gray'], action: () => {
                                    apptLink === null || apptLink === void 0 ? void 0 : apptLink.action();
                                    $windowSize.isMobileSize && $layouts.hideRightSidebar();
                                }, className: classNames(styles.text, styles.apptLink) }, { children: [t('label.appointment_#', { id: data.appointmentId }), _jsx(Arrow, { color: "#8E8E93" })] }))) : (_jsx("span", Object.assign({ className: styles.text }, { children: data.appointmentId
                                    ? t('label.appointment_#', { id: data.appointmentId })
                                    : t('label.reminders') })))] })), notViewedIds.length > 0 && (_jsx("div", Object.assign({ className: styles.markAllAsRead, onClick: () => $notices.view({ ids: notViewedIds }) }, { children: t('btn.mark_all_as_read') })))] })), _jsx(NotificationsListItem, { notice: first, isOpen: isOpen }), isOpen && (_jsx(_Fragment, { children: other.map((notice) => (_jsx(NotificationsListItem, { notice: notice, isOpen: isOpen, isOther: true }, notice.id))) })), other.length > 0 && (_jsx(ButtonLegacy, Object.assign({ mode: ['block', 'secondary-dark', 'sm'], className: classNames('mt-10', !notViewedIds.length && styles.showMoreButtonDisabled), action: () => setIsOpen((x) => !x) }, { children: isOpen ? t('btn.show_less') : t('label.plus_another', { count: other.length }) })))] })));
};
export default observer(NotificationsGroup);
