import _ from 'lodash';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import { $patients } from 'ziphy-web-shared/basic/entities/patients';
import { $settings } from 'ziphy-web-shared/basic/entities/settings';
import { isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import { TOS_PROVIDER } from '@library/plugins/termsOfService/Tos.config';
import { $modal } from '@store';
import ConfigureCoeModal from './modals/ConfigureCoe.modal';
import ConfigureDefaultPatientModal from './modals/ConfigureDefaultPatient.modal';
import ConfigureTosModal from './modals/ConfigureTos.modal';
function checkTosVersion(type) {
    var _a, _b, _c, _d;
    const selectedType = _.camelCase(type);
    const currentTos = (_c = (_b = (_a = $auth.user) === null || _a === void 0 ? void 0 : _a.tosDocs) === null || _b === void 0 ? void 0 : _b[selectedType]) === null || _c === void 0 ? void 0 : _c.version;
    const expectedTos = (_d = $settings.tosVersions) === null || _d === void 0 ? void 0 : _d[selectedType];
    return Number(currentTos) >= Number(expectedTos); // true if correct
}
export const availableConfigureSteps = [
    {
        name: 'tos',
        loaded: () => true,
        condition: () => {
            return !checkTosVersion('client') || !checkTosVersion('client_pp');
        },
        action: () => {
            $modal.add(ConfigureTosModal, { canDrop: false, uniq: false });
        },
        deps: () => { var _a; return [(_a = $auth.user) === null || _a === void 0 ? void 0 : _a.tosDocs, $settings.tosVersions]; },
    },
    {
        name: 'tos-provider',
        loaded: () => true,
        condition: () => {
            return !checkTosVersion('provider');
        },
        action: () => {
            $modal.add(ConfigureTosModal, { canDrop: false, uniq: false, type: TOS_PROVIDER });
        },
        deps: () => { var _a; return [(_a = $auth.user) === null || _a === void 0 ? void 0 : _a.tosDocs, $settings.tosVersions]; },
    },
    {
        name: 'coe',
        loaded: () => true,
        condition: () => {
            return !checkTosVersion('emergency_instructions');
        },
        action: () => {
            $modal.add(ConfigureCoeModal, { canDrop: false });
        },
        deps: () => { var _a; return [(_a = $auth.user) === null || _a === void 0 ? void 0 : _a.tosDocs, $settings.tosVersions]; },
    },
    {
        name: 'default-patient',
        loaded: () => Boolean($patients.defaultPatient),
        condition: () => {
            var _a, _b, _c, _d;
            return ($patients.defaultPatient &&
                (isAbsoluteEmpty((_a = $patients.defaultPatient) === null || _a === void 0 ? void 0 : _a.firstName) ||
                    isAbsoluteEmpty((_b = $patients.defaultPatient) === null || _b === void 0 ? void 0 : _b.lastName) ||
                    !((_c = $patients.defaultPatient) === null || _c === void 0 ? void 0 : _c.gender) ||
                    !((_d = $patients.defaultPatient) === null || _d === void 0 ? void 0 : _d.dob)));
        },
        action: () => {
            $modal.add(ConfigureDefaultPatientModal, { canDrop: false });
        },
        deps: () => [$patients.defaultPatient],
    },
];
