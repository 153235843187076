import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CustomLink } from 'ziphy-web-shared/basic/lib/buttons';
import { $modal } from '@store';
import AddInsuranceModal from '../addInsuranceModal/AddInsurance.modal';
import styles from './InsuranceListAddButton.module.scss';
const InsuranceListAddButton = ({ patient, className }) => {
    const { t } = useTranslation();
    return (_jsx(CustomLink, Object.assign({ autoTarget: {
            onClick: () => $modal.add(AddInsuranceModal, {
                patientId: patient.id,
                initValue: {
                    memberFirstName: patient.firstName,
                    memberLastName: patient.lastName,
                },
            }),
        }, className: classNames(styles.button, className) }, { children: t('btn.add_insurance') })));
};
export default InsuranceListAddButton;
