import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { forwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import { BubbleLoader } from 'ziphy-web-shared/basic/lib/loaders';
import { analytics } from 'ziphy-web-shared/basic/utils/analytics';
import './Button.scss';
const ButtonLegacy = forwardRef(({ tag = 'div', mode, id, className, label, buttonType, disabled, form, isLoading, children, autoTarget, to = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.to, href = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.href, action, // temp hack
onClick = action || (autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.onClick), wvAction = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.wvAction,
//
 }, ref) => {
    function handleOnClick(e) {
        if (isLoading) {
            e.preventDefault();
            return;
        }
        if (_.isFunction(wvAction)) {
            wvAction(e);
        }
        if (_.isFunction(onClick)) {
            onClick(e);
            analytics.breadcrumb('input-click', {
                tag: tag,
                source: onClick.name,
                class: className,
                form: form,
                label: label,
            });
        }
    }
    const preparedType = _.isArray(mode) ? mode : [mode];
    const buttonClassNames = useMemo(() => {
        const result = [
            'btn',
            disabled && 'disabled',
            preparedType.length > 0 && preparedType.map((x) => x && 'btn-' + x).join(' '),
        ];
        if (isLoading) {
            result.push('progress_button', 'progress_button--active');
        }
        return result;
    }, [preparedType, isLoading]);
    if (tag === 'a' || href) {
        return (_jsxs("a", Object.assign({ id: id, ref: ref, href: href, onClick: handleOnClick, className: classNames(buttonClassNames, className) }, { children: [_jsx(ButtonLoader, { show: isLoading }), children || label] })));
    }
    if (tag === 'link' || to) {
        return (_jsxs(Link, Object.assign({ id: id, ref: ref, to: to, onClick: handleOnClick, className: classNames(buttonClassNames, className) }, { children: [_jsx(ButtonLoader, { show: isLoading }), children || label] })));
    }
    if (tag === 'button' || form) {
        return (_jsxs("button", Object.assign({ id: id, ref: ref, type: buttonType, form: form, disabled: disabled, onClick: handleOnClick, className: classNames(buttonClassNames, className) }, { children: [_jsx(ButtonLoader, { show: isLoading }), children || label] })));
    }
    return (_jsxs("div", Object.assign({ id: id, ref: ref, onClick: handleOnClick, className: classNames(buttonClassNames, className) }, { children: [_jsx(ButtonLoader, { show: isLoading }), children || label] })));
});
export default ButtonLegacy;
function ButtonLoader({ show }) {
    if (!show) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", Object.assign({ className: "progress_button-indicator" }, { children: _jsx(BubbleLoader, {}) })));
}
