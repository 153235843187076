import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'

import { $router } from 'ziphy-web-shared/basic/utils/router'

import { $booking } from '@store'

import withBookingDataInit from '../../library/withBookingDataInit/withBookingDataInit'
import BookingChat from '../../pages/bookingSymptoms/bookingChat/BookingChat'
import BookingSelectSymptoms from '../../pages/bookingSymptoms/bookingSelectSymptoms/BookingSelectSymptoms'

const BookingSymptomsPage = () => {
  const service = $booking.service
  const [isChat, setIsChat] = useState(service.hasSymptoms === false)

  useEffect(() => {
    setIsChat(service.hasSymptoms === false)
  }, [service.hasSymptoms])

  const breadcrumbs = ['place', 'service', 'patients']

  return isChat ? (
    <BookingChat
      setChat={setIsChat}
      skipSymptoms={service.hasSymptoms === false}
      breadcrumbs={breadcrumbs}
      onClickContinue={() => $router.executeAutoTarget($booking.schemaConfig?.nextStep())}
    />
  ) : (
    <BookingSelectSymptoms
      service={service}
      onClickNext={() => setIsChat(true)}
      breadcrumbs={breadcrumbs}
    />
  )
}

export default withBookingDataInit('symptoms', observer(BookingSymptomsPage))
