var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { delayedRequest, isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import { useGetDropdownPosition, useInnerValue, useUpdateCallback, } from 'ziphy-web-shared/basic/hooks';
import { useForm } from 'ziphy-web-shared/basic/lib/forms';
import { ClickOutside } from 'ziphy-web-shared/basic/lib/utilities';
import InputBase from '@library/form/fields/new/InputBase';
import TypeAheadPopup from '@library/form/fields/typeAhead/TypeAheadPopup';
import styles from './TypeAhead.module.scss';
const TypeAhead = forwardRef(({ value = {}, onLoad, onLoadDelayLimit = 400, onLoadMinSearchLength = 3, onChange, className, fieldAttributes = {}, popupParams = {}, }, forwardedRef) => {
    var _a, _b;
    const { t } = useTranslation();
    const suggestions = useRef([]);
    const isNeedUpdate = useRef(true);
    const [isLoading, setIsLoading] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [innerValue, setInnerValue] = useInnerValue(value.hasOwnProperty('text') ? value : { text: value.text });
    const { form, useFormOnChange } = useForm({
        searchString: { value: innerValue.text, prepValue: 'valueOrEmptyString' },
    });
    const { searchString } = form.fields;
    const updateCallback = useUpdateCallback(handleTypeahead);
    useFormOnChange(updateCallback, [searchString.value]);
    useEffect(() => {
        searchString.onChange(innerValue.text);
    }, [innerValue]);
    useEffect(() => {
        if (isVisible && isNeedUpdate.current) {
            updateCallback();
        }
    }, [isVisible]);
    const { fieldRef, dropdownRef, dropdownStyles } = useGetDropdownPosition({
        isOpen: isVisible,
        alwaysBottom: true,
        itemsLength: (_a = suggestions.current) === null || _a === void 0 ? void 0 : _a.length,
    });
    function handleTypeahead() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (((_a = searchString.value) === null || _a === void 0 ? void 0 : _a.length) < onLoadMinSearchLength) {
                if (isAbsoluteEmpty(searchString.value)) {
                    suggestions.current = [];
                }
                setIsLoading(null);
                return false;
            }
            if (!isVisible) {
                return false;
            }
            setIsLoading(true);
            suggestions.current = yield delayedRequest(() => __awaiter(this, void 0, void 0, function* () {
                dropdownRef.current.style.height = 'initial'; // without this reset height calculated wrong when we have auto focus after render
                let result = yield onLoad({ searchString: searchString.value });
                return _.isArray(result) ? result : [];
            }), onLoadDelayLimit);
            setIsLoading(false);
        });
    }
    function handleSelect(item) {
        if (item.text !== innerValue.text) {
            isNeedUpdate.current = true;
            suggestions.current = [];
        }
        else {
            isNeedUpdate.current = false;
        }
        setInnerValue(item);
        onChange(item);
        setIsVisible(false);
    }
    function handleClickOutside() {
        var _a;
        if (searchString.value.length) {
            searchString.onChange(innerValue.text);
        }
        if (!((_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.toString())) {
            setIsVisible(false);
        }
    }
    useImperativeHandle(forwardedRef, () => ({
        setIsVisible(x) {
            setIsVisible(x);
        },
    }));
    return (_jsx("div", Object.assign({ ref: fieldRef, className: classNames(styles.wrapper, className) }, { children: _jsxs(ClickOutside, Object.assign({ onClick: handleClickOutside, className: classNames(styles.wrapper, className) }, { children: [_jsx(InputBase, { field: {
                        value: searchString.value,
                        onChange: (value) => {
                            if (fieldAttributes.isInvalid) {
                                onChange(value);
                            }
                            searchString.onChange(value);
                        },
                        isInvalid: fieldAttributes.isInvalid,
                    }, label: fieldAttributes.label || t('label.label'), placeholder: fieldAttributes.placeholder ||
                        (fieldAttributes.placeholder !== null && t('label.search')), maxLength: fieldAttributes.maxLength || 150, mode: fieldAttributes.mode || 'lg', clear: true, onClear: () => handleSelect({ text: '' }), onFocus: () => setIsVisible(true), unFocusComponent: fieldAttributes.unFocusedKey &&
                        !isAbsoluteEmpty(innerValue.text) &&
                        innerValue.text === searchString.value ? (_jsxs("div", Object.assign({ className: styles.unFocusedWrapper }, { children: [_jsx("div", Object.assign({ className: styles.text }, { children: innerValue.text })), innerValue[fieldAttributes.unFocusedKey] && (_jsx("div", Object.assign({ className: styles.meta }, { children: innerValue[fieldAttributes.unFocusedKey] })))] }))) : null }), _jsx("div", Object.assign({ ref: dropdownRef, style: Object.assign(Object.assign({}, dropdownStyles), (!isVisible ? { height: '0' } : {})), className: styles.ddWrapper }, { children: isVisible && ((_b = searchString.value) === null || _b === void 0 ? void 0 : _b.length) >= onLoadMinSearchLength && (_jsx(TypeAheadPopup, { searchString: searchString, items: suggestions.current, onSelect: handleSelect, isLoading: isLoading, simplifyGroups: popupParams.simplifyGroups, useAsIs: popupParams.useAsIs, fieldWidth: (fieldRef === null || fieldRef === void 0 ? void 0 : fieldRef.current) && fieldRef.current.getBoundingClientRect().width })) }))] })) })));
});
export default observer(TypeAhead);
