var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import { BubbleLoader } from 'ziphy-web-shared/basic/lib/loaders';
import { ClickOutside } from 'ziphy-web-shared/basic/lib/utilities';
import { $loader } from 'ziphy-web-shared/basic/utils';
import InputSearch from '@library/form/fields/InputSearch';
import maps from '@library/map/Map';
import SelectCountryFlag from '@library/selectCountry/SelectCountryFlag';
import { translatePlaceToAddress } from '@nedoShared/features/addresses/addressController/addAddressModal/AddAddress.helpers';
import styles from './AddressAutocomplete.module.scss';
function getConfig(selected) {
    return {
        requestOptions: {
            types: ['address'],
            componentRestrictions: {
                country: selected,
            },
        },
        debounce: 400,
    };
}
const AddressAutocomplete = ({ isDisabled, label, placeholder, country, initLineValue, isInvalid, onChangeLine, onChangeCountry, onSelectSuggestion, }) => {
    const { t } = useTranslation();
    const [showPopup, setShowPopup] = useState(false);
    const config = useMemo(() => {
        return getConfig([country]);
    }, [country]);
    const autoComplete = usePlacesAutocomplete(config);
    const searchInputRef = useRef();
    const { value, ready, setValue, suggestions, clearSuggestions } = autoComplete;
    const [lastSelectedSuggestionValue, setLastSelectedSuggestionValue] = useState('');
    const handleSelectSuggestion = $loader.registerHandler('select-suggestion', (item) => __awaiter(void 0, void 0, void 0, function* () {
        clearSuggestions();
        const detailedPlace = yield getDetails({
            placeId: item.place_id,
            fields: ['address_components'],
        });
        const formatedPlace = maps.formatApiData(detailedPlace);
        const newAddress = translatePlaceToAddress(formatedPlace);
        setLastSelectedSuggestionValue(newAddress.line[0]);
        onSelectSuggestion(newAddress);
    }));
    function clearInput() {
        clearSuggestions();
        setLastSelectedSuggestionValue('');
        onChangeLine('');
        searchInputRef.current.focus();
    }
    useEffect(() => {
        // reset all inner values in case user toggle same as residential to false
        if (!initLineValue && value) {
            setValue('');
            setLastSelectedSuggestionValue('');
        }
    }, [initLineValue]);
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx(SelectCountryFlag, { country: [country], disabled: isDisabled, onChange: onChangeCountry, className: styles.flag }), _jsx(InputSearch, Object.assign({ className: classNames(styles.input, suggestions.loading && 'search--loading'), ref: searchInputRef, field: {
                    value: isDisabled
                        ? initLineValue
                        : lastSelectedSuggestionValue
                            ? lastSelectedSuggestionValue
                            : value,
                    onChange: (newValue) => {
                        onChangeLine(newValue);
                        setValue(newValue);
                    },
                    isInvalid,
                }, onClear: clearInput, onKeyDown: () => setShowPopup(true), onFocus: () => {
                    if (lastSelectedSuggestionValue) {
                        setValue(lastSelectedSuggestionValue);
                    }
                    setShowPopup(true);
                }, disabled: !ready || isDisabled, readOnly: isDisabled, label: label, mode: 'lg', placeholder: placeholder }, { children: showPopup && (suggestions.status !== '' || suggestions.loading) && (_jsx(ClickOutside, Object.assign({ className: "search-popup", onClick: () => setShowPopup(false) }, { children: _jsxs("div", Object.assign({ className: "search-popup-scroll" }, { children: [suggestions.loading && (_jsx("div", Object.assign({ className: "search-popup-group-loader" }, { children: _jsx(BubbleLoader, { mode: "lg" }) }))), suggestions.data.length > 0
                                ? suggestions.data.map((item) => (_jsxs("div", Object.assign({ className: "search-popup-item", onClick: () => handleSelectSuggestion(item) }, { children: [_jsx("span", Object.assign({ className: "search-popup-item-title" }, { children: item.structured_formatting.main_text })), _jsxs("span", Object.assign({ className: "search-popup-item-meta" }, { children: [", ", item.structured_formatting.secondary_text] }))] }), item.place_id)))
                                : suggestions.status !== '' && (_jsx("div", Object.assign({ className: "search-popup-error" }, { children: t('errors.no_results') })))] })) }))) }))] })));
};
export default AddressAutocomplete;
