import React, { useEffect, useState } from 'react'

import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { $auth } from 'ziphy-web-shared/basic/entities/auth'
import { useLoader } from 'ziphy-web-shared/basic/hooks'
import { $windowSize, localStore } from 'ziphy-web-shared/basic/utils'

import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator'

import { $booking, $modal, $places } from '@store'

import AddPlaceModal from '../../../library/addPlaceModal/AddPlace.modal'
import SelectAddressModal from '../../../library/modals/selectAddressModal/SelectAddress.modal'
import SelectAddressHooks from '../../../pages/bookingHome/selectAddress/SelectAddress.hooks'
import SelectAddressDesktop from '../../../pages/bookingHome/selectAddress/selectAddressDesktop/SelectAddressDesktop'
import SelectAddressMobile from '../../../pages/bookingHome/selectAddress/selectAddressMobile/SelectAddressMobile'

const SelectAddress = () => {
  const [isCollapsedMap, setIsCollapsedMap] = useState(
    $auth.isLogged && Boolean(localStore.get('userSettings.bookPlaceId', null)),
  )
  const place = SelectAddressHooks.usePlaceDetails($booking.usablePlace)

  const { isLoading } = useLoader(async () => {
    await $booking.loadPlaces()

    if ($booking.place) {
      setIsCollapsedMap(true)
    }
  }, [])

  useEffect(() => {
    if (!isLoading && !$booking.place) {
      setIsCollapsedMap(false)
    } else if (!isLoading && $booking.place) {
      setIsCollapsedMap(true)
    }
  }, [isLoading, $booking.place])

  useEffect(() => {
    const disposers = [
      reaction(
        () => $booking.placeId,
        () => {
          $booking.clearAfterPlaceChanging()
        },
      ),
      reaction(
        () => $booking.$places.items,
        (places, oldPlacesValue) => {
          if (places.length && oldPlacesValue.length) {
            if ($booking.placeId !== null && places.find((item) => $booking.placeId === item.id)) {
              return
            }
            $booking.SET_PLACE_ID(places[0].id)
          } else {
            $booking.SET_PLACE_ID(null)
          }
        },
      ),
    ]

    return () => disposers.forEach((item) => item())
  }, [])

  const handleClickBook = (e) => {
    const callback = () => {
      return $booking.place ? setIsCollapsedMap((x) => !x) : $modal.add(AddPlaceModal)
    }
    confirmAction('checkAuth', { callback }, e)
  }

  const handleClickChange = (e) => {
    const callback = () => {
      return $places.items.length > 0 ? $modal.add(SelectAddressModal) : $modal.add(AddPlaceModal)
    }
    confirmAction('checkAuth', { callback }, e)
  }

  return $windowSize.isMobileSize ? (
    <SelectAddressMobile
      placeDetails={place.details}
      isDetailedPlace={place.isDetailed}
      isCollapsedMap={isCollapsedMap}
      onClickBook={handleClickBook}
      onClickChange={handleClickChange}
    />
  ) : (
    <SelectAddressDesktop
      placeDetails={place.details}
      isDetailedPlace={place.isDetailed}
      isCollapsedMap={isCollapsedMap}
      onClickBook={handleClickBook}
      onClickChange={handleClickChange}
    />
  )
}

export default observer(SelectAddress)
