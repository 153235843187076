import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { getUserText } from 'ziphy-web-shared/basic/helpers';
import { $layouts } from 'ziphy-web-shared/basic/utils/layouts';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import styles from './NotesBar.module.scss';
const NotesBar = ({ patient, appointment, encounter, sidebarDisabled }) => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: styles.NotesBar }, { children: [_jsxs("div", Object.assign({ className: styles.InfoBlock }, { children: [_jsx("p", Object.assign({ className: styles.PatientInfo }, { children: `${getUserText(patient, [
                            'fullName',
                            'genderShort',
                        ])} ${getUserText(patient, ['yo'])}` })), _jsxs("p", Object.assign({ className: styles.ApptInfo }, { children: [t('label.appointment'), "\u00A0", appointment.id] }))] })), _jsxs(ButtonLegacy, Object.assign({ className: styles.InitNotesBtn, id: 'notes_bar_button', tag: "button", mode: 'primary', disabled: sidebarDisabled, action: () => $layouts.showRightSidebar('notes', {
                    encounterId: encounter.id,
                    appointmentId: appointment.id,
                }) }, { children: [t('notes.label.fill_out'), ' ', _jsx(Icon, { type: 'moveRightArrow', color: sidebarDisabled ? '#d1d1d1' : '#fff' })] }))] })));
};
export default NotesBar;
