import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { simpleTagConverter } from 'ziphy-web-shared/basic/helpers';
import { BubbleLoader } from 'ziphy-web-shared/basic/lib/loaders';
import styles from './TypeAheadPopup.module.scss';
const TypeAheadPopup = ({ searchString, items, isLoading, onSelect, simplifyGroups, useAsIs, fieldWidth, }) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const RenderItems = ({ items }) => {
        return (_jsx(_Fragment, { children: items.map((item, index) => {
                if (item.hasOwnProperty('items') && 'items' in item) {
                    return (_jsx(React.Fragment, { children: simplifyGroups && item.items.length === 1 ? (_jsx(RenderItems, { items: item.items })) : (_jsxs("div", Object.assign({ className: styles.group }, { children: [_jsx("div", Object.assign({ className: styles.groupTitle }, { children: item.highlight ? simpleTagConverter(item.highlight) : item.text })), _jsx(RenderItems, { items: item.items })] }))) }, [item.id, item.text, index].join('_')));
                }
                else if (!('items' in item)) {
                    return (_jsxs("div", Object.assign({ className: styles.item, onClick: () => onSelect(item) }, { children: [_jsxs("div", Object.assign({ className: styles.itemInner }, { children: [_jsxs("span", Object.assign({ className: styles.itemInnerTitle }, { children: [item.highlight ? simpleTagConverter(item.highlight) : item.text, item.metaText && (_jsx("span", Object.assign({ className: styles.itemInnerMetaText }, { children: item.metaText })))] })), item.rightText && (_jsx("span", Object.assign({ className: styles.itemInnerRightText }, { children: item.rightText })))] })), item.subText && _jsx("span", Object.assign({ className: styles.itemSubText }, { children: item.subText }))] }), [item.id, item.text, index].join('_')));
                }
            }) }));
    };
    const RenderLoader = () => (_jsx("div", Object.assign({ className: styles.loader }, { children: _jsx(BubbleLoader, { mode: "lg", innerClassNames: styles.bubbleLoader }) })));
    return (_jsxs("div", Object.assign({ style: {
            width: `${fieldWidth}px` || '100%',
        }, className: classNames(styles.wrapper, isLoading && !_.isEmpty(items) && styles.isLoading) }, { children: [_jsx("div", Object.assign({ className: classNames(styles.scrollArea, 'styled_scroll'), ref: ref }, { children: isLoading === null ? (_jsx(RenderLoader, {})) : _.isEmpty(items) && !isLoading ? (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles.error }, { children: t('errors.no_results') })), useAsIs && (_jsx("div", Object.assign({ className: styles.useAsIs }, { children: _jsxs("div", Object.assign({ className: classNames(styles.item), onClick: () => onSelect({ text: searchString.value }) }, { children: [t('label.use_as_is:'), " ", _jsx("span", { children: searchString.value })] })) })))] })) : (_jsx(RenderItems, { items: items })) })), isLoading && _jsx(RenderLoader, {})] })));
};
export default TypeAheadPopup;
