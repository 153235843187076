import React, { Fragment } from 'react'

import { observer } from 'mobx-react-lite'
import { Trans } from 'react-i18next'

import { $appts } from '@store'

const ApptDesc = () => {
  const { status, actions } = $appts.byRoute

  let description = status.texts?.desc || []

  let params = {
    forms: '',
  }

  if (actions.fillForms && status.formsNotFilled.any) {
    params.forms = [
      <Trans
        i18nKey="appt_desc.forms"
        components={[<span className="a" onClick={() => actions.fillForms?.action()} />]}
      />,
    ]
  }

  description = description.map((x) => {
    if (params.hasOwnProperty(x)) {
      return params[x]
    }
    return x
  })

  description = description.reduce((r, a) => r.concat(a, ' '), [])

  return (
    <div className="mb-30 box--md">
      {description.map((item, index) => (
        <Fragment key={index}>{item}</Fragment>
      ))}
    </div>
  )
}

export default observer(ApptDesc)
