import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { PatientInfoBox } from 'ziphy-web-shared/basic/lib/simple';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
const SelectChannel = ({ setView, _core }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "modal-toolbar" }, { children: [_jsx(ModalToolbarBtn, { type: "back", action: () => setView('list') }), _jsx(ModalToolbarBtn, { type: "close", action: () => _core.onHide() })] })), _jsx(Modal.Header, Object.assign({ className: "text-lg-center" }, { children: t('label.add_communication_channel') })), _jsxs(Modal.Body, { children: [_jsx(PatientInfoBox, { onClick: () => setView('phone'), value: t('label.add_phone'), withArrow: true }), _jsx(PatientInfoBox, { onClick: () => setView('email'), value: t('label.add_email'), className: 'mt-10', withArrow: true })] })] }));
};
export default observer(SelectChannel);
