var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getUA } from 'react-device-detect';
import { APP_TYPE } from 'ziphy-web-shared/basic/helpers';
import { webView } from 'ziphy-web-shared/basic/utils';
import { BaseService } from '../baseService';
export class Auth extends BaseService {
    constructor(props) {
        super(props);
        this.device = getUA;
        this.application = webView.isWebView ? 'mobile' : 'web';
    }
    /*
     * Attention: user.auth.request_code and user.auth.onboard - sync with AWS WAF settings
     */
    requestCode(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!params.using || (params.type === 'email' && params.using === 'voice')) {
                params.using = 'text';
            }
            return this.api.fetchV3('user.auth.request_code', params, options);
        });
    }
    onboard(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!params.using || (params.type === 'email' && params.using === 'voice')) {
                params.using = 'text';
            }
            return this.api.fetchV3('user.auth.onboard', params, options);
        });
    }
    login(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            params = Object.assign(Object.assign({ application: this.application, device: this.device, preferableRoles: [APP_TYPE] }, params), { expand: Object.assign({ role: ['practiceId', 'servicedPracticeIds'] }, params.expand) });
            return this.api.fetchV3('user.auth.login', params, options);
        });
    }
    loginByToken(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            params = Object.assign(Object.assign({ application: this.application }, params), { expand: Object.assign({ role: ['practiceId', 'servicedPracticeIds'] }, params.expand) });
            return this.api.fetchV3('user.auth.login_by_token', params, options);
        });
    }
    signup(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            params = Object.assign({ expand: { role: ['practiceId', 'servicedPracticeIds'] }, application: this.application, device: this.device, preferableRoles: [APP_TYPE], acceptTos: false }, params);
            return this.api.fetchV3('user.auth.signup', params, options);
        });
    }
    logout(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.api.fetchLoggedV3('user.auth.logout', params, options);
        });
    }
    refresh(params, { loadPractices } = {}, options) {
        return __awaiter(this, void 0, void 0, function* () {
            if (loadPractices) {
                params.expand = Object.assign({ role: ['practiceId', 'servicedPracticeIds'] }, params.expand);
            }
            return this.api.fetchV3('user.auth.refresh', params, options);
        });
    }
}
