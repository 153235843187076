import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { getUserText } from 'ziphy-web-shared/basic/helpers';
import { CustomLink } from 'ziphy-web-shared/basic/lib/buttons';
import { PatientInfoBox } from 'ziphy-web-shared/basic/lib/simple';
import InputSearch from '@library/form/fields/InputSearch';
import Badge from '@library/ui/badge/Badge';
import UserPhoto from '@library/user/UserPhoto';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import { $modal } from '@store';
import { customActions } from '@config/customActions';
import mainConfig from '@config/main';
import AddPatientModal from '../addPatientModal/AddPatient.modal';
import styles from './PatientsList.module.scss';
const PatientsList = ({ items, to, onClick, addBtnText, addOnDone, withSearch, isModal, }) => {
    const { t } = useTranslation();
    addBtnText = addBtnText || t('btn.add_patient');
    const [searchString, setSearchString] = useState('');
    const preparedList = useMemo(() => {
        let result = items;
        if (_.trim(searchString)) {
            let needle = _.trim(searchString).toLowerCase().split(' ');
            result = result.filter((x) => {
                let full = [x.firstName, x.lastName].join(' ').toLowerCase();
                return _.every(needle, (n) => full.includes(n));
            });
        }
        return _.sortBy(result, (x) => {
            return [x.firstName, x.lastName].join(' ').toLowerCase();
        });
    }, [items, searchString]);
    function handleOnTo(item) {
        if (_.isFunction(to)) {
            return to(item);
        }
    }
    function handleOnClick(item) {
        if (isModal && item.isDeceased) {
            return;
        }
        if (_.isFunction(onClick)) {
            onClick(item);
        }
    }
    function handleAddPatient() {
        let params = {};
        if (_.isFunction(addOnDone)) {
            params.onDone = addOnDone;
        }
        $modal.add(AddPatientModal, {
            onDone: _.isFunction(addOnDone) ? addOnDone : null,
            hasContacts: true,
        });
    }
    return (_jsxs(_Fragment, { children: [(withSearch || items.length > mainConfig.searchIfMore) && (_jsx(InputSearch, { className: "mb-20", field: { value: searchString, onChange: setSearchString }, placeholder: t('select_patient.ph.search') })), _jsxs("div", Object.assign({ className: classNames(styles.patientsList, isModal && styles.isModal) }, { children: [customActions.HANDLE_ADD_PATIENT && isModal && (_jsx(ButtonLegacy, { autoTarget: customActions.HANDLE_ADD_PATIENT({ callback: handleAddPatient }), mode: ['create', 'block'], label: addBtnText, className: 'mb-10' })), preparedList.map((item) => (_jsx(CustomLink, Object.assign({ autoTarget: handleOnTo(item), className: styles.patientsListItem, onClick: () => handleOnClick(item) }, { children: _jsx(PatientInfoBox, { value: item.isDeceased ? (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.deceasedValue }, { children: [getUserText(item, ['fullName']), ' ', item.isDefault && _jsxs("span", { children: ["(", t('user.you'), ")"] })] })), _jsx(Badge, Object.assign({ className: "ml-1", type: [isModal ? 'gray-fill' : 'white', 'sm'] }, { children: t('label.deceased') }))] })) : (_jsxs(_Fragment, { children: [getUserText(item, ['fullName']), ' ', item.isDefault && _jsxs("span", Object.assign({ className: "text-muted" }, { children: ["(", t('user.you'), ")"] }))] })), label: getUserText(item, ['gender', 'yo']), mode: isModal && ['transparent'], className: isModal && 'p-0', leftElement: _jsx(UserPhoto, { item: item, isGray: item.isDeceased }), clickable: true }) }), item.id))), customActions.HANDLE_ADD_PATIENT && !isModal && (_jsx(ButtonLegacy, { autoTarget: customActions.HANDLE_ADD_PATIENT({ callback: handleAddPatient }), mode: ['create', 'block'], label: addBtnText, className: 'mt-10' }))] }))] }));
};
export default observer(PatientsList);
