var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { waitForVariable } from 'ziphy-web-shared/basic/helpers';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
import { $loader } from 'ziphy-web-shared/basic/utils';
import { $layouts } from 'ziphy-web-shared/basic/utils/layouts';
import { $router } from 'ziphy-web-shared/basic/utils/router';
import styles from './RightSidebar.module.scss';
const constants = {
    MAX_WIDTH: 480,
    MIN_WIDTH: 250,
    CONTENT_MIN_WIDTH: 790,
};
export const RIGHT_SIDEBAR_LOADER_KEY = 'right-sidebar-options-updated';
const Sidebar = observer(({ config, payload, isVisible, elements, removeElementsStyles }) => {
    const prevOptions = useRef();
    const sidebarUpdated = $loader.registerHandler(RIGHT_SIDEBAR_LOADER_KEY, () => __awaiter(void 0, void 0, void 0, function* () { }));
    // const scrollLocker = useScrollLock()
    const sidebar = useMemo(() => {
        const { maxWidth = constants.MAX_WIDTH, minWidth = constants.MIN_WIDTH } = config;
        return {
            maxWidth: minWidth > maxWidth ? minWidth : maxWidth,
            minWidth: minWidth,
        };
    }, [config]);
    const options = useMemo(() => {
        const { wrapper, contents, toolbar } = elements;
        if (!wrapper || !(contents === null || contents === void 0 ? void 0 : contents.length)) {
            return prevOptions.current ? prevOptions.current : null;
        }
        if (isVisible) {
            removeElementsStyles();
        }
        const setContentWidth = (maxWidth, margin, toolbarWidth) => {
            if (isVisible) {
                contents.forEach((element) => {
                    element.style.maxWidth = maxWidth + 'px';
                    element.style.marginRight = margin + 'px';
                    element.style.marginLeft = margin + 'px';
                });
                if (toolbar) {
                    toolbar.style.maxWidth =
                        toolbarWidth !== undefined ? toolbarWidth + 'px' : maxWidth + 'px';
                }
            }
        };
        const wrapperRect = wrapper.getBoundingClientRect();
        const contentRect = contents[0].getBoundingClientRect();
        let availableWidth = wrapperRect.width - contentRect.width;
        // FIRST CASE - there is free space for sidebar
        if (availableWidth >= sidebar.maxWidth) {
            setContentWidth(null, (availableWidth - sidebar.maxWidth) / 2, wrapperRect.width - sidebar.maxWidth);
            sidebarUpdated();
            return { type: 'sidebar', width: sidebar.maxWidth };
        }
        availableWidth = wrapperRect.width - constants.CONTENT_MIN_WIDTH;
        // SECOND CASE - there is no free space even with minimum content width
        let result;
        if (availableWidth < sidebar.minWidth) {
            setContentWidth(null, null);
            result = { type: 'modal' };
        }
        // THIRD CASE - there is free space with calculated content width
        else if (availableWidth > sidebar.maxWidth) {
            setContentWidth(constants.CONTENT_MIN_WIDTH + (availableWidth - sidebar.maxWidth), 0);
            result = { type: 'sidebar', width: sidebar.maxWidth };
        }
        // FOURTH CASE - there is available space with minimum content width
        else {
            setContentWidth(constants.CONTENT_MIN_WIDTH, 0);
            result = { type: 'sidebar', width: availableWidth };
        }
        sidebarUpdated();
        return result;
    }, [elements, $windowSize.width, isVisible]);
    prevOptions.current = options;
    if (!options) {
        return null;
    }
    if (options.type === 'modal') {
        return (_jsx("div", Object.assign({ 
            // ref={scrollLocker.ref}
            onClick: () => $layouts.hideRightSidebar(), className: classNames(styles.modal, isVisible && styles.modalVisible) }, { children: _jsx("div", Object.assign({ className: styles.container, onClick: (e) => e.stopPropagation() }, { children: React.cloneElement(config.component, payload) })) })));
    }
    return (_jsx("div", Object.assign({ 
        // ref={scrollLocker.ref}
        style: {
            bottom: config.bottom,
            width: options.width,
            maxWidth: sidebar.maxWidth,
            minWidth: sidebar.minWidth,
        }, className: classNames(styles.container, isVisible && styles.containerVisible) }, { children: React.cloneElement(config.component, payload) })));
});
const RightSidebar = ({ config, dependencies = [] }) => {
    const isVisible = !$router.hasTag('hideRightSidebar');
    const [elements, setElements] = useState({
        wrapper: null,
        contents: null,
        toolbar: null,
    });
    useEffect(() => {
        const func = () => __awaiter(void 0, void 0, void 0, function* () {
            yield waitForVariable(() => {
                const toolbar = document.querySelector('#toolbar');
                const wrapper = document.querySelector('.content-app-wrapper');
                const contents = wrapper === null || wrapper === void 0 ? void 0 : wrapper.querySelectorAll('.container');
                if (toolbar && wrapper && contents) {
                    setElements({ wrapper, contents, toolbar });
                    return true;
                }
                return false;
            }, 10, 10000);
        });
        func();
        // JSON.stringify(deepCleanObject(config))
    }, [config, ...dependencies]);
    const removeAppliedContentStyles = () => {
        const { toolbar, contents } = elements;
        if (toolbar) {
            toolbar.style.maxWidth = null;
        }
        contents === null || contents === void 0 ? void 0 : contents.forEach((element) => {
            element.style.maxWidth = null;
            element.style.marginLeft = null;
            element.style.marginRight = null;
        });
    };
    useEffect(() => {
        if (!$layouts.rightSidebar.active) {
            removeAppliedContentStyles();
        }
    }, [$layouts.rightSidebar.active]);
    return (_jsx(_Fragment, { children: Object.entries($layouts.rightSidebar.payload).map(([key, item]) => {
            return (config[key] && (_jsx(Sidebar, { payload: item, config: config[key], isVisible: isVisible && $layouts.rightSidebar.active === key, removeElementsStyles: removeAppliedContentStyles, elements: elements }, key)));
        }) }));
};
export default observer(RightSidebar);
