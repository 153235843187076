var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import i18n from 'i18next';
import _ from 'lodash';
import { authService } from 'ziphy-web-shared/basic/api';
import { APP_TYPE, isClientApp } from 'ziphy-web-shared/basic/helpers';
import { $loader, globalLoaderHandlers, localStore, sessionStore, } from 'ziphy-web-shared/basic/utils';
import { analytics, setRG } from 'ziphy-web-shared/basic/utils/analytics';
import { $patients } from '../patients';
import { $practicesGlobalStorage } from '../practices';
export class AuthStore {
    constructor() {
        this.currentRoleId = sessionStore.get('currentRoleId') || localStore.get('currentRoleId');
        this.loggedData = localStore.get('session');
        this.user = null;
        this.account = null;
        this.session = null;
        this.roles = [];
        this.forcedAccessToken = null;
        this.isBound = null;
        makeAutoObservable(this);
    }
    clear() {
        sessionStore.remove('currentRoleId');
        localStore.remove('currentRoleId');
        this.currentRoleId = null;
        localStore.remove('session');
        this.loggedData = null;
        this.user = null;
        this.account = null;
        this.session = null;
        this.roles = [];
        this.forcedAccessToken = null;
        this.isBound = null;
    }
    /*
     * Computed
     */
    get isLogged() {
        var _a;
        return !!((_a = this.user) === null || _a === void 0 ? void 0 : _a.id);
    }
    get role() {
        return this.roles.find((x) => x.id === this.currentRoleId);
    }
    get availableRoles() {
        return this.roles.filter((x) => x.role === APP_TYPE);
    }
    get availablePractices() {
        const practices = {};
        this.availableRoles.forEach((role) => {
            role.servicedPracticeIds.forEach((id) => {
                practices[id] = $practicesGlobalStorage.asObject[id];
            });
        });
        return _.orderBy(Object.values(practices), [(x) => x.id === this.primaryPractice.id, 'name'], ['desc', 'asc']);
    }
    get primaryPractice() {
        return $practicesGlobalStorage.asObject[this.role.practiceId];
    }
    /*
     * Base Actions
     */
    setForcedAccessToken(value) {
        this.forcedAccessToken = value;
    }
    getAccessToken(roleId) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (this.forcedAccessToken)
                return this.forcedAccessToken;
            if (!((_a = this.loggedData) === null || _a === void 0 ? void 0 : _a.refreshToken))
                return null;
            roleId = roleId || this.currentRoleId;
            const forRole = this.loggedData[`forRole_${roleId}`];
            if (forRole) {
                const now = Date.now() / 1000;
                const lastUsed = forRole.lastUsed / 1000;
                const isValid = now - lastUsed < forRole.accessTokenTimeout - 10;
                if (isValid)
                    return forRole.accessToken;
            }
            const response = yield this.refresh(roleId);
            return (_b = response.prepared) === null || _b === void 0 ? void 0 : _b.accessToken;
        });
    }
    // changeRole - left support for role change just in case. Currently relevant only for admin roles.
    refresh(roleId = null, changeRole) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!((_a = this.loggedData) === null || _a === void 0 ? void 0 : _a.refreshToken))
                return null;
            const targetRoleId = roleId || this.currentRoleId;
            const response = yield authService.refresh({ sessionToken: this.loggedData.refreshToken, roleId: targetRoleId }, { loadPractices: !this.role });
            return yield this.prepareResponse(response, changeRole, true);
        });
    }
    // Auth
    sendCode(params, options) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const response = 'patientDos' in params
                ? yield authService.onboard(params, options)
                : yield authService.requestCode(params, options);
            if (((_a = response.error) === null || _a === void 0 ? void 0 : _a.code) === 'error.resource.invalid_status') {
                if (((_b = response.error.data) === null || _b === void 0 ? void 0 : _b.actual) === 'removed') {
                    response.error.message = i18n.t(`alert.account_removed.${params.type}`);
                }
                else {
                    response.error.message = i18n.t(`alert.account_not_exist.${params.type}`);
                }
            }
            // used only for loginByCode
            if (response.prepared) {
                runInAction(() => { var _a; return (this.isBound = (_a = response.prepared) === null || _a === void 0 ? void 0 : _a.isBound); });
            }
            // console.js(params, response.prepared)
            return response;
        });
    }
    loginByCode(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let response;
            if (this.isBound) {
                response = yield authService.login(params, options);
            }
            else {
                response = yield authService.signup(params, options);
            }
            return yield this.prepareResponse(response, true);
        });
    }
    loginByToken(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield authService.loginByToken(params, options);
            return yield this.prepareResponse(response, true);
        });
    }
    logout(deleteSession = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (deleteSession && this.loggedData) {
                yield authService.logout();
            }
            this.clear();
        });
    }
    /*
     * Profile Actions
     *
     * Probably temporary and will be moved further to the $user store
     * after separating token refreshing from profile loading
     *
     * async saveProfile(params: UserCRUD['Update'], options: Options) {}
     * async deleteProfile(params: UserCRUD['Delete'], options: Options) {}
     */
    loadProfile(condition = () => this.isLogged) {
        return __awaiter(this, void 0, void 0, function* () {
            if (condition()) {
                yield this.refresh(this.currentRoleId);
            }
        });
    }
    /*
     * Helpers
     */
    prepareResponse(response, changeRole = false, logoutOnError = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (response.error) {
                if (logoutOnError) {
                    yield this.logout();
                }
                return response;
            }
            // Check role
            if (!this.isCorrectRole(response.prepared)) {
                yield this.logout();
                delete response.prepared;
                response.error = {
                    code: 'account_role',
                    message: i18n.t('alert.account_role.body', { role: 'Provider' }),
                };
                analytics.error('account_role');
                return response;
            }
            // Fill data
            runInAction(() => {
                this.SET_LOGGED_DATA(response.prepared);
                this.user = response.prepared.user;
                this.account = response.prepared.account;
                this.session = response.prepared.session;
                this.roles = response.prepared.roles.items;
                // Change role
                if (changeRole) {
                    this.SET_CURRENT_ROLE_ID(response.prepared.role.id);
                }
            });
            yield Promise.all([this.checkPractices(), this.checkDefaultPatient()]);
            return response;
        });
    }
    isCorrectRole(data) {
        var _a, _b, _c;
        if (((_a = data.role) === null || _a === void 0 ? void 0 : _a.role) === APP_TYPE)
            return true;
        return !!((_c = (_b = data.roles) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c.find((x) => x.role === APP_TYPE));
    }
    SET_LOGGED_DATA(data) {
        const result = Object.assign(Object.assign({}, this.loggedData), { userId: data.user.id, accountId: data.account.id, sessionId: data.session.id, [`forRole_${data.role.id}`]: {
                accessToken: data.accessToken,
                accessTokenTimeout: data.accessTokenTimeout,
                lastUsed: Date.now(),
            }, refreshToken: data.sessionToken, refreshTokenTimeout: data.sessionTokenTimeout });
        this.loggedData = result;
        localStore.set('session', result);
    }
    SET_CURRENT_ROLE_ID(roleId) {
        localStore.set('currentRoleId', roleId);
        sessionStore.set('currentRoleId', roleId);
        this.currentRoleId = roleId;
    }
    checkDefaultPatient() {
        return __awaiter(this, void 0, void 0, function* () {
            if (isClientApp()) {
                const keys = ['avatar', 'firstName', 'lastName', 'gender'];
                const user = _.pick(this.user, keys);
                const patient = _.pick($patients.defaultPatient, keys);
                if (!_.isEqual(user, patient)) {
                    yield $patients.loadDefault();
                }
            }
        });
    }
    checkPractices() {
        return __awaiter(this, void 0, void 0, function* () {
            const practiceIds = new Set();
            this.roles.forEach((role) => {
                const items = [...role.servicedPracticeIds, role.practiceId].filter(Boolean);
                items.forEach((id) => {
                    if (id && !$practicesGlobalStorage.asObject[id])
                        practiceIds.add(id);
                });
            });
            if (practiceIds.size > 0) {
                yield $practicesGlobalStorage.list({ filter: { in: ['id', Array.from(practiceIds)] } });
            }
        });
    }
}
export const $auth = new AuthStore();
reaction(() => { var _a; return (_a = $auth.user) === null || _a === void 0 ? void 0 : _a.id; }, (userId) => {
    if (userId) {
        setRG('setUser', {
            identifier: userId,
            isAnonymous: false,
            fullName: $auth.user.name,
        });
        analytics.startSession(String(userId), {});
        $loader.simpleTrigger(globalLoaderHandlers.LOGIN);
    }
    else {
        setRG('setUser', {
            isAnonymous: true,
        });
        analytics.endSession();
        $loader.simpleTrigger(globalLoaderHandlers.LOGOUT);
    }
});
