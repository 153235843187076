import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import classNames from 'classnames';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { getStylesMode } from 'ziphy-web-shared/basic/helpers';
import styles from './Checkbox.module.scss';
const Checkbox = ({ mode, isMulti, isDisabled, isChecked, className }) => {
    return (_jsx("div", Object.assign({ className: classNames(styles.checkbox, getStylesMode(mode, styles), isMulti && styles.multi, isDisabled && styles.disabled, isChecked && styles.checked, className) }, { children: _jsx(Icon, { className: styles.icon, type: isMulti && !isChecked ? 'plus' : 'check' }) })));
};
export default memo(Checkbox);
