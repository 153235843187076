import React, { useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { formatDate } from 'ziphy-web-shared/basic/utils/time'

import ActorProfileModal from '@library/actorProfile/ActorProfile.modal'
import DtlGroup from '@library/ui/dtl/DtlGroup'
import DtlItem from '@library/ui/dtl/DtlItem'

import styles from '@pages/appt/apptDetails/ApptDetails.module.scss'

import { extendPlace } from '@helpers/addressHelper'
import hAppt from '@helpers/hAppt'

import { $appts, $modal } from '@store'

const ApptDetails = () => {
  const { t } = useTranslation()

  const { fullAppt, status } = $appts.byRoute
  const { appointment, service, place, agent = {}, provider = {}, office } = fullAppt

  const [expanded, setExpanded] = useState(false)

  function openActorProfile(role) {
    $modal.add(ActorProfileModal, { role })
  }

  return (
    <div className={classNames(styles.apptDetails, 'mb-30')}>
      <div className={classNames(styles.content, expanded ? styles.expanded : '')}>
        <DtlGroup className="row row--mb-20">
          <DtlItem className="col-12 col-lg-4" label={t('appt.label.address')}>
            {extendPlace(place).line2()}
          </DtlItem>
          <DtlItem className="col-12 col-lg-4" label={t('appt.label.scheduled_time')}>
            {appointment.appointedStart &&
              formatDate(appointment.appointedStart, { timezone: place.timezone })}
          </DtlItem>
          <DtlItem className="col-12 col-lg-4" label={t('appt.label.type')}>
            {hAppt.typeLabel({ service })}
          </DtlItem>
          <DtlItem className="col-12 col-lg-4" label={t('appt.label.practice')}>
            {hAppt.practiceLabel({ office })}
          </DtlItem>
          <DtlItem className="col-12 col-lg-4" label={t('appt.label.occ')}>
            {status.agentAssigned && (
              <div className="a" onClick={() => openActorProfile('agent')}>
                {agent.name}
              </div>
            )}
          </DtlItem>
          <DtlItem className="col-12 col-lg-4" label={t('appt.label.provider')}>
            {status.providerAssigned && (
              <div className="a" onClick={() => openActorProfile('provider')}>
                {provider.name}
              </div>
            )}
          </DtlItem>
          <DtlItem className="col-12 col-lg-4" label={t('appt.label.booked')}>
            {formatDate(appointment.created, { timezone: place.timezone })}
          </DtlItem>
          <DtlItem className="col-12 col-lg-4" label={t('appt.label.price')}>
            {hAppt.amount({ appointment })}
          </DtlItem>
          <DtlItem className="col-12 col-lg-4" label={t('timezone')}>
            {place.timezone}
          </DtlItem>
          <DtlItem className="col-12 col-lg-4" label={t('appt.label.appointmentId')}>
            {appointment.id}
          </DtlItem>
        </DtlGroup>
      </div>
      <div className={classNames(styles.button, 'a')} onClick={() => setExpanded(!expanded)}>
        {expanded ? t('appt.label.hide') : t('appt.label.see_details')}
      </div>
    </div>
  )
}

export default observer(ApptDetails)
