var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable } from 'mobx';
import { usersService } from 'ziphy-web-shared/basic/api';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import { $loader, globalLoaderHandlers } from 'ziphy-web-shared/basic/utils';
import { $modal } from '@store';
import mainConfig from '@config/main';
import { availableConfigureSteps } from './configure.config';
import ConfigurePreviewModal from './modals/ConfigurePreview.modal';
class ConfigureStore {
    constructor() {
        this.currentStep = null;
        this.steps = [];
        makeAutoObservable(this);
    }
    clear() {
        this.currentStep = null;
        this.steps = [];
    }
    // Computed
    get allSteps() {
        return mainConfig.configureSteps.map((stepName) => {
            const step = availableConfigureSteps.find((x) => x.name === stepName);
            return Object.assign(Object.assign({}, step), { required: step.condition() });
        });
    }
    get dependenciesList() {
        return this.allSteps.flatMap((x) => x.deps());
    }
    get hasRequired() {
        return this.allSteps.every((x) => x.loaded()) && this.allSteps.some((x) => x.required);
    }
    get requiredSteps() {
        return this.steps.map((key) => this.allSteps.find((x) => x.name === key));
    }
    // Actions
    toPrev() {
        const prevStep = this.currentStep - 1;
        if (prevStep < 0) {
            $modal.add(ConfigurePreviewModal, { canDrop: false });
            this.currentStep = -1;
        }
        else {
            const step = this.requiredSteps[prevStep];
            if (step === null || step === void 0 ? void 0 : step.action)
                step.action();
        }
        this.currentStep = prevStep;
    }
    toNext() {
        if (this.currentStep === null) {
            if (!this.steps.length) {
                this.steps = this.allSteps.filter((x) => x.required).map((x) => x.name);
            }
            $modal.add(ConfigurePreviewModal, { canDrop: false });
            this.currentStep = -1;
        }
        else {
            this.currentStep = this.currentStep + 1;
            const step = this.requiredSteps[this.currentStep];
            if (step === null || step === void 0 ? void 0 : step.action) {
                step.action();
            }
            else {
                this.clear();
            }
        }
    }
    setTosAccepted(params, reloadProfile = true) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield usersService.acceptTos(params);
            if (!response.error && reloadProfile) {
                yield $auth.loadProfile();
            }
            return response;
        });
    }
}
export const $configure = new ConfigureStore();
$loader.subscribe(globalLoaderHandlers.LOGOUT, () => {
    $configure.clear();
});
