import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import Badge from '@library/ui/badge/Badge';
import './Breadcrumbs.scss';
const Breadcrumbs = ({ values, className }) => {
    return (_jsx("div", Object.assign({ className: classNames('breadcrumbs', className) }, { children: !_.isEmpty(values) &&
            values.map((x, i) => !_.isEmpty(x) && (_jsx(Badge, Object.assign({ type: "gray", className: "breadcrumbs__badge" }, { children: x }), `breadcrumb-badge-${i}`))) })));
};
export default Breadcrumbs;
