import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { autorun } from 'mobx';
import { useTranslation } from 'react-i18next';
import DocumentUploader from './DocumentUploader';
import { $documentUploader } from './documentUploader.store';
import { DocumentUploaderStatus } from './DocumentUploader.types';
const statusText = {
    [DocumentUploaderStatus.UPLOADING]: 'label.uploading',
    [DocumentUploaderStatus.UPLOADED]: 'label.uploaded',
    [DocumentUploaderStatus.ERROR]: 'label.upload_error',
};
export const useDocumentUploader = (props) => {
    const { t } = useTranslation();
    const [documents, setDocuments] = useState([]);
    const getActions = useCallback((document, index) => [
        {
            label: t('btn.try_again'),
            action: () => $documentUploader.uploadProcess(Object.assign(Object.assign({}, props), { document: document, index })),
        },
        {
            label: t('btn.clear'),
            action: () => $documentUploader.removeDocument(props.patientId, index, props.encounterId),
        },
    ], [...Object.values(props)]);
    useEffect(() => {
        const disposer = autorun(() => {
            const documents = $documentUploader.getDocumentsList(props.patientId, props.encounterId);
            const prepared = documents.map((item, index) => (Object.assign(Object.assign({}, item), { isLoading: item.status === DocumentUploaderStatus.UPLOADING, hasError: item.status === DocumentUploaderStatus.ERROR, statusText: t(statusText[item.status]), actions: item.status === DocumentUploaderStatus.ERROR ? getActions(item, index) : undefined })));
            setDocuments(prepared);
        });
        return () => disposer();
    }, [...Object.values(props)]);
    const Uploader = useCallback(({ children }) => (_jsx(DocumentUploader, Object.assign({}, props, { children: children }))), [...Object.values(props)]);
    return { documents: documents, DocumentUploader: Uploader };
};
