import { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react-lite'

import _ from 'lodash'
import moment from 'moment-timezone'
import { myackAxiosInstance } from 'ziphy-web-shared/basic/api'
import { APP_REVISION, APP_VERSION, PUBLIC_URL } from 'ziphy-web-shared/basic/helpers'

import { $modal } from '@store'

import DeprecationModal from './Deprecation.modal'

const CHECK_API_INTERVAL = 1800 * 1000 // 1 hour

const ApiDeprecationChecker = () => {
  const lastDeprecationCheck = useRef(null)
  const [updateNeeded, setUpdateNeeded] = useState(false)
  const [notSupported, setNotSupported] = useState(false)

  const checkActualBuildVersion = async () => {
    const currentBuild = {
      version: APP_VERSION,
      revision: APP_REVISION,
    }
    const response = await myackAxiosInstance.get(
      `${PUBLIC_URL}/.build.json?timestamp=${new Date().getTime()}`,
      {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      },
    )
    const lastBuild = response.data

    if (!_.isEmpty(lastBuild) && currentBuild.revision !== lastBuild.revision) {
      const currentVersion = parseInt(currentBuild.version.split('.').join(''))
      const deprecatedVersion = parseInt(lastBuild.versionDeprecation.split('.').join(''))

      if (currentVersion < deprecatedVersion) {
        setNotSupported(true)
        setUpdateNeeded(true)
      } else {
        setNotSupported(false)
        setUpdateNeeded(true)
      }
    }
  }

  useEffect(() => {
    const check = () => {
      const lastCheck = lastDeprecationCheck.current
      const diff = Math.abs(moment(lastCheck).diff(moment()))

      if ((lastCheck && diff > CHECK_API_INTERVAL) || !lastCheck) {
        checkActualBuildVersion().finally(() => {
          lastDeprecationCheck.current = moment()
          setTimeout(check, CHECK_API_INTERVAL)
        })
      } else {
        setTimeout(check, CHECK_API_INTERVAL - diff)
      }
    }

    check()
  }, [])

  const onCancelUpdate = () => {
    setNotSupported(false)
    setUpdateNeeded(false)
  }

  useEffect(() => {
    if (updateNeeded) {
      $modal.add(DeprecationModal, { canDrop: false, onLeave: onCancelUpdate, notSupported })
    }
  }, [updateNeeded]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default observer(ApiDeprecationChecker)
