import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { CustomLink } from 'ziphy-web-shared/basic/lib/buttons';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
import Vitals from '@ps/assets/svg/vitals';
import { eventsSchema } from '@ps/config/events';
import { usePsPatientLink } from '@ps/hooks';
import { $psHistory } from '@store';
const PatientNavigation = () => {
    const { t } = useTranslation();
    const psPatientLink = usePsPatientLink();
    function handleChangeTab(x) {
        const categoryId = x ? _.kebabCase(x) : null;
        return psPatientLink({ categoryId });
    }
    const preparedList = useMemo(() => {
        let result = [];
        if ($windowSize.isMobile) {
            result.push({
                id: 'vitals',
                label: t('ps.label.vitals'),
                color: '#f3a7a8',
                icon: Vitals,
                count: false,
            });
        }
        _.forEach(_.orderBy(eventsSchema, 'order', 'asc'), (x) => {
            const count = _.get($psHistory.eventsBySubcategory, [x.id + '_all'], []).length;
            if (count) {
                result.push(Object.assign(Object.assign({}, x), { label: t(x.label), count }));
            }
        });
        return result;
    }, [$windowSize.isMobile, $psHistory.eventsBySubcategory]);
    return (_jsx("div", Object.assign({ className: "ps_patient_nav mb-30" }, { children: _jsx("div", Object.assign({ className: "row row--gutters-10" }, { children: preparedList.map((item) => (_jsx("div", Object.assign({ className: "col-12 col-lg-4" }, { children: _jsxs(CustomLink, Object.assign({ autoTarget: handleChangeTab(item.id), className: "ps_patient_nav-item" }, { children: [_jsx("div", Object.assign({ className: "ps_patient_nav-item-icon", style: { backgroundColor: item.color } }, { children: _jsx(item.icon, {}) })), _jsxs("div", Object.assign({ className: "ps_patient_nav-item-label title-sm mb-0" }, { children: [item.label, " ", _jsx("span", { children: item.count })] }))] })) }), item.id))) })) })));
};
export default observer(PatientNavigation);
