import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { DropDown } from 'ziphy-web-shared/basic/lib/functional';
import { usePsPatientLink } from '@ps/hooks';
import { $psPatient } from '@store';
import styles from './ChangePsPatientPractice.module.scss';
const ChangePsPatientPractice = () => {
    const psPatientLink = usePsPatientLink();
    const currentPractice = $psPatient.practices.find((x) => x.isCurrent);
    if (!currentPractice)
        return _jsx(_Fragment, {});
    return (_jsx(DropDown, { classNameItem: styles.dropdown, renderButton: ({ ref, onToggle }) => (_jsx("div", Object.assign({ ref: ref, onClick: () => onToggle(), className: styles.dropdownButton }, { children: _jsx("div", { children: currentPractice.name }) }))), items: $psPatient.practices.map((item) => ({
            id: item.zid,
            label: item.name,
            selected: item.isCurrent,
            autoTarget: psPatientLink({ practiceId: item.zid }),
        })), alwaysBottom: true }));
};
export default observer(ChangePsPatientPractice);
