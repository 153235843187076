var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { isEqualIds } from 'ziphy-web-shared/basic/helpers';
import { useIntervalTimer, useLoader, useParsedParams } from 'ziphy-web-shared/basic/hooks';
import { WithLoaderWrapper } from 'ziphy-web-shared/basic/lib/loaders';
import { PageNoResults, Title } from 'ziphy-web-shared/basic/lib/simple';
import { $windowSize, globalLoaderHandlers, useLoaderSubscribe } from 'ziphy-web-shared/basic/utils';
import { $router } from 'ziphy-web-shared/basic/utils/router';
import { usePageErrorBoundary } from '@library/providers/PageProvider';
import Toolbar from '@nedoShared/features/layouts/toolbar/Toolbar';
import ApptConference from '@pages/appt/apptConference/ApptConference';
import ApptContacts from '@pages/appt/apptContacts/ApptContacts';
import ApptControl from '@pages/appt/apptControl/ApptControl';
import ApptDesc from '@pages/appt/apptDesc/ApptDesc';
import ApptDetails from '@pages/appt/apptDetails/ApptDetails';
import ApptDocuments from '@pages/appt/apptDocuments/ApptDocuments';
import ApptMap from '@pages/appt/apptMap/ApptMap';
import ApptNotes from '@pages/appt/apptNotes/ApptNotes';
import ApptOfficeNote from '@pages/appt/apptOfficeNote/ApptOfficeNote';
import ApptStatus from '@pages/appt/apptStatus/ApptStatus';
import ApptSymptoms from '@pages/appt/ApptSymptoms';
import ApptPatient from '@pages/appt/patient/ApptPatient';
import PatientsList from '@pages/appt/patientsList/PatientsList';
import hAppt from '@helpers/hAppt';
import { $appts } from '@store';
import { routeActions } from '@config/routeActions';
const Appt = ({ hasVideo, hasOfficeNote }) => {
    const { t } = useTranslation();
    const errorBoundary = usePageErrorBoundary();
    const { appointmentId, encounterId } = useParsedParams();
    const { fullAppt, status, actions } = $appts.byRoute;
    const { appointment, encounters, patients } = fullAppt;
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield $appts.getOne({ id: appointmentId });
        if (!response.isSuccess)
            return errorBoundary.error();
    }), [appointmentId]);
    // todo: remove useIntervalTimer and use only eventsListener
    const timer = useIntervalTimer({
        callback: () => __awaiter(void 0, void 0, void 0, function* () { return yield $appts.getOne({ id: appointmentId }); }),
        duration: 60000,
        autoStart: false,
        deps: [appointmentId],
    });
    useLoaderSubscribe(globalLoaderHandlers.EVENT, (event) => __awaiter(void 0, void 0, void 0, function* () {
        if (event.appointmentId !== appointmentId || event.isChatMessage)
            return;
        yield $appts.getOne({ id: appointmentId });
    }));
    useEffect(() => (status.isPast ? timer.stop() : timer.start()), [status.isPast]);
    useEffect(() => () => $appts.clearByRoute(), []);
    useEffect(() => {
        if (isLoading || !appointment || !isEqualIds(appointmentId, appointment.id))
            return;
        let props = null;
        if (appointment.encounterIds.length && !appointment.encounterIds.includes(encounterId)) {
            props = { appointmentId: appointment.id, encounterIds: appointment.encounterIds }; // If there are encounters on the appointment, but it is indicated as unknown.
        }
        else if (appointment.encounterIds.length === 0 && encounterId) {
            props = { appointmentId: appointment.id }; // If there are no encounters on the appointment, but it is specified in the URL.
        }
        if (props) {
            $router.executeAutoTarget(routeActions.APPT(props), { type: 'replace' });
        }
    }, [isLoading, appointment, appointmentId, encounterId]);
    const isShowVideo = hasVideo && !$windowSize.isMobileOnly && actions.startVideo && status.innerVideoStarted;
    const groupType = hAppt.getGroupTypeKey(appointment, patients);
    const activeEncounter = encounters === null || encounters === void 0 ? void 0 : encounters[encounterId];
    const activePatient = patients === null || patients === void 0 ? void 0 : patients[activeEncounter === null || activeEncounter === void 0 ? void 0 : activeEncounter.patientId];
    return (_jsxs(WithLoaderWrapper, Object.assign({ isLoading: isLoading, loaderMode: 'pageWrapper', className: "content" }, { children: [_jsx(Toolbar, { goBackProps: { autoTarget: routeActions.APPTS() } }), _jsx("div", Object.assign({ className: "container container-overflow-x-hidden h-100" }, { children: _jsxs("div", Object.assign({ className: "box box--full-height appt_details_root" }, { children: [_jsx("div", Object.assign({ className: "box-header" }, { children: _jsx(ApptStatus, {}) })), _jsxs("div", Object.assign({ className: "box-body box-body--padding" }, { children: [isShowVideo ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "appt_video_target embed-responsive embed-responsive-16by9" }), _jsx(ApptControl, {})] })) : (_jsx(_Fragment, { children: _jsx(ApptMap, {}) })), _jsx(ApptDesc, {}), _jsx(ApptDetails, {}), hasOfficeNote && _jsx(ApptOfficeNote, {}), hasVideo && _jsx(ApptConference, {}), _jsx(ApptContacts, {}), groupType && (_jsxs(_Fragment, { children: [_jsx(Title, Object.assign({ size: 'h2', className: 'mb-10' }, { children: t(`appt_type.${groupType}`) })), groupType !== 'individual' && !_.isEmpty(patients) && (_jsx(PatientsList, { encounterId: encounterId, appointment: appointment, patients: patients }))] })), activePatient && (_jsxs(_Fragment, { children: [_jsx(ApptPatient, {}), _jsx(ApptSymptoms, { byRoute: $appts.byRoute }), _jsx(ApptDocuments, { activePatient: activePatient }), _jsx(ApptNotes, { byRoute: $appts.byRoute, className: "max_width--lg" })] })), groupType === 'group' && !appointment.encounterIds.length && (_jsx(PageNoResults, { children: t('appt.no_encounters') }))] }))] })) }))] })));
};
export default observer(Appt);
