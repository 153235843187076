var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { IMask } from 'react-imask';
import moment from 'moment-timezone';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { $time } from 'ziphy-web-shared/basic/utils/time';
import InputBase from '@library/form/fields/new/InputBase';
import NewDatePickerModal from '@library/newDatePicker/NewDatePicker.modal';
import { $modal } from '@store';
const InputDate = (_a) => {
    var { minDate, maxDate, type = 'selectDay', timezone = $time.timezone, scrollTo, visibleFormat = 'MM/DD/YYYY', placeholder = visibleFormat, field } = _a, props = __rest(_a, ["minDate", "maxDate", "type", "timezone", "scrollTo", "visibleFormat", "placeholder", "field"]);
    const innerValue = useMemo(() => {
        if (type === 'selectDateTime') {
            return field.value && moment(field.value).tz(timezone).format(visibleFormat);
        }
        else {
            return field.value && moment(field.value).format(visibleFormat);
        }
    }, [field.value, timezone, type, visibleFormat]);
    minDate = useMemo(() => {
        return minDate ? moment(minDate) : moment().subtract(100, 'year');
    }, [minDate]);
    maxDate = useMemo(() => {
        return maxDate ? moment(maxDate) : moment().add(100, 'year');
    }, [maxDate]);
    function handleOnChange(value) {
        if (moment(value, ['YYYY-MM-DD', visibleFormat, 'YYYY-MM-DDTHH:mm:ss.sssZ'], true).isValid()) {
            if (type === 'selectDateTime') {
                field.onChange(value);
            }
            else {
                value = moment(value, ['YYYY-MM-DD', visibleFormat]).format('YYYY-MM-DD');
                field.onChange(value);
            }
        }
        else if (value === '') {
            field.onChange('');
        }
    }
    function getSelectedDate() {
        const currentDate = moment().format();
        let result;
        if (innerValue) {
            result = innerValue;
        }
        else {
            result = moment(maxDate).isAfter(currentDate) ? currentDate : maxDate;
        }
        return result;
    }
    return (_jsx(InputBase, Object.assign({ field: Object.assign(Object.assign({}, field), { value: innerValue, onChange: handleOnChange }), placeholder: placeholder, icon: !props.readonly && {
            class: 'calendar',
            action: () => $modal.add(NewDatePickerModal, {
                selectedDate: getSelectedDate(),
                onChange: handleOnChange,
                minDate,
                maxDate,
                type,
                scrollTo,
            }),
            img: _jsx(Icon, { type: "calendar" }),
        } }, props, { mask: {
            mask: Date,
            pattern: visibleFormat,
            lazy: true,
            autofix: true,
            min: minDate.toDate(),
            max: maxDate.toDate(),
            format(date) {
                return moment(date).format(visibleFormat);
            },
            parse(str) {
                return moment(str, visibleFormat);
            },
            blocks: {
                YYYY: {
                    mask: IMask.MaskedRange,
                    from: Number(minDate.format('YYYY')),
                    to: Number(maxDate.format('YYYY')),
                    maxLength: 4,
                },
                MM: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 12,
                    maxLength: 2,
                },
                DD: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 31,
                    maxLength: 2,
                },
            },
        } })));
};
export default observer(InputDate);
