var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class WebSocketPollingListener {
    constructor({ wsUrl, getToken, onEvent, onPolling, pollingInterval = 5000, reconnectInterval = 10000, }) {
        this.ws = null;
        this.isEnabled = false;
        this.pollingTimer = null;
        this.reconnectTimer = null;
        this.wsUrl = wsUrl;
        this.getToken = getToken;
        this.onEvent = onEvent;
        this.onPolling = onPolling;
        this.pollingInterval = pollingInterval;
        this.reconnectInterval = reconnectInterval;
    }
    start() {
        if (this.isEnabled)
            return;
        this.isEnabled = true;
        this.startWebSocket();
    }
    stop() {
        if (!this.isEnabled)
            return;
        this.isEnabled = false;
        this.stopWebSocket();
        this.stopReconnect();
        this.stopPolling();
    }
    switchToPolling(reconnect = true) {
        this.stopWebSocket();
        this.stopReconnect();
        if (reconnect) {
            this.startReconnect();
        }
        this.startPolling();
    }
    /**
     * Reconnect WebSocket
     */
    startReconnect() {
        if (this.reconnectTimer || !this.isEnabled)
            return;
        this.reconnectTimer = setTimeout(() => {
            if (this.isEnabled && !this.ws) {
                this.startWebSocket();
            }
        }, this.reconnectInterval);
    }
    stopReconnect() {
        if (this.reconnectTimer) {
            clearTimeout(this.reconnectTimer);
            this.reconnectTimer = null;
        }
    }
    /**
     * WebSocket
     */
    startWebSocket() {
        try {
            this.ws = new WebSocket(this.wsUrl);
            this.ws.onopen = () => __awaiter(this, void 0, void 0, function* () {
                var _a;
                try {
                    const token = yield this.getToken();
                    if (token) {
                        (_a = this.ws) === null || _a === void 0 ? void 0 : _a.send(token);
                        this.stopReconnect();
                        this.stopPolling();
                    }
                    else {
                        this.switchToPolling();
                    }
                }
                catch (err) {
                    this.switchToPolling();
                }
            });
            this.ws.onmessage = (message) => {
                const event = JSON.parse(message.data);
                this.onEvent(event);
            };
            this.ws.onerror = (error) => this.switchToPolling();
            this.ws.onclose = () => this.switchToPolling();
        }
        catch (error) {
            this.switchToPolling();
        }
    }
    stopWebSocket() {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
    }
    /**
     * Polling
     */
    startPolling() {
        if (this.pollingTimer || !this.isEnabled)
            return;
        this.pollingTimer = setTimeout(() => __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.onPolling();
            }
            catch (err) {
                // console.error('Polling error:', err)
            }
            finally {
                this.pollingTimer = null;
                if (this.isEnabled && !this.ws) {
                    this.startPolling();
                }
            }
        }), this.pollingInterval);
    }
    stopPolling() {
        if (this.pollingTimer) {
            clearTimeout(this.pollingTimer);
            this.pollingTimer = null;
        }
    }
}
