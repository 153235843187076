import React, { Fragment, useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useResizeDetector } from 'react-resize-detector'

import classNames from 'classnames'
import _ from 'lodash'

import { $appts } from '@store'

const ApptStatus = () => {
  const { statuses, status } = $appts.byRoute

  const [shift, setShift] = useState(0)
  const { ref, width = 0 } = useResizeDetector({ handleHeight: false })

  const shiftStyles = useMemo(() => {
    return shift ? { transform: 'translateX(-' + shift + 'px)' } : {}
  }, [shift])

  useEffect(() => {
    status && setShift(ref?.current?.offsetLeft || 0)
  }, [width, status])

  return (
    <div className="appt_status" style={shiftStyles}>
      {statuses.map((status, index) => (
        <Fragment key={status.id}>
          <div
            ref={status.current ? ref : undefined}
            className={classNames(
              'appt_status-item title-lg',
              (status.previous || status.current) && 'appt_status-item--active',
            )}
          >
            {_.get(status, 'texts.status')}
          </div>
          {index < statuses.length - 1 && <div className="appt_status-delimiter" />}
        </Fragment>
      ))}
    </div>
  )
}

export default observer(ApptStatus)
