export function calculatePositions(container, compareMode = 'both') {
    const items = Array.from(container.children);
    const positions = items.map((item) => {
        const rect = item.getBoundingClientRect();
        return { top: rect.top, bottom: rect.bottom };
    });
    let currentLineTop = positions[0].top;
    let currentLineBottom = positions[0].bottom;
    let lineIndex = 0;
    const stack = [];
    positions.forEach((pos, index) => {
        const matchesTop = pos.top === currentLineTop;
        const matchesBottom = pos.bottom === currentLineBottom;
        if ((compareMode === 'top' && !matchesTop) ||
            (compareMode === 'bottom' && !matchesBottom) ||
            (compareMode === 'both' && !matchesTop && !matchesBottom)) {
            currentLineTop = pos.top;
            currentLineBottom = pos.bottom;
            lineIndex++;
        }
        stack[index] = lineIndex;
    });
    return stack;
}
export const getClassNames = (type, lines, line, isFirstInLine, isLastInLine) => {
    let result = [];
    if (type === 'simple') {
        if (line === 0) {
            if (isFirstInLine)
                result.push('topLeft');
            if (isLastInLine)
                result.push('topRight');
        }
        if (line === lines - 1) {
            if (isFirstInLine)
                result.push('bottomLeft');
            if (isLastInLine)
                result.push('bottomRight');
        }
        return result;
    }
    const isFirst = isFirstInLine && !isLastInLine;
    const isLast = !isFirstInLine && isLastInLine;
    const isMiddle = !isFirstInLine && !isLastInLine;
    const isSingle = isFirstInLine && isLastInLine;
    if (lines === 1) {
        if (isFirst)
            result.push('firstInSingleLine');
        else if (isLast)
            result.push('lastInSingleLine');
        else if (isMiddle)
            result.push('middleInSingleLine');
        else if (isSingle)
            result.push('singleInSingleLine');
    }
    else {
        if (line === 0) {
            if (isFirst)
                result.push('firstInTopLine');
            else if (isLast)
                result.push('lastInTopLine');
            else if (isMiddle)
                result.push('middleInTopLine');
            else if (isSingle)
                result.push('singleInTopLine');
        }
        else if (line === lines - 1) {
            if (isFirst)
                result.push('firstInBottomLine');
            else if (isLast)
                result.push('lastInBottomLine');
            else if (isMiddle)
                result.push('middleInBottomLine');
            else if (isSingle)
                result.push('singleInBottomLine');
        }
        else {
            if (isFirst)
                result.push('firstInMiddleLine');
            else if (isLast)
                result.push('lastInMiddleLine');
            else if (isMiddle)
                result.push('middleInMiddleLine');
            else if (isSingle)
                result.push('singleInMiddleLine');
        }
    }
    return result;
};
