import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { cloneElement, isValidElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useChangeLanguage } from 'ziphy-web-shared/basic/utils/i18n';
import SelectModal from '@library/select/Select.modal';
import { $modal } from '@store';
const ChangeLanguage = ({ children, min = 2 }) => {
    const { t, i18n } = useTranslation();
    const { availableLangs, onChangeLang } = useChangeLanguage();
    if (availableLangs.length < min || !isValidElement(children)) {
        return _jsx(_Fragment, {});
    }
    function handleOnClick() {
        return $modal.add(SelectModal, {
            title: t('label.language'),
            items: availableLangs,
            value: [i18n.language],
            allowUnselect: false,
            onChange: (v) => onChangeLang(v[0]),
        });
    }
    return cloneElement(children, { onClick: handleOnClick });
};
export default ChangeLanguage;
