import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useInnerValue } from 'ziphy-web-shared/basic/hooks';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import mainConfig from '@config/main';
import AuthTosText from './AuthTosText';
import LoginForm from './LoginForm';
const LoginModal = ({ view: _view, accountType: _accountType, invitationCode, _core }) => {
    const { t } = useTranslation();
    const [view, setView] = useInnerValue(_view);
    const [accountType, setAccountType] = useInnerValue(_accountType);
    const canReturn = view !== 'default' && (_view === undefined || _view === 'default');
    return (_jsxs(Modal, Object.assign({ centered: true }, { children: [_jsxs("div", Object.assign({ className: "modal-toolbar" }, { children: [canReturn && _jsx(ModalToolbarBtn, { type: "back", action: () => setView('default') }), _jsx(ModalToolbarBtn, { type: "close", action: _core.onHide })] })), _jsx(Modal.Header, Object.assign({ className: canReturn && 'text-center' }, { children: view === 'default'
                    ? t('label.enter_app_name', { name: mainConfig.appName })
                    : t('label.create_account') })), _jsx(Modal.Body, { children: _jsx(LoginForm, { view: view, onChangeView: setView, accountType: accountType, onChangeAccountType: setAccountType, invitationCode: invitationCode, hasOnboarding: true, _core: _core }) }), _jsx(Modal.Footer, { children: _jsx(AuthTosText, {}) })] })));
};
LoginModal.displayName = 'LoginModal';
export default observer(LoginModal);
