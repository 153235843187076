import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useLayoutEffect } from 'react';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
const SignoutPage = () => {
    useLayoutEffect(() => {
        $auth.logout();
    }, []);
    return _jsx(_Fragment, {});
};
export default SignoutPage;
