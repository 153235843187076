var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import PatientsList from '@nedoShared/features/patients/patientController/patientsList/PatientsList';
import { $booking } from '@store';
import styles from './SelectPatient.modal.module.scss';
const SelectPatientModal = ({ selectedIds = [], onSelect, _core }) => {
    const { t } = useTranslation();
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        return yield $booking.patients.get();
    }));
    const items = $booking.patients.originalItems.filter((x) => !selectedIds.includes(x.id));
    function handleOnSelect(item) {
        onSelect(item);
        _core.onHide();
    }
    return (_jsxs(Modal, Object.assign({ centered: true, scrollable: true }, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: _core.onHide }) })), _jsx(Modal.Header, { children: t('book.select_patient') }), _jsx(Modal.Body, Object.assign({ isLoading: isLoading, className: styles.modalBody }, { children: _jsx(PatientsList, { items: items, onClick: handleOnSelect, withSearch: true, isModal: true, addOnDone: handleOnSelect }) }))] })));
};
SelectPatientModal.displayName = 'SelectPatientModal';
export default observer(SelectPatientModal);
