var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { $patients } from 'ziphy-web-shared/basic/entities/patients';
import { useLoader, useParsedParams } from 'ziphy-web-shared/basic/hooks';
import { WithLoaderWrapper } from 'ziphy-web-shared/basic/lib/loaders';
import { Title } from 'ziphy-web-shared/basic/lib/simple';
import { usePageErrorBoundary } from '@library/providers/PageProvider';
import AddressController from '@nedoShared/features/addresses/addressController/AddressController';
import PatientContactsController from '@nedoShared/features/contacts/contactsController/ContactsController';
import InsuranceController from '@nedoShared/features/insurances/insuranceController/InsuranceController';
import Toolbar from '@nedoShared/features/layouts/toolbar/Toolbar';
import CocPatientDetails from '@nedoShared/features/patients/patientController/cocPatient/CocPatientDetails';
import CocPatientHeader from '@nedoShared/features/patients/patientController/cocPatient/CocPatientHeader';
import PsPatientPractices from '@ps/library/widgets/psPatientPractices/PsPatientPractices';
import { routeActions } from '@config/routeActions';
const CocPatientPage = () => {
    const { t } = useTranslation();
    const errorBoundary = usePageErrorBoundary();
    const { patientId } = useParsedParams();
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield $patients.read({ id: patientId }, { cacheTime: 30, skipAlert: true });
        if (response.error) {
            return errorBoundary.error();
        }
    }));
    const patient = $patients.getById(patientId);
    return (_jsxs(WithLoaderWrapper, Object.assign({ isLoading: isLoading, loaderMode: 'pageWrapper', className: "content" }, { children: [_jsx(Toolbar, { goBackProps: { autoTarget: routeActions.PATIENTS() } }), _jsx("div", Object.assign({ className: "container" }, { children: _jsxs("div", Object.assign({ className: "max_width--lg" }, { children: [_jsx(CocPatientHeader, { patient: patient }), _jsx(CocPatientDetails, { patient: patient, className: 'mt-30' }), _jsx(Title, Object.assign({ size: 'h2', className: 'mb-20 mt-30' }, { children: t('label.contacts') })), _jsx(PatientContactsController, { patient: patient }), _jsx(InsuranceController, { patient: patient, className: 'mt-30' }), _jsx(AddressController, { patient: patient, className: 'mt-30' }), _jsx(Title, Object.assign({ size: 'h2', className: 'mb-20 mt-30' }, { children: t('label.practices') })), _jsx(PsPatientPractices, { patientId: patientId })] })) }))] })));
};
export default observer(CocPatientPage);
