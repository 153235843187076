import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { GoogleMap, Marker } from '@react-google-maps/api'
import { observer } from 'mobx-react-lite'

import maps from '@library/map/Map'
import mapStyles from '@library/map/mapStyles.json'

import PointMarker from '@assets/images/map/point-marker.svg'

const defaultOptions = {
  styles: mapStyles,
  zoom: 15,
  disableDefaultUI: true,
  zoomControl: true,
}

const AddPlaceModalMap = ({ coords = {}, isDetails, onChange, onClick }) => {
  const mapRef = useRef()
  const [defaultCenter] = useState(coords)
  const [marker, setMarker] = useState(coords)

  const options = useMemo(() => {
    if (isDetails) {
      return { ...defaultOptions, gestureHandling: 'none', zoomControl: false }
    }
    return { ...defaultOptions, gestureHandling: 'auto', zoomControl: true }
  }, [isDetails])

  const handleTouchStart = useCallback(() => {
    if (isDetails) {
      onClick()
      return false
    }
  }, [isDetails, onClick])

  const handleOnClick = useCallback(
    (e) => {
      if (isDetails) {
        return false
      }

      let newPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() }

      mapRef.current.panTo(newPosition)
      setMarker(newPosition)

      onChange(newPosition)

      let zoom = mapRef.current.getZoom()
      if (zoom < 17) {
        mapRef.current.setZoom(Math.min(zoom + 2, 17))
      }
    },
    [mapRef, isDetails, onChange],
  )

  const handleOnLoad = useCallback(
    (map) => {
      mapRef.current = map
    },
    [mapRef],
  )

  function changeFromOutside() {
    if (!mapRef.current) {
      return false
    }

    let center = mapRef.current.center
    let coordsOnMap = { lat: center.lat(), lng: center.lng() }

    if (!maps.isEqualCoords(coordsOnMap, coords)) {
      mapRef.current.panTo(coords)
      setMarker(coords)
    }
  }

  useEffect(() => changeFromOutside(), [coords]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => isDetails && changeFromOutside(), [isDetails]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="g-map g-map--static">
      {/*<span style={{ position: 'absolute', zIndex: 1, bottom: 0, left: 0, background: '#ffffff' }}>*/}
      {/*  {JSON.stringify(marker)}*/}
      {/*</span>*/}
      {!_.isEmpty(defaultCenter) && (
        <GoogleMap
          mapContainerClassName={'g-map-inner'}
          center={defaultCenter}
          options={options}
          onClick={handleOnClick}
          onLoad={handleOnLoad}
          onMouseDown={handleTouchStart}
          onTouchStart={handleTouchStart}
        >
          <Marker position={marker} icon={PointMarker} />)
        </GoogleMap>
      )}
    </div>
  )
}

export default observer(AddPlaceModalMap)
