import React from 'react'

import { useTranslation } from 'react-i18next'

import styles from './CategorizedSymptoms.module.scss'

const CategorizedSymptoms = ({ items, onSelectCategory }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="simple_header mb-10 mt-20">
        <div className="title-sm mb-0">{t('symptoms.groups.categories')}</div>
      </div>

      <div className={styles.group}>
        <div className={styles.itemsContainer}>
          {items.map((item) => (
            <div key={item.category} className={styles.item} onClick={() => onSelectCategory(item)}>
              <span className={styles.icon} />
              {item.category}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default CategorizedSymptoms
