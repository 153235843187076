import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { $modal } from '@store';
import { ModalViewerContext } from './ModalViewer.context';
const ModalViewer = () => {
    let modals = [];
    _.forEach($modal.current, (item) => {
        const template = item._core.template;
        if (_.isObject(template)) {
            modals.push({ component: template, item });
        }
        else {
            console.error('The template "' + template + '" is missing.');
            return;
        }
    });
    return (_jsx("div", Object.assign({ className: "modal-root" }, { children: modals.map((modal) => {
            const Component = modal.component;
            return (_jsx(ModalViewerContext.Provider, Object.assign({ value: modal.item._core }, { children: _jsx(Component, Object.assign({}, modal.item)) }), modal.item._core.id));
        }) })));
};
export default observer(ModalViewer);
