import React, { useMemo, useRef } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useResizeDetector } from 'react-resize-detector'

import classNames from 'classnames'
import { getUserText } from 'ziphy-web-shared/basic/helpers'
import { WithLoaderWrapper } from 'ziphy-web-shared/basic/lib/loaders'
import { Title } from 'ziphy-web-shared/basic/lib/simple'
import { $windowSize } from 'ziphy-web-shared/basic/utils'
import { $layouts } from 'ziphy-web-shared/basic/utils/layouts'

import Breadcrumbs from '@library/ui/breadcrumbs/Breadcrumbs'

import Toolbar from '@nedoShared/features/layouts/toolbar/Toolbar'

import { $booking } from '@store'

import styles from './BookingLayout.module.scss'

const BookingLayout = ({
  children,
  button,
  breadcrumbs = [],
  title,
  isMobileFooterFixed,
  isDesktopFooterFixed,
  isLoading,
  className,
}) => {
  const { t } = useTranslation()
  const footerRef = useRef()

  const { height } = useResizeDetector({
    targetRef: footerRef,
    handleWidth: false,
  })

  const breadcrumbsValues = useMemo(() => {
    return breadcrumbs.map((item) => {
      switch (item) {
        case 'place':
          return $booking.place && $booking.placeExtended.buildingStreetCity()
        case 'service':
          return $booking.service && $booking.service.name
        case 'patients':
          return $booking.patients.items.length > 1
            ? t('book.patients', { count: $booking.patients.items.length })
            : getUserText($booking.patients.items[0], ['fullName', 'gender', 'age'])
      }
    })
  }, [])

  const prevStep = $booking.schemaConfig?.prevStep && $booking.schemaConfig?.prevStep()

  return (
    <WithLoaderWrapper
      isLoading={isLoading}
      loaderMode={'pageWrapper'}
      className={classNames('content', className)}
    >
      <Toolbar goBackProps={prevStep ? { autoTarget: prevStep } : null} />
      <div className={'container'}>
        <div
          style={{
            paddingBottom: height ? height + ($windowSize.isMobileSize ? 20 : 40) : undefined,
          }}
          className={classNames(
            styles.body,
            styles.maxWidth860,
            'box',
            'box--full-height',
            isDesktopFooterFixed && 'box--desktop-footer-fixed',
            isMobileFooterFixed && 'box--mobile-footer-fixed',
          )}
        >
          {breadcrumbs.length > 0 && <Breadcrumbs values={breadcrumbsValues} />}
          <Title size={'h1'} className={'mb-10'}>
            {title}
          </Title>
          <div className="box-body box-body--padding">{children}</div>
          {button && (
            <div
              ref={footerRef}
              className={classNames(styles.footer, 'box-footer')}
              style={{
                paddingBottom: $layouts.marginBottom
                  ? $layouts.marginBottom + ($windowSize.isMobileSize ? 15 : 30)
                  : undefined,
              }}
            >
              {isDesktopFooterFixed || (isMobileFooterFixed && $windowSize.isMobileSize) ? (
                <div className={'container'}>
                  <div className={styles.maxWidth860}>{button}</div>
                </div>
              ) : (
                button
              )}
            </div>
          )}
        </div>
      </div>
    </WithLoaderWrapper>
  )
}
export default observer(BookingLayout)
