var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { isEqualIds } from 'ziphy-web-shared/basic/helpers';
import { useLoader, useParsedParams } from 'ziphy-web-shared/basic/hooks';
import { usePageErrorBoundary } from '@library/providers/PageProvider';
import { $psHistory, $psPatient, $psResources } from '@store';
export const useInitPsPatient = ({ callback } = {}) => {
    var _a;
    const errorBoundary = usePageErrorBoundary();
    const params = useParsedParams();
    const { patientId, practiceId, categoryId } = params;
    const { isLoading, reload } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        if (!isEqualIds(patientId, (_b = $psPatient.demographics) === null || _b === void 0 ? void 0 : _b.zid)) {
            $psPatient.clear();
            $psResources.clear();
        }
        yield $psPatient.loadData();
        if (!$psPatient.demographics) {
            return errorBoundary.error();
        }
        if (typeof callback === 'function') {
            yield callback({ params, errorBoundary });
        }
        yield $psPatient.loadPractices();
    }), [patientId, practiceId]);
    useEffect(() => {
        $psHistory.clearSearch();
        $psHistory.SET_CATEGORY(categoryId);
        $psHistory.clearSubcategory();
        if (categoryId) {
            $psHistory.loadEvents();
        }
        else {
            $psPatient.loadData();
        }
    }, [categoryId]);
    const patient = $psPatient.patient;
    const TabComponent = $psHistory.category && ((_a = $psHistory.categoryConfig) === null || _a === void 0 ? void 0 : _a.component);
    return {
        isLoading,
        reload,
        patient,
        TabComponent,
        patientId,
        practiceId,
        categoryId,
    };
};
