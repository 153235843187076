import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { getStylesMode } from 'ziphy-web-shared/basic/helpers';
import styles from './Icon.module.scss';
import { default as audio, ReactComponent as Audio } from './ui/audio.svg';
import { default as back, ReactComponent as Back } from './ui/back.svg';
import { default as bell, ReactComponent as Bell } from './ui/bell.svg';
import { default as burger, ReactComponent as Burger } from './ui/burger.svg';
import { default as calendar, ReactComponent as Calendar } from './ui/calendar.svg';
import { default as carTravel, ReactComponent as CarTravel } from './ui/car-travel.svg';
import { default as check, ReactComponent as Check } from './ui/check.svg';
import { default as checks, ReactComponent as Checks } from './ui/checks.svg';
import { default as clipboardList, ReactComponent as ClipboardList } from './ui/clipboard-list.svg';
import { default as close, ReactComponent as Close } from './ui/close.svg';
import { default as copy, ReactComponent as Copy } from './ui/copy.svg';
import { default as document, ReactComponent as Document } from './ui/document.svg';
import { default as down, ReactComponent as Down } from './ui/down.svg';
import { default as edit, ReactComponent as Edit } from './ui/edit.svg';
import { default as email, ReactComponent as Email } from './ui/email.svg';
import { default as filtersList, ReactComponent as FiltersList } from './ui/filters-list.svg';
import { default as geoscape, ReactComponent as Geoscape } from './ui/geoscape.svg';
import { default as home, ReactComponent as Home } from './ui/home.svg';
import { default as image, ReactComponent as Image } from './ui/image.svg';
import { default as info, ReactComponent as Info } from './ui/info.svg';
import { default as linkArrow, ReactComponent as LinkArrow } from './ui/link-arrow.svg';
import { default as list, ReactComponent as List } from './ui/list.svg';
import { default as locationMarker, ReactComponent as LocationMarker, } from './ui/location-marker.svg';
import { default as location, ReactComponent as Location } from './ui/location.svg';
import { default as moveLeftArrow, ReactComponent as MoveLeftArrow } from './ui/move-left-arrow.svg';
import { default as moveRightArrow, ReactComponent as MoveRightArrow, } from './ui/move-right-arrow.svg';
import { default as next, ReactComponent as Next } from './ui/next.svg';
import { default as notification, ReactComponent as Notification } from './ui/notification.svg';
import { default as phone, ReactComponent as Phone } from './ui/phone.svg';
import { default as plus, ReactComponent as Plus } from './ui/plus.svg';
import { default as settings2, ReactComponent as Settings2 } from './ui/settings2.svg';
import { default as settings, ReactComponent as Settings } from './ui/settings.svg';
import { default as trash, ReactComponent as Trash } from './ui/trash.svg';
import { default as user, ReactComponent as User } from './ui/user.svg';
import { default as users, ReactComponent as Users } from './ui/users.svg';
import { default as voucher, ReactComponent as Voucher } from './ui/voucher.svg';
import { default as wallet, ReactComponent as Wallet } from './ui/wallet.svg';
const Icon = ({ tag = 'svg', mode, alt = '', type, className, color, size = 28, width = size, height = size, onClick, }) => {
    const isSvg = tag === 'svg';
    let result = { img: '', svg: null };
    if (type === 'audio')
        result = { img: audio, svg: Audio };
    else if (type === 'back')
        result = { img: back, svg: Back };
    else if (type === 'bell')
        result = { img: bell, svg: Bell };
    else if (type === 'burger')
        result = { img: burger, svg: Burger };
    else if (type === 'calendar')
        result = { img: calendar, svg: Calendar };
    else if (type === 'check')
        result = { img: check, svg: Check };
    else if (type === 'checks')
        result = { img: checks, svg: Checks };
    else if (type === 'clipboardList')
        result = { img: clipboardList, svg: ClipboardList };
    else if (type === 'close')
        result = { img: close, svg: Close };
    else if (type === 'copy')
        result = { img: copy, svg: Copy };
    else if (type === 'document')
        result = { img: document, svg: Document };
    else if (type === 'down')
        result = { img: down, svg: Down };
    else if (type === 'plus')
        result = { img: plus, svg: Plus };
    else if (type === 'trash')
        result = { img: trash, svg: Trash };
    else if (type === 'phone')
        result = { img: phone, svg: Phone };
    else if (type === 'edit')
        result = { img: edit, svg: Edit };
    else if (type === 'info')
        result = { img: info, svg: Info };
    else if (type === 'linkArrow')
        result = { img: linkArrow, svg: LinkArrow };
    else if (type === 'location')
        result = { img: location, svg: Location };
    else if (type === 'locationMarker')
        result = { img: locationMarker, svg: LocationMarker };
    else if (type === 'next')
        result = { img: next, svg: Next };
    else if (type === 'email')
        result = { img: email, svg: Email };
    else if (type === 'filtersList')
        result = { img: filtersList, svg: FiltersList };
    else if (type === 'geoscape')
        result = { img: geoscape, svg: Geoscape };
    else if (type === 'image')
        result = { img: image, svg: Image };
    else if (type === 'notification')
        result = { img: notification, svg: Notification };
    else if (type === 'settings')
        result = { img: settings, svg: Settings };
    else if (type === 'settings2')
        result = { img: settings2, svg: Settings2 };
    else if (type === 'carTravel')
        result = { img: carTravel, svg: CarTravel };
    else if (type === 'moveLeftArrow')
        result = { img: moveLeftArrow, svg: MoveLeftArrow };
    else if (type === 'moveRightArrow')
        result = { img: moveRightArrow, svg: MoveRightArrow };
    else if (type === 'home')
        result = { img: home, svg: Home };
    else if (type === 'list')
        result = { img: list, svg: List };
    else if (type === 'user')
        result = { img: user, svg: User };
    else if (type === 'users')
        result = { img: users, svg: Users };
    else if (type === 'voucher')
        result = { img: voucher, svg: Voucher };
    else if (type === 'wallet')
        result = { img: wallet, svg: Wallet };
    if (isSvg && result.svg) {
        return (_jsx(result.svg, { width: width, height: height, className: classNames(mode && getStylesMode(mode, styles), className), color: color, onClick: onClick }));
    }
    if (result.img) {
        return (_jsx("img", { src: result.img, alt: alt, width: width, height: height, className: className, onClick: onClick }));
    }
    return null;
};
export default Icon;
