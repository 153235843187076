import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { $contactsContexts } from 'ziphy-web-shared/basic/entities/contacts';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import { $modal } from '@store';
import EmailModal from '../systemsModal/Email.modal';
import PhoneModal from '../systemsModal/Phone.modal';
import styles from './SelectContactSystem.module.scss';
const SelectContactSystemModal = ({ patientId, _core }) => {
    const { t } = useTranslation();
    const $controller = $contactsContexts.get(patientId);
    const phoneLimit = Object.values($controller.contactsLimit.phone).reduce((a, b) => a + b, 0);
    const emailLimit = Object.values($controller.contactsLimit.email).reduce((a, b) => a + b, 0);
    return (_jsxs(Modal, Object.assign({ centered: true, scrollable: true }, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: _core.onHide }) })), _jsx(Modal.Header, Object.assign({ className: 'text-center' }, { children: t('label.add_contact') })), _jsx(Modal.Body, { children: _jsxs("div", Object.assign({ className: styles.buttons }, { children: [_jsx(ButtonLegacy, Object.assign({ autoTarget: {
                                onClick: () => {
                                    _core.onHide();
                                    $modal.add(PhoneModal, { patientId });
                                },
                            }, mode: ['secondary-dark'], disabled: phoneLimit <= 0 }, { children: t('label.phone') })), _jsx(ButtonLegacy, Object.assign({ autoTarget: {
                                onClick: () => {
                                    _core.onHide();
                                    $modal.add(EmailModal, { patientId });
                                },
                            }, mode: ['secondary-dark'], disabled: emailLimit <= 0 }, { children: t('label.email') }))] })) })] })));
};
SelectContactSystemModal.displayName = 'SelectContactSystemModal';
export default observer(SelectContactSystemModal);
