import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './ShowMoreDetails.module.scss';
const ShowMoreDetails = ({ items = [], collapsedLabel = '', expanded: _expanded = false, className = '', }) => {
    const [expanded, setExpanded] = useState(_expanded);
    function handleOpen() {
        setExpanded(!expanded);
    }
    if (!items.length) {
        return _jsx(_Fragment, {});
    }
    if (items.length === 1) {
        return items[0].value;
    }
    return (_jsx("div", Object.assign({ className: classNames(styles.wrapper, className) }, { children: !expanded ? (_jsx("div", Object.assign({ className: styles.button, onClick: handleOpen }, { children: collapsedLabel || items[0].value }))) : (items.map((item, index) => (_jsxs("div", Object.assign({ className: styles.line }, { children: [item.label, " ", item.value] }), item.id || [index, ...Object.values(item)].join('_'))))) })));
};
export default ShowMoreDetails;
