import React from 'react'

import { observer } from 'mobx-react-lite'

import { Checkbox } from 'ziphy-web-shared/basic/lib/simple'

import { $booking } from '@store'

import * as imgs from '@assets/images/symptoms'

import styles from './CommonSymptoms.module.scss'

const CommonSymptoms = ({ items }) => {
  return (
    <div className={styles.container}>
      {items.map((symptom) => (
        <div className={styles.symptomContainer} key={symptom.id}>
          <label className={styles.label}>
            <input
              type="checkbox"
              checked={$booking.$symptoms.isSelected(symptom.id)}
              onChange={() => $booking.$symptoms.TOGGLE_SELECTED(symptom.id)}
            />
            <img
              src={imgs[symptom.img]}
              srcSet={imgs[symptom.img + '2X'] + ' 2x, ' + imgs[symptom.img + '3X'] + ' 3x'}
              alt=""
            />
            <Checkbox
              mode={['round', 'small']}
              className={styles.checkbox}
              isChecked={$booking.$symptoms.isSelected(symptom.id)}
              isMulti={true}
            />
            <span className={styles.text}>{symptom.name}</span>
          </label>
        </div>
      ))}
    </div>
  )
}

export default observer(CommonSymptoms)
