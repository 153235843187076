var TrendsListChartHelpers;
(function (TrendsListChartHelpers) {
    const getDatesDifferent = (minDate, maxDate) => Math.abs(new Date(maxDate).getTime() - new Date(minDate).getTime());
    TrendsListChartHelpers.getRangeOfDates = (minDate, maxDate, count) => {
        count -= 1;
        const gap = getDatesDifferent(minDate, maxDate) / count;
        const result = [new Date(minDate)];
        for (let i = 0; i < count; i++)
            result.push(new Date(result[i].getTime() + gap));
        return result;
    };
})(TrendsListChartHelpers || (TrendsListChartHelpers = {}));
export default TrendsListChartHelpers;
