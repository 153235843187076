var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import { $router } from 'ziphy-web-shared/basic/utils/router';
import { $notices } from '@store';
const NotificationsButton = (_a) => {
    var props = __rest(_a, []);
    const { t } = useTranslation();
    const { notice = {}, btn = {}, onClose, isPopup } = props;
    const { type, to, action, label: _label = '', viewOnClick = true } = btn;
    const label = t('btn.' + (_label || type));
    function handleOnClick(e) {
        return __awaiter(this, void 0, void 0, function* () {
            e.stopPropagation();
            if (viewOnClick) {
                $notices.view({ ids: [notice.id] });
            }
            if (_.isFunction(action)) {
                yield action();
            }
            if (onClose && _.isFunction(onClose)) {
                onClose();
            }
        });
    }
    let className = 'btn btn-white btn-xs';
    if (isPopup) {
        className = 'btn btn-block btn-gray btn-xs';
    }
    return _.isArray(to) ? (_jsx(Link, Object.assign({ to: $router.toPath(...to), className: classNames(className), onClick: handleOnClick }, { children: label }))) : _.isString(to) ? (_jsx("a", Object.assign({ href: to, className: classNames(className), onClick: handleOnClick }, { children: label }))) : (_jsx("button", Object.assign({ className: classNames(className), onClick: handleOnClick }, { children: label })));
};
export default observer(NotificationsButton);
