import React from 'react'

import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'
import { TOS_GENERAL } from '@library/plugins/termsOfService/Tos.config'
import { useLoadJson } from '@library/plugins/termsOfService/Tos.hooks'
import { TosHeader } from '@library/plugins/termsOfService/TosHeader'
import { TosText } from '@library/plugins/termsOfService/TosText'

const TosModal = ({ type = TOS_GENERAL, _core }) => {
  const { json, isLoading, extractRef } = useLoadJson({ type })

  return (
    <Modal centered scrollable size="xl">
      <div className="modal-toolbar">
        <ModalToolbarBtn type="close" action={_core.onHide} />
      </div>
      <Modal.Header>
        <TosHeader type={type} />
      </Modal.Header>
      <Modal.Body isLoading={isLoading} className="styled_scroll">
        <TosText type={type} json={json} extractRef={extractRef} />
      </Modal.Body>
    </Modal>
  )
}

TosModal.displayName = 'Tos'

export default TosModal
