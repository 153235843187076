var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
const CustomLink = forwardRef((props, ref) => {
    const { autoTarget, to = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.to, href = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.href, hrefTarget = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.hrefTarget, onClick = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.onClick, wvAction = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.wvAction, type, id, form, disabled, className, style, children, params, } = props;
    function handleOnClick(event) {
        return __awaiter(this, void 0, void 0, function* () {
            if (disabled) {
                event.preventDefault();
                return;
            }
            if (typeof wvAction === 'function') {
                yield wvAction(event);
            }
            if (typeof onClick === 'function') {
                yield onClick(event);
            }
        });
    }
    if (href) {
        return (_jsx("a", Object.assign({ id: id, ref: ref, href: href, onClick: handleOnClick, className: className, target: hrefTarget, style: style }, params, { children: children })));
    }
    if (to) {
        if (type === 'nav') {
            return (_jsx(NavLink, Object.assign({ id: id, ref: ref, to: to, onClick: handleOnClick, className: className, target: hrefTarget, style: style }, params, { children: children })));
        }
        return (_jsx(Link, Object.assign({ id: id, ref: ref, to: to, onClick: handleOnClick, className: className, target: hrefTarget, style: style }, params, { children: children })));
    }
    if (form) {
        return (_jsx("button", Object.assign({ id: id, ref: ref, onClick: handleOnClick, className: className, style: style, type: type === 'nav' ? undefined : type }, params, { children: children })));
    }
    return (_jsx("div", Object.assign({ id: id, ref: ref, onClick: handleOnClick, className: className, style: style }, params, { children: children })));
});
export default CustomLink;
