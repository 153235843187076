import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import { $router } from 'ziphy-web-shared/basic/utils/router';
export function useLoginForm({ redirectAfterComplete, _core }) {
    const location = useLocation();
    const { referrer } = queryString.parse(location.search);
    const successUrl = referrer ? { to: $router.toPath(referrer) } : redirectAfterComplete;
    function handleComplete() {
        if (successUrl) {
            $router.executeAutoTarget(successUrl);
        }
        _core === null || _core === void 0 ? void 0 : _core.onUnblock();
        _core === null || _core === void 0 ? void 0 : _core.onHide();
    }
    useEffect(() => {
        if ($auth.isLogged) {
            handleComplete();
        }
    }, [$auth.isLogged]);
    return {
        referrer,
        handleOnComplete: handleComplete,
    };
}
