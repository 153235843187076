import _ from 'lodash';
export const trendsListConfig = {
    bloodPressure: {
        label: 'trends.title.blood_pressure',
        code: '35094-2',
        chart: [
            {
                lineColor: 'rgba(53, 211, 254, 1)',
                backgroundColor: 'rgba(196, 237, 248)',
            },
            {
                lineColor: 'rgba(216, 141, 218, 1)',
                backgroundColor: 'rgba(216, 141, 218, 0.25)',
            },
        ],
    },
    oxygenSaturation: {
        label: 'trends.title.saturation',
        code: '59408-5',
        chart: [
            {
                lineColor: 'rgba(53, 211, 254, 1)',
                backgroundColor: 'rgba(53, 211, 254, 0.25)',
            },
        ],
    },
    heartRate: {
        label: 'ps.notes.vitals.label.pulse_bpm',
        code: '8867-4',
        chart: [
            {
                lineColor: 'rgba(242, 198, 180, 1)',
                backgroundColor: 'rgba(242, 198, 180, 0.25)',
            },
        ],
    },
    bodyMassIndex: {
        label: 'trends.title.bmi',
        code: 'bmi',
        chart: [
            {
                lineColor: 'rgba(112, 221, 103, 1)',
                backgroundColor: 'rgba(112, 221, 103, 0.25)',
            },
        ],
    },
    bodyHeight: {
        label: 'label.height_ft&in',
        code: '8302-2',
        chart: [
            {
                lineColor: 'rgba(117, 147, 246, 1)',
                backgroundColor: 'rgba(117, 147, 246, 0.25)',
            },
        ],
    },
    bodyWeight: {
        label: 'label.weight_lbs',
        code: '29463-7',
        chart: [
            {
                lineColor: 'rgba(216, 141, 218, 1)',
                backgroundColor: 'rgba(216, 141, 218, 0.25)',
            },
        ],
    },
    bodyTemperature: {
        label: 'trends.title.temperature',
        code: '8310-5',
        chart: [
            {
                lineColor: 'rgba(216, 141, 218, 1)',
                backgroundColor: 'rgba(216, 141, 218, 0.25)',
            },
        ],
    },
};
export const trendsListConfigValues = Object.values(trendsListConfig);
export const trendsListConfigMap = new Map();
_.forEach(trendsListConfigValues, (item) => {
    trendsListConfigMap.set(item.code, item);
});
