import React from 'react'

import { observer } from 'mobx-react-lite'
import { Trans, useTranslation } from 'react-i18next'

import { $windowSize } from 'ziphy-web-shared/basic/utils'

import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import { $appts } from '@store'

const JoinConferenceModal = ({ _core }) => {
  const { t } = useTranslation()

  const { fullAppt, actions = {} } = $appts.byRoute
  const { appointment } = fullAppt

  function startClient(source) {
    if (actions.startVideo) {
      actions.startVideo.action({ source })
    }
  }

  function textToChat(message) {
    if (actions.textGeneral) {
      actions.textGeneral.action(message)
    } else if (actions.textStaff) {
      actions.textStaff.action(message)
    }
  }

  function handleClose() {
    _core.onHide()
  }

  return (
    <Modal centered scrollable onHide={() => $appts.getOne({ id: appointment.id })}>
      <div className="modal-toolbar">
        <ModalToolbarBtn type="close" action={_core.onHide} />
      </div>
      <Modal.Header>{t('appt_join_conference.title')}</Modal.Header>
      <Modal.Body>
        <div className="mb-30">
          <Trans
            i18nKey="appt_join_conference.message_client"
            components={[
              <a
                href="https://support.zoom.us/hc/en-us/articles/206175806"
                target="_blank"
                rel="noreferrer"
              />,
            ]}
          />
        </div>
        <div className="title-sm mb-20">{t('appt_join_conference.ok_title')}</div>

        <div className="btn-group btn-group--column">
          {$windowSize.isMobile ? (
            <>
              <ButtonLegacy
                mode={['secondary', 'block']}
                action={() => startClient('app')}
                label={t('appt_join_conference.btn.restart.app')}
              />
              <ButtonLegacy
                mode={['secondary', 'block']}
                action={() => startClient('browser')}
                label={t('appt_join_conference.btn.restart.browser')}
              />
            </>
          ) : (
            <ButtonLegacy
              mode={['secondary', 'block']}
              action={() => startClient('auto')}
              label={t('appt_join_conference.btn.restart.video')}
            />
          )}

          <ButtonLegacy
            mode={['secondary', 'block']}
            action={() => textToChat(t('appt_join_conference.problem.could_not_start'))}
            label={t('appt_join_conference.btn.could_not_start')}
          />
          <ButtonLegacy
            mode={['secondary', 'block']}
            action={() => textToChat(t('appt_join_conference.problem.connection'))}
            label={t('appt_join_conference.btn.connection')}
          />
          <ButtonLegacy
            mode={['primary', 'block']}
            action={handleClose}
            label={t('appt_join_conference.btn.done')}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default observer(JoinConferenceModal)
