import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import _ from 'lodash'
import { formatAmount, T } from 'ziphy-web-shared/basic/helpers'

import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'
import Badge from '@library/ui/badge/Badge'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import { $booking } from '@store'

import PatientGroupTypes from '../patientGroupTypes/PatientGroupTypes'
import styles from './ServiceInfo.modal.module.scss'

const ServiceInfoModal = ({ serviceId, onBook, _core }) => {
  const { t } = useTranslation()
  const service = $booking.$services.getServiceById(serviceId)

  const preparedPrices = useMemo(() => {
    let result = []

    let items = _.filter(
      $booking.practiceServices,
      (x) => x.serviceId === serviceId && $booking.availablePracticeIds.includes(x.practiceId),
    )
    if (!items.length) return result

    items = _.orderBy(_.uniqBy(items, 'price'), 'price', 'asc')

    if (items.length > 1) {
      result = [_.head(items), _.last(items)]
    } else {
      result = [_.head(items)]
    }

    return result.map((x, i) => {
      // add currency only for last value
      if (i === result.length - 1) {
        return formatAmount(x.price, x.currency)
      }
      return formatAmount(x.price, '')
    })
  }, [serviceId])

  return (
    <Modal centered scrollable size={'lg'}>
      <div className="modal-toolbar">
        <ModalToolbarBtn type="close" action={_core.onHide} />
      </div>
      <Modal.Header titleClassName="title-md">{service.name}</Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <div className={styles.badgesContainer}>
          <PatientGroupTypes service={service} mode="round" />
          <Badge type={['round', 'gray-fill']}>{preparedPrices.join(' - ')}</Badge>
        </div>
        <div className={styles.description}>
          <T className="text-group text-group--lg text-pre-line">{service.description}</T>
        </div>
        <ButtonLegacy
          mode="primary"
          className={styles.bookButton}
          disabled={service.disabled}
          action={(e) => onBook(e, service.id)}
        >
          {t('btn.book_appointment')}
        </ButtonLegacy>
      </Modal.Body>
    </Modal>
  )
}

export default ServiceInfoModal
