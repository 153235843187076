var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseService } from '../baseService';
export class Patients extends BaseService {
    constructor(props) {
        super(props);
    }
    create(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patients.create', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.patient });
        });
    }
    read(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patients.read', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.patient });
        });
    }
    update(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patients.update', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.patient });
        });
    }
    delete(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patients.delete', params, options);
            return res;
        });
    }
    //
    list(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patients.list', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: ((_a = res.prepared) === null || _a === void 0 ? void 0 : _a.patients.items) || [] });
        });
    }
    acceptTos(params, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patients.accept_tos', params, options);
            return Object.assign(Object.assign({}, res), { preparedValue: (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.patient });
        });
    }
    /*
     * Custom Methods
     */
    getDefault(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.list({ filter: { eq: ['is_default', true] }, limit: 1 }, Object.assign({ addition: 'default' }, options));
        });
    }
    getActiveList(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.list(Object.assign({ filter: { or: [{ eq: ['is_default', true] }, { ne: ['is_hidden', true] }] }, order: [
                    ['is_default', 'desc'],
                    ['id', 'desc'],
                ] }, params), options);
        });
    }
    deleteAnyway(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.delete(params, Object.assign({ skipAlert: true }, options));
            if (res.error) {
                res = yield this.update(Object.assign(Object.assign({}, params), { isHidden: true }), options);
            }
            return res;
        });
    }
    getOnboardCode(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patients.get_onboard_code', params, options);
            return res;
        });
    }
}
