import React from 'react'

import { Route, Switch } from 'react-router-dom'

import { getInitConfig } from 'ziphy-web-shared'

const PageSwitcher = () => {
  return (
    <Switch>
      {getInitConfig().routes.map((route, index) => (
        <Route
          key={route.path + index}
          path={route.path}
          exact={route.exact}
          render={() => {
            return route.element
          }}
        />
      ))}
    </Switch>
  )
}

export default PageSwitcher
