import React, { useEffect, useMemo } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import _ from 'lodash'
import { CustomLink } from 'ziphy-web-shared/basic/lib/buttons'
import { $windowSize } from 'ziphy-web-shared/basic/utils'
import { $layouts } from 'ziphy-web-shared/basic/utils/layouts'
import { $router } from 'ziphy-web-shared/basic/utils/router'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import { extendPlace } from '@helpers/addressHelper'
import hAppt from '@helpers/hAppt'

import { $appts } from '@store'

import { routeActions } from '@config/routeActions'

import logoSimpleWhite from '@assets/images/logo-simple-white.svg'

import styles from './NearestAppt.module.scss'

const NearestAppt = () => {
  const { t } = useTranslation()

  const nearestAppt = useMemo(() => {
    if ($appts.activeList.length) {
      let fullAppt
      let status

      _.find($appts.activeList, (x) => {
        status = hAppt.getCurrentStatus({ fullAppt: x })

        if (!status.isHasClientConfirmation) {
          return (fullAppt = x)
        }
      })

      if (fullAppt) {
        return { fullAppt, status }
      }
    }

    return false
  }, [$appts.activeList])

  const status = nearestAppt?.status
  const appointment = nearestAppt?.fullAppt?.appointment
  const place = nearestAppt?.fullAppt?.place

  const text = useMemo(() => {
    if (_.isEmpty(status)) {
      return false
    }

    const result = [status.texts.nearestStart]

    if ($windowSize.minMD) {
      result.push(status.texts.status)
    }
    return result.join(', ')
  }, [status, $windowSize.minMD]) // eslint-disable-line react-hooks/exhaustive-deps

  const apptPath = useMemo(() => {
    if (_.isEmpty(appointment)) {
      return ''
    }

    return routeActions.APPT({
      appointmentId: appointment.id,
      encounterIds: appointment.encounterIds,
    })
  }, [appointment])

  const nearestBarIsVisible = (() => {
    const nearestId = nearestAppt?.fullAppt?.appointment.id
    if (!nearestId) return false

    return !$router.hasTag('hideNearestBar')
  })()

  useEffect(() => {
    if (nearestBarIsVisible) {
      $layouts.SET_MARGIN_BOTTOM(88)
    } else {
      $layouts.SET_MARGIN_BOTTOM(0)
    }
    return () => {
      $layouts.SET_MARGIN_BOTTOM(0)
    }
  }, [nearestBarIsVisible]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classNames(styles.nearestAppt, nearestBarIsVisible && styles.nearestApptVisible)}
    >
      {text && (
        <div className="container">
          <div className={styles.nearestApptWrapper}>
            <div className={styles.nearestApptLogo}>
              <img src={logoSimpleWhite} alt="" />
            </div>
            <div className={styles.nearestApptContent}>
              <div className={styles.nearestApptContentText}>
                <div className="font-weight-bold">{text}</div>
                {$windowSize.minSM && extendPlace(place).shortAddress()}
              </div>
              <div className={styles.nearestApptContentButtons}>
                {$windowSize.minMD ? (
                  <ButtonLegacy
                    mode={['primary', 'outline']}
                    autoTarget={apptPath}
                    label={t('btn.view_details')}
                  />
                ) : (
                  <CustomLink autoTarget={apptPath} className={styles.nearestApptContentBtn} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(NearestAppt)
