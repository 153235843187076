var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { action, makeAutoObservable, runInAction } from 'mobx';
import _ from 'lodash';
import { getUuid } from 'ziphy-web-shared/basic/helpers';
class LoaderStore {
    constructor() {
        this.registry = {};
        this.subscribers = {};
        makeAutoObservable(this);
    }
    registerHandler(id, callback) {
        return (...args) => __awaiter(this, void 0, void 0, function* () {
            const { beforeStart, beforeFinish, afterFinish } = this.getSubscribesByHandlerId(id);
            runInAction(() => (this.registry[id] = { isLoading: true }));
            if (beforeStart.length) {
                yield Promise.all(beforeStart.map((x) => x.callback()));
            }
            const result = yield callback(...args);
            if (beforeFinish.length) {
                yield Promise.all(beforeFinish.map((x) => x.callback(result)));
            }
            runInAction(() => (this.registry[id] = { isLoading: false }));
            if (afterFinish.length) {
                afterFinish.forEach((x) => x.callback(result));
            }
            return result;
        });
    }
    isRunHandler(handler) {
        var _a;
        return !!((_a = this.registry[handler]) === null || _a === void 0 ? void 0 : _a.isLoading);
    }
    simpleTrigger(handler, payload) {
        this.registerHandler(handler, () => payload)();
    }
    subscribe(handler, callback, type = 'beforeFinish') {
        const handlers = _.isArray(handler) ? handler : [handler];
        const ids = [];
        handlers.forEach((x) => {
            const item = { id: getUuid(), callback: action(callback), type: type };
            ids.push(item.id);
            if (this.subscribers[x]) {
                this.subscribers[x].push(item);
            }
            else {
                this.subscribers[x] = [item];
            }
        });
        return _.isArray(handler) ? ids : ids[0];
    }
    unsubscribe(handler, subscribeId) {
        const handlers = _.isArray(handler) ? handler : [handler];
        const ids = _.isArray(subscribeId) ? subscribeId : [subscribeId].filter((x) => x);
        handlers.forEach((x) => {
            if (this.subscribers[x]) {
                if (ids.length) {
                    let filtered = this.subscribers[x].filter((x) => !ids.includes(x.id));
                    if (filtered.length) {
                        this.subscribers[x] = filtered;
                    }
                    else {
                        delete this.subscribers[x];
                    }
                }
                else {
                    delete this.subscribers[x];
                }
            }
        });
    }
    getSubscribesByHandlerId(handlerId) {
        let result = {
            beforeStart: [],
            beforeFinish: [],
            afterFinish: [],
        };
        if (this.subscribers[handlerId]) {
            result.beforeStart = this.subscribers[handlerId].filter((x) => x.type === 'beforeStart');
            result.beforeFinish = this.subscribers[handlerId].filter((x) => x.type === 'beforeFinish');
            result.afterFinish = this.subscribers[handlerId].filter((x) => x.type === 'afterFinish');
        }
        return result;
    }
}
export const $loader = new LoaderStore();
export function useLoaderSubscribe(ids, callback, type, deps = []) {
    useEffect(() => {
        const subIds = $loader.subscribe(ids, callback, type);
        return () => $loader.unsubscribe(ids, subIds);
    }, deps);
}
export const globalLoaderHandlers = {
    LOGIN: 'global-auth-login',
    LOGOUT: 'global-auth-logout',
    NEW_MINUTE: 'global-new-minute',
    EVENT: 'global-listened-event',
    //
    NOTES_SAVE_MEDICAL_CONDITION: 'notes-save-medical-condition',
    NOTES_SAVE_MEDICATION: 'notes-save-medication',
    NOTES_SAVE_ASSESSMENTS: 'notes-save-assessments',
};
