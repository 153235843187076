import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { $modal } from '@store';
import DocumentUploaderModal from './DocumentUploader.modal';
const DocumentUploader = ({ patientId, encounterId, baseFileName, children }) => {
    const inputRef = useRef();
    useEffect(() => {
        const changeFile = (e) => {
            const target = e.target;
            if (target.files[0]) {
                $modal.add(DocumentUploaderModal, {
                    patientId,
                    encounterId,
                    baseFileName,
                    file: target.files[0],
                });
            }
        };
        if (inputRef.current) {
            inputRef.current.removeEventListener('change', changeFile);
            inputRef.current.addEventListener('change', changeFile);
        }
        return () => { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('change', changeFile); };
    }, [inputRef.current, encounterId]);
    const openUploadModal = useCallback(() => {
        inputRef.current.value = null;
        inputRef.current.click();
    }, [inputRef.current, encounterId]);
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ style: { display: 'none' } }, { children: _jsx("input", { ref: inputRef, type: "file", name: "file" }) })), React.cloneElement(children, { onClick: openUploadModal })] }));
};
export default observer(DocumentUploader);
