import React from 'react'

import { observer } from 'mobx-react-lite'
import { Trans, useTranslation } from 'react-i18next'

import _ from 'lodash'

import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'

import { $booking } from '@store'

import styles from './OfficesPhones.modal.module.scss'

const OfficesPhonesModal = ({ _core }) => {
  const { t } = useTranslation()

  return (
    <Modal centered scrollable core={_core}>
      <div className="modal-toolbar">
        <ModalToolbarBtn type="close" action={_core.onHide} />
      </div>
      <Modal.Header>{t('book.ph.symptoms')}</Modal.Header>
      <Modal.Body>
        <Trans i18nKey={'book.symptoms.more_info_needed'} components={[<br />]} />
        {_.map($booking.offices, (item) => (
          <div className={styles.officeItem} key={item.id}>
            <span className={styles.name}>{item.name}</span>
            <a href={`tel:${item.phone}`} className={styles.phone}>
              {item.phone}
            </a>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  )
}

export default observer(OfficesPhonesModal)
