var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { $accounts } from 'ziphy-web-shared/basic/entities/accounts';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import { getPhoneNumberDetails } from '@library/phoneNumber/PhoneNumber';
import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import styles from './AccountsList.module.scss';
const AccountsList = ({ isLoading, setView, setCurrent, _core }) => {
    const { t } = useTranslation();
    const deleteContact = (item) => {
        const isCurrent = $auth.account.id === item.id;
        confirmAction('customAlert', {
            data: {
                title: t('profile_contacts.remove_alert.title'),
                message: isCurrent
                    ? t('profile_contacts.remove_alert.message_current')
                    : t('profile_contacts.remove_alert.message'),
            },
            callback: () => __awaiter(void 0, void 0, void 0, function* () {
                const response = yield $accounts.delete({ id: item.id });
                if (isCurrent && !response.error) {
                    yield $auth.logout();
                }
            }),
        });
    };
    const primaryAccounts = $accounts.items.filter((x) => x.primary);
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: _core.onHide }) })), _jsx(Modal.Header, { children: t('label.emails_&_phones') }), _jsxs(Modal.Body, Object.assign({ isLoading: isLoading }, { children: [_jsx("div", Object.assign({ className: styles.contactList }, { children: $accounts.items.map((item) => (_jsxs("div", Object.assign({ className: styles.contactListItem }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: item.type === 'phone' ? getPhoneNumberDetails(item.value).formatted : item.value })), _jsxs("div", Object.assign({ className: styles.label }, { children: [t('profile_contacts.type.' + item.type), item.primary && _jsxs(_Fragment, { children: [", ", item.primary && t('profile_contacts.type.primary')] }), item.status === 'new' && _jsxs(_Fragment, { children: [", ", t('profile_contacts.type.inactive')] })] })), _jsxs("div", Object.assign({ className: classNames(styles.buttons) }, { children: [!item.primary && (item.type === 'phone' || item.type === 'email') && (_jsx(ButtonLegacy, { mode: ['white', 'sm'], action: () => __awaiter(void 0, void 0, void 0, function* () { return yield $accounts.setPrimary({ id: item.id }); }), label: t('btn.set_as_primary') })), !(item.primary && primaryAccounts.length === 1) && (_jsx(ButtonLegacy, { mode: ['white', 'sm'], action: () => deleteContact(item), label: t('btn.delete') })), item.status === 'new' && (_jsxs(_Fragment, { children: [_jsx(ButtonLegacy, { mode: ['white', 'sm'], action: () => {
                                                        setCurrent(item);
                                                        if (item.type === 'phone')
                                                            setView('phoneActivate');
                                                        if (item.type === 'email')
                                                            setView('emailActivate');
                                                    }, label: t('btn.activate') }), _jsx(ButtonLegacy, { mode: ['white', 'sm'], action: () => deleteContact(item), label: t('btn.delete') })] }))] }))] }), item.id))) })), _jsx("div", Object.assign({ className: "mt-20" }, { children: _jsx(ButtonLegacy, { mode: ['create', 'block'], action: () => setView('channel'), label: t('profile_contacts.btn.add_channel') }) }))] }))] }));
};
export default observer(AccountsList);
