var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import { $patients } from 'ziphy-web-shared/basic/entities/patients';
import { ENUMS_PERSON_GENDER } from 'ziphy-web-shared/basic/entities/persons';
import { personAgeLimit } from 'ziphy-web-shared/basic/helpers';
import { useForm } from 'ziphy-web-shared/basic/lib/forms';
import { Input } from 'ziphy-web-shared/basic/lib/forms/fields';
import MultiSelect from 'ziphy-web-shared/basic/lib/forms/fields/multiSelect/MultiSelect';
import { $loader } from 'ziphy-web-shared/basic/utils';
import InputDate from '@library/form/fields/new/InputDate';
import UploadAvatar from '@library/form/fields/UploadAvatar';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import PhoneNumber, { preparePhone } from '@library/phoneNumber/PhoneNumber';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import { $configure } from '../configure.store';
import ConfigureProgress from '../library/ConfigureProgress';
const ConfigureDefaultPatientModal = ({ _core }) => {
    const { t } = useTranslation();
    const patient = $patients.defaultPatient;
    const dobType = 'profile';
    const { form } = useForm({
        avatar: { value: patient.avatar, rules: [], output: 'avatar' },
        firstName: { value: patient.firstName, rules: ['name', 'required'] },
        lastName: { value: patient.lastName, rules: ['name', 'required'] },
        gender: { value: patient.gender, rules: ['gender', 'required'], prepValue: 'singleSelect' },
        dob: { value: patient.dob, rules: [{ name: 'dob', dobType }, 'required'], output: 'date' },
        email: { value: $auth.role.email, rules: ['email'], output: 'emptyNull' },
        phone: {
            value: preparePhone($auth.role.phone),
            rules: ['phone'],
            output: ['phone', 'emptyNull'],
        },
        code: { value: patient.code, rules: [] },
    });
    const { avatar, firstName, lastName, gender, dob, email, phone, code } = form.fields;
    const handleSubmit = $loader.registerHandler('filling-default-patient', (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (form.validateAll().isValid) {
            _core.onBlock();
            let response;
            if (!_.isEmpty(patient)) {
                const data = form.values;
                response = yield $patients.update(Object.assign({ id: patient.id }, data));
            }
            else {
                const data = form.values;
                response = yield $patients.create(data);
            }
            _core.onUnblock();
            if (!response.error) {
                yield $auth.loadProfile();
                $configure.toNext();
                _core.onHide();
                $loader.simpleTrigger('filling-default-patient-done');
            }
        }
    }));
    function handleBack() {
        $configure.toPrev();
        _core.onHide();
    }
    return (_jsxs(Modal, Object.assign({ centered: true, scrollable: true, closeOnOverlay: false }, { children: [_jsxs("div", Object.assign({ className: "modal-toolbar" }, { children: [_jsx(ModalToolbarBtn, { type: "back", action: handleBack }), _jsx("div", Object.assign({ className: "modal-toolbar-meta d-lg-none" }, { children: t('configure_card.meta_title') })), _jsx("div", Object.assign({ className: "modal-toolbar-progress" }, { children: _jsx(ConfigureProgress, {}) }))] })), _jsx(Modal.Header, Object.assign({ className: "text-lg-center" }, { children: t('configure_card.title') })), _jsx(Modal.Body, { children: _jsxs("form", Object.assign({ id: "configure_default_patient_form", onSubmit: handleSubmit }, { children: [_jsx(UploadAvatar, { user: Object.assign(Object.assign({}, patient), { firstName: firstName.value, lastName: lastName.value }), field: avatar, value: avatar.value, onChange: avatar.onChange }), _jsxs("div", Object.assign({ className: "row row--gutters-5" }, { children: [_jsx("div", Object.assign({ className: "col-12" }, { children: _jsx(Input, { className: "mb-10", label: t('label.first_name'), field: firstName, mode: "lg" }) })), _jsx("div", Object.assign({ className: "col-12" }, { children: _jsx(Input, { className: "mb-10", label: t('label.last_name'), field: lastName, mode: "lg" }) }))] })), _jsx(MultiSelect, { className: "mb-10", label: t('label.sex_assigned_at_birth'), items: Object.values(ENUMS_PERSON_GENDER).map((x) => ({ id: x.id, text: t(x.tKey) })), field: gender, mode: "lg" }), _jsxs("div", Object.assign({ className: "row row--gutters-5" }, { children: [_jsx("div", Object.assign({ className: "col-6" }, { children: _jsx(InputDate, { minDate: personAgeLimit(dobType).from, maxDate: personAgeLimit(dobType).to, 
                                        // @ts-ignore
                                        label: t('label.date_of_birth'), field: dob, mode: "lg" }) })), _jsx("div", Object.assign({ className: "col-6" }, { children: _jsx(Input, { className: "mb-10", label: t('label.referral_partner_code'), field: code, mode: "lg" }) }))] })), _jsx(Input, { className: "mb-10", label: t('label.email'), field: email, type: "email", placeholder: t('placeholder.empty_email'), mode: "lg" }), _jsx(Input, Object.assign({ label: t('label.phone'), field: phone, mode: "lg" }, { children: _jsx(PhoneNumber, { popupClass: 'phone_number-popup--on_top', phone: phone.value, onChange: phone.onChange }) }))] })) }), _jsx(Modal.Footer, { children: _jsx(ButtonLegacy, Object.assign({ tag: "button", mode: ['primary', 'block'], form: "configure_default_patient_form", isLoading: $loader.isRunHandler('filling-default-patient') }, { children: t('btn.done') })) })] })));
};
ConfigureDefaultPatientModal.displayName = 'ConfigureDefaultPatientModal';
export default observer(ConfigureDefaultPatientModal);
