var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { $insurancesContexts } from 'ziphy-web-shared/basic/entities/insurances';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import { QuickEditButtons } from 'ziphy-web-shared/basic/lib/buttons';
import { WithLoaderWrapper } from 'ziphy-web-shared/basic/lib/loaders';
import { Title } from 'ziphy-web-shared/basic/lib/simple';
import { $loader } from 'ziphy-web-shared/basic/utils';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import styles from './InsuranceController.module.scss';
import InsuranceList from './insuranceList/InsuranceList';
const InsuranceController = ({ patient, title, isModal, className }) => {
    const { t } = useTranslation();
    const $controller = $insurancesContexts.get(patient.id);
    const [mode, setMode] = useState('view');
    const [selectedId, setSelectedId] = useState(null);
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        yield $controller.list();
    }));
    const sortedItems = useMemo(() => {
        if (mode === 'setPrimary') {
            return _.cloneDeep($controller.sortedItems).map((x) => {
                return Object.assign(Object.assign({}, x), { isPrimary: x.id === selectedId });
            });
        }
        return $controller.sortedItems;
    }, [selectedId, $controller.sortedItems]);
    function handleSetPrimary(id) {
        setSelectedId((x) => (x === id ? null : id));
    }
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, isModal && styles.isModal, className) }, { children: [_jsx(Title, Object.assign({ size: 'h2', className: styles.title }, { children: title || t('label.insurances') })), $controller.sortedItems.length > 0 && (_jsx(QuickEditButtons, { className: styles.buttons, actions: {
                    default: ({ setActionsGroup }) => [
                        _jsx(ButtonLegacy, Object.assign({ action: () => {
                                var _a;
                                setMode('setPrimary');
                                setSelectedId((_a = _.find(sortedItems, (x) => x.isPrimary)) === null || _a === void 0 ? void 0 : _a.id);
                                setActionsGroup('active');
                            }, mode: ['anchor'] }, { children: t('btn.set_primary') })),
                    ],
                    active: ({ setActionsGroup }) => [
                        _jsx(ButtonLegacy, Object.assign({ action: $loader.registerHandler('set-primary-insurance', () => __awaiter(void 0, void 0, void 0, function* () {
                                let found = _.find(sortedItems, (x) => x.isPrimary);
                                if (!found) {
                                    const prev = _.find($controller.sortedItems, (x) => x.isPrimary);
                                    found = _.find(sortedItems, (x) => x.id === (prev === null || prev === void 0 ? void 0 : prev.id));
                                }
                                if (found) {
                                    const response = yield $controller.setPrimary({
                                        id: found.id,
                                        isPrimary: found.isPrimary,
                                    });
                                    if (!response.error) {
                                        setMode('view');
                                        setSelectedId(null);
                                        setActionsGroup('default');
                                    }
                                }
                            })), mode: ['anchor'], isLoading: $loader.isRunHandler('set-primary-insurance') }, { children: t('btn.save') })),
                        _jsx(ButtonLegacy, Object.assign({ action: () => {
                                setMode('view');
                                setSelectedId(null);
                                setActionsGroup('default');
                            }, mode: ['anchor-gray-dashed'] }, { children: t('btn.cancel') })),
                    ],
                } })), _jsx(WithLoaderWrapper, Object.assign({ isLoading: isLoading, className: styles.list }, { children: _jsx(InsuranceList, { items: sortedItems, onSetPrimary: handleSetPrimary, onDelete: ({ id }) => __awaiter(void 0, void 0, void 0, function* () {
                        return yield $controller.delete({ id });
                    }), patient: patient, mode: mode, isModal: isModal }) }))] })));
};
export default observer(InsuranceController);
