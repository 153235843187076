import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { TOS_GENERAL, TOS_PROVIDER } from '@library/plugins/termsOfService/Tos.config';
import mainConfig from '@config/main';
import styles from './AuthTosText.module.scss';
const AuthTosText = ({ className }) => {
    return (_jsx("div", Object.assign({ className: classNames(styles.wrapper, className) }, { children: mainConfig.custom.tosProviderEnabled ? (_jsx(Trans, { i18nKey: "login.agreement_provider", values: { name: mainConfig.appName }, components: [
                _jsx(Link, { to: `?${TOS_GENERAL}`, className: styles.a }),
                _jsx(Link, { to: `?${TOS_GENERAL}=pp`, className: styles.a }),
                _jsx(Link, { to: `?${TOS_PROVIDER}`, className: styles.a }),
            ] })) : (_jsx(Trans, { i18nKey: "login.agreement", values: { name: mainConfig.appName }, components: [
                _jsx(Link, { to: `?${TOS_GENERAL}`, className: styles.a }),
                _jsx(Link, { to: `?${TOS_GENERAL}=pp`, className: styles.a }),
            ] })) })));
};
export default AuthTosText;
