import { makeAutoObservable, runInAction } from 'mobx';
import i18n from 'i18next';
import _ from 'lodash';
import moment from 'moment-timezone';
import { isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import { $loader, globalLoaderHandlers } from 'ziphy-web-shared/basic/utils';
import { calendarFormats, dateFormats } from './time.constants';
class TimeStore {
    constructor() {
        makeAutoObservable(this);
        this.startUpdatingTime();
    }
    get timezone() {
        return this._timezone || Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
    }
    get weekType() {
        return this._weekType || 'week';
    }
    get today() {
        return this.now.clone().startOf('day');
    }
    SET_TIMEZONE(value) {
        runInAction(() => (this._timezone = value));
    }
    startUpdatingTime() {
        this.updateTime();
        const secondsUntilNextMinute = 60 - this.now.seconds();
        setTimeout(() => {
            this.updateTime();
            this.intervalId = setInterval(() => this.updateTime(), 60000);
        }, secondsUntilNextMinute * 1000);
    }
    updateTime() {
        this.now = moment.tz(this.timezone);
        $loader.simpleTrigger(globalLoaderHandlers.NEW_MINUTE);
    }
    dispose() {
        clearInterval(this.intervalId);
    }
}
export const $time = new TimeStore();
export function getFormatByLocale(key, locale) {
    const currentLocale = locale || moment.locale();
    const dates = dateFormats[currentLocale] || dateFormats['en'];
    const calendars = calendarFormats[currentLocale] || calendarFormats['en'];
    return Object.assign(Object.assign({}, dates), calendars)[key];
}
export function formatAsAge(date, withText = true) {
    const years = moment.utc().diff(date, 'years');
    if (withText) {
        return years + i18n.t('user.yo');
    }
    return years;
}
export function formatDate(date, formatOrOptions, timezone) {
    let format = 'auto';
    timezone = timezone || $time.timezone;
    let empty = '';
    if (typeof formatOrOptions === 'string') {
        format = formatOrOptions || format;
    }
    else {
        format = (formatOrOptions === null || formatOrOptions === void 0 ? void 0 : formatOrOptions.format) || format;
        timezone = (formatOrOptions === null || formatOrOptions === void 0 ? void 0 : formatOrOptions.timezone) || timezone;
        empty = (formatOrOptions === null || formatOrOptions === void 0 ? void 0 : formatOrOptions.empty) || empty;
    }
    if (!date) {
        return empty;
    }
    date = moment(date);
    const isDate = date.creationData().format === 'YYYY-MM-DD';
    if (format === 'auto') {
        format = isDate ? 'date' : 'datetime';
    }
    if (!isDate && timezone) {
        date = date.tz(timezone);
    }
    if (getFormatByLocale(format)) {
        format = getFormatByLocale(format);
    }
    return date.format(format);
}
export function calendar(date, { type = 'calendarDatetime', timezone = $time.timezone, relative = false, } = {}) {
    let result;
    let relativeDate = null;
    const hasDateAndTime = 
    // @ts-ignore
    typeof date === 'object' && 'date' in date && 'time' in date && !!date.date && !!date.time;
    if (hasDateAndTime) {
        // @ts-ignore
        result = moment(new Date(date.date + ' ' + date.time));
    }
    else {
        result = moment(date);
        if (relative && timezone) {
            relativeDate = moment().tz(timezone);
        }
    }
    if (timezone && relativeDate) {
        result = result.tz(timezone);
    }
    return moment(result).calendar(relativeDate, getFormatByLocale(type));
}
export const formatDateOrDash = (value, params) => {
    return formatDate(value, Object.assign(Object.assign({}, params), { empty: '—' }));
};
export function formatDuration({ from, to, seconds, format = 'hh:mm', units = 'short', ignoreLeftZero = true, ignoreRightZero = true, }) {
    let duration;
    if (from && to) {
        duration = moment.duration(moment(to).diff(moment(from)));
    }
    else if (typeof seconds === 'number') {
        duration = moment.duration(seconds, 'seconds');
    }
    else {
        throw new Error('Invalid arguments: provide either { from, to } or { seconds }');
    }
    const maxUnit = format.includes('h') ? 'h' : format.includes('m') ? 'm' : 's';
    const minUnit = format.includes('s') ? 's' : format.includes('m') ? 'm' : 'h';
    const sign = duration.asMilliseconds() < 0 ? '-' : '+';
    let totalSeconds = Math.abs(duration.asSeconds());
    if (minUnit === 'm') {
        const tmp = totalSeconds / 60;
        totalSeconds = (sign === '-' ? Math.floor(tmp) : Math.ceil(tmp)) * 60;
    }
    else if (minUnit === 'h') {
        const tmp = totalSeconds / 3600;
        totalSeconds = (sign === '-' ? Math.floor(tmp) : Math.ceil(tmp)) * 3600;
    }
    const roundedDuration = moment.duration(totalSeconds, 'seconds');
    const [hours, mins, secs] = (() => {
        switch (maxUnit) {
            case 'h':
                return [roundedDuration.hours(), roundedDuration.minutes(), roundedDuration.seconds()];
            case 'm':
                return [0, roundedDuration.minutes(), roundedDuration.seconds()];
            default:
                return [0, 0, roundedDuration.seconds()];
        }
    })();
    const formatMap = {
        h: {
            value: hours,
            long: 'label.hours',
            short: 'label.hours_as_char',
        },
        hh: {
            value: hours === null || hours === void 0 ? void 0 : hours.toString().padStart(2, '0'),
            long: 'label.hours',
            short: 'label.hours_as_char',
        },
        m: {
            value: mins,
            long: 'label.minutes',
            short: 'label.minutes_as_char',
        },
        mm: {
            value: mins === null || mins === void 0 ? void 0 : mins.toString().padStart(2, '0'),
            long: 'label.minutes',
            short: 'label.minutes_as_char',
        },
        s: {
            value: secs,
            long: 'label.seconds',
            short: 'label.seconds_as_char',
        },
        ss: {
            value: secs === null || secs === void 0 ? void 0 : secs.toString().padStart(2, '0'),
            long: 'label.seconds',
            short: 'label.seconds_as_char',
        },
    };
    let left = ignoreLeftZero;
    let right = !ignoreRightZero;
    const asString = format
        .split(':')
        .map((token) => {
        const value = formatMap[token].value;
        if (left && !Number(value))
            return '';
        left = false;
        if (!right && !Number(value))
            return '';
        right = false;
        if (units === 'none')
            return value;
        return i18n.t(formatMap[token][units], { count: Number(value) });
    })
        .filter(Boolean)
        .join(units === 'none' ? ':' : ' ');
    return {
        hours,
        minutes: mins,
        seconds: secs,
        sign,
        asString: asString.trim(),
    };
}
export const composeDateComponentsToDateString = (dateComponents, asStringAlways) => {
    const { year, month, day } = dateComponents;
    let result;
    if (!isAbsoluteEmpty(day)) {
        if (asStringAlways) {
            result = `${year}-${_.padStart((month + 1).toString(), 2, '0')}-${_.padStart(day.toString(), 2, '0')}`;
        }
        else {
            result = new Date(year, month, day).toISOString();
        }
    }
    else {
        result = year.toString();
        if (!isAbsoluteEmpty(month)) {
            result += `-${_.padStart((month + 1).toString(), 2, '0')}`;
        }
    }
    return result;
};
export const decomposeDateStringToDateComponents = (dateString) => {
    let result = {};
    if (!isAbsoluteEmpty(dateString)) {
        if (dateString.length < 8) {
            // incomplete date string
            if (dateString.length === 4) {
                result.year = +dateString;
            }
            else {
                result.year = +dateString.split('-')[0];
                result.month = +dateString.split('-')[1];
            }
        }
        else {
            const tempDate = new Date(dateString);
            result.year = tempDate.getFullYear();
            result.month = tempDate.getMonth() + 1;
            result.day = tempDate.getDate();
        }
    }
    return result;
};
export const decodeAndFormatDateObject = (dateObj) => {
    const { dateString, isFull } = dateObj;
    if (isAbsoluteEmpty(dateString)) {
        return '';
    }
    let result;
    if (isFull) {
        result = formatDate(dateString, { format: 'date' });
    }
    else {
        if (dateString.length === 4) {
            // only a year, like 1994
            result = dateString;
        }
        else if (dateString.length > 7) {
            result = formatDate(dateString, { format: 'date' });
        }
        else {
            result = formatDate(dateString, { format: 'dateNoDay' });
        }
    }
    return result;
};
export function isValidDateString(str) {
    const regex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(?:.\d+)?(?:Z|[+-]\d{2}:\d{2})?)?$/;
    return regex.test(str);
}
export function getUtcOffset(timezone = $time.timezone, type = 'hour') {
    const timeInZone = moment.tz(timezone);
    const offsetInMinutes = timeInZone.utcOffset();
    const delimiter = type === 'hour' ? 60 : 1;
    return offsetInMinutes / delimiter;
}
export function getIsoDate(value, timezone = $time.timezone) {
    let date = moment.utc(value);
    if (timezone) {
        date = date.tz(timezone);
    }
    return date.startOf('day').format('YYYY-MM-DD');
}
