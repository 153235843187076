var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { $accounts } from 'ziphy-web-shared/basic/entities/accounts';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import Modal from '@library/modal/Modal';
import ContactList from './accountsList/AccountsList';
import AddContact from './addAccount/AddAccount';
import SelectChannel from './selectChannel/SelectChannel';
const AccountsControllerModal = ({ _core }) => {
    const [view, setView] = useState('list');
    const [current, setCurrent] = useState();
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        yield $accounts.activeList();
    }));
    return (_jsx(Modal, Object.assign({ centered: true, heightAuto: true, scrollable: !['phone', 'phoneActivate'].includes(view) }, { children: view === 'channel' ? (_jsx(SelectChannel, { setView: setView, _core: _core }, view)) : ['phone', 'phoneActivate'].includes(view) ? (_jsx(AddContact, { setView: setView, type: "phone", value: current === null || current === void 0 ? void 0 : current.value, _core: _core }, view)) : ['email', 'emailActivate'].includes(view) ? (_jsx(AddContact, { setView: setView, type: "email", value: current === null || current === void 0 ? void 0 : current.value, _core: _core }, view)) : (_jsx(ContactList, { setView: setView, setCurrent: setCurrent, isLoading: isLoading, _core: _core }, view)) })));
};
AccountsControllerModal.displayName = 'AccountsControllerModal';
export default observer(AccountsControllerModal);
