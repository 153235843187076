var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { isLocal, T } from 'ziphy-web-shared/basic/helpers';
import { showAlert } from 'ziphy-web-shared/basic/lib/utilities';
import { $loader } from 'ziphy-web-shared/basic/utils';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import { customExecute } from '@helpers/other';
import { customActions } from '@config/customActions';
import mainConfig from '@config/main';
import { $configure } from '../configure.store';
import ConfigureProgress from '../library/ConfigureProgress';
import styles from './ConfigureCoe.module.scss';
const ConfigureCoeModal = ({ _core }) => {
    const { t } = useTranslation();
    const [scrolled, setScrolled] = useState(isLocal());
    const bodyRef = useRef(null);
    useEffect(() => {
        setTimeout(() => handleScroll({ target: bodyRef.current }), 0);
    }, []);
    function handleBack() {
        $configure.toPrev();
        _core.onHide();
    }
    function handleScroll(e) {
        const target = e.target;
        if (Math.floor(target.scrollHeight - target.scrollTop) <= target.clientHeight + 10) {
            setScrolled(true);
        }
    }
    function handleCancel() {
        confirmAction('customAlert', {
            data: {
                title: t('confirmator.logout.title'),
                message: t('confirmator.logout.message'),
            },
            callback: () => __awaiter(this, void 0, void 0, function* () {
                yield customExecute(customActions.HANDLE_REJECT_PAPERWORK());
                _core.onHide();
            }),
        });
    }
    const handleSubmit = $loader.registerHandler('configure-coe-submit', (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (scrolled) {
            _core.onBlock();
            const response = yield $configure.setTosAccepted({ tosType: 'emergency_instructions' });
            _core.onUnblock();
            if (!response.error) {
                $configure.toNext();
                _core.onHide();
            }
        }
        else {
            showAlert.error(t('configure_coe.not_read'));
        }
    }));
    return (_jsxs(Modal, Object.assign({ centered: true, scrollable: true, size: "xl", closeOnOverlay: false }, { children: [_jsxs("div", Object.assign({ className: "modal-toolbar" }, { children: [_jsx(ModalToolbarBtn, { type: "back", action: handleBack }), _jsx("div", Object.assign({ className: "modal-toolbar-meta d-lg-none" }, { children: t('configure.meta_title', { n: $configure.currentStep }) })), _jsx("div", Object.assign({ className: "modal-toolbar-progress" }, { children: _jsx(ConfigureProgress, {}) }))] })), _jsx(Modal.Header, Object.assign({ className: "text-center" }, { children: t('configure_coe.title') })), _jsxs(Modal.Body, Object.assign({ ref: bodyRef, className: "styled_scroll", onScroll: handleScroll }, { children: [_jsx("p", { children: t('configure_coe_text.p1') }), _jsxs("p", { children: ["- ", t('configure_coe_text.l1'), _jsx("br", {}), "- ", t('configure_coe_text.l2'), _jsx("br", {}), "- ", t('configure_coe_text.l3'), _jsx("br", {}), "- ", t('configure_coe_text.l4'), _jsx("br", {})] }), mainConfig.custom.coeExtendedText && (_jsxs(_Fragment, { children: [_jsx(T, Object.assign({ tag: "p" }, { children: t('configure_coe_text.p2') })), _jsx(T, Object.assign({ tag: "p" }, { children: t('configure_coe_text.p3') }))] }))] })), _jsxs(Modal.Footer, Object.assign({ className: styles.buttons }, { children: [_jsx(ButtonLegacy, Object.assign({ mode: "primary", isLoading: $loader.isRunHandler('configure-coe-submit'), action: handleSubmit }, { children: t('btn.agree') })), _jsx(ButtonLegacy, Object.assign({ mode: "secondary", action: handleCancel }, { children: t('btn.cancel') }))] }))] })));
};
ConfigureCoeModal.displayName = 'ConfigureCoeModal';
export default observer(ConfigureCoeModal);
