var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { $addressesContexts } from 'ziphy-web-shared/basic/entities/addresses';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import { WithLoaderWrapper } from 'ziphy-web-shared/basic/lib/loaders';
import { Title } from 'ziphy-web-shared/basic/lib/simple';
import { prepareAddress } from '@fhir/fhir.converters';
import styles from './AddressController.module.scss';
import AddressList from './addressList/AddressList';
const AddressController = ({ patient, title, isModal, className }) => {
    const { t } = useTranslation();
    const $controller = $addressesContexts.get(patient.id);
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        yield $controller.list();
    }));
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, isModal && styles.isModal, className) }, { children: [_jsx(Title, Object.assign({ size: 'h2', className: styles.title }, { children: title || t('menu.addresses') })), _jsx(WithLoaderWrapper, Object.assign({ isLoading: isLoading, className: styles.list }, { children: _jsx(AddressList, { items: $controller.sortedItems.map((x) => prepareAddress(x)), patient: patient, isModal: isModal }) }))] })));
};
export default observer(AddressController);
