import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { getUserText } from 'ziphy-web-shared/basic/helpers';
import { Title } from 'ziphy-web-shared/basic/lib/simple';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
import Badge from '@library/ui/badge/Badge';
import UserPhoto from '@library/user/UserPhoto';
import styles from './PatientPhotoAndName.module.scss';
const PatientPhotoAndName = ({ firstName, lastName, fullName, avatar, withAvatar = true, photoSize, moreInfo, mobileView = true, isDeceased, additionalInfo, }) => {
    const { t } = useTranslation();
    const photoData = { firstName, lastName, name: fullName, avatar };
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: classNames(styles.content, mobileView && styles.mobileView) }, { children: [withAvatar && (_jsx(UserPhoto, { item: photoData, size: photoSize ? photoSize : `${$windowSize.maxLG ? 100 : 48}`, isGray: isDeceased })), _jsxs("div", Object.assign({ className: styles.textContainer }, { children: [_jsx(Title, Object.assign({ size: 'h1', className: classNames(styles.text, moreInfo && styles.withMoreInfo) }, { children: firstName && lastName ? getUserText({ firstName, lastName }, ['fullName']) : fullName })), isDeceased && (_jsx(Badge, Object.assign({ className: styles.deceasedBadge, type: ['gray-fill', 'round', 'sm'] }, { children: t('label.deceased') }))), moreInfo && _jsx("div", Object.assign({ className: styles.moreInfoText }, { children: moreInfo }))] })), additionalInfo && _jsx("div", Object.assign({ className: styles.additionalInfo }, { children: additionalInfo }))] })) }));
};
export default observer(PatientPhotoAndName);
