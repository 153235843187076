import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'

import _ from 'lodash'
import { $auth } from 'ziphy-web-shared/basic/entities/auth'
import { $patients } from 'ziphy-web-shared/basic/entities/patients'
import { WithLoaderWrapper } from 'ziphy-web-shared/basic/lib/loaders'
import { useLoaderSubscribe } from 'ziphy-web-shared/basic/utils'
import { $router } from 'ziphy-web-shared/basic/utils/router'

import { $booking } from '@store'

import { routeActions } from '@config/routeActions'

import { bookingSchema } from '../../config/bookingSchema'

const withBookingDataInit = (schemaConfigKey, WrappedComponent) => {
  const WithBookingDataInit = ({ ...props }) => {
    const [isLoading, setIsLoading] = useState(true)

    // TODO uncomment when url upload will be ready
    // const searchParams = useRouterSearchParams('replace')
    // useEffect(() => {
    //   searchParams.set($booking.searchParams)
    // }, [$booking.searchParams])
    const schemaConfig = bookingSchema[schemaConfigKey]

    const checkRoute = () => {
      let fulfilledConditions = []

      if ($booking.place) fulfilledConditions.push('place')
      if (_.isNumber($booking.serviceId)) fulfilledConditions.push('service')
      if ($booking.patients.selectedIds.length) fulfilledConditions.push('patients')
      if (_.isNumber($booking.practiceId)) fulfilledConditions.push('practice')

      if (schemaConfig) {
        if (!schemaConfig.required.every((x) => fulfilledConditions.includes(x))) {
          $router.executeAutoTarget(schemaConfig.initFailAction())
        } else {
          $booking.SET_SCHEMA_CONFIG_KEY(schemaConfigKey)
        }
      } else {
        $router.executeAutoTarget(routeActions.BOOK_START())
      }
    }

    useEffect(() => {
      setIsLoading(true)
      if (!$auth.isLogged) {
        $booking.clear(true)
      } else if (schemaConfig?.isInitPage) {
        $booking.clear(false)
      }
      const init = async () => {
        const initValues = _.isFunction(schemaConfig?.initValues)
          ? schemaConfig.initValues()
          : undefined
        await $booking.init(initValues)
      }
      init().finally(() => {
        checkRoute()
        setIsLoading(false)
      })
    }, [$auth.isLogged, schemaConfigKey])

    useLoaderSubscribe(['filling-default-patient-done'], async () => await $patients.activeList())

    return (
      <WithLoaderWrapper
        isLoading={isLoading}
        loaderMode={props.loaderMode ? props.loaderMode : 'pageWrapper'}
        withWrapper={false}
      >
        <WrappedComponent {...props} />
      </WithLoaderWrapper>
    )
  }

  WithBookingDataInit.displayName = `withBookingDataInit(${WrappedComponent.displayName || WrappedComponent.name})`

  return observer(WithBookingDataInit)
}

export default withBookingDataInit
