import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import { $configure } from './configure.store';
const ConfigureViewer = ({ isInitialized }) => {
    useEffect(() => {
        if (isInitialized &&
            $auth.isLogged &&
            $configure.currentStep === null &&
            $configure.hasRequired) {
            $configure.toNext();
        }
    }, [isInitialized, $configure.dependenciesList]);
    return _jsx(_Fragment, {});
};
export default observer(ConfigureViewer);
