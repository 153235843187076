import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Title } from 'ziphy-web-shared/basic/lib/simple';
import mainConfig from '@config/main';
import styles from './QuickLinks.module.scss';
const QuickLinks = ({ className }) => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: classNames(className) }, { children: [_jsx(Title, Object.assign({ size: 'h3', className: 'mb-10' }, { children: t('quick_links.title') })), _jsxs("div", Object.assign({ className: "row row--mb-10" }, { children: [_jsx("div", Object.assign({ className: "col-md-6 col-xl-4" }, { children: _jsx("div", Object.assign({ className: styles.item }, { children: _jsx("a", Object.assign({ href: "https://ziphycare.com/welcome-to-myziphy/", target: "_blank", rel: "noreferrer" }, { children: t('quick_links.welcome', { appName: mainConfig.appName }) })) })) })), _jsx("div", Object.assign({ className: "col-md-6 col-xl-4" }, { children: _jsx("div", Object.assign({ className: styles.item }, { children: _jsx("a", Object.assign({ href: "https://ziphycare.com/about-ziphycare/", target: "_blank", rel: "noreferrer" }, { children: t('quick_links.about', { serviceCompanyAbbr: mainConfig.serviceCompanyAbbr }) })) })) })), _jsx("div", Object.assign({ className: "col-md-6 col-xl-4" }, { children: _jsx("div", Object.assign({ className: styles.item }, { children: _jsx("a", Object.assign({ href: "https://ziphycare.com/downloads/", target: "_blank", rel: "noreferrer" }, { children: t('quick_links.download') })) })) }))] }))] })));
};
export default observer(QuickLinks);
