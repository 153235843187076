import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import classNames from 'classnames'
import _ from 'lodash'
import Icon from 'ziphy-web-shared/basic/assets/images/Icon'
import { getUserText } from 'ziphy-web-shared/basic/helpers'
import { Button } from 'ziphy-web-shared/basic/lib/buttons'
import { $windowSize } from 'ziphy-web-shared/basic/utils'

import PatientPhotoAndName from '@library/ui/patientPhotoAndName/PatientPhotoAndName'

import EncounterControl from '@pages/appt/patient/EncounterControl'

import hAppt from '@helpers/hAppt'

import { $appts } from '@store'

import { routeActions } from '@config/routeActions'

import styles from './ApptPatient.module.scss'

const ApptPatient = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { encounterId } = useParams()
  const { fullAppt, status } = $appts.byRoute
  const { appointment, patients, encounters } = fullAppt

  const [activePatient, setActivePatient] = useState(null)
  const [updatingPatientInfo, setUpdatingPatientInfo] = useState(false)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (encounterId) {
      setUpdatingPatientInfo(true)
      setActivePatient(_.find(patients, (x) => x.activeEncounter === +encounterId))
    }
  }, [encounterId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activePatient && updatingPatientInfo) {
      setUpdatingPatientInfo(false)
    }
  }, [activePatient]) // eslint-disable-line react-hooks/exhaustive-deps

  const encounterControlIsVisible =
    hAppt.needToFinishEncountersManually(fullAppt) &&
    status.isStartProvider &&
    appointment.status === 'started'

  return (
    <div className={classNames(styles.wrapper, 'mb-30')}>
      {activePatient && (
        <>
          <div className={styles.apptPatient}>
            <div className={styles.name}>
              <PatientPhotoAndName
                firstName={activePatient.firstName}
                lastName={activePatient.lastName}
                avatar={activePatient.avatar}
              />
            </div>
            <div className={styles.info}>
              {$windowSize.isMobile && !expanded ? (
                <>
                  <div className={styles.mobileInfoBlock}>
                    <p>{getUserText(activePatient, ['gender', 'yo'])}</p>
                  </div>
                  <div
                    className={classNames('a', styles.moreInfoBtn)}
                    onClick={() => setExpanded(!expanded)}
                  >
                    {t('btn.more')}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.patientInfoBlock}>
                    <div className={styles.patientInfoBlockTitle}>
                      {t('label.sex_assigned_at_birth')}
                    </div>
                    <div className={styles.patientInfoBlockText}>
                      {getUserText(activePatient, ['gender'])}
                    </div>
                  </div>
                  <div className={styles.patientInfoBlock}>
                    <p className={styles.patientInfoBlockTitle}>{t('label.date_of_birth')}</p>
                    <p className={styles.patientInfoBlockText}>
                      {getUserText(activePatient, ['dob'])} ({getUserText(activePatient, ['yo'])})
                    </p>
                  </div>
                  <div className={styles.patientInfoBlock}>
                    <p className={styles.patientInfoBlockTitle}>{t('label.phone')}</p>
                    <p className={styles.patientInfoBlockText}>
                      {activePatient.phone || (
                        <span className={'text-muted'}>{t('label.none')}</span>
                      )}
                    </p>
                  </div>
                  <div className={styles.patientInfoBlock}>
                    <p className={styles.patientInfoBlockTitle}>{t('label.email')}</p>
                    <p className={styles.patientInfoBlockText}>
                      {activePatient.email || (
                        <span className={'text-muted'}>{t('label.none')}</span>
                      )}
                    </p>
                  </div>
                  <div className={styles.patientInfoBlock}>
                    <p className={styles.patientInfoBlockTitle}>{t('label.encounter_id')}</p>
                    <p className={styles.patientInfoBlockText}>{encounterId}</p>
                  </div>
                  <div className={styles.patientInfoBlock}>
                    <p className={styles.patientInfoBlockTitle}>{t('label.patient_id')}</p>
                    <p className={styles.patientInfoBlockText}>{activePatient.id}</p>
                  </div>
                  {$windowSize.isMobile && (
                    <div
                      className={classNames('a', styles.moreInfoBtn)}
                      onClick={() => setExpanded(!expanded)}
                    >
                      {t('label.minimize')}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div
            className={classNames(
              styles.controls,
              $windowSize.isMobile && styles.controlsMobileView,
            )}
          >
            <div className={styles.controlsHistoryBtn}>
              <Button
                mode={$windowSize.isMobile ? 'secondary' : 'white'}
                autoTarget={routeActions.PS_PATIENT(
                  {
                    patientId: activePatient.id,
                    practiceId: appointment.practiceId,
                  },
                  { backToUrl: location.pathname },
                )}
                rightElement={<Icon type={'linkArrow'} size={20} className={'ml-10'} />}
              >
                {t('btn.view_open_patient_history')}
              </Button>
            </div>
            <EncounterControl
              withControls={encounterControlIsVisible}
              encounter={encounters[encounterId]}
              appointment={appointment}
              durationInButton={$windowSize.isMobile}
              isLoading={updatingPatientInfo}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default observer(ApptPatient)
