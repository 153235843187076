var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { insuranceProvidersService } from 'ziphy-web-shared/basic/api';
import { isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import TypeAhead from '@library/form/fields/typeAhead/TypeAhead';
const AddInsuranceProvider = ({ providerName, providerId, className }) => {
    const { t } = useTranslation();
    function handleOnload({ searchString = '' }) {
        return __awaiter(this, void 0, void 0, function* () {
            let result = [];
            const isSearch = searchString.length;
            let filter;
            if (isSearch) {
                const needle = searchString.split(' ').filter((x) => !isAbsoluteEmpty(x));
                filter = {
                    or: [
                        { imatch: ['payer_id', _.escapeRegExp(searchString)] },
                        { and: _.map(needle, (x) => ({ imatch: ['name', _.escapeRegExp(x)] })) },
                    ],
                };
            }
            else {
                filter = { eq: ['is_popular', true] };
            }
            const response = yield insuranceProvidersService.list({
                filter: filter,
                order: [['name', 'asc']],
            });
            const items = response.preparedValue;
            if (items.length) {
                result = items.map((x) => ({
                    id: x.payerId,
                    text: x.name,
                    rightText: x.payerId,
                }));
                if (!isSearch) {
                    result = [{ id: null, text: t('insurance.label.most_popular'), items: result }];
                }
            }
            return result;
        });
    }
    return (_jsx(TypeAhead, { value: {
            id: providerId.value,
            text: providerName.value,
            rightText: providerId.value,
        }, onLoad: handleOnload, onLoadMinSearchLength: 0, onChange: (data) => {
            let id;
            let text;
            if (typeof data === 'string') {
                id = data;
            }
            else {
                if ('id' in data)
                    id = data.id;
                if ('text' in data)
                    text = data.text;
            }
            providerId.onChange(isAbsoluteEmpty(id) ? '' : id);
            providerName.onChange(isAbsoluteEmpty(text) ? '' : text);
        }, className: className, fieldAttributes: {
            label: t('insurance.label.provider_name'),
            placeholder: null,
            isInvalid: providerName.isInvalid,
            unFocusedKey: 'rightText',
        }, popupParams: {
            useAsIs: true,
        } }));
};
export default observer(AddInsuranceProvider);
