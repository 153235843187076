var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { isLocal } from 'ziphy-web-shared/basic/helpers';
import { showAlert } from 'ziphy-web-shared/basic/lib/utilities';
import { $loader } from 'ziphy-web-shared/basic/utils';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import { TOS_GENERAL, TOS_PROVIDER } from '@library/plugins/termsOfService/Tos.config';
import { useLoadJson } from '@library/plugins/termsOfService/Tos.hooks';
import { TosHeader } from '@library/plugins/termsOfService/TosHeader';
import { TosText } from '@library/plugins/termsOfService/TosText';
import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import { customExecute } from '@helpers/other';
import { customActions } from '@config/customActions';
import { $configure } from '../configure.store';
import ConfigureProgress from '../library/ConfigureProgress';
import styles from './ConfigureTos.module.scss';
const ConfigureTosModal = ({ type = TOS_GENERAL, _core }) => {
    const { t } = useTranslation();
    const [scrolled, setScrolled] = useState(isLocal());
    const bodyRef = useRef(null);
    const { json, isLoading, extractRef } = useLoadJson({ type });
    useEffect(() => {
        if (isLoading)
            return;
        setTimeout(() => handleScroll({ target: bodyRef.current }), 0);
    }, [isLoading]);
    function handleBack() {
        $configure.toPrev();
        _core.onHide();
    }
    function handleScroll(e) {
        const target = e.target;
        if (Math.floor(target.scrollHeight - target.scrollTop) <= target.clientHeight + 10) {
            setScrolled(true);
        }
    }
    function handleCancel() {
        confirmAction('customAlert', {
            data: {
                title: t('confirmator.tos_reject.title'),
                message: t('confirmator.tos_reject.message'),
                done: t('btn.reject_tos'),
                cancel: t('btn.view_tos'),
            },
            callback: () => __awaiter(this, void 0, void 0, function* () {
                yield customExecute(customActions.HANDLE_REJECT_PAPERWORK());
                _core.onHide();
            }),
        });
    }
    const handleSubmit = $loader.registerHandler('configure-tos-submit', (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (scrolled) {
            let response;
            let isSuccess = false;
            _core.onBlock();
            if (type === TOS_PROVIDER) {
                response = yield $configure.setTosAccepted({ tosType: 'provider' });
                isSuccess = !response.error;
            }
            else {
                response = yield Promise.all([
                    $configure.setTosAccepted({ tosType: 'client' }, false),
                    $configure.setTosAccepted({ tosType: 'client_pp' }),
                ]);
                isSuccess = response.every((x) => !x.error);
            }
            _core.onUnblock();
            if (isSuccess) {
                $configure.toNext();
                _core.onHide();
            }
        }
        else {
            showAlert.error(t('configure_tos.not_read'));
        }
    }));
    return (_jsxs(Modal, Object.assign({ centered: true, scrollable: true, size: "xl", closeOnOverlay: false }, { children: [_jsxs("div", Object.assign({ className: "modal-toolbar" }, { children: [_jsx(ModalToolbarBtn, { type: "back", action: handleBack }), _jsx("div", Object.assign({ className: "modal-toolbar-meta d-lg-none" }, { children: t('configure.meta_title', { n: $configure.currentStep }) })), _jsx("div", Object.assign({ className: "modal-toolbar-progress" }, { children: _jsx(ConfigureProgress, {}) }))] })), _jsx(Modal.Header, Object.assign({ className: "text-center" }, { children: _jsx(TosHeader, { type: type }) })), _jsx(Modal.Body, Object.assign({ ref: bodyRef, isLoading: isLoading, className: "styled_scroll", onScroll: handleScroll }, { children: _jsx(TosText, { type: type, json: json, extractRef: extractRef, onlyScroll: true }) })), _jsxs(Modal.Footer, Object.assign({ className: styles.buttons }, { children: [_jsx(ButtonLegacy, Object.assign({ mode: "primary", isLoading: $loader.isRunHandler('configure-tos-submit'), action: handleSubmit }, { children: t('btn.accept') })), _jsx(ButtonLegacy, Object.assign({ mode: "secondary", action: handleCancel }, { children: t('btn.reject') }))] }))] })));
};
ConfigureTosModal.displayName = 'ConfigureTosModal';
export default observer(ConfigureTosModal);
