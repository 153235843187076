import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import InsuranceController from './InsuranceController';
const InsuranceControllerModal = ({ patient, _core }) => {
    return (_jsxs(Modal, Object.assign({ centered: true, scrollable: true, className: "add-insurance-modal" }, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: _core.onHide }) })), _jsx(Modal.Body, { children: _jsx(InsuranceController, { patient: patient, isModal: true }) })] })));
};
InsuranceControllerModal.displayName = 'InsuranceControllerModal';
export default observer(InsuranceControllerModal);
