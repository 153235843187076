import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { ContainerScrollable, PageNoResults } from 'ziphy-web-shared/basic/lib/simple';
import { Title } from 'ziphy-web-shared/basic/lib/simple';
import { $layouts } from 'ziphy-web-shared/basic/utils/layouts';
import { $notices } from '@store';
import styles from './Notifications.module.scss';
import NotificationsGroup from './notificationsGroup/NotificationsGroup';
const Notifications = () => {
    const { t } = useTranslation();
    const notViewedIds = $notices.notViewedItems.map((x) => x.id);
    return (_jsx("div", Object.assign({ className: classNames(styles.container) }, { children: _jsxs("div", Object.assign({ className: styles.box }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx(Icon, { type: 'moveRightArrow', className: styles.hideIcon, onClick: () => $layouts.hideRightSidebar() }), _jsx("div", Object.assign({ className: styles.title }, { children: _jsx(Title, Object.assign({ size: 'h2' }, { children: t('notices.title') })) })), notViewedIds.length > 0 ? (_jsx("div", Object.assign({ className: styles.readIcon, onClick: () => $notices.view({ ids: notViewedIds }) }, { children: _jsx(Icon, { type: "checks" }) }))) : (_jsx("div", Object.assign({ className: styles.closeIcon, onClick: () => $layouts.removeRightSidebar('notifications') }, { children: _jsx(Icon, { type: "close" }) })))] })), _jsx(ContainerScrollable, Object.assign({ className: classNames(styles.body, 'styled_scroll'), "data-scrollable": true }, { children: _jsx("div", Object.assign({ className: styles.notices }, { children: $notices.allItems.size > 0 ? (Array.from($notices.allItems, ([appointmentId, group]) => (_jsx(NotificationsGroup, { data: group }, appointmentId)))) : (_jsx(PageNoResults, { children: _jsx(Trans, { i18nKey: "notices.empty.current", components: [_jsx("br", {})] }) })) })) }))] })) })));
};
export default observer(Notifications);
