import React, { useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import _ from 'lodash'
import { $auth } from 'ziphy-web-shared/basic/entities/auth'
import { delayedRequest } from 'ziphy-web-shared/basic/helpers'
import { useLoader } from 'ziphy-web-shared/basic/hooks'
import { $router } from 'ziphy-web-shared/basic/utils/router'

import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator'

import { $booking, $modal } from '@store'

import mainConfig from '@config/main'

import AddPlaceModal from '../../../library/addPlaceModal/AddPlace.modal'
import SelectAddressModal from '../../../library/modals/selectAddressModal/SelectAddress.modal'
import PatientGroupTypes from './patientGroupTypes/PatientGroupTypes'
import styles from './SelectService.module.scss'
import ServiceInfoModal from './serviceInfoModal/ServiceInfo.modal'

const VISIBLE_SERVICES_LIMIT = 8

const SelectService = () => {
  const { t } = useTranslation()

  const [isFullList, setIsFullList] = useState(false)
  const [isOnlyFamily, setIsOnlyFamily] = useState(false)

  const { isLoading } = useLoader(async () => {
    await delayedRequest(async () => await $booking.loadPracticeServices(), 100)
  }, [$booking.servePracticeIds])

  const [servicesList, setServicesList] = useState([])

  useEffect(() => {
    let result = $booking.$services.items.filter((x) => {
      return x && (!isOnlyFamily || x.patientGroupType === 'family')
    })

    if ($auth.isLogged) {
      result = result.map((x) => {
        return {
          ...x,
          isDisabled: $booking.place && !$booking.availableServiceIds.includes(x.id),
        }
      })
    }

    result = _.orderBy(result, ['isDisabled', 'rank'], ['asc', 'asc'])
    setServicesList(result)
  }, [$booking.availableServiceIds, isOnlyFamily])

  const slicedServicesList = isFullList
    ? servicesList
    : servicesList.slice(0, VISIBLE_SERVICES_LIMIT)

  const isAllDisabled = useMemo(
    () =>
      !isLoading &&
      !$booking.isLoading &&
      $booking.place &&
      servicesList.every((x) => x.isDisabled),
    [servicesList],
  )

  const checkAuth = (props, e) => confirmAction('checkAuth', props, e)

  function handleStartBook(e, serviceId) {
    const callback = () => {
      if (!$booking.place) {
        $modal.add($booking.$places.items.length ? SelectAddressModal : AddPlaceModal)
      } else {
        $booking.SET_SERVICE_ID(serviceId)
        $router.executeAutoTarget($booking.schemaConfig?.nextStep())
      }
    }
    checkAuth({ callback }, e)
  }

  function handleOpenServiceInfoModal(e, serviceId) {
    e.stopPropagation()
    const callback = () => {
      $modal.add(ServiceInfoModal, { serviceId, onBook: handleStartBook })
    }
    checkAuth({ callback }, e)
  }

  return (
    <div className={styles.help}>
      <div className={styles.helpHeader}>
        <div className={styles.helpHeaderTitle}>{t('select_appt.visit_type')}</div>
        <span
          className={styles.helpHeaderTypeSwitcher}
          onClick={(e) => checkAuth({ callback: () => setIsOnlyFamily((x) => !x) }, e)}
        >
          {isOnlyFamily
            ? t('select_appt.types_switcher.all')
            : t('select_appt.types_switcher.only_family')}
        </span>
      </div>
      <div className="box-body">
        {isAllDisabled ? (
          <div className="alert alert--warning">
            <div className="text-group--md">
              {t('select_appt.warning.no_cases', { appName: mainConfig.appName })}
            </div>
          </div>
        ) : (
          <div className="row row--equal_height row--mb-10">
            {slicedServicesList.map((item) => (
              <div key={item.id} className="col-md-6 col-xl-4">
                <div
                  className={classNames(styles.helpItem, item.isDisabled && styles.disabled)}
                  onClick={(e) => handleStartBook(e, item.id)}
                >
                  <div className={styles.helpItemBody}>
                    <div className={styles.texClamp2}>{item.name}</div>
                    <PatientGroupTypes service={item} />
                  </div>
                  <div
                    onClick={(e) => handleOpenServiceInfoModal(e, item.id)}
                    className="ct_actions ct_actions--full"
                  >
                    <span className="ct_actions-btn ct_actions-btn--info" />
                  </div>
                </div>
              </div>
            ))}
            {!isFullList && servicesList.length > VISIBLE_SERVICES_LIMIT && (
              <div className="col-md-6 col-xl-4">
                <div
                  onClick={(e) => checkAuth({ callback: () => setIsFullList(true) }, e)}
                  className={classNames(styles.helpItem, styles.helpItemButton)}
                >
                  {t('btn.view_all')}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(SelectService)
