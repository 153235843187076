import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import SignatureCanvas from 'react-signature-canvas';
import classNames from 'classnames';
import { getStylesMode } from 'ziphy-web-shared/basic/helpers';
import styles from './SignaturePad.module.scss';
const SignaturePad = ({ field, mode, initValue, height = 170, canvasProps }) => {
    const { t } = useTranslation();
    const [isActive, setIsActive] = useState(false);
    const { value, onChange, isInvalid } = field;
    let sigPad = {};
    function handleOnChangeStart() {
        // onChange('')
        setIsActive(true);
    }
    function handleOnChangeEnd() {
        const dataURL = sigPad.getTrimmedCanvas().toDataURL('image/png');
        onChange(dataURL);
        setIsActive(false);
    }
    function handleClear() {
        sigPad.clear();
        onChange('');
    }
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: classNames(styles.signature, mode && getStylesMode(mode, styles), isInvalid && styles.hasError), style: { height: height } }, { children: initValue ? (_jsx("div", Object.assign({ className: styles.img }, { children: _jsx("img", { src: initValue, alt: "" }) }))) : (_jsxs(_Fragment, { children: [!isActive && (_jsxs("div", Object.assign({ className: styles.topBar }, { children: [_jsx("div", Object.assign({ className: styles.label }, { children: t('label.use_mouse_or_finger_to_draw_signature') })), value && (_jsx("div", Object.assign({ className: styles.clearButton, onClick: () => handleClear() }, { children: t('btn.clear') })))] }))), _jsx(SignatureCanvas, { onBegin: () => handleOnChangeStart(), onEnd: () => handleOnChangeEnd(), ref: (ref) => (sigPad = ref), canvasProps: Object.assign({}, canvasProps) })] })) })) }));
};
export default SignaturePad;
