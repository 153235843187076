var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseService } from '../baseService';
export class CoSign extends BaseService {
    constructor(props) {
        super(props);
    }
    searchEncounters(params, options) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patient_stories.list_for_cosign', this.clearPayload(params), options);
            return Object.assign(Object.assign({}, res), { preparedValue: ((_b = (_a = res.prepared) === null || _a === void 0 ? void 0 : _a.cosignItemOutputs) === null || _b === void 0 ? void 0 : _b.items) || [] });
        });
    }
    cosignNotes(params, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let res;
            res = yield this.api.fetchLoggedV3('patient_stories.cosign_notes', this.clearPayload(params), options);
            return Object.assign({}, res);
        });
    }
}
