var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { $contactsContexts, ENUMS_CONTACTS_EMAIL_USE, ENUMS_CONTACTS_PHONE_USE, } from 'ziphy-web-shared/basic/entities/contacts';
import { getEnumObjectValue } from 'ziphy-web-shared/basic/helpers';
import { PatientInfoBox } from 'ziphy-web-shared/basic/lib/simple';
import { $loader, $windowSize } from 'ziphy-web-shared/basic/utils';
import { getPhoneNumberDetails } from '@library/phoneNumber/PhoneNumber';
import Badge from '@library/ui/badge/Badge';
import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import { $modal } from '@store';
import SelectContactSystemModal from '../selectContactSystemModal/SelectContactSystem.modal';
import EmailModal from '../systemsModal/Email.modal';
import PhoneModal from '../systemsModal/Phone.modal';
import styles from './ContactsList.module.scss';
const ContactsList = ({ patientId }) => {
    const { t } = useTranslation();
    const $controller = $contactsContexts.get(patientId);
    function handleDelete(e, item) {
        e.stopPropagation();
        confirmAction('customAlert', {
            data: {
                title: t('confirmator.patient_contact.delete.title'),
                message: t('confirmator.patient_contact.delete.message'),
            },
            callback: $loader.registerHandler('delete-patient-contact', () => __awaiter(this, void 0, void 0, function* () {
                yield $controller.delete({ id: item.id });
            })),
        });
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles.list }, { children: $controller.sortedItems.map((item) => {
                    var _a, _b;
                    return item.system === 'phone' ? (_jsx(PatientInfoBox, { onClick: () => $modal.add(PhoneModal, { patientId, initValue: item }), value: getPhoneNumberDetails(item.value).formatted, secondaryValue: _jsx(_Fragment, { children: ((_a = ENUMS_CONTACTS_PHONE_USE[item.use]) === null || _a === void 0 ? void 0 : _a.isPrimary) ? (_jsx(Badge, Object.assign({ type: ['primary-fill', 'sm'] }, { children: t('label.primary') }))) : (_jsx(Badge, Object.assign({ type: ['dark-gray-fill', 'sm'] }, { children: getEnumObjectValue(item.use, ENUMS_CONTACTS_PHONE_USE).alterLabel }))) }), mode: ['hiddenRightElement'], leftElement: _jsx(Icon, { type: "phone" }), rightElement: {
                            onDelete: !$windowSize.isMobileSize
                                ? (e) => handleDelete(e, item)
                                : null,
                        } }, item.id)) : (item.system === 'email' && (_jsx(PatientInfoBox, { onClick: () => $modal.add(EmailModal, { patientId, initValue: item }), value: item.value, secondaryValue: _jsx(_Fragment, { children: ((_b = ENUMS_CONTACTS_EMAIL_USE[item.use]) === null || _b === void 0 ? void 0 : _b.isPrimary) ? (_jsx(Badge, Object.assign({ type: ['primary-fill', 'sm'] }, { children: t('label.primary') }))) : (_jsx(Badge, Object.assign({ type: ['dark-gray-fill', 'sm'] }, { children: getEnumObjectValue(item.use, ENUMS_CONTACTS_EMAIL_USE).alterLabel }))) }), mode: ['hiddenRightElement'], leftElement: _jsx(Icon, { type: "email" }), rightElement: {
                            onDelete: !$windowSize.isMobileSize
                                ? (e) => handleDelete(e, item)
                                : null,
                        } }, item.id)));
                }) })), $controller.contactsLimit.all > 0 && (_jsx(ButtonLegacy, { autoTarget: {
                    onClick: () => $modal.add(SelectContactSystemModal, { patientId }),
                }, mode: ['create', 'block'], label: t('btn.add_contact'), className: $controller.sortedItems.length && 'mt-10' }))] }));
};
export default observer(ContactsList);
