var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useRef } from 'react';
export const useIntervalTimer = ({ callback, duration = 1000, autoStart = true, startOnInit = false, deps = [], }) => {
    const isRunning = useRef(false);
    const timerId = useRef(undefined);
    const started = useRef(undefined);
    const remaining = useRef(duration);
    const start = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!timerId.current) {
            isRunning.current = true;
            if (startOnInit &&
                duration > 0 &&
                started.current === undefined &&
                typeof callback === 'function') {
                yield callback();
            }
            started.current = Date.now();
            clearTimeout(timerId.current);
            const func = () => setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
                if (!isRunning.current) {
                    return;
                }
                if (typeof callback === 'function') {
                    yield callback();
                }
                timerId.current = func();
            }), remaining.current);
            timerId.current = func();
        }
    }), [callback, duration, startOnInit]);
    useEffect(() => {
        if (!timerId.current && autoStart) {
            start();
        }
        return () => {
            stop();
        };
    }, [autoStart, start, ...deps]);
    const pause = useCallback(() => {
        if (timerId.current) {
            stop();
            if (started.current) {
                remaining.current -= Date.now() - started.current;
            }
        }
    }, []);
    const stop = useCallback(() => {
        if (timerId.current) {
            isRunning.current = false;
            clearTimeout(timerId.current);
            timerId.current = undefined;
        }
    }, []);
    return {
        isRunning: isRunning.current,
        started: started.current,
        remaining: remaining.current,
        start,
        pause,
        stop,
    };
};
