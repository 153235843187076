import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { $booking, $modal } from '@store';
import VoucherSelectModal from '../voucherSelectModal/VoucherSelect.modal';
import styles from './Voucher.module.scss';
const Voucher = () => {
    const { t } = useTranslation();
    const openAddVoucherModal = () => {
        $modal.add(VoucherSelectModal);
    };
    const removeVoucher = () => {
        $booking.patients.updateAllSelected(() => ({ voucher: null }));
    };
    useEffect(() => {
        const patients = $booking.patients.items;
        if (patients.some((item) => item.voucher && item.voucher.practiceId !== $booking.practiceId)) {
            removeVoucher();
        }
    }, [$booking.practiceId]);
    const voucher = $booking.patients.assignedVoucher;
    return (_jsx(_Fragment, { children: voucher ? (_jsxs("div", Object.assign({ className: styles.voucher }, { children: [_jsx("span", { children: voucher.code }), _jsx(Icon, { type: 'close', onClick: removeVoucher, className: styles.closeIcon })] }))) : (_jsxs("div", Object.assign({ className: styles.selector, onClick: openAddVoucherModal }, { children: [_jsx(Icon, { type: 'voucher' }), _jsx("span", { children: t('book_details.label.have_a_voucher') })] }))) }));
};
export default observer(Voucher);
