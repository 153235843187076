import { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { myackAxiosInstance } from 'ziphy-web-shared/basic/api'
import { loadScript } from 'ziphy-web-shared/basic/helpers'
import { showAlert } from 'ziphy-web-shared/basic/lib/utilities'
import { setRG } from 'ziphy-web-shared/basic/utils/analytics'

import { config } from '@library/plugins/termsOfService/Tos.config'

import mainConfig from '@config/main'

export const useLoadJson = ({ type }) => {
  const { t } = useTranslation()
  const lang = 'en'

  const document = {
    mapUrl: `/legal/documents.json?v=${Date.now()}`,
    name: config[type]['document'],
  }

  const [json, setJson] = useState(null)
  const extractRef = useRef({})
  const isLoading = json === null

  const getFullUrl = (url) => `${mainConfig.web.tosUrl}${url}`

  useEffect(async () => {
    try {
      const documentsRes = await myackAxiosInstance.get(getFullUrl(document.mapUrl))
      const documents = documentsRes.data

      const tosRes = await myackAxiosInstance.get(getFullUrl(documents[lang][document.name]))
      const tosJson = tosRes.data

      loadScript(getFullUrl(documents[lang]['renderJSON']), () => setJson(tosJson))
    } catch (error) {
      setRG('send', {
        error: `Unable to load tos`,
        customData: { error },
      })

      showAlert.error(t('errors.load.tos'))
    }
  }, [])

  return { json, isLoading, extractRef }
}
