import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { $layouts } from 'ziphy-web-shared/basic/utils/layouts';
import styles from './Notes.module.scss';
const Notes = ({ className }) => {
    const { t } = useTranslation();
    const notesPayload = $layouts.rightSidebar.payload.notes;
    if (!notesPayload) {
        return null;
    }
    const handleClick = () => {
        $layouts.showRightSidebar('notes', notesPayload);
    };
    return (_jsxs("div", Object.assign({ onClick: handleClick, className: classNames(styles.container, className) }, { children: [_jsx(Icon, { type: 'moveLeftArrow' }), t('label.appointment'), "\u00A0", notesPayload.appointmentId] })));
};
export default observer(Notes);
