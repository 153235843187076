import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import classNames from 'classnames'
import _ from 'lodash'
import { DropDown } from 'ziphy-web-shared/basic/lib/functional'
import { $loader } from 'ziphy-web-shared/basic/utils'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import { $appts } from '@store'

import { apptMapActions } from '@config/appts'

const ActionButtons = ({ byRoute }) => {
  const { fullAppt, status, actions } = byRoute || $appts.byRoute

  const preparedList = useMemo(() => {
    let inPanel = [],
      inDropdown = []

    let mapActions = apptMapActions({ fullAppt, status, actions })
    mapActions = _.orderBy(mapActions, ['visible'], ['desc'])

    _.forEach(mapActions, (item) => {
      let hidden = false

      if (item.visible < 0) {
        inDropdown.push(item)
        hidden = true
      } else if (item.visible) {
        if (inPanel.length < 2) {
          inPanel.push(item)
        } else {
          inDropdown.push(item)
          hidden = true
        }
      }

      if (hidden) {
        inPanel.push({ ...item, hidden: true })
      }
    })

    return { inPanel, inDropdown }
  }, [fullAppt, status, actions])

  const handleAction = async (item) => {
    const fn = $loader.registerHandler('appt-handle-action-button-' + item.id, async () => {
      if (!_.isEmpty(item.actionProp)) {
        await item.action(_.get(fullAppt, item.actionProp))
      } else {
        await item.action()
      }
    })
    await fn()
  }

  function getClass(item) {
    return classNames(
      item.primary ? 'btn-primary' : 'btn-secondary',
      item.disabled && 'disabled',
      item.marker && 'red_marker',
      item.hidden && 'btn--hidden',
    )
  }

  return (
    <div className="appt_control-buttons btn-group">
      {preparedList.inPanel.map((item) => (
        <ButtonLegacy
          key={item.id}
          className={getClass(item)}
          isLoading={$loader.isRunHandler('appt-handle-action-button-' + item.id)}
          onClick={() => handleAction(item)}
        >
          {item.label}
        </ButtonLegacy>
      ))}

      {preparedList.inDropdown.length > 0 && (
        <DropDown
          renderButton={({ ref, onToggle }) => (
            <ButtonLegacy ref={ref} onClick={onToggle} mode={['secondary', 'more']} />
          )}
          items={preparedList.inDropdown.map((x) => ({ ...x, onClick: x.action }))}
        />
      )}
    </div>
  )
}

export default observer(ActionButtons)
