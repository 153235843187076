export const dateFormats = {
    en: {
        datetime: 'MMM D, YYYY h:mm A',
        datetimeNoYear: 'MMM D, h:mm A',
        datetimeFullNoYear: 'MMMM D, h:mm A',
        date: 'MMM D, YYYY',
        dateNum: 'MM/DD/YYYY',
        dateOnlyDay: 'D',
        dateOnlyMonth: 'ddd',
        dateFullMonth: 'MMMM',
        dateFullNoDay: 'MMMM YYYY',
        dateFullNoYear: 'MMMM D',
        dateNoYear: 'MMM D',
        dateNoDay: 'MMM YYYY',
        dateFull: 'MMMM D, YYYY',
        time: 'h:mm A',
        iso: 'YYYY-MM-DDTHH:mm:ss',
        isoShort: 'YYYY-MM-DD',
    },
    ru: {
        datetime: 'D MMM, YYYY HH:mm',
        datetimeNoYear: 'D MMM, HH:mm',
        datetimeFullNoYear: 'D MMMM, HH:mm',
        date: 'D MMM, YYYY',
        dateNum: 'DD/MM/YYYY',
        dateOnlyDay: 'D',
        dateOnlyMonth: 'ddd',
        dateFullMonth: 'MMMM',
        dateFullNoDay: 'MMMM YYYY',
        dateFullNoYear: 'D MMMM',
        dateNoYear: 'D MMM',
        dateNoDay: 'MMM YYYY',
        dateFull: 'D MMMM, YYYY',
        time: 'HH:mm',
        iso: 'YYYY-MM-DDTHH:mm:ss',
        isoShort: 'YYYY-MM-DD',
    },
    uk: {
        datetime: 'D MMM, YYYY HH:mm',
        datetimeNoYear: 'D MMM, HH:mm',
        datetimeFullNoYear: 'D MMMM, HH:mm',
        date: 'D MMM, YYYY',
        dateNum: 'DD/MM/YYYY',
        dateOnlyDay: 'D',
        dateOnlyMonth: 'ddd',
        dateFullMonth: 'MMMM',
        dateFullNoDay: 'MMMM YYYY',
        dateFullNoYear: 'D MMMM',
        dateNoYear: 'D MMM',
        dateNoDay: 'MMM YYYY',
        dateFull: 'D MMMM, YYYY',
        time: 'HH:mm',
        iso: 'YYYY-MM-DDTHH:mm:ss',
        isoShort: 'YYYY-MM-DD',
    },
    pl: {
        datetime: 'D MMM, YYYY HH:mm',
        datetimeNoYear: 'D MMM, HH:mm',
        datetimeFullNoYear: 'D MMMM, HH:mm',
        date: 'D MMM, YYYY',
        dateNum: 'DD/MM/YYYY',
        dateOnlyDay: 'D',
        dateOnlyMonth: 'ddd',
        dateFullMonth: 'MMMM',
        dateFullNoDay: 'MMMM YYYY',
        dateFullNoYear: 'D MMMM',
        dateNoYear: 'D MMM',
        dateNoDay: 'MMM YYYY',
        dateFull: 'D MMMM, YYYY',
        time: 'HH:mm',
        iso: 'YYYY-MM-DDTHH:mm:ss',
        isoShort: 'YYYY-MM-DD',
    },
    es: {
        datetime: 'D MMM, YYYY HH:mm',
        datetimeNoYear: 'D MMM, HH:mm',
        datetimeFullNoYear: 'D MMMM, HH:mm',
        date: 'D MMM, YYYY',
        dateNum: 'DD/MM/YYYY',
        dateOnlyDay: 'D',
        dateOnlyMonth: 'ddd',
        dateFullMonth: 'MMMM',
        dateFullNoDay: 'MMMM YYYY',
        dateFullNoYear: 'D MMMM',
        dateNoYear: 'D MMM',
        dateNoDay: 'MMM YYYY',
        dateFull: 'D MMMM, YYYY',
        time: 'HH:mm',
        iso: 'YYYY-MM-DDTHH:mm:ss',
        isoShort: 'YYYY-MM-DD',
    },
};
export const calendarFormats = {
    en: {
        calendarDatetime: {
            sameDay: '[Today], h:mm A',
            nextDay: '[Tomorrow], h:mm A',
            nextWeek: 'MMM D, h:mm A',
            lastDay: '[Yesterday], h:mm A',
            lastWeek: 'MMM D, h:mm A',
            sameElse: 'MMM D, h:mm A',
        },
        calendarDatetimeShort: {
            sameDay: 'h:mm A',
            nextDay: '[Tomorrow], h:mm A',
            nextWeek: 'MMM D, h:mm A',
            lastDay: '[Yesterday], h:mm A',
            lastWeek: 'MMM D, h:mm A',
            sameElse: 'MMM D, h:mm A',
        },
        calendarWithText: {
            sameDay: '[at] h:mm A',
            nextDay: '[Tomorrow], h:mm A',
            nextWeek: 'MMM D, h:mm A',
            lastDay: '[Yesterday], h:mm A',
            lastWeek: 'MMM D, h:mm A',
            sameElse: 'MMM D, h:mm A',
        },
    },
    ru: {
        calendarDatetime: {
            sameDay: '[Today], HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'D MMM, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'D MMM, HH:mm',
            sameElse: 'D MMM, HH:mm',
        },
        calendarDatetimeShort: {
            sameDay: 'HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'D MMM, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'D MMM, HH:mm',
            sameElse: 'D MMM, HH:mm',
        },
        calendarWithText: {
            sameDay: '[at] HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'D MMM, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'D MMM, HH:mm',
            sameElse: 'D MMM, HH:mm',
        },
    },
    uk: {
        calendarDatetime: {
            sameDay: '[Today], HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'D MMM, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'D MMM, HH:mm',
            sameElse: 'D MMM, HH:mm',
        },
        calendarDatetimeShort: {
            sameDay: 'HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'D MMM, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'D MMM, HH:mm',
            sameElse: 'D MMM, HH:mm',
        },
        calendarWithText: {
            sameDay: '[at] HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'D MMM, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'D MMM, HH:mm',
            sameElse: 'D MMM, HH:mm',
        },
    },
    pl: {
        calendarDatetime: {
            sameDay: '[Today], HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'D MMM, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'D MMM, HH:mm',
            sameElse: 'D MMM, HH:mm',
        },
        calendarDatetimeShort: {
            sameDay: 'HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'D MMM, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'D MMM, HH:mm',
            sameElse: 'D MMM, HH:mm',
        },
        calendarWithText: {
            sameDay: '[at] HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'D MMM, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'D MMM, HH:mm',
            sameElse: 'D MMM, HH:mm',
        },
    },
    es: {
        calendarDatetime: {
            sameDay: '[Today], HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'D MMM, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'D MMM, HH:mm',
            sameElse: 'D MMM, HH:mm',
        },
        calendarDatetimeShort: {
            sameDay: 'HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'D MMM, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'D MMM, HH:mm',
            sameElse: 'D MMM, HH:mm',
        },
        calendarWithText: {
            sameDay: '[at] HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'D MMM, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'D MMM, HH:mm',
            sameElse: 'D MMM, HH:mm',
        },
    },
};
