import React from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import _ from 'lodash'
import { formatAmount } from 'ziphy-web-shared/basic/helpers'
import { PatientInfoBox } from 'ziphy-web-shared/basic/lib/simple'
import { Title } from 'ziphy-web-shared/basic/lib/simple'
import { $loader } from 'ziphy-web-shared/basic/utils'
import { $router } from 'ziphy-web-shared/basic/utils/router'
import { formatDate } from 'ziphy-web-shared/basic/utils/time'

import clientService from '@services/client'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'
import PaymentMethodSelector from '@nedoShared/features/paymentMethods/paymentMethodsController/paymentMethodSelector/PaymentMethodSelector'

import { $booking } from '@store'

import mainConfig from '@config/main'

import BookingLayout from '../../library/bookingLayout/BookingLayout'
import withBookingDataInit from '../../library/withBookingDataInit/withBookingDataInit'
import CopyVoucher from '../../pages/bookingFinish/copyVoucher/CopyVoucher'
import PatientItem from '../../pages/bookingFinish/patientItem/PatientItem'
import Voucher from '../../pages/bookingFinish/voucher/Voucher'
import styles from './BookingFinishPage.module.scss'

const BookingFinishPage = () => {
  const { t } = useTranslation()

  const handleBook = $loader.registerHandler('create-appointment', async () => {
    const response = await clientService.createAppointment($booking.bookingPayload)
    if (!response.error && response.prepared) {
      const appointmentId = response.prepared.appointment.id
      $router.executeAutoTarget($booking.schemaConfig?.nextStep({ appointmentId }))
      $booking.clear()
    }
  })

  const formattedTotal = formatAmount($booking.payments.total, $booking.practiceService.currency)
  const formattedPriceForAll = formatAmount(
    $booking.payments.priceForAll,
    $booking.practiceService.currency,
  )

  return (
    <BookingLayout
      title={t('book_details.title')}
      isMobileFooterFixed={true}
      isDesktopFooterFixed={false}
      isLoading={$loader.isRunHandler('create-appointment')}
      button={
        <ButtonLegacy
          mode="primary"
          tag="button"
          buttonType="button"
          disabled={$booking.payments.total > 0 && _.isEmpty($booking.paymentMethod)}
          label={
            $booking.payments.total > 0
              ? t('book_details.btn.price_book_now', { price: formattedTotal })
              : t('book_details.btn.book_now')
          }
          action={handleBook}
        />
      }
    >
      <div className="row row--gutters-5 row--mb-10">
        <div className="col-12">
          <PatientInfoBox
            value={$booking.placeExtended.buildingStreetCity()}
            label={t('book_details.label.address')}
            mode={'reverseContent'}
          />
        </div>
        <div className="col-12 col-lg-6">
          <PatientInfoBox
            value={t('book_details.label.service.to_practice', {
              service: $booking.service.name,
              practice: $booking.practice?.name,
            })}
            label={t('book_details.label.visit')}
            mode={'reverseContent'}
          />
        </div>
        <div className="col-12 col-lg-6">
          <PatientInfoBox
            value={formatDate($booking.selectedTime, {
              format: 'datetimeFullNoYear',
              timezone: $booking.place.timezone,
            })}
            label={t('label.visit_date')}
            mode={'reverseContent'}
          />
        </div>
      </div>

      <Title size={'h2'} className={'mt-30 mb-10'}>
        {t('book_details.label.patients')}
      </Title>
      {$booking.patients.items.map((item, index) => (
        <PatientItem key={item.id} patient={item} number={index + 1} />
      ))}

      {$booking.practiceService.price > 0 && !$booking.patients.isAllUseInsurance && (
        <>
          <div className={classNames(styles.paymentInfo, 'mt-30', 'mb-10')}>
            <Title size={'h2'}>
              {t('book_details.label.total', { amount: formattedTotal })}
              {formattedTotal !== formattedPriceForAll && (
                <span className={styles.priceForAll}>{formattedPriceForAll}</span>
              )}
            </Title>
            {mainConfig.booking.isSelectablePatientInsuranceOrVoucher && <Voucher />}
          </div>
          {$booking.voucher && <CopyVoucher className={styles.copyVoucher} />}
          <PaymentMethodSelector
            selectedId={$booking.paymentMethodId}
            onChangeSelectedId={$booking.SET_PAYMENT_METHOD_ID}
          />
        </>
      )}
    </BookingLayout>
  )
}

export default withBookingDataInit('finish', observer(BookingFinishPage))
