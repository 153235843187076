import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { firstLetters, isBase64, isUrl, splitName } from 'ziphy-web-shared/basic/helpers';
const UserPhoto = ({ item, size = '', isGray }) => {
    const { avatar, updated } = item;
    const image = useMemo(() => {
        if (_.isEmpty(_.trim(avatar)) || _.isPlainObject(avatar)) {
            return false;
        }
        if (isBase64(avatar)) {
            return avatar;
        }
        if (!isUrl(avatar)) {
            return `data:image;base64,${avatar}`;
        }
        if (updated) {
            return `${avatar}?cache=${new Date(updated).getTime()}`;
        }
        return avatar;
    }, [avatar, updated]);
    if (image) {
        return (_jsx("div", Object.assign({ className: classNames('user_photo', size && 'user_photo--' + size, isGray && 'user_photo--gray') }, { children: _jsx("img", { src: image, alt: "" }) })));
    }
    function getNameLetters(item) {
        let prepared;
        if (typeof item.firstName === 'undefined' && item.name) {
            prepared = splitName(item.name);
        }
        else {
            prepared = [item.firstName, item.lastName];
        }
        return firstLetters(prepared);
    }
    return (_jsx("div", Object.assign({ className: classNames('user_photo', 'user_photo--empty', size && 'user_photo--' + size, isGray && 'user_photo--gray') }, { children: getNameLetters(item) })));
};
export default UserPhoto;
