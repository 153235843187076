import { useLocation } from 'react-router-dom'

import _ from 'lodash'
import queryString from 'query-string'
import { $auth } from 'ziphy-web-shared/basic/entities/auth'
import { $settings } from 'ziphy-web-shared/basic/entities/settings'
import { useLoader, useRouterSearchParams } from 'ziphy-web-shared/basic/hooks'
import { $layouts } from 'ziphy-web-shared/basic/utils/layouts'

import maps from '@library/map/Map'

import mainConfig from '@config/main'

export const useAppInitLoader = () => {
  const searchParams = useRouterSearchParams()
  const location = useLocation()

  const { isLoading } = useLoader(async () => {
    // await devService.availableMethodsList(['appointments'])

    await Promise.all([
      $settings.init(), // $practices.items.list({ limit: 1000 }),
      maps.mapLoader(),
    ])

    if (mainConfig.auth.loginToken) {
      //
      // mb move for separate helper const { token, extraParams } = getExtraParams()
      //
      // ONLY FOR DEV
      // await devService.reconfigure()
      // await devService.getConfig()
      // ONLY FOR DEV

      let allSearchParams = searchParams.getAll()
      let token = allSearchParams[mainConfig.auth.loginToken]

      if (!token && location.hash) {
        const hash = _.trimStart(_.trimStart(location.hash, '#'), '?')
        allSearchParams = queryString.parse(hash, { parseBooleans: true, parseNumbers: true })
        allSearchParams = _.mapValues(allSearchParams, (item, key) => {
          if (key === 'serviceCodes') {
            return (_.isArray(item) ? item : [item])
              .map((x) => _.split(x, ','))
              .filter((x) => x[1] > 0)
              .map((x) => x[0])
          }
          return item
        })
        token = allSearchParams[mainConfig.auth.loginToken]
      }

      const extraParams = _.pick(allSearchParams, mainConfig.auth.loginExtraParams)
      //
      // mb move for separate helper const { token, extraParams } = getExtraParams()
      //

      if (token) {
        const response = await $auth.loginByToken({ token, extraParams })

        if (response.error) {
          //
        } else if (response.prepared) {
          if (response.prepared.extraData) {
            const extraData = { extraParams, ...response.prepared.extraData }
            $layouts.SET_INIT_EXTRA_DATA(extraData)
          }
        }
      }
    }

    await $auth.loadProfile(() => true)
  })

  return { isInitialized: !isLoading }
}
