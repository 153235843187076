import { jsx as _jsx } from "react/jsx-runtime";
import React, { Fragment, memo, useLayoutEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import classNames from 'classnames';
import { defaultStyles } from './';
import { calculatePositions, getClassNames } from './ConnectedGroup.helpers';
import s from './ConnectedGroup.module.scss';
const ConnectedGroup = ({ renderItems, type = 'extended', styleSet = type === 'simple' ? ['setCorners', 'resetBorders'] : ['resetCorners', 'resetBorders'], gap = 0, wrap = false, asColumn = false, compareMode = 'both', className, }) => {
    const [positions, setPositions] = useState([]);
    const lines = new Set(positions).size;
    const { ref: containerRef, width, height, } = useResizeDetector({
    // refreshMode: 'debounce',
    // refreshRate: 500,
    });
    useLayoutEffect(() => {
        if (!type || !containerRef.current)
            return;
        const newPositions = calculatePositions(containerRef.current, compareMode);
        setPositions(newPositions);
    }, [type, renderItems, width, height]);
    return (_jsx("div", Object.assign({ ref: containerRef, className: classNames(s.group, lines > 0 && s.initialized, wrap && s.wrap, asColumn && s.asColumn, className), style: { gap } }, { children: renderItems.filter(Boolean).map((item, index) => {
            const line = positions[index] || 0;
            const isFirstInLine = positions[index - 1] !== line;
            const isLastInLine = positions[index + 1] !== line;
            const tags = getClassNames(type, lines, line, isFirstInLine, isLastInLine);
            return (_jsx(Fragment, { children: item({
                    getClass(arr = styleSet) {
                        let result = [];
                        arr = Array.isArray(arr) ? arr : [arr];
                        arr.forEach((item) => {
                            const obj = typeof item === 'string' ? defaultStyles[item] : item;
                            if (obj)
                                tags.forEach((x) => result.push(obj[x]));
                        });
                        return result.join(' ');
                    },
                    line,
                    lines,
                    tags,
                }) }, index));
        }) })));
};
export default memo(ConnectedGroup);
