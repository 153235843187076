import React, { Fragment, useMemo } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'
import queryString from 'query-string'
import { documentsService } from 'ziphy-web-shared/basic/api'
import { $auth } from 'ziphy-web-shared/basic/entities/auth'
import { getStagedEnv, humanize, isLocal } from 'ziphy-web-shared/basic/helpers'
import { PatientInfoBox } from 'ziphy-web-shared/basic/lib/simple'
import { showAlert } from 'ziphy-web-shared/basic/lib/utilities'
import { $loader } from 'ziphy-web-shared/basic/utils'

import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import { apptDocumentsFormList } from '@config/apptDocumentsFormsList'
import mainConfig from '@config/main'

import styles from './ApptFillOutForms.module.scss'

const FillOutFormsModal = ({ practiceId, encounterId, _core }) => {
  const { t } = useTranslation()

  function getOpenBlank(obj, config) {
    obj = _.cloneDeep(obj)

    obj.tag = 'a'
    obj.to = config.link
    obj.onClick = async (e) => {
      e.preventDefault()
      window.open(config.link, '_blank')
      _core.onHide()
    }

    return obj
  }

  function getGetSurvey(obj, config, buttonType) {
    obj = _.cloneDeep(obj)

    if (isLocal() && buttonType) {
      obj.id = `${obj.id}[${buttonType}]`
      obj.label = `${obj.label}[${buttonType}]`
      obj.buttonType = buttonType
    }

    obj.tag = 'div'
    obj.onClick = async (e) => {
      e.preventDefault()

      const response = await documentsService.getSurvey({
        documentTemplateId: config.documentTemplateId,
        encounterId,
      })

      if (response.error) {
        return showAlert.error(response.error.message)
      }

      const queryParams = queryString.stringify({
        ...response.prepared,
        backUrl: window.location.href,
      })

      let resultUrl

      if (isLocal() && ['pdfHtml', 'pdfRender'].includes(buttonType)) {
        let targetUrl = 'http://localhost:3235' + config.devPdfLink
        resultUrl = [targetUrl, queryParams].join('?')

        if (buttonType === 'pdfHtml') {
        } else if (buttonType === 'pdfRender') {
          resultUrl = [`http://localhost:3315?url=${encodeURIComponent(resultUrl)}`]
        }
      } else {
        const targetUrl = mainConfig.web['formsUrl'] + config.link
        resultUrl = [targetUrl, queryParams].join('?')
      }

      window.open(resultUrl, '_blank')

      if (!isLocal()) {
        _core.onHide()
      }
    }

    return obj
  }

  const practice = $auth.availablePractices.find((x) => x.id === practiceId)

  const preparedList = useMemo(() => {
    let result = []

    _.forEach(apptDocumentsFormList, (item) => {
      const groupByEnv = item[getStagedEnv()]
      if (!groupByEnv) return

      let targetFormsList = {}

      Object.entries(groupByEnv).forEach(([ids, value]) => {
        ids.split('_').forEach((id) => {
          targetFormsList[id] = value
        })
      })

      let config = targetFormsList[practice.id]
      if (!config) {
        if (targetFormsList.any) {
          config = targetFormsList.any
        } else if (
          _.includes(
            practice?.documentTemplateIds,
            targetFormsList.byPracticeConfig?.documentTemplateId,
          )
        ) {
          config = targetFormsList.byPracticeConfig
        } else {
          return
        }
      }

      let tmp = { id: item.id, label: t(item.label), config }

      if (config.type === 'openBlank') {
        tmp = getOpenBlank(tmp, config)
      } else if (config.type === 'getSurvey') {
        tmp = getGetSurvey(tmp, config)
      } else {
        return
      }

      tmp.onClick = $loader.registerHandler('fill-out-forms-' + item.id, tmp.onClick)

      result.push(tmp)
    })

    result = _.orderBy(result, ['label'], ['asc'])

    return result
  }, [practice, encounterId])

  return (
    <Modal centered scrollable>
      <div className="modal-toolbar">
        <ModalToolbarBtn type="close" action={_core.onHide} />
      </div>
      <Modal.Header>{t('label.fill_out_forms')}</Modal.Header>
      <Modal.Body className="styled_scroll">
        <div>
          {preparedList.map((item) => {
            const isLocalSurvey =
              isLocal() && item.config.type === 'getSurvey' && item.config.devPdfLink

            let devItems = []
            if (isLocalSurvey) {
              devItems.push(getGetSurvey(item, item.config, 'pdfHtml'))
              devItems.push(getGetSurvey(item, item.config, 'pdfRender'))
            }

            return (
              <Fragment key={item.id}>
                <PatientInfoBox
                  href={item.to}
                  onClick={item.onClick}
                  value={item.label}
                  label={isLocalSurvey && <small>{item.id}</small>}
                  className={'mb-10'}
                  withArrow
                  isLoading={$loader.isRunHandler('fill-out-forms-' + item.id)}
                />
                {devItems.map((x) => (
                  <ButtonLegacy
                    key={x.id}
                    to={x.to}
                    action={x.onClick}
                    mode={['sm', 'blue']}
                    className={styles.devButton}
                    isLoading={$loader.isRunHandler('fill-out-forms-' + x.id)}
                  >
                    {humanize(_.snakeCase(x.buttonType))}
                  </ButtonLegacy>
                ))}
              </Fragment>
            )
          })}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default observer(FillOutFormsModal)
