import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import withBookingDataInit from '../../library/withBookingDataInit/withBookingDataInit';
import SelectAddress from '../../pages/bookingHome/selectAddress/SelectAddress';
import SelectPractice from '../../pages/bookingHome/selectPractice/SelectPractice';
import SelectService from '../../pages/bookingHome/selectService/SelectService';
const BookingHome = () => {
    return (_jsxs(_Fragment, { children: [_jsx(SelectAddress, {}), _jsx(SelectPractice, {}), _jsx(SelectService, {})] }));
};
export default withBookingDataInit('home', observer(BookingHome));
