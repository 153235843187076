import { useCallback, useEffect, useRef, useState } from 'react'

import { useResizeDetector } from 'react-resize-detector'

import _ from 'lodash'

// deprecated
export const useTimer = ({ callback, duration, autoStart = true }) => {
  const [isRunning, setIsRunning] = useState(true)

  const timerId = useRef()
  const started = useRef()
  const remaining = useRef(duration)

  const start = useCallback(() => {
    if (!timerId.current) {
      setIsRunning(true)

      started.current = Date.now()
      clearTimeout(timerId.current)

      timerId.current = setTimeout(() => {
        stop()

        if (_.isFunction(callback)) {
          callback()
        }
      }, remaining.current)
    }
  }, [callback])

  useEffect(() => {
    if (!timerId.current && autoStart) {
      start()
    }

    return () => stop()
  }, [])

  function pause() {
    if (timerId.current) {
      stop()

      remaining.current -= Date.now() - started.current
    }
  }

  function stop() {
    if (timerId.current) {
      setIsRunning(false)
      clearTimeout(timerId.current)
      timerId.current = undefined
    }
  }

  return {
    isRunning,
    started: started.current,
    remaining: remaining.current,
    start,
    pause,
    stop,
  }
}

// deprecated
export const useInterval = ({
  callback,
  interval = 1000,
  autoStart = true,
  skipStartCallback = false,
  onStart = () => {},
  deps = [],
}) => {
  const [isRunning, setIsRunning] = useState(true)
  const [isStarted, setIsStarted] = useState(false)

  const timerId = useRef()
  const started = useRef()

  const start = useCallback(
    (data) => {
      if (!timerId.current) {
        setIsStarted(true)

        const func = async () => {
          await onStart(data)
          setIsRunning(true)

          started.current = Date.now()
          stop()

          timerId.current = setInterval(() => {
            if (_.isFunction(callback)) {
              callback()
            }
          }, interval)

          if (_.isFunction(callback) && !skipStartCallback) {
            callback()
          }
        }

        func()
      }
    },
    [callback, interval, onStart, skipStartCallback],
  )

  useEffect(() => {
    if (!timerId.current && autoStart) {
      start()
    }

    return () => stop()
  }, [...deps])

  function stop() {
    if (timerId.current) {
      setIsRunning(false)
      clearInterval(timerId.current)
      timerId.current = undefined
    }
  }

  return {
    isRunning,
    isStarted,
    started: started.current,
    start,
    stop,
  }
}

export const useChatResizeDetector = ({ wrapperRef, stopDetecting = false }) => {
  const [lastHeight, setLastHeight] = useState(0)
  const [lastScrollTop, setLastScrollTop] = useState(0)

  const wrapperOnResize = useCallback(
    (width, height) => {
      const delta = lastHeight - height
      if (lastHeight > 0 && !stopDetecting) {
        wrapperRef.current.scrollTo({ top: lastScrollTop + delta })
      }
      setLastHeight(height)
    },
    [wrapperRef, lastHeight, lastScrollTop, stopDetecting],
  )

  useResizeDetector({ onResize: wrapperOnResize, targetRef: wrapperRef, refreshMode: 'throttle' })

  return { setLastScrollTop }
}
