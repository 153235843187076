import React, { forwardRef, useEffect } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import Icon from 'ziphy-web-shared/basic/assets/images/Icon'
import { getUserText } from 'ziphy-web-shared/basic/helpers'
import { useForm } from 'ziphy-web-shared/basic/lib/forms'
import { PatientInfoBox } from 'ziphy-web-shared/basic/lib/simple'

import InputBase from '@library/form/fields/new/InputBase'
import PhoneNumber, { preparePhone } from '@library/phoneNumber/PhoneNumber'

import mainConfig from '@config/main'

import styles from './PatientDetails.module.scss'

const PatientDetails = forwardRef(({ patient, onClickSave, onClickRemove, className }, ref) => {
  const { t } = useTranslation()

  const { form } = useForm({
    email: { value: patient.email || '', rules: ['email'], output: 'emptyNull' },
    phone: { value: preparePhone(patient.phone), rules: ['phone'], output: ['phone', 'emptyNull'] },
  })

  const { email, phone } = form.fields

  useEffect(() => {
    ref.current.set(patient.id, form)
    return () => {
      delete ref.current.delete(patient.id)
    }
  }, [form])

  return (
    <div className={className}>
      <div className={styles.header}>
        <p className={styles.title}>{t('label.patient_details')}</p>
        <div className={styles.iconsContainer}>
          {onClickSave && (
            <div className={styles.icon} onClick={async () => await onClickSave(form)}>
              <Icon type="check" />
            </div>
          )}
          {mainConfig.booking.isPatientsRemovable && (
            <div className={styles.icon} onClick={onClickRemove}>
              <Icon type="trash" />
            </div>
          )}
        </div>
      </div>
      <div className={styles.body}>
        <PatientInfoBox
          value={getUserText(patient, ['fullName', 'gender', 'yo'])}
          label={t('book.label.patient')}
          className="mb-10"
          mode={['reverseContent']}
        />
        <InputBase
          className="mb-10"
          type="email"
          label={t('book.label.email')}
          field={email}
          placeholder={t('book.ph.email')}
          mode={['lg', 'gray-without-border', 'value-bold']}
        />
        <InputBase
          label={t('label.phone')}
          field={phone}
          mode={['lg', 'gray-without-border', 'value-bold']}
          className="mt-0"
        >
          <PhoneNumber
            className={'phone_number--pt-0'}
            popupClass={'phone_number-popup--on_top'}
            phone={phone.value}
            onChange={phone.onChange}
          />
        </InputBase>
      </div>
    </div>
  )
})

export default observer(PatientDetails)
