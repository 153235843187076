import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { configure } from 'mobx';
import { Router } from 'react-router-dom';
import { getInitConfig } from 'ziphy-web-shared';
import { initServices } from 'ziphy-web-shared/basic/api';
import { APP_BRAND, APP_REVISION, APP_TYPE, APP_VERSION } from 'ziphy-web-shared/basic/helpers';
import { initWebView, localStore, loggableSession, useInitWindowSize, webView, } from 'ziphy-web-shared/basic/utils';
import { analytics, initAnalytics, setRG } from 'ziphy-web-shared/basic/utils/analytics';
import { initI18N } from 'ziphy-web-shared/basic/utils/i18n';
import { initRouter } from 'ziphy-web-shared/basic/utils/router';
import App from '@src/App';
const Initializer = () => {
    const [isCommonLoading, setIsCommonLoading] = useState(true);
    const initConfig = getInitConfig();
    const $router = initRouter(initConfig.hashRouter, initConfig.routes);
    useEffect(() => {
        var _a, _b;
        configure({
            enforceActions: 'always',
        });
        loggableSession.init({
            appType: APP_TYPE,
            appVersion: APP_VERSION,
            appRevision: APP_REVISION,
            renderType: webView.isWebView ? 'webView' : null,
            getAdditional: () => { var _a; return ((_a = localStore.get('session')) === null || _a === void 0 ? void 0 : _a.sessionId) || ''; },
        });
        (_b = (_a = document.documentElement) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.add(APP_TYPE);
    }, []);
    // Init ZiphyShared
    const [i18nIsLoading, setI18nIsLoading] = useState(!!initConfig.i18n);
    useInitWindowSize();
    // Other
    useEffect(() => {
        if (i18nIsLoading) {
            if (initConfig.analytics) {
                initAnalytics(initConfig.analytics, {
                    appEnv: initConfig.appEnv,
                    appType: APP_TYPE,
                    appBrand: APP_BRAND,
                    appVersion: APP_VERSION,
                    appRevision: APP_REVISION,
                    getCustomData: () => loggableSession.get(),
                });
            }
            if (initConfig.i18n) {
                initI18N(Object.assign({ onIsLoading: setI18nIsLoading }, initConfig.i18n));
            }
            initServices(initConfig.apiService);
            if (initConfig.webView) {
                initWebView(initConfig.webView);
            }
        }
        else {
            $router.history.listen((location) => {
                setRG('trackEvent', {
                    type: 'pageView',
                    path: location.pathname + location.hash,
                });
                analytics.nav(location.pathname + location.hash);
            });
            setIsCommonLoading(false);
        }
    }, [i18nIsLoading]);
    const isLoading = isCommonLoading || i18nIsLoading;
    return isLoading ? (_jsx(_Fragment, {})) : (
    // <RouterProvider router={$router.instance}>
    _jsx(Router, Object.assign({ history: $router.history }, { children: _jsx(App, {}) })));
};
export default Initializer;
