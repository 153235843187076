import React from 'react'

import { observer } from 'mobx-react-lite'

import ApptConference from '@pages/appt/apptConference/ApptConference'
import ActionButtons from '@pages/appt/apptMap/ActionButtons'

import { $appts } from '@store'

const ApptControl = ({ byRoute = undefined }) => {
  const { status } = byRoute || $appts.byRoute
  const { texts } = status

  return (
    <div className="appt_control">
      <div className="appt_control-address">
        {texts && texts.map.label && (
          <div className="appt_control-address-label">{texts.map.label}</div>
        )}
        {texts && texts.map.meta && (
          <div className="appt_control-address-meta">{texts.map.meta}</div>
        )}
      </div>
      <ApptConference byRoute={byRoute} isMap={true} />
      <ActionButtons byRoute={byRoute} />
    </div>
  )
}

export default observer(ApptControl)
