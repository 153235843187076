import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import _ from 'lodash';
import { useInnerValue } from 'ziphy-web-shared/basic/hooks';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
const AutoTextarea = forwardRef(({ value = '', placeholder, onChange, onSubmit, minRows = 1, maxRows = 5, className = '', disabled, }, ref) => {
    const [innerValue, setInnerValue] = useInnerValue(value);
    const [height, setHeight] = useState('auto');
    const [parentHeight, setParentHeight] = useState('auto');
    const textareaRef = useRef(null);
    const lineHeight = useMemo(() => {
        if (!textareaRef || !(textareaRef === null || textareaRef === void 0 ? void 0 : textareaRef.current))
            return null;
        return parseInt(window.getComputedStyle(textareaRef.current).getPropertyValue('line-height'));
    }, [textareaRef === null || textareaRef === void 0 ? void 0 : textareaRef.current]);
    useEffect(() => {
        if (!textareaRef || !textareaRef.current)
            return;
        let scrollHeight = textareaRef.current.scrollHeight;
        let minHeight = lineHeight * minRows;
        let maxHeight = lineHeight * maxRows;
        if (_.isEmpty(innerValue)) {
            scrollHeight = minHeight;
        }
        if (maxRows > 0 && scrollHeight > maxHeight) {
            scrollHeight = maxHeight;
        }
        setHeight(scrollHeight + 'px');
        setParentHeight(scrollHeight + 'px');
    }, [ref, innerValue, lineHeight, minRows, maxRows]);
    function handleOnChange(e) {
        setHeight('auto');
        const value = e.target.value;
        setInnerValue(value);
        if (_.isFunction(onChange)) {
            onChange(value);
        }
    }
    function handleOnKeyDown(e) {
        if (!$windowSize.isMobile && e.keyCode === 13 && !e.shiftKey && _.isFunction(onSubmit)) {
            e.preventDefault();
            onSubmit(e);
        }
    }
    useImperativeHandle(ref, () => ({
        focus: () => textareaRef === null || textareaRef === void 0 ? void 0 : textareaRef.current.focus(),
    }));
    return (_jsx("div", Object.assign({ style: { width: '100%', height: parentHeight } }, { children: _jsx("textarea", { ref: textareaRef, rows: minRows, style: { height }, className: classNames('styled_scroll', className), value: innerValue, onChange: handleOnChange, onKeyDown: handleOnKeyDown, placeholder: placeholder, disabled: disabled }) })));
});
export default observer(AutoTextarea);
