import React, { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import _ from 'lodash'
import { getUserText } from 'ziphy-web-shared/basic/helpers'
import { PatientInfoBox } from 'ziphy-web-shared/basic/lib/simple'
import { $loader } from 'ziphy-web-shared/basic/utils'
import { $router } from 'ziphy-web-shared/basic/utils/router'

import UserPhoto from '@library/user/UserPhoto'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import { $booking, $modal } from '@store'

import mainConfig from '@config/main'

import BookingLayout from '../../library/bookingLayout/BookingLayout'
import withBookingDataInit from '../../library/withBookingDataInit/withBookingDataInit'
import PatientDetails from '../../pages/bookingSelectPatients/patientDetails/PatientDetails'
import styles from './BookingSelectPatients.module.scss'
import SelectPatientModal from './selectPatientModal/SelectPatient.modal'

const BookingSelectPatientsPage = () => {
  const { t } = useTranslation()

  const [openIds, setOpenIds] = useState([])
  const openFormsMapRef = useRef(new Map())

  const showSelectPatientModal = () => {
    $modal.add(SelectPatientModal, {
      selectedIds: $booking.patients.selectedIds,
      onSelect: (patient) => {
        $booking.patients.addSelected(patient.id)
      },
    })
  }

  useEffect(() => {
    if (!$booking.patients.items.length) {
      showSelectPatientModal()
    }
  }, [])

  const updatePatientDetails = async (patientId, updateOriginal) => {
    const form = openFormsMapRef.current.get(patientId)

    if (form) {
      const validation = form.validateAll()

      if (validation.isValid) {
        const { phone, email } = form.values
        return $booking.patients.updateSelected(patientId, { phone, email }, updateOriginal)
      }
    }

    return false
  }

  const openForm = (patientId) => setOpenIds((prevIds) => [...prevIds, patientId])
  const closeForm = (patientId) => setOpenIds((prevIds) => prevIds.filter((x) => x !== patientId))

  const handleNext = $loader.registerHandler('booking-next', async () => {
    let isFormsValid = true

    for (const [patientId] of openFormsMapRef.current.entries()) {
      const res = await updatePatientDetails(patientId, true)
      if (!res) isFormsValid = false
    }

    if (isFormsValid) {
      if ($booking.patients.invalidList.length) {
        $booking.patients.invalidList.forEach((item) => {
          openForm(item.id)
          setTimeout(() => openFormsMapRef.current.get(item.id)?.validateAll())
        })
      } else {
        $router.executeAutoTarget($booking.schemaConfig?.nextStep())
      }
    }
  })

  const isFamilyType = $booking.service?.patientGroupType === 'family'

  return (
    <BookingLayout
      breadcrumbs={['place', 'service']}
      title={t('book.title.who_needs_help')}
      isMobileFooterFixed={$booking.patients.items.length > 0}
      button={
        <ButtonLegacy
          mode="primary"
          tag="button"
          buttonType="button"
          disabled={_.isEmpty($booking.patients.items)}
          label={t('btn.next')}
          action={handleNext}
          isLoading={$loader.isRunHandler('booking-next')}
        />
      }
    >
      {$booking.patients.items.map((patient, index) =>
        openIds.includes(patient.id) || !isFamilyType ? (
          <PatientDetails
            key={patient.id}
            ref={openFormsMapRef}
            className={styles.patientItemDetails}
            patient={patient}
            onClickSave={
              isFamilyType
                ? async () => {
                    const res = await updatePatientDetails(patient.id, true)
                    if (res) closeForm(patient.id)
                  }
                : undefined
            }
            onClickRemove={() => {
              $booking.patients.removeSelected(patient.id)
              closeForm(patient.id)
            }}
          />
        ) : (
          <PatientInfoBox
            key={patient.id}
            value={getUserText(patient, ['fullName'])}
            label={getUserText(patient, ['gender', 'yo'])}
            leftElement={<UserPhoto item={patient} />}
            className={styles.patientItem}
            rightElement={{
              onEdit: () => openForm(patient.id),
              ...(mainConfig.booking.isPatientsRemovable && {
                onDelete: () => $booking.patients.removeSelected(patient.id),
              }),
            }}
          />
        ),
      )}
      {(isFamilyType || $booking.patients.items.length === 0) && (
        <ButtonLegacy
          mode={['create', 'block']}
          className={classNames({ 'mt-30': $booking.patients.items.length })}
          action={showSelectPatientModal}
          label={t('btn.add_patient')}
        />
      )}
    </BookingLayout>
  )
}

export default withBookingDataInit('patients', observer(BookingSelectPatientsPage))
