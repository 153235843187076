import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { showAlert } from 'ziphy-web-shared/basic/lib/utilities';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import { prepareAddress } from '@fhir/fhir.converters';
import { $modal } from '@store';
import AddAddressModal from './AddAddress.modal';
const ChooseAddressTypeModal = ({ patientId, existingAddresses, _core }) => {
    const { t } = useTranslation();
    const existingAddressTypes = existingAddresses.map((x) => x.localType);
    const handleOpenAddAddressModal = (params, withResidential) => {
        const extractedAddress = prepareAddress(params);
        if (_.includes(existingAddressTypes, extractedAddress.localType)) {
            showAlert.error(t('add_address.error.only_one_each_type'));
            return;
        }
        _core.onHide();
        $modal.add(AddAddressModal, {
            patientId,
            address: prepareAddress(params),
            residentialAddress: withResidential && _.find(existingAddresses, (x) => x.localType === 'residential'),
        });
    };
    return (_jsxs(Modal, Object.assign({ centered: true }, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: () => _core.onHide(false) }) })), _jsx(Modal.Header, Object.assign({ className: "text-lg-center" }, { children: t('add_address.title') })), _jsxs(Modal.Body, { children: [_jsx(ButtonLegacy, { tag: "button", mode: ['secondary-dark'], className: "btn-block", label: t('address.label.address_residential'), disabled: _.includes(existingAddressTypes, 'residential'), action: () => handleOpenAddAddressModal({ use: 'home', type: 'physical' }) }), _jsx(ButtonLegacy, { tag: "button", mode: ['secondary-dark'], className: "btn-block mt-10", label: t('address.label.address_billing'), disabled: _.includes(existingAddressTypes, 'billing'), action: () => handleOpenAddAddressModal({ use: 'billing', type: 'postal' }, true) }), _jsx(ButtonLegacy, { tag: "button", mode: ['secondary-dark'], className: "btn-block mt-10", label: t('address.label.address_mailing'), disabled: _.includes(existingAddressTypes, 'mailing'), action: () => handleOpenAddAddressModal({ use: 'home', type: 'postal' }, true) })] })] })));
};
export default ChooseAddressTypeModal;
