import _ from 'lodash';
import { getBodyMassIndex } from 'ziphy-web-shared/basic/helpers';
import { trendsListConfig } from '@library/trendsList/TrendsList.config';
import { getCodingObjects } from '@fhir/fhir.helpers';
const getPreparedItem = (item, valueQuantity) => {
    const value = valueQuantity.value;
    return {
        date: item.effectiveDateTime,
        value: value,
        valueAsString: [value, valueQuantity.unit].join(' '),
        id: item.id,
    };
};
export const measurementsResponseAdapter = (groups) => {
    const prepared = [];
    _.forEach(groups, (group) => {
        const codeObj = getCodingObjects(group.code).first();
        const result = {
            label: codeObj === null || codeObj === void 0 ? void 0 : codeObj.display,
            code: codeObj === null || codeObj === void 0 ? void 0 : codeObj.code,
            unit: '',
            items: [],
        };
        const uniqMap = new Map();
        const setUnit = (unit) => {
            if (!result.unit && unit) {
                result.unit = unit;
            }
        };
        const isUniq = (preparedItem, index = 0) => {
            const uniqKey = [preparedItem.date, preparedItem.value, index].join('|');
            if (uniqMap.get(uniqKey))
                return false;
            uniqMap.set(uniqKey, true);
            return true;
        };
        _.forEach(group.resources, (item) => {
            if (!item.valueQuantity) {
                _.forEach(item.component, (component, index) => {
                    setUnit(component.valueQuantity.unit);
                    const preparedItem = getPreparedItem(item, component.valueQuantity);
                    if (!isUniq(preparedItem, index))
                        return;
                    if (!result.items[index])
                        result.items[index] = [];
                    result.items[index].push(preparedItem);
                });
            }
            else {
                setUnit(item.valueQuantity.unit);
                const preparedItem = getPreparedItem(item, item.valueQuantity);
                if (!isUniq(preparedItem))
                    return;
                if (!result.items.length)
                    result.items[0] = [];
                result.items[0].push(preparedItem);
            }
        });
        if (result.items.length) {
            // blood pressure - overlapping higher line background color with bottom line
            if (result.items.length > 1) {
                result.items.reverse();
            }
            prepared.push(result);
        }
    });
    return prepared;
};
export const getBMIMeasurement = (height, weight) => {
    const result = {
        label: 'Body mass index',
        code: trendsListConfig.bodyMassIndex.code,
        unit: 'bmi',
        items: [],
    };
    _.forEach(weight.items, (items, arrayIndex) => {
        _.forEach(items, (item, elementIndex) => {
            var _a, _b, _c;
            const bmi = getBodyMassIndex(item.value, (_c = (_b = (_a = height.items) === null || _a === void 0 ? void 0 : _a[arrayIndex]) === null || _b === void 0 ? void 0 : _b[elementIndex]) === null || _c === void 0 ? void 0 : _c.value);
            if (!result.items[arrayIndex]) {
                result.items[arrayIndex] = [];
            }
            result.items[arrayIndex].push({
                id: item.id,
                date: item.date,
                value: +bmi,
                valueAsString: bmi,
            });
        });
    });
    return result;
};
