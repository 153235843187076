import React, { useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'
import Icon from 'ziphy-web-shared/basic/assets/images/Icon'
import { useLoader } from 'ziphy-web-shared/basic/hooks'
import Checkbox from 'ziphy-web-shared/basic/lib/forms/fields/checkbox/Checkbox'

import InputSearch from '@library/form/fields/InputSearch'
import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import { $booking, $modal } from '@store'

import mainConfig from '@config/main'

import AddPlaceModal from '../../../library/addPlaceModal/AddPlace.modal'
import styles from './SelectAddress.modal.module.scss'

const SelectAddressModal = ({ _core }) => {
  const { t } = useTranslation()

  let [selected, setSelected] = useState([$booking.placeId])
  let [searchString, setSearchString] = useState('')

  useEffect(() => {
    setSelected([$booking.placeId])
  }, [$booking.placeId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selected[0]) {
      const place = $booking.$places.getById(selected[0])
      if (!place) {
        setSelected([$booking.placeId])
      }
    }
  }, [$booking.$places.items]) // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading } = useLoader(async () => {
    await $booking.$places.get()
  })

  const isValidForm = !!selected.length

  const preparedList = useMemo(() => {
    let result = $booking.$places.extendedItems

    if (_.trim(searchString)) {
      let needle = _.trim(searchString).toLowerCase().split(' ')

      result = result.filter((x) => {
        let full = [x.address()].join(' ').toLowerCase()
        return _.every(needle, (n) => full.includes(n))
      })
    }

    return result
  }, [$booking.$places.extendedItems, searchString]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (value, name) => {
    let result = selected.slice()
    const index = result.indexOf(name)

    if (index !== -1) {
      result = []
    } else if (value) {
      result = [name]
    }

    setSelected(result)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (isValidForm) {
      $booking.SET_PLACE_ID(selected[0])
      _core.onHide()
    }
  }

  return (
    <Modal centered scrollable>
      <div className="modal-toolbar">
        <ModalToolbarBtn type="close" action={_core.onHide} />
      </div>
      <Modal.Header>{t('addresses.title')}</Modal.Header>
      <Modal.Body isLoading={isLoading}>
        {$booking.$places.items.length > mainConfig.searchIfMore - 10 && (
          <InputSearch
            className="mb-20"
            field={{ value: searchString, onChange: setSearchString }}
            placeholder={t('addresses.ph.search')}
          />
        )}
        <form id="change_address_modal" onSubmit={handleSubmit}>
          {preparedList.length > 0 && (
            <div className="mb-20">
              {preparedList.map((item) => (
                <div key={item.id} className={styles.listRow}>
                  <Checkbox
                    field={{ value: selected, onChange: handleChange }}
                    name={item.id}
                    label={item.address()}
                    className={styles.checkbox}
                  />
                  <div
                    className={styles.editButton}
                    onClick={() => $modal.add(AddPlaceModal, { editId: item.id })}
                  >
                    <Icon type={'settings'} className={styles.icon} />
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="mt-20">
            <ButtonLegacy
              mode="link"
              action={() => $modal.add(AddPlaceModal)}
              label={t('btn.add_address')}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonLegacy
          mode={['primary', 'block']}
          tag="button"
          buttonType="submit"
          form="change_address_modal"
          disabled={!isValidForm && !isLoading}
          label={t('btn.done')}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default observer(SelectAddressModal)
