import React, { useRef, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { Trans, useTranslation } from 'react-i18next'

import _ from 'lodash'
import { $accounts } from 'ziphy-web-shared/basic/entities/accounts'
import { $auth } from 'ziphy-web-shared/basic/entities/auth'
import { useForm } from 'ziphy-web-shared/basic/lib/forms'
import { Input } from 'ziphy-web-shared/basic/lib/forms/fields'
import { $awsCaptcha, AwsCaptcha, showAlert } from 'ziphy-web-shared/basic/lib/utilities'
import { $loader } from 'ziphy-web-shared/basic/utils'

import CodeResender from '@library/codes/CodeResender'
import CodeResenderButton from '@library/codes/CodeResenderButton'
import CodeResenderCallMe from '@library/codes/CodeResenderCallMe'
import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'
import PhoneNumber, { preparePhone } from '@library/phoneNumber/PhoneNumber'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import mainConfig from '@config/main'

import styles from './AddAccount.module.scss'

const AddAccount = ({ type, value, setView, _core }) => {
  const { t } = useTranslation()

  let isInit = useRef(true)
  const [initialPrefetch, setInitialPrefetch] = useState()

  const isPhone = type === 'phone'
  const isActivate = !!value

  const formPhone = useForm({
    account: {
      value: preparePhone(value),
      rules: [{ name: 'phone', required: true }],
      output: ['phone', 'emptyUndefined'],
    },
    code: { value: '', rules: ['code', 'required'] },
  })
  const formEmail = useForm({
    account: { value: value, rules: ['email', 'required'], output: 'emptyUndefined' },
    code: { value: '', rules: ['code', 'required'] },
  })
  const { form } = isPhone ? formPhone : formEmail
  const { account, code } = form.fields

  const [codeResenderData, setCodeResenderData] = useState({})
  const isSent = useRef(false)
  const [tmpValue, setTmpValue] = useState(false)
  const isWaiting = isSent.current && _.isEqual(account.value, tmpValue)

  async function handlePrefetchCode(using) {
    setTmpValue(account.value)
    code.onChange('')

    const response = await $auth.sendCode({ type, using, value: form.values.account })

    if (response.prepared?.isBound && !isActivate) {
      showAlert.error(t(`alert.account_exists.${type}`), { isApiMessage: true })
      return false
    }

    return response.prepared?.codeSent
  }

  const submitSendCodeHandle = $loader.registerHandler('add-contact-submit-handle', async (e) => {
    if (e && _.isFunction(e.preventDefault)) {
      e.preventDefault()
    }

    if (form.validate('account').isValid) {
      _core.onBlock()

      isSent.current = false
      const codeSent = await handlePrefetchCode()

      if (codeSent) {
        isSent.current = true
        setInitialPrefetch(codeSent)
        isInit.current = false
      } else {
        setInitialPrefetch(null)
        if (!$awsCaptcha.enabled) {
          form.forceError('account')
        }
      }

      _core.onUnblock()
    }
  })

  const submitDoneHandle = $loader.registerHandler('add-contact-submit-handle', async (e) => {
    if (e && _.isFunction(e.preventDefault)) {
      e.preventDefault()
    }

    if (form.validateAll().isValid) {
      _core.onBlock()

      const response = await $accounts.create({
        type,
        value: form.values.account,
        code: form.values.code,
      })

      if (response.error) {
        form.forceError('code')
      }

      _core.onUnblock()

      if (!response.error) {
        setView('list')
      }
    }
  })

  return (
    <>
      <div className="modal-toolbar">
        <ModalToolbarBtn type="back" action={() => setView('list')} />
        <ModalToolbarBtn type="close" action={_core.onHide} />
      </div>
      <Modal.Header className="text-lg-center">
        {t(type === 'phone' ? 'label.add_phone' : 'label.add_email')}
      </Modal.Header>
      <Modal.Body>
        <form
          id="profile_contacts_form"
          onSubmit={isWaiting ? submitDoneHandle : submitSendCodeHandle}
          noValidate
        >
          {isPhone ? (
            <Input key="inputPhone" mode="noLabel" className="mb-10" field={account}>
              <PhoneNumber
                phone={account.value}
                onChange={account.onChange}
                clear={!isActivate}
                disabled={isActivate}
              />
            </Input>
          ) : (
            <Input
              key="inputEmail"
              field={account}
              type="email"
              placeholder={t('placeholder.empty_email')}
              clear={!isActivate}
              readonly={isActivate}
              className="mb-10"
              mode="noLabel"
            />
          )}
          <AwsCaptcha
            show={$awsCaptcha.enabled}
            apiKey={mainConfig.awsCaptcha.key}
            domainList={mainConfig.awsCaptcha.domains}
            className="mb-10"
          />
          {isWaiting && (
            <>
              <div className={styles.infoText}>
                <Trans i18nKey="code_resender.message.sent" components={[<br />]} />
              </div>
              <CodeResender
                key={type}
                initialPrefetch={initialPrefetch}
                prefetch={handlePrefetchCode}
                code={code}
                onChange={code.onChange}
                onSetData={setCodeResenderData}
                textButton={false}
                autoFocus={isInit.current}
                className="mb-10"
              />
            </>
          )}
          <ButtonLegacy
            tag="button"
            mode={['primary', 'block']}
            form="profile_contacts_form"
            className="mb-10"
            isLoading={$loader.isRunHandler('add-contact-submit-handle')}
          >
            {isWaiting ? t('btn.done') : t('btn.send_code')}
          </ButtonLegacy>
          {isWaiting && (
            <CodeResenderButton type="button" className="btn-block" {...codeResenderData} />
          )}
          {isWaiting && isPhone && (
            <div className={'mt-20 text-center'}>
              <CodeResenderCallMe {...codeResenderData} />
            </div>
          )}
        </form>
      </Modal.Body>
    </>
  )
}

export default observer(AddAccount)
