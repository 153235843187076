import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { isClientApp } from 'ziphy-web-shared/basic/helpers';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
import hAppt from '@helpers/hAppt';
import ApptsListPageItemDesktop from './ApptsListItemDesktop';
import ApptsListPageItemMobile from './ApptsListItemMobile';
const ApptsListItem = ({ fullAppt }) => {
    const { appointment, place, office, patients, service, payment } = fullAppt;
    const status = useMemo(() => hAppt.getCurrentStatus({ fullAppt }), [fullAppt]);
    const timezone = isClientApp() ? place.timezone : undefined;
    return (_jsx(_Fragment, { children: $windowSize.isMobile ? (_jsx(ApptsListPageItemMobile, { status: status, appointment: appointment, patients: patients, service: service, 
            //
            payment: payment, timezone: timezone })) : (_jsx(ApptsListPageItemDesktop, { status: status, appointment: appointment, patients: patients, service: service, 
            //
            place: place, office: office, 
            //
            payment: payment, timezone: timezone })) }));
};
export default observer(ApptsListItem);
