import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import 'ziphy-web-shared/basic/assets/styles/animations.scss';
import 'ziphy-web-shared/basic/assets/styles/flags.scss';
import { AlertsViewer, TooltipsViewer } from 'ziphy-web-shared/basic/lib/utilities';
import 'ziphy-web-shared/basic/utils/charts';
const PluginsProvider = ({ alerts = true, tooltips = true, children }) => {
    return (_jsxs(_Fragment, { children: [children, alerts && _jsx(AlertsViewer, {}), tooltips && _jsx(TooltipsViewer, {})] }));
};
export default PluginsProvider;
