import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { ProgressRing } from 'ziphy-web-shared/basic/lib/loaders';
import { $configure } from '../configure.store';
const ConfigureProgress = () => {
    const progress = Math.round(($configure.currentStep / $configure.requiredSteps.length) * 100);
    return _jsx(ProgressRing, { progress: progress, radius: 14, stroke: 2 });
};
export default observer(ConfigureProgress);
