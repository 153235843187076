var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo, useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { useIntervalTimer } from 'ziphy-web-shared/basic/hooks';
import { formatDuration } from 'ziphy-web-shared/basic/utils/time';
const Duration = (_a) => {
    var { from, to, format = 'hh:mm:ss' } = _a, props = __rest(_a, ["from", "to", "format"]);
    const [duration, setDuration] = useState(calcDuration());
    const [spanWidth, setSpanWidth] = useState(0);
    const timerRef = useRef(null);
    const { start, stop } = useIntervalTimer({
        callback: () => setDuration(calcDuration()),
        duration: 1000,
        autoStart: false,
    });
    useEffect(() => {
        if (!spanWidth && timerRef.current) {
            calculateSpanWidth();
        }
    }, [spanWidth]);
    useEffect(() => {
        if (!to) {
            start();
        }
        return () => stop();
    }, [to, start, stop]);
    function calcDuration() {
        return formatDuration(Object.assign({ from, to: to || moment.utc(), format: format }, props)).asString;
    }
    function calculateSpanWidth() {
        const timerEl = timerRef.current;
        if (timerEl) {
            const sampleEl = document.createElement('span');
            sampleEl.innerText = '4';
            sampleEl.style.color = 'transparent';
            sampleEl.style.position = 'absolute';
            sampleEl.style.visibility = 'hidden';
            timerEl.appendChild(sampleEl);
            const targetWidth = sampleEl.getBoundingClientRect().width;
            setSpanWidth(parseFloat(targetWidth.toFixed(1)));
            sampleEl.remove();
        }
    }
    return (_jsx("div", Object.assign({ ref: timerRef, style: {
            position: 'relative',
            display: 'inline-block',
            textAlign: 'left',
        }, className: "duration-wrapper" }, { children: duration.split('').map((char, index) => (_jsx("span", Object.assign({ style: {
                display: 'inline-block',
                width: char === ':' ? 'initial' : `${spanWidth}px`,
                textAlign: 'center',
            } }, { children: char }), `timer-item-${char}-${index}`))) })));
};
export default memo(Duration);
