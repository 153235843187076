var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { $patients } from 'ziphy-web-shared/basic/entities/patients';
import { WithLoaderWrapper } from 'ziphy-web-shared/basic/lib/loaders';
import { Title } from 'ziphy-web-shared/basic/lib/simple';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
import TrendsList from '@library/trendsList/TrendsList';
import PatientPhotoAndName from '@library/ui/patientPhotoAndName/PatientPhotoAndName';
import { useInitPsPatient } from '@ps/hooks';
import ChangePsPatientPractice from '@ps/library/widgets/changePsPatientPractice/ChangePsPatientPractice';
import PatientDetails from '@ps/pages/patient/PatientDetails';
import PatientNavigation from '@ps/pages/patient/PatientNavigation';
import PatientToolbar from '@ps/pages/patient/PatientToolbar';
import TabOverview from '@ps/pages/patient/tabs/TabOverview';
import { $psHistory } from '@store';
const PsPatientPage = ({ byRoute = {} }) => {
    const { t } = useTranslation();
    const { isLoading, patient, TabComponent, categoryId } = useInitPsPatient({
        callback: ({ params, errorBoundary }) => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield $patients.read({ id: params.patientId }, { cacheTime: 30 });
            if (response.error)
                return errorBoundary.error();
        }),
    });
    const cocPatient = $patients.getById(Number(patient.zid));
    const isVitals = categoryId === 'vitals';
    const hasVitals = $windowSize.isMobile && isVitals;
    return (_jsxs(WithLoaderWrapper, Object.assign({ isLoading: isLoading, visibleCondition: !!cocPatient, loaderMode: 'pageWrapper', className: "content" }, { children: [_jsx(PatientToolbar, { hasVitals: hasVitals }), _jsxs("div", Object.assign({ className: "container" }, { children: [hasVitals ? (_jsx(Title, Object.assign({ size: "h1" }, { children: t('ps.label.vitals') }))) : $psHistory.category ? (_jsx(Title, Object.assign({ size: "h1" }, { children: t('ps.categories.' + $psHistory.category) }))) : (_jsx(PatientPhotoAndName, { firstName: cocPatient === null || cocPatient === void 0 ? void 0 : cocPatient.firstName, lastName: cocPatient === null || cocPatient === void 0 ? void 0 : cocPatient.lastName, avatar: cocPatient === null || cocPatient === void 0 ? void 0 : cocPatient.avatar, isDeceased: cocPatient === null || cocPatient === void 0 ? void 0 : cocPatient.isDeceased, additionalInfo: _jsx(ChangePsPatientPractice, {}) })), _jsx("div", Object.assign({ className: "mt-30" }, { children: TabComponent ? (_jsx(_Fragment, { children: _jsx(TabComponent, { byRoute: byRoute }) })) : (_jsxs(_Fragment, { children: [(!$windowSize.isMobile || hasVitals) && (_jsx(PatientDetails, { patient: patient, cocPatient: cocPatient })), !hasVitals && (_jsxs(_Fragment, { children: [$windowSize.isMobile && (_jsx(Title, Object.assign({ size: "h3", className: "mb-20" }, { children: t('label.demographics') }))), _jsx(PatientNavigation, {}), _jsx(TabOverview, {}), _jsx(TrendsList, {})] }))] })) }))] }))] })));
};
export default observer(PsPatientPage);
