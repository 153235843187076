var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { $contactsContexts, ENUMS_CONTACTS_EMAIL_USE, } from 'ziphy-web-shared/basic/entities/contacts';
import { useForm } from 'ziphy-web-shared/basic/lib/forms';
import { Input } from 'ziphy-web-shared/basic/lib/forms/fields';
import MultiSelect from 'ziphy-web-shared/basic/lib/forms/fields/multiSelect/MultiSelect';
import { $loader } from 'ziphy-web-shared/basic/utils';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
const EmailModal = ({ patientId, initValue, _core }) => {
    var _a;
    const { t } = useTranslation();
    const isEdit = !!initValue;
    const $controller = $contactsContexts.get(patientId);
    const useItems = useMemo(() => {
        return Object.values(ENUMS_CONTACTS_EMAIL_USE).map((x) => ({
            id: x.id,
            text: t(x.alterLabel),
            isDisabled: x.id !== (initValue === null || initValue === void 0 ? void 0 : initValue.use) && $controller.contactsLimit.email[x.id] <= 0,
        }));
    }, [initValue]);
    const { form } = useForm({
        value: {
            value: initValue === null || initValue === void 0 ? void 0 : initValue.value,
            rules: ['email', 'required'],
        },
        use: {
            value: (initValue === null || initValue === void 0 ? void 0 : initValue.use) || ((_a = useItems.find((x) => !x.isDisabled)) === null || _a === void 0 ? void 0 : _a.id),
            rules: ['required'],
            prepValue: 'singleSelect',
        },
    });
    const { value, use } = form.fields;
    const handleSubmit = $loader.registerHandler('create-patient-contact', (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (form.validateAll().isValid) {
            const values = { system: 'email', use: form.values.use, value: form.values.value };
            const response = isEdit
                ? yield $controller.update(Object.assign(Object.assign({}, values), { id: initValue.id }))
                : yield $controller.create(values);
            if (!response.error) {
                _core.onHide();
            }
        }
    }));
    function handleDelete(e, item) {
        e.stopPropagation();
        confirmAction('customAlert', {
            data: {
                title: t('confirmator.patient_contact.delete.title'),
                message: t('confirmator.patient_contact.delete.message'),
            },
            callback: $loader.registerHandler('delete-patient-contact', () => __awaiter(this, void 0, void 0, function* () {
                const response = yield $controller.delete({ id: item.id });
                if (!response.error) {
                    _core.onHide();
                }
            })),
        });
    }
    return (_jsxs(Modal, Object.assign({ centered: true }, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: _core.onHide }) })), _jsx(Modal.Header, { children: isEdit ? t('label.edit_email') : t('label.add_email') }), _jsx(Modal.Body, { children: _jsxs("form", Object.assign({ id: "create-patient-contact-form", onSubmit: handleSubmit }, { children: [_jsx(Input, { field: value, label: t('label.email'), type: "email", mode: 'lg', placeholder: 'email@example.com' }), _jsx(MultiSelect, { label: t('label.type'), items: useItems, field: use, className: 'mt-10', mode: ['lg'] })] })) }), _jsxs(Modal.Footer, { children: [_jsx(ButtonLegacy, Object.assign({ mode: ['primary', 'block'], form: "create-patient-contact-form", isLoading: $loader.isRunHandler('create-patient-contact') }, { children: t('btn.submit') })), _jsx(ButtonLegacy, Object.assign({ mode: ['secondary', 'block'], className: "mt-10", action: () => _core.onHide() }, { children: t('btn.cancel') })), isEdit && (_jsx(ButtonLegacy, Object.assign({ mode: ['secondary-danger', 'block'], className: "mt-10", action: (e) => handleDelete(e, initValue) }, { children: t('btn.delete_email') })))] })] })));
};
EmailModal.displayName = 'EmailModal';
export default observer(EmailModal);
