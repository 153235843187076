var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { $insurancesContexts, ENUMS_INSURANCE_POLICY_HOLDERS, ENUMS_INSURANCE_TYPES, } from 'ziphy-web-shared/basic/entities/insurances';
import { getEnumObjectValue } from 'ziphy-web-shared/basic/helpers';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import { Switch } from 'ziphy-web-shared/basic/lib/forms/fields';
import { Title } from 'ziphy-web-shared/basic/lib/simple';
import { $loader } from 'ziphy-web-shared/basic/utils';
import { formatDate } from 'ziphy-web-shared/basic/utils/time';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import Badge from '@library/ui/badge/Badge';
import DtlGroup from '@library/ui/dtl/DtlGroup';
import DtlItem from '@library/ui/dtl/DtlItem';
import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import styles from './ViewInsurance.module.scss';
const ViewInsuranceModal = ({ patientId, id, insurance: _insurance, canEdit, _core }) => {
    const { t } = useTranslation();
    const $controller = $insurancesContexts.get(patientId);
    const [insurance, setInsurance] = useState(_insurance || {});
    const type = getEnumObjectValue(insurance.type, ENUMS_INSURANCE_TYPES).value;
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        if (_.isEmpty(insurance)) {
            const response = yield $controller.read({ id });
            if (!response.error) {
                setInsurance(response.preparedValue);
            }
        }
    }));
    function handleDelete(e) {
        if (!canEdit)
            return;
        confirmAction('customAlert', {
            data: {
                title: t('insurance.remove_alert.title'),
                message: t('insurance.remove_alert.message'),
            },
            callback: $loader.registerHandler('delete-insurance', () => __awaiter(this, void 0, void 0, function* () {
                const response = yield $controller.delete({ id: insurance.id });
                if (!response.error) {
                    _core.onHide();
                }
            })),
        });
    }
    const handleIsPrimary = $loader.registerHandler('set-primary-insurance', (value) => {
        setInsurance(Object.assign(Object.assign({}, insurance), { isPrimary: value }));
        $controller.setPrimary({ id: insurance.id, isPrimary: value });
    });
    return (_jsxs(Modal, Object.assign({ centered: true, scrollable: true }, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: _core.onHide }) })), _jsxs(Modal.Body, Object.assign({ isLoading: isLoading }, { children: [_jsx(Title, Object.assign({ size: 'h2' }, { children: insurance.providerName === insurance.type ? type : insurance.providerName })), _jsxs("div", Object.assign({ className: 'text-muted' }, { children: [type, insurance.isPrimary && (_jsx(Badge, Object.assign({ type: ['primary-fill'], className: styles.badge }, { children: t('label.primary') })))] })), canEdit && (_jsx(Switch, { field: {
                            value: insurance.isPrimary,
                            onChange: handleIsPrimary,
                        }, label: t('insurance.label.use_as_primary'), asBox: true, className: 'mt-30' })), _jsx("div", Object.assign({ className: styles.list }, { children: _jsxs(DtlGroup, Object.assign({ className: "row mt-30 row--mb-20", asList: true }, { children: [_jsx(DtlItem, Object.assign({ className: "col-12", label: t('insurance.label.provider_id') }, { children: insurance.providerId })), _jsx(DtlItem, Object.assign({ className: "col-12", label: getEnumObjectValue(insurance.type, ENUMS_INSURANCE_TYPES).labelMemberId ||
                                        t('insurance.label.member_id') }, { children: insurance.memberId })), _jsx(DtlItem, Object.assign({ className: "col-12", label: t('insurance.label.group_id') }, { children: insurance.groupId })), _jsx(DtlItem, Object.assign({ className: "col-12", label: t('insurance.label.member_name') }, { children: [insurance.memberFirstName, insurance.memberLastName].join(' ') })), _jsx(DtlItem, Object.assign({ className: "col-12", label: t('insurance.label.policy_holder') }, { children: insurance.policyHolder &&
                                        getEnumObjectValue(insurance.policyHolder, ENUMS_INSURANCE_POLICY_HOLDERS).value })), _jsx(DtlItem, Object.assign({ className: "col-12", label: t('label.date_added') }, { children: insurance.createdTime && formatDate(insurance.createdTime) }))] })) })), canEdit && (_jsx(ButtonLegacy, Object.assign({ autoTarget: { onClick: handleDelete }, mode: ['secondary-danger', 'block'], className: 'mt-30' }, { children: t('insurance.btn.delete_insurance') })))] }))] })));
};
ViewInsuranceModal.displayName = 'ViewInsuranceModal';
export default observer(ViewInsuranceModal);
