import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { useGetDropdownPosition } from 'ziphy-web-shared/basic/hooks';
import { CustomLink } from 'ziphy-web-shared/basic/lib/buttons';
import { ClickOutside } from 'ziphy-web-shared/basic/lib/utilities';
import styles from './DropDown.module.scss';
export const DropDown = ({ renderButton, items, alwaysBottom = false, className, classNameItem, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { fieldRef, dropdownRef, dropdownStyles } = useGetDropdownPosition({
        isOpen: isOpen,
        itemsFullWidth: true,
        alwaysBottom,
    });
    function handleClose() {
        setIsOpen(false);
    }
    function handleItemClick(item) {
        if (typeof item.onClick === 'function') {
            item.onClick();
        }
        handleClose();
    }
    function handleToggle() {
        setIsOpen((x) => !x);
    }
    return (_jsxs(ClickOutside, Object.assign({ className: classNames(styles.dropdown, items.some((x) => x.selected) && styles.hasSelected, 'dropdown', className), onClick: handleClose }, { children: [renderButton({ ref: fieldRef, onToggle: handleToggle, isOpen }), _jsx("div", Object.assign({ className: classNames(styles.popup, isOpen && styles.visible), style: dropdownStyles, ref: dropdownRef }, { children: items.map((item, index) => (_jsxs(CustomLink, Object.assign({ autoTarget: item.autoTarget, 
                    // replace onClick with handleOnClick
                    onClick: () => handleItemClick(item), className: classNames(styles.item, classNameItem, item.disabled && styles.disabled, item.selected && styles.selected) }, { children: [item.label, " ", item.selected && _jsx(Icon, { type: 'check', className: styles.icon })] }), [item.id, index, item.label].join('_')))) }))] })));
};
