var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import { $patients } from 'ziphy-web-shared/basic/entities/patients';
import { ENUMS_PERSON_GENDER } from 'ziphy-web-shared/basic/entities/persons';
import { personAgeLimit } from 'ziphy-web-shared/basic/helpers';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import { useForm } from 'ziphy-web-shared/basic/lib/forms';
import { Input } from 'ziphy-web-shared/basic/lib/forms/fields';
import MultiSelect from 'ziphy-web-shared/basic/lib/forms/fields/multiSelect/MultiSelect';
import { $loader } from 'ziphy-web-shared/basic/utils';
import { $router } from 'ziphy-web-shared/basic/utils/router';
import InputDate from '@library/form/fields/new/InputDate';
import UploadAvatar from '@library/form/fields/UploadAvatar';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import PhoneNumber, { preparePhone } from '@library/phoneNumber/PhoneNumber';
import { DeleteProfileModal } from '@library/profileController/DeleteProfileModal';
import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import { $modal } from '@store';
import { routeActions } from '@config/routeActions';
const AddPatientModal = ({ patientId, patient: _patient, hasContacts, isProfile, _core, }) => {
    const { t } = useTranslation();
    const [patient, setPatient] = useState(_patient || {});
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        if (patientId && _.isEmpty(patient)) {
            const response = yield $patients.read({ id: patientId });
            if (!response.error) {
                setPatient(response.preparedValue);
            }
        }
    }));
    const dobType = patient.isDefault ? 'profile' : 'patient';
    const { form } = useForm(Object.assign({ avatar: { value: patient.avatar, rules: [], output: 'avatar' }, firstName: { value: patient.firstName, rules: ['name', 'required'] }, lastName: { value: patient.lastName, rules: ['name', 'required'] }, gender: { value: patient.gender, rules: ['gender', 'required'], prepValue: 'singleSelect' }, dob: { value: patient.dob, rules: [{ name: 'dob', dobType }, 'required'], output: 'date' }, code: { value: patient.code, rules: [] } }, (hasContacts && {
        email: { value: patient.email, rules: ['email'], output: 'emptyNull' },
        phone: {
            value: preparePhone(patient.phone),
            rules: ['phone'],
            output: ['phone', 'emptyNull'],
        },
    })), { isReadyForInit: !isLoading });
    const { avatar, firstName, lastName, gender, dob, email, phone, code } = form.fields;
    function handleRemove(event) {
        event.stopPropagation();
        confirmAction('customAlert', {
            data: {
                title: t('add_patient.remove_alert.title'),
                message: t('add_patient.remove_alert.message'),
            },
            callback: () => __awaiter(this, void 0, void 0, function* () {
                const response = yield $patients.delete({ id: patientId });
                if (!response.error) {
                    _core.onHide();
                    $router.executeAutoTarget(routeActions.PATIENTS());
                }
            }),
        }, event);
    }
    const handleSubmit = $loader.registerHandler('edit-patient-modal', (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (form.validateAll().isValid) {
            _core.onBlock();
            let response;
            if (patientId) {
                const data = form.values;
                response = yield $patients.update(Object.assign({ id: patientId }, data));
            }
            else {
                const data = form.values;
                response = yield $patients.create(data);
            }
            _core.onUnblock();
            if (!response.error) {
                if (response.preparedValue.isDefault) {
                    yield $auth.loadProfile();
                }
                _core.onHide();
            }
        }
    }));
    return (_jsxs(Modal, Object.assign({ centered: true, scrollable: true }, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: _core.onHide }) })), _jsx(Modal.Header, { children: isProfile
                    ? t('label.edit_profile')
                    : patientId
                        ? t('label.patient_details')
                        : t('label.add_patient') }), _jsx(Modal.Body, Object.assign({ isLoading: isLoading }, { children: _jsxs("form", Object.assign({ id: "add_patient_form", onSubmit: handleSubmit }, { children: [_jsx(UploadAvatar, { user: Object.assign(Object.assign({}, patient), { firstName: firstName.value, lastName: lastName.value }), field: avatar, value: avatar.value, onChange: avatar.onChange }), _jsxs("div", Object.assign({ className: "row row--gutters-5" }, { children: [_jsx("div", Object.assign({ className: "col-12" }, { children: _jsx(Input, { className: "mb-10", label: t('label.first_name'), field: firstName, mode: "lg" }) })), _jsx("div", Object.assign({ className: "col-12" }, { children: _jsx(Input, { className: "mb-10", label: t('label.last_name'), field: lastName, mode: "lg" }) }))] })), _jsx(MultiSelect, { className: "mb-10", label: t('label.sex_assigned_at_birth'), items: Object.values(ENUMS_PERSON_GENDER).map((x) => ({ id: x.id, text: t(x.tKey) })), field: gender, mode: "lg" }), _jsxs("div", Object.assign({ className: "row row--gutters-5" }, { children: [_jsx("div", Object.assign({ className: "col-sm-6" }, { children: _jsx(InputDate, { minDate: personAgeLimit(dobType).from, maxDate: personAgeLimit(dobType).to, 
                                        // @ts-ignore
                                        className: "mb-10", label: t('label.date_of_birth'), field: dob, mode: "lg" }) })), _jsx("div", Object.assign({ className: "col-sm-6" }, { children: _jsx(Input, { className: "mb-10", label: t('label.referral_partner_code'), field: code, mode: "lg" }) }))] })), hasContacts && (_jsxs(_Fragment, { children: [_jsx(Input, { label: t('label.email'), field: email, type: "email", placeholder: t('placeholder.empty_email'), mode: "lg", className: "mb-10" }), _jsx(Input, Object.assign({ className: 'mb-10', label: t('label.phone'), field: phone, mode: "lg" }, { children: _jsx(PhoneNumber, { popupClass: 'phone_number-popup--on_top', phone: phone.value, onChange: phone.onChange }) }))] }))] })) })), !isLoading && (_jsxs(Modal.Footer, { children: [_jsx(ButtonLegacy, Object.assign({ tag: "button", mode: ['primary', 'block'], form: "add_patient_form", isLoading: $loader.isRunHandler('edit-patient-modal') }, { children: t('btn.save') })), patientId && (_jsxs(_Fragment, { children: [!patient.isDefault && (_jsx(ButtonLegacy, Object.assign({ mode: ['secondary-danger', 'block'], className: "mt-10", action: handleRemove }, { children: t('add_patient.btn.delete') }))), patient.isDefault && isProfile && (_jsx(ButtonLegacy, Object.assign({ mode: ['secondary-danger', 'block'], className: "mt-10", action: () => $modal.add(DeleteProfileModal) }, { children: t('btn.delete_my_profile') })))] }))] }))] })));
};
AddPatientModal.displayName = 'AddPatientModal';
export default observer(AddPatientModal);
