import React, { useCallback, useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import classNames from 'classnames'

import Badge from '@library/ui/badge/Badge'

import { $booking } from '@store'

import styles from './SelectedSymptoms.module.scss'

const SelectedSymptoms = () => {
  return (
    $booking.$symptoms.combinedSelectedSymptomsIds.length > 0 && (
      <div className={classNames('badge-group', styles.badge)}>
        {$booking.$symptoms.combinedSelectedSymptomsIds.map((symptomId, index) => (
          <Badge
            key={index}
            type="primary"
            onClose={() => $booking.$symptoms.REMOVE_SELECTED(symptomId)}
          >
            {$booking.$symptoms.getSymptomName(symptomId)}
          </Badge>
        ))}
      </div>
    )
  )
}

export default observer(SelectedSymptoms)
