import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from 'ziphy-web-shared/basic/lib/buttons';
import { $loader } from 'ziphy-web-shared/basic/utils';
import { CALL_ME_LOADER } from '../CodeResender.constants';
import styles from './CallMeButton.module.scss';
const CallMeButton = ({ onClick, className }) => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: classNames(styles.callMe, className) }, { children: [_jsx("div", { children: t('label.i_didnt_receive_a_verification_code') }), _jsx(Button, Object.assign({ mode: ['anchor-gray-underline'], onClick: onClick, isLoading: $loader.isRunHandler(CALL_ME_LOADER) }, { children: t('btn.call_me') }))] })));
};
export default observer(CallMeButton);
