import React, { useMemo } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'
import { useLoader } from 'ziphy-web-shared/basic/hooks'

import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator'
import TabsBar from '@library/widgets/tabsBar/TabsBar'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import { $booking, $modal } from '@store'

import AddPlaceModal from '../../../library/addPlaceModal/AddPlace.modal'
import SelectAddressModal from '../../../library/modals/selectAddressModal/SelectAddress.modal'
import SelectedVoucherOrCode from '../../../pages/bookingHome/selectPractice/selectedVoucherOrCode/SelectedVoucherOrCode'
import VoucherModal from '../../../pages/bookingHome/selectPractice/voucherModal/Voucher.modal'
import styles from './SelectPractice.module.scss'

const SelectPractice = () => {
  const { t } = useTranslation()

  useLoader(async () => {
    await $booking.loadOfficesPracticesByPlace()
  }, [$booking.usablePlace?.id])

  const preparedList = useMemo(() => {
    const servePractices = _.orderBy($booking.servePractices, 'name', 'asc')
    return servePractices.map((x) => ({ id: x.id, text: x.name }))
  }, [$booking.servePractices])

  const checkAuth = (props, e) => confirmAction('checkAuth', props, e)

  function handleOpenVoucherModal(e) {
    const callback = () => {
      if (!$booking.place) {
        $modal.add($booking.$places.items.length ? SelectAddressModal : AddPlaceModal)
      } else {
        $modal.add(VoucherModal)
      }
    }
    checkAuth({ callback }, e)
  }

  function handleSelectPractice(value) {
    const callback = () => {
      $booking.SET_PRACTICE_ID(value)
    }
    checkAuth({ callback })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h3 className={styles.title}>{t('service_practice.title')}</h3>
        <ButtonLegacy mode={'anchor'} autoTarget={{ onClick: handleOpenVoucherModal }}>
          {$booking.hasVoucherOrCode
            ? t('service_practice.btn.edit_voucher_or_code')
            : t('service_practice.btn.add_voucher_or_code')}
        </ButtonLegacy>
      </div>
      <div className={styles.body}>
        {$booking.hasVoucherOrCode ? (
          <SelectedVoucherOrCode onOpenVoucherModal={handleOpenVoucherModal} />
        ) : (
          <TabsBar
            mode={['secondary-dark', 'block']}
            modeSelected="white-dark"
            items={preparedList}
            selected={$booking.practiceId}
            selectAll={t('service_practice.btn.all_practices')}
            onChange={handleSelectPractice}
            moreModalParams={{
              title: t('service_practice.label.search_practice'),
              items: preparedList,
              allOption: t('service_practice.btn.all_available_practices'),
              searchBar: true,
              searchLabel: t('service_practice.label.search_by_name'),
              allowUnselect: false,
              value: [$booking.practiceId].filter((x) => x),
              onChange: (values) => $booking.SET_PRACTICE_ID(values[0]),
            }}
          />
        )}
        {$booking.isLoadingPractices && <div className={styles.overlay} />}
      </div>
    </div>
  )
}

export default observer(SelectPractice)
