import React, { useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import { $booking } from '@store'

import VoucherSelector from '../../../library/voucherSelector/VoucherSelector'
import styles from './VoucherSelect.modal.module.scss'

const VoucherSelectModal = ({ _core }) => {
  const { t } = useTranslation()
  const [voucher, setVoucher] = useState(null)

  const handleSubmit = () => {
    $booking.patients.updateAllSelected(() => ({ voucher: voucher }))
    _core.onHide()
  }

  return (
    <Modal centered _core={_core}>
      <div className="modal-toolbar">
        <ModalToolbarBtn type="close" action={_core.onHide} />
      </div>
      <Modal.Header>{t('book_details.label.add_voucher')}</Modal.Header>
      <Modal.Body>
        <VoucherSelector
          selected={voucher}
          onChangeSelected={setVoucher}
          availablePracticeIds={$booking.availablePracticeIds}
          practiceServiceId={$booking.practiceService.id}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonLegacy
          mode="primary"
          tag="button"
          buttonType="button"
          className={styles.submitButton}
          disabled={_.isEmpty(voucher)}
          label={t('btn.submit')}
          action={handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default observer(VoucherSelectModal)
