import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import i18n from 'i18next';
import _ from 'lodash';
import { ENUMS_ADDRESS_LOCAL_TYPE, orderAddressesItems, } from 'ziphy-web-shared/basic/entities/addresses';
import { ENUMS_CONTACTS_EMAIL_USE, ENUMS_CONTACTS_PHONE_USE, orderContactsItems, } from 'ziphy-web-shared/basic/entities/contacts';
import { ENUMS_INSURANCE_TYPES } from 'ziphy-web-shared/basic/entities/insurances';
import { getEnumObjectValue } from 'ziphy-web-shared/basic/helpers';
import ViewInsuranceModal from '@nedoShared/features/insurances/insuranceController/viewInsuranceModal/ViewInsurance.modal';
import { getAddressObjects, getTelecomObjects } from '@fhir/fhir.helpers';
import { $modal } from '@store';
export function getPatientTelecom(items) {
    const telecom = getTelecomObjects(orderContactsItems(items));
    const phones = _.map(telecom.list({ system: 'phone' }), (x) => ({
        label: '',
        value: (_jsxs(_Fragment, { children: [x.asString, ' ', _jsxs("span", Object.assign({ className: "text-muted" }, { children: ["\u2022 ", getEnumObjectValue(x.use, ENUMS_CONTACTS_PHONE_USE).value] }))] })),
    }));
    const emails = _.map(telecom.list({ system: 'email' }), (x) => ({
        label: '',
        value: (_jsxs(_Fragment, { children: [x.asString, ' ', _jsxs("span", Object.assign({ className: "text-muted" }, { children: ["\u2022 ", getEnumObjectValue(x.use, ENUMS_CONTACTS_EMAIL_USE).value] }))] })),
    }));
    return { phones, emails };
}
export function getPatientAddresses(items) {
    const address = getAddressObjects(orderAddressesItems(items));
    const addresses = _.map(address.list(), (x) => {
        return {
            label: '',
            value: (_jsxs(_Fragment, { children: [x.asString, ' ', _jsxs("span", Object.assign({ className: "text-muted" }, { children: ["\u2022 ", getEnumObjectValue(x.localType, ENUMS_ADDRESS_LOCAL_TYPE).value] }))] })),
        };
    });
    return { addresses };
}
export function getInsurances(items) {
    const sortedItems = _.orderBy(items, ['isPrimary', (x) => (x.createdTime ? x.createdTime : '')], ['desc', 'desc']);
    return _.map(sortedItems, (x) => {
        const type = getEnumObjectValue(x.type, ENUMS_INSURANCE_TYPES).value;
        return {
            label: '',
            value: (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: "a", onClick: (e) => {
                            e.stopPropagation();
                            $modal.add(ViewInsuranceModal, { insurance: x });
                        } }, { children: x.providerName === x.type ? type : x.providerName })), ' ', _jsxs("span", Object.assign({ className: "text-muted" }, { children: ["\u2022 ", type] }))] })),
        };
    });
}
export function getLifestyle(data) {
    var _a, _b, _c, _d;
    let result = [];
    function getValue(value, label) {
        return {
            label: '',
            value: (_jsxs(_Fragment, { children: [value, " ", _jsxs("span", Object.assign({ className: "text-muted" }, { children: ["\u2022 ", label] }))] })),
        };
    }
    if ((_a = data.sexualBehaviorSocialHistory) === null || _a === void 0 ? void 0 : _a.text) {
        result.push(getValue(data.sexualBehaviorSocialHistory.text, i18n.t('ps.notes.social_history.label.sexual_behavior')));
    }
    if ((_b = data.hobbiesOrOtherRecreationalActivitiesSocialHistory) === null || _b === void 0 ? void 0 : _b.text) {
        result.push(getValue(data.hobbiesOrOtherRecreationalActivitiesSocialHistory.text, i18n.t('ps.notes.social_history.label.hobbies')));
    }
    if ((_c = data.travelSocialHistory) === null || _c === void 0 ? void 0 : _c.text) {
        result.push(getValue(data.travelSocialHistory.text, i18n.t('ps.notes.social_history.label.travel')));
    }
    if ((_d = data.hoursOfSleepSocialHistory) === null || _d === void 0 ? void 0 : _d.text) {
        result.push(getValue(data.hoursOfSleepSocialHistory.text, i18n.t('ps.notes.social_history.label.hours_of_sleep')));
    }
    return result;
}
