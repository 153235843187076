import React from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { $insurancesContexts } from 'ziphy-web-shared/basic/entities/insurances'
import { formatAmount, getUserText } from 'ziphy-web-shared/basic/helpers'
import { useLoader } from 'ziphy-web-shared/basic/hooks'
import { WithLoaderWrapper } from 'ziphy-web-shared/basic/lib/loaders'
import { RadioTabs } from 'ziphy-web-shared/basic/lib/tabs'
import { $windowSize } from 'ziphy-web-shared/basic/utils'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'
import AddInsuranceModal from '@nedoShared/features/insurances/insuranceController/addInsuranceModal/AddInsurance.modal'
import InsuranceControllerModal from '@nedoShared/features/insurances/insuranceController/InsuranceController.modal'

import { $booking, $modal } from '@store'

import mainConfig from '@config/main'

import styles from './PatientItem.module.scss'

const INSURANCE_TABS = [
  { id: false, text: 'book_details.label.out_of_pocket' },
  {
    id: true,
    text: 'book_details.label.insurance',
  },
]

const PatientItem = ({ patient, number }) => {
  const { t } = useTranslation()
  const $insuranceController = $insurancesContexts.get(patient.id)
  const isFree = $booking.practiceService.price <= 0

  const { isLoading } = useLoader(async () => {
    if (isFree || !mainConfig.booking.isSelectablePatientInsuranceOrVoucher) {
      return
    }
    const response = await $insuranceController.list()
    if (!response.error && response.preparedValue?.length) {
      $booking.patients.updateSelected(patient.id, { useInsurance: true })
    }
  })

  const UserText = () => {
    return (
      <div className={styles.column}>
        <div className={classNames(styles.row, styles.rowLabel)}>
          {t('book_details.label.patient_number', { number: number })}
        </div>
        <div className={styles.row}>{getUserText(patient, ['fullName', 'gender', 'yo'])}</div>
      </div>
    )
  }

  const InsuranceSwitcher = observer(() => {
    if (isFree || !mainConfig.booking.isSelectablePatientInsuranceOrVoucher) return null

    return (
      <WithLoaderWrapper
        isLoading={isLoading}
        loader={'bubble'}
        className={styles.column}
        loaderClassName={styles.loader}
      >
        <RadioTabs
          value={patient.useInsurance}
          setValue={(value) => {
            $booking.patients.updateSelected(patient.id, { useInsurance: value })
          }}
          items={INSURANCE_TABS.map((item) => ({ ...item, text: t(item.text) }))}
          className={styles.insurance}
          mode={'modern'}
        />
      </WithLoaderWrapper>
    )
  })

  const Price = observer(() => {
    if (isFree || isLoading) return null

    return (
      <div className={classNames(styles.column, styles.columnAlignEnd)}>
        <div className={classNames(styles.row, styles.rowLabel)}>{t('label.price')}</div>
        <div className={classNames(styles.row, styles.noWrap)}>
          {formatAmount($booking.practiceService.price, $booking.practiceService.currency)}
        </div>
      </div>
    )
  })

  const Insurance = observer(() => {
    if (isFree || isLoading) return null

    return (
      <div className={classNames(styles.column, styles.columnAlignEnd, styles.selectInsurance)}>
        <div className={classNames(styles.row, styles.rowLabel)}>{t('label.insurance')}</div>
        <div className={classNames(styles.row, styles.noWrap)}>
          {$insuranceController.sortedItems.length > 0 ? (
            <ButtonLegacy
              autoTarget={{
                onClick: () => $modal.add(InsuranceControllerModal, { patient: patient }),
              }}
              mode={['anchor-dashed']}
            >
              {t('label.insurances', { count: $insuranceController.sortedItems.length })}
            </ButtonLegacy>
          ) : (
            <ButtonLegacy
              autoTarget={{
                onClick: () =>
                  $modal.add(AddInsuranceModal, {
                    patientId: patient.id,
                    initValue: {
                      memberFirstName: patient.firstName,
                      memberLastName: patient.lastName,
                    },
                  }),
              }}
              mode={['anchor-dashed']}
            >
              {t('btn.add_insurance')}
            </ButtonLegacy>
          )}
        </div>
      </div>
    )
  })

  return $windowSize.isMobileSize ? (
    <div className={styles.containerMobile}>
      <div className={styles.containerMobileHeader}>
        <UserText />
        {patient.useInsurance ? <Insurance /> : <Price />}
      </div>
      <InsuranceSwitcher />
    </div>
  ) : (
    <div className={styles.containerDesktop}>
      <UserText />
      <InsuranceSwitcher />
      {patient.useInsurance ? <Insurance /> : <Price />}
    </div>
  )
}

export default observer(PatientItem)
