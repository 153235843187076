var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import { useForm } from 'ziphy-web-shared/basic/lib/forms';
import MultiSelect from 'ziphy-web-shared/basic/lib/forms/fields/multiSelect/MultiSelect';
import TextareaBase from '@library/form/fields/new/TextareaBase';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import styles from './DocumentUploader.modal.module.scss';
import { $documentUploader } from './documentUploader.store';
const DocumentUploaderModal = ({ patientId, encounterId, baseFileName, file, _core, }) => {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation();
    const items = useMemo(() => {
        let result = [];
        _.forEach($documentUploader.documentTypes, (value, key) => {
            result.push({ id: key, text: value });
        });
        result = _.orderBy(result, 'text', 'asc');
        return result;
    }, [$documentUploader.documentTypes]);
    const { form } = useForm({
        description: { value: '' },
    });
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        yield $documentUploader.getDocumentTypes();
    }));
    useEffect(() => {
        if (!isLoading && items.length > 0) {
            form.addField('type', { value: items[0]['id'], prepValue: 'singleSelect' });
        }
    }, [isLoading, form, items]);
    const onUpload = useCallback(() => {
        _core.onHide();
        const documentType = form.fields.type.value;
        const documentDescription = form.fields.description.value;
        $documentUploader.uploadDocument({
            patientId: patientId,
            encounterId: encounterId,
            file: file,
            type: {
                name: documentType,
                label: _.find(items, (x) => x.id === documentType).text,
            },
            baseFileName: baseFileName,
            description: documentDescription,
        });
    }, [
        encounterId,
        baseFileName,
        file,
        items,
        (_b = (_a = form === null || form === void 0 ? void 0 : form.fields) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.value,
        (_d = (_c = form === null || form === void 0 ? void 0 : form.fields) === null || _c === void 0 ? void 0 : _c.description) === null || _d === void 0 ? void 0 : _d.value,
        _core,
    ]);
    return (_jsxs(Modal, Object.assign({ centered: true, scrollable: true }, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: _core.onHide }) })), _jsx(Modal.Header, Object.assign({ titleClassName: classNames('title-md', styles.title) }, { children: file.name })), _jsx(Modal.Body, Object.assign({ isLoading: isLoading || !((_e = form === null || form === void 0 ? void 0 : form.fields) === null || _e === void 0 ? void 0 : _e.type) }, { children: _jsxs("div", Object.assign({ className: styles.body }, { children: [_jsx(MultiSelect, { search: true, label: t('label.document_type'), items: items, field: form.fields.type, mode: "lg" }), _jsx(TextareaBase, { field: form.fields.description, label: t('label.description'), placeholder: t('label.value'), mode: "lg", rows: 6, 
                            // @ts-ignore
                            maxLength: 150 })] })) })), !isLoading && ((_f = form === null || form === void 0 ? void 0 : form.fields) === null || _f === void 0 ? void 0 : _f.type) && (_jsx(Modal.Footer, { children: _jsxs("div", Object.assign({ className: styles.buttons }, { children: [_jsx(ButtonLegacy, { mode: ['primary'], label: t('btn.upload'), isLoading: false, action: onUpload }), _jsx(ButtonLegacy, { mode: ['secondary'], label: t('btn.cancel'), action: _core.onHide })] })) }))] })));
};
DocumentUploaderModal.displayName = 'DocumentUploaderModal';
export default observer(DocumentUploaderModal);
