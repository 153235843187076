import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Badge from '@library/ui/badge/Badge';
const PatientGroupTypes = ({ service, mode = 'sm' }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [service.patientGroupType === 'individual' && (_jsx(Badge, Object.assign({ type: [mode, 'green'] }, { children: t('appt_type.individual') }))), service.patientGroupType === 'family' && (_jsxs(_Fragment, { children: [_jsx(Badge, Object.assign({ type: [mode, 'green'] }, { children: t('appt_type.individual') })), _jsx(Badge, Object.assign({ type: [mode, 'purple'] }, { children: t('appt_type.family') }))] })), service.patientGroupType === 'group' && (_jsx(Badge, Object.assign({ type: [mode, 'purple'] }, { children: t('appt_type.group') })))] }));
};
export default observer(PatientGroupTypes);
