import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import { useClassNameByOS, useRemoveRootLoader } from 'ziphy-web-shared/basic/hooks';
import { useEventsListener } from 'ziphy-web-shared/basic/lib/utilities/eventsListener';
import { PluginsProvider } from 'ziphy-web-shared/basic/providers';
import { useWebViewAnchorClickHandler } from 'ziphy-web-shared/basic/utils';
import { $layouts } from 'ziphy-web-shared/basic/utils/layouts';
import RightSidebar from '@src/layouts/rightSidebar/RightSidebar';
import ApiDeprecationChecker from '@library/deprecation/ApiDeprecationChecker';
import ModalViewer from '@library/modal/ModalViewer';
import NearestAppt from '@library/nearestAppt/NearestAppt';
import NotificationsViewer from '@library/notifications/NotificationsViewer';
import PageSwitcher from '@library/PageProvider';
import ConfigureViewer from '@library/plugins/configure/ConfigureViewer';
import TosLauncher from '@library/plugins/termsOfService/TosLauncher';
import ActionConfirmator from '@library/utils/actionConfirmator/ActionConfirmator';
import Notifications from '@library/widgets/notifications/Notifications';
import { useAppInitLoader } from '@hooks/useAppInitLoader';
import '@assets/styles/styles.scss';
const App = () => {
    useClassNameByOS();
    useWebViewAnchorClickHandler();
    const { isInitialized } = useAppInitLoader();
    useRemoveRootLoader({ isInitialized });
    useEventsListener({ isEnabled: isInitialized && $auth.isLogged });
    return (_jsxs(PluginsProvider, { children: [isInitialized && (_jsxs(_Fragment, { children: [_jsx(PageSwitcher, {}), $auth.isLogged && (_jsxs(_Fragment, { children: [_jsx(RightSidebar, { config: {
                                    notifications: {
                                        component: _jsx(Notifications, {}),
                                        bottom: $layouts.marginBottom,
                                        minWidth: 365,
                                    },
                                } }), _jsx(NearestAppt, {})] }))] })), _jsx(NotificationsViewer, {}), _jsx(ModalViewer, {}), _jsx(ActionConfirmator, {}), _jsx(TosLauncher, {}), _jsx(ApiDeprecationChecker, {}), _jsx(ConfigureViewer, { isInitialized: isInitialized })] }));
};
export default observer(App);
