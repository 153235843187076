import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import SelectModal from '@library/select/Select.modal';
import { $modal } from '@store';
import { COUNTRIES } from '@config/countries';
import styles from './SelectCountryFlag.module.scss';
const SelectCountryFlag = ({ className, disabled, country: _country = [], onChange, }) => {
    const { t } = useTranslation();
    const [country, setCountry] = useState(_country);
    useEffect(() => {
        if (!_.isEqual(_country, country)) {
            setCountry(_country);
        }
    }, [_country]); //eslint-disable-line react-hooks/exhaustive-deps
    const preparedItems = useMemo(() => {
        let result = [];
        _.forEach(COUNTRIES, (item) => {
            result.push({ id: item.iso2, text: item.label });
        });
        return result;
    }, []);
    function handleOnClick() {
        if (disabled) {
            return;
        }
        $modal.add(SelectModal, {
            title: t('select_country_modal.title'),
            searchBar: true,
            searchLabel: t('select_country_modal.ph.search'),
            allOption: t('select_country_modal.label.all'),
            items: preparedItems,
            value: country,
            onChange: (values) => {
                setCountry(values);
                if (_.isFunction(onChange)) {
                    onChange(values);
                }
            },
        });
    }
    return (_jsx("div", Object.assign({ className: classNames(className, styles.flagSelector, disabled && styles.flagSelector_disabled, 'flag_selector'), onClick: handleOnClick }, { children: _.isEmpty(country) ? (_jsx(Icon, { type: 'geoscape', className: styles.flagSelectorFlagAll })) : (_jsx("div", { className: classNames(styles.flagIcon, 'flag-icon', 'flag-icon--' + country[0]) })) })));
};
export default observer(SelectCountryFlag);
