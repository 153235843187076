import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import InputSearch from '@library/form/fields/InputSearch';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import styles from './Select.modal.module.scss';
const SelectModal = (props) => {
    const { t } = useTranslation();
    const { title, value = [], childValue = [], items = [], onChange, onChangeChild, allOption, multiSelect = false, allowUnselect = true, searchBar = false, searchLabel = '', searchMap = ['text'], saveOnSelect = true, buttons = false, backButton = false, doneText = t('btn.apply'), cancelText = t('btn.cancel'), modalOptions, _core, } = props;
    const [selected, setSelected] = useState(value);
    const [selectedChilds, setSelectedChilds] = useState(childValue);
    const [q, setQ] = useState('');
    function getContent(content) {
        return _.isFunction(content) ? content() : content;
    }
    function isSelected(id, childId) {
        if (!selected.length && typeof id === 'undefined' && !selectedChilds.length) {
            return true;
        }
        if (_.includes(selectedChilds, childId)) {
            return true;
        }
        return _.includes(selected, id);
    }
    function isSelectedChild(id) {
        return _.includes(selectedChilds, id);
    }
    function hasSelectedChild(childs) {
        // todo: typify it
        let hasSelected = false;
        _.forEach(childs, (item) => {
            hasSelected = isSelectedChild(item.id);
            if (hasSelected) {
                return false;
            }
        });
        return hasSelected;
    }
    function handleSelect(id, childId) {
        if (typeof childId !== 'undefined') {
            handleSelectChild(childId);
            return;
        }
        let result = selected;
        if (isSelected(id)) {
            if (allowUnselect) {
                result = _.filter(result, (x) => x !== id);
            }
            else {
                return;
            }
        }
        else if (typeof id === 'undefined') {
            result = [];
        }
        else {
            if (multiSelect) {
                result = [...result, id].filter((x) => x);
            }
            else {
                result = [id];
            }
        }
        setSelected(result);
        setSelectedChilds([]);
        if (_.isFunction(onChange) && saveOnSelect && !multiSelect && !buttons) {
            onChange(result);
            _core.onHide();
        }
    }
    function handleSelectChild(id) {
        let result = selectedChilds;
        if (isSelectedChild(id)) {
            if (allowUnselect) {
                result = _.filter(result, (x) => x !== id);
            }
        }
        else if (typeof id === 'undefined') {
            result = [];
        }
        else {
            if (multiSelect) {
                result = [...result, id].filter((x) => x);
            }
            else {
                result = [id];
            }
        }
        setSelected([]);
        setSelectedChilds(result);
        if (_.isFunction(onChangeChild) && saveOnSelect && !multiSelect && !buttons) {
            onChangeChild(result);
            _core.onHide();
        }
    }
    function handleSelectAll() {
        setSelected([]);
        setSelectedChilds([]);
        if (saveOnSelect && !multiSelect && !buttons) {
            if (_.isFunction(onChange)) {
                onChange([]);
            }
            if (_.isFunction(onChangeChild)) {
                onChangeChild([]);
            }
            _core.onHide();
        }
    }
    function handleClose() {
        if (_.isFunction(onChange) && (!saveOnSelect || multiSelect) && !buttons) {
            onChange(selected);
        }
        if (_.isFunction(onChangeChild) && saveOnSelect && !multiSelect && !buttons) {
            onChangeChild(selectedChilds);
        }
        _core.onHide();
    }
    function handleDone() {
        if (_.isFunction(onChange)) {
            onChange(selected);
        }
        if (_.isFunction(onChangeChild)) {
            onChangeChild(selectedChilds);
        }
        _core.onHide();
    }
    const filteredByQ = useMemo(() => {
        if (!q.length) {
            return items;
        }
        let needle = q
            .toLowerCase()
            .split(' ')
            .map((x) => x.trim())
            .filter((x) => x.length);
        const result = [];
        _.forEach(items, (item) => {
            let tmp = item;
            let full = searchMap
                .reduce((acc, current) => [...acc, tmp[current]], [])
                .join(' ')
                .toLowerCase();
            const isCurrent = _.every(needle, (n) => full.indexOf(n) !== -1);
            let isChild = false;
            if (tmp.childs) {
                tmp.childs = tmp.childs.filter((child) => {
                    // todo: typify it
                    let full = searchMap
                        .reduce((acc, current) => [...acc, child[current]], [])
                        .join(' ')
                        .toLowerCase();
                    return _.every(needle, (n) => full.indexOf(n) !== -1);
                });
                if (!tmp.childs.length) {
                    delete tmp['childs'];
                }
                else {
                    isChild = true;
                }
            }
            if (isCurrent || isChild) {
                result.push(tmp);
            }
        });
        return result;
    }, [q, items, searchMap]);
    return (_jsxs(Modal, Object.assign({ className: styles.selectModal, size: "md", scrollable: true, centered: true, headerBordered: buttons }, modalOptions, { onHide: handleClose }, { children: [_jsxs("div", Object.assign({ className: "modal-toolbar" }, { children: [backButton && _jsx(ModalToolbarBtn, { type: "back", action: handleClose }), _jsx(ModalToolbarBtn, { type: "close", action: handleClose })] })), title && (_jsx(Modal.Header, Object.assign({ className: classNames(backButton && 'text-lg-center') }, { children: getContent(title) }))), _jsxs(Modal.Body, Object.assign({ className: "styled_scroll" }, { children: [searchBar && (_jsx(InputSearch, { className: "mb-20", field: { value: q, onChange: setQ }, placeholder: searchLabel })), _jsxs("div", Object.assign({ className: styles.selectModalGroup }, { children: [allOption && (_jsx("div", { children: _jsx("div", Object.assign({ className: classNames(styles.item, selected.length === 0 && styles.itemActive), onClick: handleSelectAll }, { children: allOption })) })), filteredByQ.map((item) => (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: classNames(styles.item, isSelected(item.id, item.childId) && styles.itemActive, hasSelectedChild(item.childs) && styles.itemChildActive), onClick: () => handleSelect(item.id, item.childId) }, { children: [_jsx("div", Object.assign({ className: styles.checkContainer }, { children: _jsx(Icon, { className: styles.icon, type: "check" }) })), item.text] })), item.childs &&
                                        item.childs.map((child /* todo: typify it */) => (_jsxs("div", Object.assign({ className: classNames(styles.item, styles.itemChild, isSelected(item.id, item.childId) && styles.itemParentActive, styles.itemActive, isSelectedChild(child.id) && styles.itemActive), onClick: () => handleSelectChild(child.id) }, { children: [_jsx("div", Object.assign({ className: styles.checkContainer }, { children: _jsx(Icon, { className: styles.icon, type: "check" }) })), child.text] }), child.id)))] }, item.id || 'default')))] }))] })), buttons && (doneText || cancelText) && (_jsx(_Fragment, { children: _jsx(Modal.Footer, { children: _jsxs("div", Object.assign({ className: "btn-group" }, { children: [doneText && (_jsx(ButtonLegacy, { mode: "primary", className: "btn-sm", action: handleDone, label: getContent(doneText) })), cancelText && (_jsx(ButtonLegacy, { mode: "secondary", className: "btn-sm", action: handleClose, label: getContent(cancelText) }))] })) }) }))] })));
};
export default SelectModal;
