import React, { useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'
import { vouchersService } from 'ziphy-web-shared/basic/api'
import { isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers'
import { useUpdateCallback } from 'ziphy-web-shared/basic/hooks'
import { useForm } from 'ziphy-web-shared/basic/lib/forms'
import { Input } from 'ziphy-web-shared/basic/lib/forms/fields'
import { BubbleLoader } from 'ziphy-web-shared/basic/lib/loaders'

import { $booking } from '@store'

import VoucherCard from '../../pages/bookingHome/selectPractice/voucherModal/voucherCard/VoucherCard'
import styles from './VoucherSelector.module.scss'

const VoucherSelector = ({
  selected,
  onChangeSelected,
  availablePracticeIds = [],
  practiceServiceId = null,
  className,
}) => {
  const { t } = useTranslation()
  const [loadedData, setLoadedData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  // Search and select
  const { form, useFormOnChange } = useForm({ searchString: { value: '' } })
  const { searchString } = form.fields

  const updateCallback = useUpdateCallback(async () => {
    const code = _.trim(searchString.value)
    if (isAbsoluteEmpty(code)) {
      setIsLoading(false)
      return handleClear()
    }

    const response = await vouchersService.search({
      code,
      practiceIds: availablePracticeIds,
      practiceServiceId: practiceServiceId,
    })

    handleClear()
    setLoadedData({
      vouchers: response.prepared?.vouchers.items || [],
      practices: response.prepared?.practices || [],
      practiceServices: response.prepared?.practiceServices || [],
    })
    setIsLoading(false)
  })
  useEffect(() => {
    if (searchString.value) {
      setIsLoading(true)
    }
  }, [searchString.value])

  useFormOnChange(updateCallback, [searchString.value])

  const handleClear = () => {
    setLoadedData(null)
    onChangeSelected(null)
  }

  // clear after code changing
  useEffect(() => {
    handleClear()
    searchString.onChange('')
  }, [availablePracticeIds])

  // View
  const preparedList = useMemo(() => {
    const list = _.map(loadedData?.vouchers, (voucher) => {
      const practiceService = loadedData.practiceServices.find(
        (x) => x.id === voucher.practiceServiceId,
      )
      const practice = loadedData.practices.find((x) => x.id === voucher.practiceId)
      const service = $booking.$services.getServiceById(practiceService.serviceId)

      return { voucher, practice, practiceService, service }
    })

    return _.orderBy(list, 'practice.name')
  }, [loadedData?.vouchers])

  return (
    <div className={className}>
      <Input
        field={searchString}
        label={t('label.voucher')}
        placeholder={t('label.enter_the_voucher_here')}
        mode="lg"
        clear={true}
        onClear={handleClear}
      />
      {isLoading ? (
        <div className={styles.loader}>
          <BubbleLoader />
        </div>
      ) : !preparedList.length && searchString.value ? (
        <div className={styles.notFound}>{t('ps.errors.no_results')}</div>
      ) : (
        preparedList.map((item) => (
          <VoucherCard
            key={item.voucher.id}
            className={styles.voucherCard}
            title={item.service.name}
            subTitle={item.practice.name}
            description={item.voucher.description}
            price={{ amount: item.voucher.price, currency: item.voucher.currency }}
            isCheckbox={true}
            isChecked={selected?.id === item.voucher.id}
            onClick={() => onChangeSelected(item.voucher)}
          />
        ))
      )}
    </div>
  )
}

export default observer(VoucherSelector)
