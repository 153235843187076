import React, { useMemo, useRef, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { Chart } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import 'chart.js/auto'
import 'chartjs-adapter-moment'
import _ from 'lodash'
import moment from 'moment-timezone'
import MultiSelect from 'ziphy-web-shared/basic/lib/forms/fields/multiSelect/MultiSelect'

import { eventsSchema } from '@ps/config/events'
import {
  getClosest,
  getEventsMinMaxDate,
  getSubcategoriesByEventConfigId,
} from '@ps/helpers/events'

import { $psHistory } from '@store'

import styles from './TimelineChart.module.scss'

const barOptions = {
  barPercentage: 1,
  maxBarThickness: 30,
}

const TimelineChart = () => {
  const { t } = useTranslation()

  const [initialDays, setInitialDays] = useState(365 * 100)
  const [historyType, setHistoryType] = useState([''])
  const chartRef = useRef(null)
  const allTime = 365 * 100

  function groupEventsByDay(events = []) {
    let result = {}

    _.forEach(events, (event) => {
      const subcategories = _.uniqBy(
        getSubcategoriesByEventConfigId(event.configId, eventsSchema),
        'category',
      )

      if (!_.isEmpty(subcategories)) {
        let date = event.effectiveDate.split('T')[0]

        _.forEach(subcategories, (subcategory) => {
          result[date] = result[date] || {}
          result[date][subcategory.category] = result[date][subcategory.category] || []

          result[date][subcategory.category].push(event)
        })
      }
    })

    return result
  }

  const pointData = useMemo(() => {
    let result = {}

    const eventsByDay = groupEventsByDay($psHistory.events)

    _.forEach(eventsByDay, (group, date) => {
      _.forEach(group, (events, categoryId) => {
        result[categoryId] = result[categoryId] || []
        result[categoryId].push({ x: date, y: events.length })
      })
    })

    return result
  }, [$psHistory.events])

  const data = useMemo(() => {
    let datasets = []

    _.forEach(_.orderBy(eventsSchema, 'order', 'asc'), (category) => {
      if (pointData[category.id]) {
        datasets.push({
          label: t(category.label),
          data: pointData[category.id] || [],
          backgroundColor: category.color,
          ...barOptions,
        })
      }
    })
    if (historyType && historyType.length === 0) setHistoryType([datasets.length])

    return { datasets }
  }, [pointData])

  const filteredData = useMemo(() => {
    let datasets = _.cloneDeep(data.datasets)

    if (historyType.length) {
      if (historyType[0] !== '') {
        let filteredDatasets = []
        for (let i = 0; i < datasets.length; i++) {
          if (historyType.includes(i)) filteredDatasets.push(datasets[i])
        }
        datasets = filteredDatasets
      }
    }

    if (initialDays !== allTime) {
      let minDate = Date.now()
      minDate = minDate - initialDays * 86400000
      for (let dataset of datasets) {
        dataset.data = dataset.data.filter((el) => Date.parse(el.x) > minDate)
      }
    }

    const dates = _.flatten(
      datasets.map((item) => item.data.map((item) => moment(item.x).valueOf())),
    )

    // ROUND DATES BY 2.5%
    if (dates.length > 1) {
      const maxDate = _.max(dates)
      const minDate = _.min(dates)
      const dateGap = (maxDate - minDate) * 0.025 // 2.5%
      const preparedDates = []

      if (dateGap) {
        for (let i = minDate; i <= maxDate; i += dateGap) {
          preparedDates.push(i)
        }
      } else {
        preparedDates.push(minDate)
      }

      for (let dataset of datasets) {
        const dataMap = {}
        _.forEach(dataset.data, (item) => {
          const date = moment(item.x).valueOf()
          const closestDate = getClosest(date, preparedDates)
          dataMap[closestDate] = dataMap[closestDate] || []
          dataMap[closestDate].push(item.y)
        })
        const newData = []
        _.forEach(dataMap, (item, key) => {
          newData.push({ x: +key, y: _.mean(item) })
        })
        _.sortBy(newData, ['x'])
        dataset.data = newData.map((item) => ({ ...item, x: moment(item.x).format('YYYY-MM-DD') }))
      }
    }

    return { datasets }
  }, [data, initialDays, historyType])

  const { minDate, maxDate } = useMemo(() => {
    let { minDate: min, maxDate: max } = getEventsMinMaxDate($psHistory.events)

    if (!max) max = Date.now()
    if (!min) min = max - Array.isArray(initialDays) ? initialDays[0] : initialDays * 86400000

    min = _.isNumber(min) ? min : Date.parse(min)
    max = _.isNumber(max) ? max : Date.parse(max)

    const days = Math.floor((max - min) / 86400000)
    const delta = Math.max(Array.isArray(initialDays) ? initialDays[0] : initialDays - days, 0)

    return {
      minDate: min - (delta / 2) * 86400000,
      maxDate: max + (delta / 2) * 86400000,
    }
  }, [$psHistory.events, initialDays])

  const options = useMemo(() => {
    return {
      plugins: {
        legend: {
          align: 'end',
          labels: {
            usePointStyle: true,
            boxWidth: 5,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      scales: {
        x: {
          type: 'time',
          ticks: {
            autoSkip: true,
            autoSkipPadding: 20,
            maxRotation: 0,
            major: { enabled: true },
            font(ctx) {
              if (ctx.tick && ctx.tick.major) return { weight: '600' }
            },
          },
          time: {
            unit: 'day',
            displayFormats: {
              day: 'DD/MM/YYYY',
              month: 'MM/YYYY',
            },
          },
          grid: {
            display: false,
          },
          stacked: true,
        },
        y: {
          suggestedMin: 4,
          title: {
            display: true,
            text: 'Amount',
            font: { size: 14 },
            padding: { top: 0, left: 0, right: 0, bottom: 10 },
          },
          ticks: {
            precision: 0,
          },
          grid: {
            drawBorder: false,
          },
          alignToPixels: true,
          stacked: true,
        },
      },
    }
  }, [minDate, maxDate]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="row justify-content-between">
        <div className="col-12 col-sm-6 mb-10">
          <div className={styles.formGroup}>
            <MultiSelect
              field={{
                value: initialDays,
                onChange: setInitialDays,
              }}
              items={[
                { id: 30, text: t('label.months', { count: 1 }) },
                {
                  id: 90,
                  text: t('label.months', { count: 3 }),
                },
                { id: 180, text: t('label.months', { count: 6 }) },
                {
                  id: 365,
                  text: t('label.years', { count: 1 }),
                },
                { id: 365 * 2, text: t('label.years', { count: 2 }) },
                {
                  id: 365 * 5,
                  text: t('label.years', { count: 5 }),
                },
                { id: allTime, text: t('label.allTime') },
              ]}
            />
          </div>
        </div>
        <div className="col-12 col-sm-6 mb-10">
          <div className={`${styles.formGroup} ${styles.toRight}`}>
            {data ? (
              <MultiSelect
                field={{
                  value: historyType,
                  onChange: setHistoryType,
                }}
                items={[...data.datasets.map((el, id) => ({ id, text: el.label }))]}
                multiSelect={true}
                allowUnselect
                allOption={t('label.allTypes')}
              />
            ) : null}
          </div>
        </div>
      </div>
      {filteredData ? (
        <div className={styles.timelineChart}>
          <Chart type="bar" ref={chartRef} data={filteredData} options={options} />
        </div>
      ) : null}
    </>
  )
}

export default observer(TimelineChart)
