import React, { useEffect, useRef } from 'react'

import { observer } from 'mobx-react-lite'

import _ from 'lodash'
import { $router } from 'ziphy-web-shared/basic/utils/router'

import { TOS_GENERAL, TOS_PROVIDER } from '@library/plugins/termsOfService/Tos.config'
import TosModal from '@library/plugins/termsOfService/Tos.modal'

import { $modal } from '@store'

import mainConfig from '@config/main'

const TosLauncher = () => {
  const modal = useRef(null)

  useEffect(() => {
    function showModal(type) {
      const modalItem = $modal.add(TosModal, {
        type,
        canDrop: false,
        onLeave() {
          $router.searchParams.remove(type)
        },
      })

      if (!modal.current) {
        modal.current = modalItem
      }
    }

    if (_.has($router.searchParams.getAll(), TOS_GENERAL)) {
      showModal(TOS_GENERAL)
    } else if (
      mainConfig.custom.tosProviderEnabled &&
      _.has($router.searchParams.getAll(), TOS_PROVIDER)
    ) {
      showModal(TOS_PROVIDER)
    } else if (modal.current) {
      modal.current._core.onHide()
      modal.current = null
    }
  }, [$router.search])

  return <></>
}

export default observer(TosLauncher)
