import _ from 'lodash';
import { getStagedEnv } from 'ziphy-web-shared/basic/helpers';
import byPracticeConfig from '@config/byPractice';
export const getLinkByPractice = (configKey, practiceId) => {
    var _a;
    const config = (_a = byPracticeConfig[configKey]) === null || _a === void 0 ? void 0 : _a[getStagedEnv()];
    if (!config) {
        return null;
    }
    for (let [practiceIds, value] of Object.entries(config)) {
        if (practiceIds === 'any') {
            return value;
        }
        else if (practiceIds.split('_').includes(_.toString(practiceId))) {
            return value;
        }
    }
    return null;
};
