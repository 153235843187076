import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import SignoutPage from 'ziphy-web-shared/pages/app/SignoutPage';
import DefaultLayout from '@src/layouts/DefaultLayout';
import AuthProvider from '@library/providers/AuthProvider';
import PageProvider from '@library/providers/PageProvider';
import ApptPage from '@pages/ApptPage';
import ApptsPage from '@pages/ApptsPage';
import ClientProfilePage from '@pages/ClientProfilePage';
import CocPatientPage from '@pages/CocPatientPage';
import CocPatientsPage from '@pages/CocPatientsPage';
import ErrorPage from '@pages/errors/ErrorPage';
import HomePage from '@pages/HomePage';
import PaymentMethodsPage from '@pages/PaymentMethodsPage';
import BookingFinishPage from '@booking/pages/bookingFinish/BookingFinishPage';
import BookingSelectPatientsPage from '@booking/pages/bookingSelectPatients/BookingSelectPatientsPage';
import BookingSymptomsPage from '@booking/pages/bookingSymptoms/BookingSymptomsPage';
import BookingTimePickerPage from '@booking/pages/bookingTimePicker/BookingTimePickerPage';
import PsPatientPage from '@ps/pages/PsPatientPage';
export const routes = [
    {
        id: 'home',
        path: '/',
        element: (_jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(HomePage, {}) }) })),
        exact: true,
    },
    {
        id: 'homeInvitation',
        path: '/r/:invitationCode?',
        element: (_jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(HomePage, { registration: true }) }) })),
    },
    {
        id: 'bookPatients',
        tags: ['hideNearestBar', 'hideRightSidebar'],
        path: '/book/patients',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(BookingSelectPatientsPage, {}) }) }) })),
    },
    {
        id: 'bookSymptoms',
        tags: ['hideNearestBar', 'hideRightSidebar'],
        path: '/book/symptoms',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, Object.assign({ fullHeight: true }, { children: _jsx(PageProvider, { children: _jsx(BookingSymptomsPage, {}) }) })) })),
    },
    {
        id: 'bookDate',
        tags: ['hideNearestBar', 'hideRightSidebar'],
        path: '/book/date',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(BookingTimePickerPage, {}) }) }) })),
    },
    {
        id: 'bookFinish',
        tags: ['hideNearestBar', 'hideRightSidebar'],
        path: '/book/finish',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(BookingFinishPage, {}) }) }) })),
    },
    {
        id: 'appts',
        path: '/appointments',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(ApptsPage, {}) }) }) })),
    },
    {
        id: 'appt',
        tags: ['hideNearestBar'],
        path: '/appointments/:appointmentId/:encounterId?',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, Object.assign({ onEnterScrollToTop: true }, { children: _jsx(ApptPage, {}) })) }) })),
    },
    {
        id: 'profile',
        path: '/profile',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(ClientProfilePage, {}) }) }) })),
    },
    {
        id: 'patients',
        path: '/circle',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(CocPatientsPage, {}) }) }) })),
    },
    {
        id: 'patient',
        path: '/circle/:patientId',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(CocPatientPage, {}) }) }) })),
    },
    {
        id: 'psPatient',
        path: '/circle/:patientId/practice/:practiceId/:categoryId?',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(PsPatientPage, {}) }) }) })),
    },
    {
        id: 'pms',
        path: '/payment-methods',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(PaymentMethodsPage, {}) }) }) })),
    },
    {
        id: 'signout',
        path: '/signout',
        element: (_jsx(AuthProvider, { children: _jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(SignoutPage, {}) }) }) })),
    },
    {
        id: 'error',
        path: '*',
        element: (_jsx(DefaultLayout, { children: _jsx(PageProvider, { children: _jsx(ErrorPage, {}) }) })),
    },
];
