import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import moment from 'moment-timezone';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { $time, formatDate } from 'ziphy-web-shared/basic/utils/time';
import styles from './DateRewind.module.scss';
const DateRewind = ({ isDay, begin, end, onChange, daysInRewind = 180, weekType = $time.weekType, timezone = $time.timezone, }) => {
    const now = moment.tz(timezone);
    const startWeek = now.clone().startOf(weekType);
    const today = now.clone().startOf('day');
    const period = isDay ? 'day' : 'week';
    begin = moment(begin);
    end = moment(end);
    const backInactive = isDay ? today.isSame(begin, 'day') : startWeek.isSame(begin, 'day');
    const forwardInactive = end.isAfter(now.clone().add(daysInRewind, 'day').endOf(weekType), 'day');
    function onBack() {
        if (!backInactive) {
            onChange({ begin: begin.clone().subtract(1, period), end: begin });
        }
    }
    function onForward() {
        if (!forwardInactive) {
            onChange({ begin: end, end: end.clone().add(1, period) });
        }
    }
    const getDate = (date) => formatDate(date, { format: 'dateNoYear' });
    return (_jsxs("div", Object.assign({ className: styles.rewind }, { children: [_jsx("div", Object.assign({ className: classNames(styles.rewindControl, styles.rewindControlBack, backInactive && styles.rewindControlInactive), onClick: onBack }, { children: _jsx(Icon, { type: "back" }) })), _jsx("div", Object.assign({ className: classNames(styles.rewindSelected, isDay && styles.rewindSelectedIsDay) }, { children: isDay
                    ? `${getDate(begin)}`
                    : `${getDate(begin)} — ${getDate(end.clone().subtract(1, 'day'))}` })), _jsx("div", Object.assign({ className: classNames(styles.rewindControl, styles.rewindControlForward, forwardInactive && styles.rewindControlInactive), onClick: onForward }, { children: _jsx(Icon, { type: "back" }) }))] })));
};
export default observer(DateRewind);
