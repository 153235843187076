import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, isValidElement, memo } from 'react';
import classNames from 'classnames';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { getStylesMode } from 'ziphy-web-shared/basic/helpers';
import { CustomLink } from 'ziphy-web-shared/basic/lib/buttons';
import { BubbleLoader } from 'ziphy-web-shared/basic/lib/loaders';
import styles from './Button.module.scss';
const Button = forwardRef((props, ref) => {
    const { autoTarget, to = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.to, href = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.href, hrefTarget = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.hrefTarget, onClick = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.onClick, wvAction = autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.wvAction, id, form, type, disabled, className, style, children, 
    //
    mode, borderRadius, isActive, isLoading, leftElement, rightElement, } = props;
    const modes = Array.isArray(mode) ? mode : [mode];
    const modeStyles = [];
    let foundSize = false;
    modes.forEach((x) => {
        modeStyles.push(styles[x]);
        if (!foundSize && ['xs', 'sm', 'md', 'lg'].includes(x))
            foundSize = true;
    });
    if (!foundSize)
        modeStyles.push(styles.md);
    return (_jsxs(CustomLink, Object.assign({ ref: ref, autoTarget: Object.assign(Object.assign({}, autoTarget), { to, href, hrefTarget, onClick, wvAction }), type: type, id: id, form: form, disabled: disabled, style: style, className: classNames(styles.btn, modeStyles, borderRadius && getStylesMode(borderRadius, styles), className, disabled && styles.isDisabled, isActive && styles.isActive, isLoading && styles.isLoading) }, { children: [leftElement && (_jsx("div", Object.assign({ className: styles.left }, { children: typeof leftElement === 'string' ? (_jsx(Icon, { type: leftElement, className: styles.icon })) : (isValidElement(leftElement) && leftElement) }))), children && _jsx("div", Object.assign({ className: styles.content }, { children: children })), rightElement && (_jsx("div", Object.assign({ className: styles.right }, { children: typeof rightElement === 'string' ? (_jsx(Icon, { type: rightElement, className: styles.icon })) : (isValidElement(rightElement) && rightElement) }))), isLoading && (_jsx("div", Object.assign({ className: styles.loader }, { children: _jsx(BubbleLoader, { className: styles.bubbleLoader }) })))] })));
});
export default memo(Button);
