var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import { $patients } from 'ziphy-web-shared/basic/entities/patients';
import { getUserText } from 'ziphy-web-shared/basic/helpers';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import { WithLoaderWrapper } from 'ziphy-web-shared/basic/lib/loaders';
import { PatientInfoBox } from 'ziphy-web-shared/basic/lib/simple';
import { Title } from 'ziphy-web-shared/basic/lib/simple';
import { useChangeLanguage } from 'ziphy-web-shared/basic/utils/i18n';
import { usePageErrorBoundary } from '@library/providers/PageProvider';
import SelectModal from '@library/select/Select.modal';
import UserPhoto from '@library/user/UserPhoto';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import AccountsControllerModal from '@nedoShared/features/accounts/accountsController/AccountsController.modal';
import Toolbar from '@nedoShared/features/layouts/toolbar/Toolbar';
import EditPatientModal from '@nedoShared/features/patients/patientController/editPatientModal/EditPatient.modal';
import { $modal } from '@store';
import { routeActions } from '@config/routeActions';
const ClientProfilePage = () => {
    const { t, i18n } = useTranslation();
    const { availableLangs, onChangeLang } = useChangeLanguage();
    const errorBoundary = usePageErrorBoundary();
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        yield $auth.loadProfile();
        if (!$patients.defaultPatient) {
            return errorBoundary.error();
        }
    }));
    const patient = $patients.defaultPatient;
    return (_jsxs(WithLoaderWrapper, Object.assign({ isLoading: isLoading, loaderMode: 'pageWrapper', className: "content" }, { children: [_jsx(Toolbar, { goBackProps: { autoTarget: routeActions.HOME() } }), _jsx("div", Object.assign({ className: "container" }, { children: _jsxs("div", Object.assign({ className: "max_width--lg" }, { children: [_jsx(Title, Object.assign({ size: 'h1', className: 'mb-30' }, { children: t('label.profile') })), _jsx(PatientInfoBox, { onClick: () => $modal.add(EditPatientModal, { patientId: patient.id, isProfile: true }), value: getUserText(patient, ['fullName']), label: getUserText(patient, ['gender', 'yo']), className: "mb-10", leftElement: _jsx(UserPhoto, { item: patient }), withArrow: true }), _jsx(PatientInfoBox, { onClick: () => $modal.add(AccountsControllerModal), value: t('label.emails_&_phones'), className: "mb-10", withArrow: true }), availableLangs.length > 1 && (_jsx(PatientInfoBox, { onClick: () => $modal.add(SelectModal, {
                                title: t('label.language'),
                                items: availableLangs,
                                value: [i18n.language],
                                allowUnselect: false,
                                onChange: (v) => onChangeLang(v[0]),
                            }), value: t('label.language'), className: "mb-10", withArrow: true })), _jsx("div", Object.assign({ className: "btn-group" }, { children: _jsx(ButtonLegacy, { label: t('btn.sign_out'), mode: ['secondary-dark', 'block'], autoTarget: routeActions.SIGNOUT(), className: 'mt-20' }) }))] })) }))] })));
};
export default observer(ClientProfilePage);
