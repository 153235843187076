var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ENUMS_INSURANCE_TYPES, } from 'ziphy-web-shared/basic/entities/insurances';
import { getEnumObjectValue } from 'ziphy-web-shared/basic/helpers';
import { Checkbox } from 'ziphy-web-shared/basic/lib/simple';
import { $loader, $windowSize } from 'ziphy-web-shared/basic/utils';
import Badge from '@library/ui/badge/Badge';
import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator';
import { $modal } from '@store';
import ViewInsuranceModal from '../viewInsuranceModal/ViewInsurance.modal';
import styles from './InsuranceListItem.module.scss';
const InsuranceListItem = ({ patientId, insurance, mode, onSetPrimary, onDelete, className, }) => {
    const { t } = useTranslation();
    const type = getEnumObjectValue(insurance.type, ENUMS_INSURANCE_TYPES).value;
    function handleClick() {
        if (mode === 'setPrimary') {
            onSetPrimary(insurance.id);
        }
        else if (mode === 'view') {
            $modal.add(ViewInsuranceModal, { patientId, id: insurance.id, canEdit: true });
        }
    }
    function handleDelete(e) {
        e.stopPropagation();
        confirmAction('customAlert', {
            data: {
                title: t('insurance.remove_alert.title'),
                message: t('insurance.remove_alert.message'),
            },
            callback: $loader.registerHandler('delete-insurance', () => __awaiter(this, void 0, void 0, function* () {
                yield onDelete({ id: insurance.id, patientId });
            })),
        });
    }
    return (_jsxs("div", Object.assign({ className: classNames(styles.item, styles[mode], className), onClick: handleClick }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("div", Object.assign({ className: styles.headerText, title: type }, { children: type })), insurance.isPrimary && (_jsx(Badge, Object.assign({ type: ['primary-fill'], className: styles.badge }, { children: t('label.primary') })))] })), _jsx("div", Object.assign({ className: styles.body }, { children: _jsx("div", Object.assign({ className: styles.title }, { children: insurance.providerName === insurance.type ? type : insurance.providerName })) })), mode === 'view' && !$windowSize.isMobileSize && (_jsx("div", { className: styles.deleteButton, onClick: handleDelete })), mode === 'setPrimary' && (_jsx(Checkbox, { className: styles.checkbox, isChecked: insurance.isPrimary, mode: ['round', 'dark'] }))] })));
};
export default observer(InsuranceListItem);
