import React, { useEffect, useMemo } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { Input } from 'ziphy-web-shared/basic/lib/forms/fields'
import { useForm } from 'ziphy-web-shared/basic/lib/forms/Form.hooks'
import { PatientInfoBox } from 'ziphy-web-shared/basic/lib/simple'
import { $windowSize } from 'ziphy-web-shared/basic/utils'

import { TransitionWrapper } from '@library/animations/Animations'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import { extendPlace } from '@helpers/addressHelper'

import { $places } from '@store'

import styles from './AddPlaceModalDetails.module.scss'

const AddPlaceModalDetails = ({ ...props }) => {
  const { t } = useTranslation()

  const {
    place: _place,
    editId,
    isDetails,
    onChange,
    onSave,
    onRemove,
    toggleView,
    _core,
    isBuildingReadOnly,
    isStreetReadOnly,
    isCityReadOnly,
    isStateReadOnly,
    isZipReadOnly,
    isEdit,
    isLoading,
  } = props

  const { form } = useForm({
    building: { value: _place.building, rules: ['required'] },
    street: { value: _place.street, rules: ['required'] },
    city: { value: _place.city, rules: ['required'] },
    state: { value: _place.state, rules: ['required'] },
    zip: { value: _place.zip, rules: [] },
    apartment: { value: _place.apartment },
    floor: { value: _place.floor },
    directions: { value: _place.directions },
  })

  const { building, street, city, state, zip, apartment, floor, directions } = form.fields

  const formInvalid = useMemo(() => {
    return _place.country === 'US' && !_.trim(state.value)
  }, [_place.country, state.value]) // eslint-disable-line react-hooks/exhaustive-deps

  const place = { ..._place, ...form.values } // eslint-disable-line react-hooks/exhaustive-deps
  const extendedPlace = extendPlace(place)

  useEffect(() => {
    _.forEach(form.values, (value, key) => {
      if (!_.isEqual(value, _place[key])) {
        form.fields[key].setValue(_place[key])
      }
    })
    if (_place.country === 'US' && !_.trim(_place.state)) {
      form.forceError('state')
    }
  }, [_place]) // eslint-disable-line react-hooks/exhaustive-deps

  const isChanged = useMemo(() => {
    return editId && !_.isEqual(place, $places.getById(editId))
  }, [$places, place, editId]) // eslint-disable-line react-hooks/exhaustive-deps

  function onToggle(e) {
    onChange(place)
    toggleView(e)
  }

  function onCancel(e) {
    e.preventDefault()
    _core.onHide()
  }

  function handleOnSave(e) {
    e.preventDefault()

    if (form.validateAll().isValid) {
      onChange(place, true)
    } else {
      onChange(place)
    }
  }

  function handleOnRemove(e) {
    onRemove(e)
  }

  return (
    <TransitionWrapper
      items={isDetails}
      initial={!isDetails}
      from={{ opacity: 0, height: 0, marginTop: 0 }}
      enter={{ opacity: 1, height: 'auto', marginTop: 20 }}
      leave={{ opacity: 0, height: 0, marginTop: 0 }}
    >
      {(item) =>
        item &&
        ((style) => (
          <form className={styles.details} style={style}>
            <>
              {$windowSize.isMobileSize ? (
                <PatientInfoBox
                  label={extendedPlace.line2()}
                  value={extendedPlace.shortLine1()}
                  className="mb-10"
                  onClick={isEdit ? undefined : onToggle}
                />
              ) : (
                <>
                  <PatientInfoBox
                    label={extendedPlace.line2()}
                    value={extendedPlace.shortLine1()}
                    className="mb-10"
                    mode={['clearRightElement']}
                    rightElement={
                      !isEdit && (
                        <ButtonLegacy
                          mode={['white', 'sm']}
                          className={'text-nowrap'}
                          action={onToggle}
                        >
                          {t('btn.change_address')}
                        </ButtonLegacy>
                      )
                    }
                  />
                </>
              )}
              <div className="mb-30">
                <div className="row row--gutters-10 row--mb-10">
                  <div className="col-4 col-lg-3">
                    <Input
                      className={classNames({ 'pe-none': isEdit })}
                      label={t('add_address.label.building')}
                      field={building}
                      mode="lg"
                      readonly={isBuildingReadOnly}
                      onClick={() => isBuildingReadOnly && toggleView()}
                    />
                  </div>
                  <div className="col-8 col-lg-5">
                    <Input
                      className={classNames({ 'pe-none': isEdit })}
                      label={t('add_address.label.street')}
                      field={street}
                      mode="lg"
                      readonly={isStreetReadOnly}
                      onClick={() => isStreetReadOnly && toggleView()}
                    />
                  </div>
                  <div className="col-6 col-lg-2">
                    <Input label={t('add_address.label.apt')} field={apartment} mode="lg" />
                  </div>
                  <div className="col-6 col-lg-2">
                    <Input label={t('add_address.label.floor')} field={floor} mode="lg" />
                  </div>
                  <div className="col-12 col-lg-6">
                    <Input
                      className={classNames({ 'pe-none': isEdit })}
                      label={t('label.city')}
                      field={city}
                      mode="lg"
                      readonly={isCityReadOnly}
                      onClick={() => isCityReadOnly && toggleView()}
                    />
                  </div>
                  <div className="col-8 col-lg-4">
                    <Input
                      className={classNames({ 'pe-none': isEdit })}
                      label={t('label.state')}
                      field={state}
                      mode="lg"
                      readonly={isStateReadOnly}
                      onClick={() => isStateReadOnly && toggleView()}
                    />
                  </div>
                  <div className="col-4 col-lg-2">
                    <Input
                      className={classNames({ 'pe-none': isEdit })}
                      label={t('add_address.label.zip')}
                      field={zip}
                      mode="lg"
                      readonly={isZipReadOnly}
                      onClick={() => isZipReadOnly && toggleView()}
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      label={t('add_address.label.directions')}
                      field={directions}
                      placeholder={t('add_address.ph.directions')}
                      mode="lg"
                    />
                  </div>
                </div>
              </div>
              <div className="btn-group">
                {editId ? (
                  isChanged ? (
                    <>
                      <ButtonLegacy
                        tag="button"
                        mode="primary"
                        disabled={formInvalid}
                        action={handleOnSave}
                        isLoading={isLoading}
                      >
                        {t('btn.save')}
                      </ButtonLegacy>
                      {!$windowSize.isMobileSize && (
                        <ButtonLegacy tag="button" mode="secondary-dark" action={onCancel}>
                          {t('btn.cancel')}
                        </ButtonLegacy>
                      )}
                    </>
                  ) : (
                    <>
                      <ButtonLegacy tag="button" mode="primary" action={onCancel}>
                        {t('btn.done')}
                      </ButtonLegacy>
                      {!$windowSize.isMobileSize && (
                        <ButtonLegacy tag="button" mode="secondary-dark" action={handleOnRemove}>
                          {t('btn.delete')}
                        </ButtonLegacy>
                      )}
                    </>
                  )
                ) : (
                  <ButtonLegacy
                    tag="button"
                    mode="primary"
                    disabled={formInvalid}
                    action={handleOnSave}
                  >
                    {t('btn.add')}
                  </ButtonLegacy>
                )}
              </div>
            </>
          </form>
        ))
      }
    </TransitionWrapper>
  )
}

export default observer(AddPlaceModalDetails)
