var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { $contactsContexts } from 'ziphy-web-shared/basic/entities/contacts';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import { WithLoaderWrapper } from 'ziphy-web-shared/basic/lib/loaders';
import ContactsList from './contactsList/ContactsList';
const ContactsController = ({ patient, className }) => {
    const $controller = $contactsContexts.get(patient.id);
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        yield $controller.list();
    }));
    return (_jsx(WithLoaderWrapper, Object.assign({ isLoading: isLoading, className: className }, { children: _jsx(ContactsList, { patientId: patient.id }) })));
};
export default observer(ContactsController);
