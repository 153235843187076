import React from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import hAppt from '@helpers/hAppt'

const ApptSymptoms = ({ byRoute }) => {
  const { t } = useTranslation()
  const { fullAppt } = byRoute

  const symptoms =
    !_.isEmpty(fullAppt.patientSymptoms) &&
    hAppt.prepareSymptoms({ patientSymptoms: fullAppt.patientSymptoms })

  if (!symptoms) {
    return <></>
  }

  return (
    <div className="appt-symptoms">
      <div className="title-sm">{t('appt.appt_symptoms.title')}</div>
      <p className="appt-symptoms__text m-0">{symptoms}</p>
    </div>
  )
}

export default observer(ApptSymptoms)
