import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { Trans, useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { $booking } from '@store'

import styles from './CopyVoucher.module.scss'

const modes = {
  ATTACH: 'attach',
  COPY: 'copy',
}

const CopyVoucher = ({ className, mode = modes.ATTACH }) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState()

  useEffect(() => {
    if (isCopied) {
      const id = setTimeout(() => setIsCopied(false), 1000)
      return () => clearTimeout(id)
    }
  }, [isCopied])

  const handleClickCopy = () => {
    switch (mode) {
      case modes.COPY:
        navigator.clipboard.writeText($booking.voucher.code)
        setIsCopied(true)
        break
      case modes.ATTACH:
        $booking.patients.updateAllSelected(() => ({ voucher: $booking.voucher }))
    }
  }

  if ($booking.patients.items.every((item) => !!item.voucher || item.useInsurance)) {
    return null
  }

  return (
    <div className={classNames(styles.container, className)}>
      <div>
        <Trans
          i18nKey="book_details.label.have_voucher"
          values={{ voucherCode: $booking.voucher.code }}
          components={[<b />]}
        />
      </div>
      <div className={styles.copy} onClick={handleClickCopy}>
        {isCopied ? t('label.copied') : t('label.copy')}
      </div>
    </div>
  )
}

export default observer(CopyVoucher)
