import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import './Badge.scss';
const Badge = ({ type, onClose, callback, clickable, children, className }) => {
    const clickHandler = () => {
        if (clickable && callback) {
            return callback();
        }
    };
    function getTypes(type) {
        const array = _.isArray(type) ? type : [type];
        return array.map((x) => 'badge--' + x).join(' ');
    }
    const closeHandler = (e) => {
        e.stopPropagation();
        onClose();
    };
    return (_jsxs("div", Object.assign({ className: classNames('badge', type && getTypes(type), clickable && 'badge--clickable', className), onClick: clickHandler }, { children: [children, onClose && (_jsx("div", Object.assign({ className: "badge-close", onClick: (e) => closeHandler(e) }, { children: _jsx(Icon, { type: "close", size: 20 }) })))] })));
};
export default Badge;
