import React from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import Icon from 'ziphy-web-shared/basic/assets/images/Icon'
import { $auth } from 'ziphy-web-shared/basic/entities/auth'
import { Button } from 'ziphy-web-shared/basic/lib/buttons'

import SidebarMenuItem from '@src/layouts/sidebar/SidebarMenuItem'

import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator'

import { $appts } from '@store'

import { routeActions } from '@config/routeActions'

const SidebarMenu = ({ onHideSidebar }) => {
  const { t } = useTranslation()

  const countAppts = $appts.activeList.length

  function checkAuth(e, props) {
    confirmAction('checkAuth', props, e)
  }

  return (
    <>
      {$auth.isLogged ? (
        <>
          <SidebarMenuItem
            autoTarget={routeActions.HOME()}
            onClick={onHideSidebar}
            params={{ exact: true }}
            icon={<Icon type={'home'} />}
          >
            {t('btn.home')}
          </SidebarMenuItem>
          <SidebarMenuItem
            autoTarget={routeActions.APPTS()}
            onClick={onHideSidebar}
            params={{ exact: true }}
            icon={<Icon type={'list'} />}
            counter={countAppts}
          >
            {t('btn.appointments')}
          </SidebarMenuItem>
          <SidebarMenuItem
            autoTarget={routeActions.PROFILE()}
            onClick={onHideSidebar}
            icon={<Icon type={'user'} />}
          >
            {t('btn.profile')}
          </SidebarMenuItem>
          <SidebarMenuItem
            autoTarget={routeActions.PATIENTS()}
            onClick={onHideSidebar}
            icon={<Icon type={'users'} />}
          >
            {t('menu.circle_of_care')}
          </SidebarMenuItem>
          <SidebarMenuItem
            autoTarget={routeActions.PMS()}
            onClick={onHideSidebar}
            icon={<Icon type={'wallet'} />}
          >
            {t('menu.payment_method')}
          </SidebarMenuItem>
        </>
      ) : (
        <div className="text-center">
          <Button mode={['secondary', 'block']} onClick={checkAuth}>
            {t('btn.start')}
          </Button>
        </div>
      )}
    </>
  )
}

export default observer(SidebarMenu)
