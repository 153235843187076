import { jsx as _jsx } from "react/jsx-runtime";
import React, { Fragment, memo } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'ziphy-web-shared/basic/lib/utilities';
import styles from './ShowMoreTooltip.module.scss';
const ShowMoreTooltip = ({ value, tooltipData, mode = 'inlineDashed', className }) => {
    const hasTooltip = Boolean(tooltipData);
    const classes = classNames(styles.wrapper, hasTooltip && styles.hasTooltip, styles[mode], className);
    function getItems(targetItems) {
        return targetItems.map((item, index) => {
            const isPrimitive = typeof item === 'string' || typeof item === 'number';
            const isNeedComma = isPrimitive && index < targetItems.length - 1;
            return _jsx(Fragment, { children: item + (isNeedComma ? ', ' : '') }, index);
        });
    }
    if (hasTooltip) {
        return (_jsx(Tooltip, Object.assign({ content: getItems(Array.isArray(tooltipData) ? tooltipData : [tooltipData]), className: classes }, { children: getItems(Array.isArray(value) ? value : [value]) })));
    }
    return _jsx("div", Object.assign({ className: classes }, { children: getItems(Array.isArray(value) ? value : [value]) }));
};
export default memo(ShowMoreTooltip);
