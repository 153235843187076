import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useForm } from 'ziphy-web-shared/basic/lib/forms';
import { Title } from 'ziphy-web-shared/basic/lib/simple';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
import ApptsSearch from '@nedoShared/entityViews/appointments/apptsSearch/ApptsSearch';
import ApptsTabCurrent from '@nedoShared/features/appointments/apptsTabs/ApptsTabCurrent';
import ApptsTabHistory from '@nedoShared/features/appointments/apptsTabs/ApptsTabHistory';
import Toolbar from '@nedoShared/features/layouts/toolbar/Toolbar';
import { routeActions } from '@config/routeActions';
const Appts = ({ hasBurger }) => {
    const { t } = useTranslation();
    const { form } = useForm({
        view: { value: 'current' },
        practiceId: { value: null, prepValue: 'singleSelect' },
        showFilters: { value: false },
        dateFrom: { value: '' },
        dateTo: { value: '' },
    });
    const practiceId = form.fields.practiceId.value;
    const dateFrom = form.fields.dateFrom.value;
    const dateTo = form.fields.dateTo.value
        ? moment(form.fields.dateTo.value).endOf('day').toISOString()
        : '';
    return (_jsxs("div", Object.assign({ className: "content" }, { children: [_jsx(Toolbar, { hasBurger: hasBurger && $windowSize.isMobileSize, goBackProps: !hasBurger && { autoTarget: routeActions.ROOT() } }), _jsxs("div", Object.assign({ className: "container" }, { children: [_jsx(Title, Object.assign({ size: 'h1', className: 'mb-30' }, { children: t('label.appointments') })), _jsx(ApptsSearch, { filters: form.fields, className: 'mb-30' }), form.fields.view.value === 'current' && (_jsx(ApptsTabCurrent, { practiceId: practiceId, dateFrom: dateFrom, dateTo: dateTo })), form.fields.view.value === 'history' && (_jsx(ApptsTabHistory, { practiceId: practiceId, dateFrom: dateFrom, dateTo: dateTo }))] }))] })));
};
export default observer(Appts);
