var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from 'mobx';
import _ from 'lodash';
import moment from 'moment-timezone';
import { documentsService } from 'ziphy-web-shared/basic/api';
import { DocumentUploaderStatus, } from './DocumentUploader.types';
class DocumentUploaderStore {
    constructor() {
        this.encounterDocumentsMap = {};
        this.patientDocumentsMap = {};
        this.documentTypes = {};
        this.addDocument = (document, patientId, encounterId) => {
            const documentsList = this.getDocumentsList(patientId, encounterId);
            documentsList.push(document);
        };
        this.removeDocument = (patientId, index, encounterId) => {
            const documentsList = this.getDocumentsList(patientId, encounterId);
            documentsList.splice(index, 1);
        };
        this.setDocumentStatus = (props, status) => {
            const documentsList = this.getDocumentsList(props.patientId, props.encounterId);
            documentsList[props.index].status = status;
        };
        makeAutoObservable(this);
    }
    getDocumentsList(patientId, encounterId) {
        const documentsMap = encounterId ? this.encounterDocumentsMap : this.patientDocumentsMap;
        const documentsMapKey = encounterId ? encounterId : patientId;
        if (!documentsMap[documentsMapKey]) {
            runInAction(() => {
                documentsMap[documentsMapKey] = [];
            });
        }
        return documentsMap[documentsMapKey];
    }
    getDocumentTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield documentsService.getListDocumentTypes();
            runInAction(() => (this.documentTypes = response.prepared || {}));
            return response;
        });
    }
    getDocumentUploadUrl({ patientId, encounterId, index, document, baseFileName, }) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = document.file.type;
            const dateUpload = moment(new Date()).utc().format('YYYYMMDD_kkmmss');
            const fileName = [baseFileName, document.type.name, dateUpload].join('_') +
                '.' +
                _.last(document.file.name.split('.'));
            const response = yield documentsService.getDocumentsUploadUrls({
                encounterId: encounterId,
                data: [
                    {
                        contentType: contentType,
                        filename: fileName,
                        // documentType: typeName,
                    },
                ],
            });
            const isSuccess = (_b = (_a = response.prepared) === null || _a === void 0 ? void 0 : _a.urls) === null || _b === void 0 ? void 0 : _b[0];
            if (!isSuccess) {
                this.setDocumentStatus({ patientId, encounterId, index }, DocumentUploaderStatus.ERROR);
            }
            return { isSuccess, data: (_d = (_c = response.prepared) === null || _c === void 0 ? void 0 : _c.urls) === null || _d === void 0 ? void 0 : _d[0] };
        });
    }
    uploadFile({ patientId, encounterId, index, url, file, fields, filename, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            _.forEach(fields, (value, key) => formData.append(key, value));
            formData.append('file', file, filename);
            let isSuccess;
            try {
                yield fetch(url, { method: 'POST', body: formData, mode: 'no-cors' });
                // await myackAxiosInstance.post(url, formData)
                isSuccess = true;
            }
            catch (e) {
                this.setDocumentStatus({ patientId, encounterId, index }, DocumentUploaderStatus.ERROR);
                isSuccess = false;
            }
            return { isSuccess };
        });
    }
    createDocument({ patientId, encounterId, index, key, document, }) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield documentsService.createDocument({
                encounterId: encounterId,
                key: key,
                contentType: document.file.type,
                description: document.description,
                documentType: document.type.name,
            });
            const isSuccess = (_a = response.prepared) === null || _a === void 0 ? void 0 : _a.result;
            this.setDocumentStatus({ patientId, encounterId, index }, isSuccess ? DocumentUploaderStatus.UPLOADED : DocumentUploaderStatus.ERROR);
        });
    }
    uploadProcess({ patientId, encounterId, baseFileName, document, index, }) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            this.setDocumentStatus({ patientId, encounterId, index }, DocumentUploaderStatus.UPLOADING);
            const uploadUrlParams = yield this.getDocumentUploadUrl({
                patientId,
                encounterId,
                index,
                document,
                baseFileName,
            });
            if (uploadUrlParams.isSuccess) {
                const uploadFile = yield this.uploadFile(Object.assign(Object.assign({}, uploadUrlParams.data), { patientId: patientId, encounterId: encounterId, index: index, file: document.file, filename: uploadUrlParams.data.filename }));
                if (uploadFile.isSuccess) {
                    yield this.createDocument({
                        patientId: patientId,
                        encounterId: encounterId,
                        index: index,
                        key: (_a = uploadUrlParams.data.fields) === null || _a === void 0 ? void 0 : _a.key,
                        document: document,
                    });
                }
            }
        });
    }
    uploadDocument({ patientId, encounterId, file, type, baseFileName, description, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const document = {
                file: file,
                type: type,
                status: DocumentUploaderStatus.UPLOADING,
                description: description,
            };
            this.addDocument(document, patientId, encounterId);
            yield this.uploadProcess({
                patientId: patientId,
                encounterId: encounterId,
                index: this.getDocumentsList(patientId, encounterId).length - 1,
                baseFileName: baseFileName,
                document: document,
            });
        });
    }
}
export const $documentUploader = new DocumentUploaderStore();
