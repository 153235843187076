import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PatientPhotoAndName from '@library/ui/patientPhotoAndName/PatientPhotoAndName';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import { customActions } from '@config/customActions';
import styles from './CocPatientHeader.module.scss';
const CocPatientHeader = ({ patient, className }) => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: classNames(styles.header, className) }, { children: [_jsx(PatientPhotoAndName, { firstName: patient.firstName, lastName: patient.lastName, avatar: patient.avatar, isDeceased: patient.isDeceased }), _jsx("div", Object.assign({ className: styles.buttonGroup }, { children: customActions.HANDLE_EDIT_PATIENT && (_jsx(ButtonLegacy, Object.assign({ autoTarget: customActions.HANDLE_EDIT_PATIENT({ patientId: patient.id }), mode: ['secondary', 'sm'] }, { children: t('btn.edit_patient') }))) }))] })));
};
export default CocPatientHeader;
