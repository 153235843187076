var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { $patients } from 'ziphy-web-shared/basic/entities/patients';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import { WithLoaderWrapper } from 'ziphy-web-shared/basic/lib/loaders';
import { Title } from 'ziphy-web-shared/basic/lib/simple';
import Toolbar from '@nedoShared/features/layouts/toolbar/Toolbar';
import PatientsList from '@nedoShared/features/patients/patientController/patientsList/PatientsList';
import { routeActions } from '@config/routeActions';
const CocPatientsPage = () => {
    const { t } = useTranslation();
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        yield $patients.activeList();
    }));
    function handleOnClick(item) {
        return routeActions.PATIENT({ patientId: item.id });
    }
    return (_jsxs("div", Object.assign({ className: "content" }, { children: [_jsx(Toolbar, { goBackProps: { autoTarget: routeActions.HOME() } }), _jsxs("div", Object.assign({ className: "container" }, { children: [_jsx(Title, Object.assign({ size: 'h1' }, { children: t('label.circle_of_care') })), _jsx(WithLoaderWrapper, Object.assign({ isLoading: isLoading, className: "mt-30 max_width--lg" }, { children: _jsx(PatientsList, { items: $patients.items, to: handleOnClick }) }))] }))] })));
};
export default observer(CocPatientsPage);
