import React from 'react'

import { Trans, useTranslation } from 'react-i18next'

import { PageNoResults } from 'ziphy-web-shared/basic/lib/simple'

import mainConfig from '@config/main'

const TabOverviewNoResults = () => {
  const { t } = useTranslation()

  return (
    <PageNoResults className="text-left font-weight-normal">
      <div className="title-sm">{t('ps.overview.no_results.title')}</div>
      <Trans
        i18nKey="ps.overview.no_results.text"
        values={{ email: mainConfig.supportMail }}
        components={[
          <a href={'mailto:' + mainConfig.supportMail} target="_blank" rel="noreferrer" />,
        ]}
      />
    </PageNoResults>
  )
}

export default TabOverviewNoResults
