var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirstRender, useIntervalTimer } from 'ziphy-web-shared/basic/hooks';
import { useForm } from 'ziphy-web-shared/basic/lib/forms';
import { showAlert } from 'ziphy-web-shared/basic/lib/utilities';
import { $loader } from 'ziphy-web-shared/basic/utils';
import { CALL_ME_LOADER, SEND_CODE_LOADER, VERIFY_CODE } from './CodeResender.constants';
export function useCodeResender({ onSubmit, clearDeps = [] }) {
    const { t } = useTranslation();
    const [codeSent, setCodeSent] = useState(null);
    const [messageType, setMessageType] = useState('text');
    const { form, fields } = useForm({
        code: {
            value: '',
            rules: [(v) => v.search(new RegExp(VERIFY_CODE.pattern)) !== -1],
        },
    });
    const { code } = fields;
    useEffect(() => {
        if (!codeSent)
            return;
        handleClear();
    }, clearDeps);
    function handleClear() {
        code.onChange('');
        setCodeSent(null);
        setMessageType('text');
    }
    const handleSendCode = useCallback($loader.registerHandler(SEND_CODE_LOADER, (value) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        code.onChange('');
        setMessageType(value);
        const response = yield onSubmit(value);
        if ((_a = response.prepared) === null || _a === void 0 ? void 0 : _a.codeSent) {
            setCodeSent(response.prepared.codeSent);
        }
        else if (response.error) {
            showAlert.error(response.error.message, { isApiMessage: true });
        }
        return response;
    })), [onSubmit]);
    const handleCallMe = useCallback($loader.registerHandler(CALL_ME_LOADER, () => __awaiter(this, void 0, void 0, function* () {
        const response = yield onSubmit('voice');
        if (response.prepared) {
            showAlert.success(t('alert.calling_you_now'), { isApiMessage: true });
        }
        return response;
    })), [onSubmit]);
    return {
        form,
        code,
        codeSent,
        isWaiting: Boolean(codeSent),
        messageType,
        clear: handleClear,
        sendCode: handleSendCode,
        callMe: handleCallMe,
    };
}
export function useCodeResenderSeconds(initTime) {
    const isFirstRender = useFirstRender();
    const [seconds, setSeconds] = useState(calcSeconds());
    const { start, stop } = useIntervalTimer({
        callback: () => setSeconds(calcSeconds()),
        autoStart: false,
    });
    useEffect(() => {
        if (!isFirstRender) {
            setSeconds(calcSeconds());
        }
    }, [initTime]);
    useEffect(() => {
        if (seconds > 0) {
            start();
        }
        else {
            stop();
        }
    }, [seconds, start, stop]);
    function calcSeconds() {
        const deltaMs = Date.parse(initTime) - Date.now();
        const remainingTimeMs = Math.max(0, deltaMs + VERIFY_CODE.resendInterval);
        return Math.floor(remainingTimeMs / 1000);
    }
    return { seconds, inProgress: seconds > 0 };
}
