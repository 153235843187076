import React from 'react'

import { observer } from 'mobx-react-lite'

import Icon from 'ziphy-web-shared/basic/assets/images/Icon'
import { APP_TYPE } from 'ziphy-web-shared/basic/helpers'
import { $windowSize } from 'ziphy-web-shared/basic/utils'

import ApptControl from '@pages/appt/apptControl/ApptControl'
import Map from '@pages/appt/apptMap/Map'

import { $appts } from '@store'

const ApptMap = ({ byRoute = undefined }) => {
  const { fullAppt, status } = byRoute || $appts.byRoute
  const { place } = fullAppt

  const isSuccess = APP_TYPE === 'provider' ? status.isDone : status.isSuccess
  const isCompleted = !status.payAttention?.length && isSuccess

  return (
    <div className="appt_map">
      {place && (
        <>
          <div className="appt_map-map">
            <Map coords={place.coords} showMarker={!isCompleted} />
          </div>
          {isCompleted && (
            <div className="appt_map-completed_marker">
              <Icon type="check" size={$windowSize.isMobile ? 56 : 80} />
            </div>
          )}
          <ApptControl byRoute={byRoute} />
        </>
      )}
    </div>
  )
}

export default observer(ApptMap)
