import React from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import i18n from 'i18next'
import _ from 'lodash'
import { $loader } from 'ziphy-web-shared/basic/utils'

import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

const CustomAlertModal = ({
  title,
  message,
  done = 'btn.confirm',
  cancel = 'btn.cancel',
  data = {},
  callback,
  cancelCallback,
  afterCallback,
  modalOptions,
  size = 'md',
  _core,
}) => {
  const { t } = useTranslation()

  title = title || data.title
  message = message || data.message
  done = done || data.done
  cancel = cancel || data.cancel

  function _getContent(content) {
    if (_.isFunction(content)) {
      return content()
    } else {
      if (i18n.exists(content)) {
        return t(content)
      } else {
        return content
      }
    }
  }

  const onDone = $loader.registerHandler('custom-alert-done', async (e) => {
    _core.onBlock()

    if (_.isFunction(callback)) {
      await callback()
    }
    if (_.isFunction(afterCallback)) {
      await afterCallback()
    }

    _core.onUnblock()
    _core.onHide()
  })

  async function onCancel() {
    if (_.isFunction(cancelCallback)) {
      await cancelCallback()
    }
    if (_.isFunction(afterCallback)) {
      await afterCallback()
    }
    _core.onHide()
  }

  async function afterCancel() {
    _core.onHide()
  }

  async function callCancel() {
    await onCancel()
    await afterCancel()
  }

  return (
    <Modal size={size} {...modalOptions} onHide={onCancel}>
      <div className="modal-toolbar">
        <ModalToolbarBtn type="close" action={callCancel} />
      </div>
      {title && <Modal.Header>{_getContent(title)}</Modal.Header>}
      <Modal.Body>{message && _getContent(message)}</Modal.Body>
      <Modal.Footer>
        <div className="btn-group btn-group--full-mobile">
          {done && (
            <ButtonLegacy
              tag="button"
              mode="primary"
              action={onDone}
              isLoading={$loader.isRunHandler('custom-alert-done')}
            >
              {_getContent(done)}
            </ButtonLegacy>
          )}
          {cancel && (
            <ButtonLegacy tag="button" mode="secondary" action={callCancel}>
              {_getContent(cancel)}
            </ButtonLegacy>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default observer(CustomAlertModal)
