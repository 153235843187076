import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { firstLetters, getStylesMode, splitName } from 'ziphy-web-shared/basic/helpers';
import logoSimpleWhite from '@assets/images/logo-simple-white.svg';
import styles from './PracticeIcon.module.scss';
const PracticeIcon = ({ mode = 'primary', imageUrl, name, isActive = false, isHoverable = false, wrapperClassName = '', }) => {
    return (_jsx("div", Object.assign({ className: classNames(styles.icon, mode && getStylesMode(mode, styles), isActive && styles.isActive, isHoverable && styles.isHoverable, wrapperClassName) }, { children: imageUrl ? (_jsx("div", Object.assign({ className: classNames(styles.inner) }, { children: _jsx("img", { src: imageUrl, alt: "" }) }))) : name ? (_jsx("div", Object.assign({ className: classNames(styles.inner, styles.firstLetters) }, { children: firstLetters(splitName(name, 'consistently')) }))) : (_jsx("div", Object.assign({ className: classNames(styles.inner, styles.empty) }, { children: _jsx("img", { src: logoSimpleWhite, alt: "" }) }))) })));
};
export default PracticeIcon;
