export const apptDocumentsFormList = [
    {
        id: 'healthRiskAssessment',
        label: 'appt_documents.fill_out_forms.label.health_risk_assessment',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347800563049',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347800563049',
            },
        },
    },
    {
        id: 'patientHealthQuestionnaire9',
        label: 'appt_documents.fill_out_forms.label.patient_health_questionnaire_9',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347627318054',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347627318054',
            },
        },
    },
    {
        id: 'generalizedAnxietyDisorder7',
        label: 'appt_documents.fill_out_forms.label.generalized_anxiety_disorder_7',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347668204053',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347668204053',
            },
        },
    },
    {
        id: 'functionalRiskAssessment',
        label: 'appt_documents.fill_out_forms.label.functional_risk_assessment',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678702997168',
            },
            27: {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678889564174',
            },
            26: {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678947560165',
            },
            59: {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678563637063',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678702997168',
            },
            '5_32': {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678889564174',
            },
            8: {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678947560165',
            },
            7: {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678563637063',
            },
        },
    },
    {
        id: 'painAssessment',
        label: 'appt_documents.fill_out_forms.label.pain_assessment',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348022147044',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348022147044',
            },
        },
    },
    {
        id: 'painAssessmentNonVerbal',
        label: 'appt_documents.fill_out_forms.label.pain_assessment_non_verbal',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347724915055',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347724915055',
            },
        },
    },
    {
        id: 'fallRiskAssessment',
        label: 'appt_documents.fill_out_forms.label.fall_risk_assessment',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348504867058',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348504867058',
            },
        },
    },
    {
        id: 'urinaryIncontinenceAssessment',
        label: 'appt_documents.fill_out_forms.label.urinary_incontinence_assessment',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347663214049',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347663214049',
            },
        },
    },
    {
        id: 'socialNeedsScreening',
        label: 'appt_documents.fill_out_forms.label.social_needs_screening',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348467284059',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348467284059',
            },
        },
    },
    {
        id: 'auditAssessment',
        label: 'appt_documents.fill_out_forms.label.audit_assessment',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348076608055',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348076608055',
            },
        },
    },
    {
        id: 'drugAssessmentScreeningTest10',
        label: 'appt_documents.fill_out_forms.label.drug_assessment_screening_test_10',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348586431055',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348586431055',
            },
        },
    },
    {
        id: 'healthScreeningFormPneumococcal',
        label: 'appt_documents.fill_out_forms.label.health_screening_form_pneumococcal',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://hipaa.jotform.com/223467897698078',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://hipaa.jotform.com/223467897698078',
            },
        },
    },
    {
        id: 'healthScreeningFormInfluenza',
        label: 'appt_documents.fill_out_forms.label.health_screening_form_influenza',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://hipaa.jotform.com/223466229758063',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://hipaa.jotform.com/223466229758063',
            },
        },
    },
    {
        id: 'miniMentalStatusExamForm',
        label: 'appt_documents.fill_out_forms.label.mini_mental_status_exam_form',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348432183049',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348432183049',
            },
        },
    },
    {
        id: 'medicationReconciliationForm',
        label: 'appt_documents.fill_out_forms.label.medication_reconciliation_form',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348025771050',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348025771050',
            },
        },
    },
    {
        id: 'cognitionAndMemoryAssessmentCognitiveImpairmentScreening',
        label: 'appt_documents.fill_out_forms.label.cognition_and_memory_assessment.cognitive_impairment_screening',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347823211044',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347823211044',
            },
        },
    },
    {
        id: 'medicalInformationReleaseForm',
        label: 'appt_documents.fill_out_forms.label.medical_information_release_form',
        local: {
            any: {
                type: 'openBlank',
                link: 'https://hipaa.jotform.com/223558099718066',
            },
        },
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://hipaa.jotform.com/223558099718066',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://hipaa.jotform.com/223558099718066',
            },
        },
    },
    {
        id: 'reviewOfSystem',
        label: 'appt_documents.fill_out_forms.label.review_of_system',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231430114456142',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231430114456142',
            },
        },
    },
    {
        id: 'hearingTest',
        label: 'appt_documents.fill_out_forms.label.hearing_test',
        staging: {
            '1_12_38_63': {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678117358159',
            },
            27: {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678774307162',
            },
            26: {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678103778160',
            },
            59: {
                type: 'openBlank',
                link: 'https://form.jotform.com/230677751012048',
            },
        },
        production: {
            '1_11_12_27_29_33_34_35_36_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678117358159',
            },
            '5_32_40_41_42': {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678774307162',
            },
            8: {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678103778160',
            },
            7: {
                type: 'openBlank',
                link: 'https://form.jotform.com/230677751012048',
            },
        },
    },
    {
        id: 'woundCareForm',
        label: 'appt_documents.fill_out_forms.label.wound_care_form',
        staging: {
            '1_26_27_38_63_72_75': {
                type: 'openBlank',
                link: 'https://form.jotform.com/242205768986066',
            },
        },
        production: {
            '1_5_8_11_12_27_29_33_34_35_36_37_38_39_40_41_42_46_47_48_49': {
                type: 'openBlank',
                link: 'https://form.jotform.com/242205768986066',
            },
        },
    },
    {
        id: 'comprehensiveAssessmentCareAtHome',
        label: 'appt_documents.fill_out_forms.label.comprehensive_assessment',
        local: {
            byPracticeConfig: {
                type: 'getSurvey',
                documentTemplateId: 12,
                link: '/forms/#/comprehensive-assessment/',
                devPdfLink: '/forms-pdf/#/comprehensive-assessment/care_at_home/',
            },
        },
        staging: {
            byPracticeConfig: {
                type: 'getSurvey',
                documentTemplateId: 12,
                link: '/forms/#/comprehensive-assessment/',
            },
        },
        production: {
            byPracticeConfig: {
                type: 'getSurvey',
                documentTemplateId: 12,
                link: '/forms/#/comprehensive-assessment/',
            },
        },
    },
    {
        id: 'gaps',
        label: 'appt_documents.fill_out_forms.label.gaps',
        local: {
            byPracticeConfig: {
                type: 'getSurvey',
                documentTemplateId: 13,
                link: '/forms/#/gaps/',
                devPdfLink: '/forms-pdf/#/auto/gaps/',
            },
        },
        staging: {
            byPracticeConfig: {
                type: 'getSurvey',
                documentTemplateId: 13,
                link: '/forms/#/gaps/',
            },
        },
        production: {
            byPracticeConfig: {
                type: 'getSurvey',
                documentTemplateId: 13,
                link: '/forms/#/gaps/',
            },
        },
    },
    {
        id: 'comprehensiveAssessment',
        label: 'appt_documents.fill_out_forms.label.comprehensive_assessment',
        local: {
            byPracticeConfig: {
                type: 'getSurvey',
                documentTemplateId: 14,
                link: '/forms/#/comprehensive-assessment/',
                devPdfLink: '/forms-pdf/#/comprehensive-assessment/',
            },
        },
        staging: {
            byPracticeConfig: {
                type: 'getSurvey',
                documentTemplateId: 14,
                link: '/forms/#/comprehensive-assessment/',
            },
        },
        production: {
            byPracticeConfig: {
                type: 'getSurvey',
                documentTemplateId: 14,
                link: '/forms/#/comprehensive-assessment/',
            },
        },
    },
];
