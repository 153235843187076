var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { psPatientsService } from 'ziphy-web-shared/basic/api';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import { SkeletonSimpleLine, WithLoaderWrapper } from 'ziphy-web-shared/basic/lib/loaders';
import { PatientInfoBox } from 'ziphy-web-shared/basic/lib/simple';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
import PracticeIcon from '@library/practice/practiceIcon/PracticeIcon';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import { routeActions } from '@config/routeActions';
const PsPatientPractices = ({ patientId }) => {
    const { t } = useTranslation();
    const [practicesList, setPracticesList] = useState([]);
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield psPatientsService.viewPractices({ patientId });
        if (response.preparedValue) {
            const result = _.orderBy(response.preparedValue, (x) => x.lastUpdated, 'desc');
            setPracticesList(result);
        }
    }), []);
    return (_jsx(WithLoaderWrapper, Object.assign({ isLoading: isLoading, visibleCondition: practicesList.length > 0, loader: _jsx(SkeletonSimpleLine, { count: 2, photo: true }) }, { children: practicesList.map((item) => (_jsx(PatientInfoBox, { autoTarget: routeActions.PS_PATIENT({ patientId: item.patientZid, practiceId: item.zid }), value: item.name, className: "mb-10", mode: 'clearRightElement', leftElement: _jsx(PracticeIcon, { imageUrl: item.imageUrl, name: item.name, mode: 'secondary' }), rightElement: _jsx(ButtonLegacy, { mode: ['white', 'sm'], className: 'text-nowrap', label: $windowSize.maxMD ? t('btn.view') : t('btn.view_patient_history') }) }, item.zid))) })));
};
export default observer(PsPatientPractices);
