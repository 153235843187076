import React, { useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useResizeDetector } from 'react-resize-detector'

import classNames from 'classnames'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import styles from './TabsBarDesktop.module.scss'

const TabsBarDesktop = ({
  mode,
  modeSelected,
  items,
  selected,
  selectAll,
  onChange,
  autoTarget,
  moreButtonText = 'btn.more',
  moreModalParams,
}) => {
  const { t } = useTranslation()
  const { ref, width = 0 } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 50,
  })

  function getButtonWith(className) {
    const el = ref.current?.querySelector('.' + className)
    return el ? el.getBoundingClientRect().width : 0
  }

  const [visibleItems, setVisibleItems] = useState([])

  useEffect(() => {
    if (!ref.current) return
    let result = []

    const containerWidth = ref.current.getBoundingClientRect().width
    const btnAllWidth = getButtonWith('select-all')
    const btnMoreWidth = getButtonWith('select-more')

    let availableWidth = containerWidth - btnAllWidth - btnMoreWidth

    items.forEach((x) => {
      const btnWidth = getButtonWith(`select-id-${x.id}`) + 10 // 10px margin between buttons

      if (availableWidth - btnWidth > 0) {
        availableWidth -= btnWidth
        result.push(x.id)
      }
    })

    setVisibleItems(result)
  }, [width, selected, items])

  const defaultMoreText = t(moreButtonText)
  const moreBtnText = useMemo(() => {
    if (selected && items.length && !visibleItems.includes(selected)) {
      return items.find((x) => x.id === selected).text
    } else {
      return defaultMoreText
    }
  }, [selected, items, visibleItems, defaultMoreText])

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={classNames(styles.selectAll, 'select-all')}>
        {selectAll && (
          <ButtonLegacy
            mode={selected ? mode : modeSelected}
            className={classNames(styles.btn, styles.btnVisible)}
            action={() => onChange(null)}
          >
            {selectAll}
          </ButtonLegacy>
        )}
      </div>
      {items.map((x, index) => (
        <ButtonLegacy
          key={index}
          mode={selected === x.id ? modeSelected : mode}
          className={classNames(
            styles.btn,
            visibleItems.includes(x.id) && styles.btnVisible,
            `select-id-${x.id}`,
          )}
          action={() => onChange && onChange(x.id)}
          autoTarget={autoTarget && autoTarget(x.id)}
        >
          {x.text}
        </ButtonLegacy>
      ))}
      <ButtonLegacy
        mode={moreBtnText !== defaultMoreText ? modeSelected : mode}
        className={classNames(
          styles.btn,
          styles.btnMore,
          items.length && items.length > visibleItems.length && styles.btnVisible,
          'select-more',
        )}
        action={moreModalParams}
      >
        {moreBtnText}
      </ButtonLegacy>
    </div>
  )
}

export default observer(TabsBarDesktop)
