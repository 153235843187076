import React, { useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { usersService } from 'ziphy-web-shared/basic/api'
import { $auth } from 'ziphy-web-shared/basic/entities/auth'
import { useForm } from 'ziphy-web-shared/basic/lib/forms'
import { $awsCaptcha, AwsCaptcha, showAlert } from 'ziphy-web-shared/basic/lib/utilities'
import { $loader } from 'ziphy-web-shared/basic/utils'

import CodeResender from '@library/codes/CodeResender'
import CodeResenderButton from '@library/codes/CodeResenderButton'
import CodeResenderCallMe from '@library/codes/CodeResenderCallMe'
import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import mainConfig from '@config/main'

export const DeleteProfileModal = ({ _core }) => {
  const { t } = useTranslation()

  const { type, value } = $auth.account
  const isPhone = type === 'phone'

  const { form } = useForm({
    code: { value: '', rules: ['code', 'required'] },
  })
  const { code } = form.fields

  const [codeResenderData, setCodeResenderData] = useState({})

  async function handlePrefetchCode(using) {
    code.onChange('')

    const params = { type, using, value }
    const response = await $auth.sendCode(params)

    if (response.prepared?.codeSent) {
      return response.prepared?.codeSent
    } else if (response.error) {
      showAlert.error(response.error.message)
    }
  }

  const submitHandle = $loader.registerHandler('delete-profile-submit', async (e) => {
    e.preventDefault()

    if (form.validateAll().isValid) {
      _core.onBlock()

      const response = await usersService.delete({
        accountId: $auth.account.id,
        code: form.values.code,
      })

      if (response.error) {
        form.forceError('code')
      }

      _core.onUnblock()

      if (!response.error) {
        _core.forceHide()
        $auth.logout(false)
      }
    } else {
      showAlert.error(t('delete_profile.alert.provide_code'))
    }
  })

  return (
    <Modal centered scrollable>
      <div className="modal-toolbar">
        <ModalToolbarBtn type="close" action={_core.onHide} />
      </div>

      <Modal.Header className="text-lg-center">{t('delete_profile.title')}</Modal.Header>

      <Modal.Body className="styled_scroll">
        <form id="delete_profile_form" onSubmit={submitHandle} noValidate>
          <p>
            {t('delete_profile.description')}&nbsp;{value}
          </p>
          <AwsCaptcha
            show={$awsCaptcha.enabled}
            apiKey={mainConfig.awsCaptcha.key}
            domainList={mainConfig.awsCaptcha.domains}
            className="mb-10"
            onSuccess={handlePrefetchCode}
          />
          <div className={classNames($awsCaptcha.enabled && 'd-none')}>
            <CodeResender
              prefetch={handlePrefetchCode}
              code={code}
              onChange={code.onChange}
              onSetData={setCodeResenderData}
              label={t(`code_resender.ph.${type}`)}
              textButton={false}
              autoFocus={true}
              className="mb-10"
            />
          </div>
          {!$awsCaptcha.enabled && (
            <ButtonLegacy
              tag="button"
              mode={['danger', 'block']}
              className="mb-10"
              form="delete_profile_form"
              isLoading={$loader.isRunHandler('delete-profile-submit')}
            >
              {t('delete_profile.btn.confirm')}
            </ButtonLegacy>
          )}
          <CodeResenderButton type="button" className="btn-block" {...codeResenderData} />
          {isPhone && (
            <div className="text-center text-muted font-weight-600 mt-20">
              <CodeResenderCallMe {...codeResenderData} />
            </div>
          )}
        </form>
      </Modal.Body>
    </Modal>
  )
}

DeleteProfileModal.displayName = 'DeleteProfileModal'

export default observer(DeleteProfileModal)
