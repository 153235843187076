import React, { useMemo, useRef } from 'react'

// map loader
import { GoogleMap, Marker } from '@react-google-maps/api'
import { observer } from 'mobx-react-lite'
import { getUA } from 'react-device-detect'
import { useResizeDetector } from 'react-resize-detector'

import _ from 'lodash'
import { $windowSize } from 'ziphy-web-shared/basic/utils'

import PointMarker from '@assets/images/map/point-marker.svg'

import SelectAddressMapConfigs from './SelectAddressMap.configs'

const SelectAddressMap = ({ coords = {}, isCollapsed }) => {
  const ref = useRef()
  const { width = 0, height = 0 } = useResizeDetector({ targetRef: ref })

  const isAwsCanary = _.includes(getUA, 'CloudWatchSynthetics')

  const getShiftedCenter = useMemo(() => {
    let lat = coords.lat || 0
    let lng = coords.lng || 0

    let latShift = 0,
      lngShift = 0

    if (!$windowSize.isMobileSize) {
      latShift = Math.abs(lat) * height * 0.000000045
      lngShift = -(Math.abs(lng) * width * 0.0000000991)
    }

    return {
      lat: lat + latShift,
      lng: lng + lngShift,
    }
  }, [width, height, coords, $windowSize.isMobileSize]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={ref} className={'g-map g-map--static'}>
      {!isAwsCanary && !_.isEmpty(coords) && (
        // if map is collapsed, we show marker in the center and make map 200% width/height in css
        <GoogleMap
          mapContainerClassName={'g-map-inner'}
          center={isCollapsed ? coords : getShiftedCenter}
          zoom={SelectAddressMapConfigs.options.zoom}
          options={
            !isCollapsed
              ? SelectAddressMapConfigs.options
              : {
                  ...SelectAddressMapConfigs.options,
                  styles: [
                    ...SelectAddressMapConfigs.options.styles,
                    // if map is collapsed hide labels
                    {
                      elementType: 'labels',
                      stylers: [
                        {
                          visibility: 'off',
                        },
                      ],
                    },
                  ],
                }
          }
        >
          <Marker position={coords.lat ? coords : undefined} icon={PointMarker} />)
        </GoogleMap>
      )}
    </div>
  )
}

export default observer(SelectAddressMap)
