import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import MultiSelect from 'ziphy-web-shared/basic/lib/forms/fields/multiSelect/MultiSelect';
import { RadioTabs } from 'ziphy-web-shared/basic/lib/tabs';
import { formatDate } from 'ziphy-web-shared/basic/utils/time';
import InputBase from '@library/form/fields/new/InputBase';
import NewDatePickerModal from '@library/newDatePicker/NewDatePicker.modal';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import { $modal } from '@store';
import styles from './ApptsSearch.module.scss';
const ApptsSearch = ({ filters, className }) => {
    const { t } = useTranslation();
    const { view, practiceId, showFilters, dateFrom, dateTo } = filters;
    const practiceItems = $auth.availablePractices.map((x) => ({ id: x.id, text: x.name }));
    const minDate = moment().add(-1, 'year');
    const maxDate = moment().add(2, 'month');
    return (_jsxs("div", Object.assign({ className: className }, { children: [_jsxs("div", Object.assign({ className: classNames(styles.apptsSearch, 'mb-20') }, { children: [_jsx(RadioTabs, { value: view.value, setValue: view.onChange, items: [
                            { id: 'current', text: t('label.current') },
                            { id: 'history', text: t('label.history') },
                        ], className: styles.tabs, mode: "dark" }), practiceItems.length > 1 && (_jsx("div", Object.assign({ className: styles.practiceSelect }, { children: _jsx(MultiSelect, { items: practiceItems, field: practiceId, mode: ['sm'], allOption: t('label.all_practices'), alwaysBottom: true }) }))), _jsx(ButtonLegacy, Object.assign({ mode: ['filter-outline', 'sm'], className: classNames(showFilters.value && 'btn--active'), action: () => showFilters.onChange(!showFilters.value) }, { children: t('btn.filters') }))] })), showFilters.value && (_jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ className: "col-12 col-lg-5" }, { children: _jsxs("div", Object.assign({ className: "tsform-join-group" }, { children: [_jsx(InputBase, { field: {
                                    value: formatDate(dateFrom.value, { format: 'dateNum' }),
                                    onChange: dateFrom.onChange,
                                    isInvalid: dateFrom.isInvalid,
                                }, onClick: () => {
                                    $modal.add(NewDatePickerModal, {
                                        type: 'selectDateTime',
                                        selectedDate: dateFrom.value,
                                        onChange: dateFrom.onChange,
                                        minDate,
                                        maxDate,
                                    });
                                }, label: t('label.date_from'), placeholder: t('placeholder.empty_date'), 
                                // @ts-ignore
                                mode: ['sm', 'cursor-pointer'], readOnly: true, clear: true }), _jsx(InputBase, { field: {
                                    value: formatDate(dateTo.value, { format: 'dateNum' }),
                                    onChange: dateTo.onChange,
                                    isInvalid: dateTo.isInvalid,
                                }, onClick: () => {
                                    $modal.add(NewDatePickerModal, {
                                        type: 'selectDateTime',
                                        selectedDate: dateTo.value,
                                        onChange: dateTo.onChange,
                                        minDate,
                                        maxDate,
                                    });
                                }, label: t('label.date_to'), placeholder: t('placeholder.empty_date'), 
                                // @ts-ignore
                                mode: ['sm', 'cursor-pointer'], readOnly: true, clear: true })] })) })) })))] })));
};
export default observer(ApptsSearch);
