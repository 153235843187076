import React, { useEffect, useState } from 'react'

import { Trans, useTranslation } from 'react-i18next'

import moment from 'moment-timezone'
import { useIntervalTimer } from 'ziphy-web-shared/basic/hooks'

import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'

import mainConfig from '@config/main'

const DeprecationModal = ({ _core, notSupported }) => {
  const { t } = useTranslation()
  const [startTime] = useState(moment())
  const [timeLeft, setTimeLeft] = useState(61)

  const { stop } = useIntervalTimer({
    callback: async () => {
      setTimeLeft(Math.max(0, Math.floor(61 - moment().diff(startTime) / 1000)))
    },
    duration: 1000,
    autoStart: notSupported,
  })

  useEffect(() => {
    if (timeLeft <= 0) {
      stop()
      window.location.reload(true)
    }
  }, [timeLeft]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      centered
      scrollable
      size="lg"
      closeOnOverlay={!notSupported}
      className="deprecation-modal"
    >
      {notSupported ? (
        <>
          <Modal.Header>
            {t('deprecation_modal.not_supported.title', { productName: mainConfig.appName })}
          </Modal.Header>
          <Modal.Body className="styled_scroll">
            <p className="deprecation-modal__text">
              {t('deprecation_modal.not_supported.text_one', {
                time: timeLeft,
                counter: 'seconds',
              })}
            </p>
            <p className="deprecation-modal__text mb-0">
              <Trans
                i18nKey="deprecation_modal.not_supported.text_two"
                values={{
                  supportEmail: mainConfig.helpMail,
                }}
                components={[<a href={`mailto:${mainConfig.helpMail}`} />]}
              />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={() => window.location.reload(true)}>
              {t('deprecation_modal.btn.reload')}
            </button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <div className="modal-toolbar">
            <ModalToolbarBtn type="close" action={_core.onHide} />
          </div>
          <Modal.Header>
            {t('deprecation_modal.deprecated.title', { productName: mainConfig.appName })}
          </Modal.Header>
          <Modal.Body className="styled_scroll">
            <p className="deprecation-modal__text mb-0">
              {t('deprecation_modal.deprecated.text_one')}
            </p>
          </Modal.Body>
          <Modal.Footer className="btn-group">
            <button className="btn btn-primary" onClick={() => window.location.reload(true)}>
              {t('deprecation_modal.btn.reload')}
            </button>
            <button className="btn btn-secondary" onClick={_core.onHide}>
              {t('deprecation_modal.btn.cancel')}
            </button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

DeprecationModal.displayName = 'DeprecationModal'

export default DeprecationModal
