import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import _ from 'lodash'
import { officesService } from 'ziphy-web-shared/basic/api'
import { showAlert } from 'ziphy-web-shared/basic/lib/utilities'
import { $loader, $windowSize } from 'ziphy-web-shared/basic/utils'

import maps from '@library/map/Map'
import Modal from '@library/modal/Modal'
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn'
import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator'

import { getConfigByCountry } from '@helpers/countries'

import { $places } from '@store'

import styles from './AddPlace.modal.module.scss'
import Details from './addPlaceModalDetails/AddPlaceModalDetails'
import Map from './addPlaceModalMap/AddPlaceModalMap'
import SearchPlace from './addPlaceModalSearchPlace/AddPlaceModalSearchPlace'

const AddPlaceModal = ({ editId = false, _core, setPlaceId }) => {
  const { t } = useTranslation()

  const [country, setCountry] = useState([])
  const updatePlaceAfterCountry = useRef(true)

  const getCurrentPlace = maps.useCurrentPlace()

  const [place, setPlace] = useState(() => (editId ? $places.getById(editId) : {}))
  const config = getConfigByCountry(country[0])
  const [coords, setCoords] = useState(editId ? null : config.defaultPlace.coords)
  const [lastPlace, setLastPlace] = useState(place)
  const [isDetails, setIsDetails] = useState(Boolean(editId))
  const [isBuildingReadOnly, setIsBuildingReadOnly] = useState(!!(place && place.building))
  const [isStreetReadOnly, setIsStreetReadOnly] = useState(!!(place && place.street))
  const [isCityReadOnly, setIsCityReadOnly] = useState(!!(place && place.city))
  const [isStateReadOnly, setIsStateReadOnly] = useState(!!(place && place.state))
  const [isZipReadOnly, setIsZipReadOnly] = useState(!!(place && place.zip))

  const isEdit = useMemo(() => editId !== false, [editId])

  useEffect(() => isDetails && setLastPlace(place), [isDetails, place])

  useEffect(() => {
    if (editId) {
      return false
    }

    const currentPlace = getCurrentPlace({
      callback: (x) => {
        handleSetPlace(x)
      },
      details: true,
    })

    handleSetPlace(currentPlace)
  }, [editId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      country.length && // !editId &&
      // !currentPlaceIsAvailable.current === false &&
      updatePlaceAfterCountry.current
    ) {
      const config = getConfigByCountry(country[0])

      if (config && !_.isEmpty(config.defaultPlace)) {
        handleSetPlace(config.defaultPlace)
      } else {
        setPlace(maps.getClearedPlace(place))
      }
    }
  }, [country, editId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateCountry = (value, updatePlace = true) => {
    updatePlaceAfterCountry.current = updatePlace
    setCountry(value)
  }

  useEffect(() => {
    if (coords) {
      ;(async () => {
        const address = await maps.fetchPlaceDetails({ coords: coords })
        setPlace({ id: editId, ...address })
        const newCountry = _.toLower(address.country)
        if (newCountry) {
          if (newCountry !== country[0]) {
            handleUpdateCountry([newCountry], false)
          }
        } else {
          handleUpdateCountry([], false)
        }

        setIsBuildingReadOnly(Boolean(address.building))
        setIsStreetReadOnly(Boolean(address.street))
        setIsCityReadOnly(Boolean(address.city))
        setIsStateReadOnly(Boolean(address.state))
        setIsZipReadOnly(Boolean(address.zip))
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords])

  const handleSetPlace = $loader.registerHandler(
    'update-address-place',
    async (newPlace, save = false, withUpdate = true) => {
      if (withUpdate) {
        newPlace = await maps.fetchPlaceDetails(newPlace)
      }
      const newCountry = _.toLower(newPlace.country)

      if (newCountry) {
        if (newCountry !== country[0]) {
          handleUpdateCountry([newCountry], false)
        }
      } else {
        handleUpdateCountry([], false)
      }

      if (save) {
        _core.onBlock()

        const res = await officesService.listServeAddress({
          zip: newPlace.zip,
          coords: newPlace.coords,
          country: newPlace.country,
        })
        const isAvailable = res.prepared?.offices?.items.length > 0

        if (isAvailable) {
          if (editId) {
            await $places.update(editId, newPlace)
            if (typeof setPlaceId === 'function') {
              setPlaceId(editId)
            }
          } else {
            await $places.add(newPlace)
          }
          _core.onUnblock()
          _core.onHide(true)
        } else {
          showAlert.error({
            title: t('addresses.unavailable.title'),
            content: t('addresses.unavailable.message'),
          })
          _core.onUnblock()
        }
      } else {
        setPlace({ id: editId, ...newPlace })
        setCoords(newPlace.coords)
      }
    },
  )

  const handleOnRemove = useCallback(
    (e) => {
      confirmAction(
        'customAlert',
        {
          data: {
            title: t('addresses.remove_alert.title'),
            message: t('addresses.remove_alert.message'),
          },
          callback: async () => {
            await $places.remove(editId)
            _core.onHide()
          },
        },
        e,
      )
    },
    [$places, _core, editId], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const toggleView = useCallback(() => {
    if (isEdit && isDetails !== false) return
    setIsDetails(!isDetails)
  }, [isDetails, isEdit])

  function handleBack() {
    handleSetPlace(lastPlace)
    setIsDetails(!isDetails)
  }

  return (
    <Modal
      className={classNames(styles.addAddress, isDetails && styles.addAddressMapDetails)}
      size="xl"
      centered
    >
      <div className="modal-toolbar">
        {!isDetails && !_.isEmpty(lastPlace) && <ModalToolbarBtn type="back" action={handleBack} />}
        <ModalToolbarBtn type="close" action={() => _core.onHide(false)} />
        {editId && $windowSize.isMobileSize && (
          <ModalToolbarBtn type="trash" action={handleOnRemove} />
        )}
      </div>
      <Modal.Header className="text-lg-center">
        {editId
          ? t('add_address.title_edit')
          : isDetails
            ? t('add_address.title_details')
            : t('add_address.title')}
      </Modal.Header>
      <Modal.Body>
        <SearchPlace
          place={place}
          country={{ value: country, onChange: handleUpdateCountry }}
          isDetails={isDetails}
          toggleView={toggleView}
          onChange={handleSetPlace}
        />
        <div className={styles.addAddressMap}>
          {place && !_.isEmpty(place.coords) && (
            <Map
              coords={place.coords}
              onChange={setCoords}
              isDetails={isDetails}
              onClick={() => !isEdit && setIsDetails(false)}
            />
          )}
        </div>
        {!_.isEmpty(place) && (
          <Details
            place={place}
            editId={editId}
            isDetails={isDetails}
            toggleView={toggleView}
            onChange={handleSetPlace}
            onRemove={handleOnRemove}
            isBuildingReadOnly={isBuildingReadOnly}
            isStreetReadOnly={isStreetReadOnly}
            isCityReadOnly={isCityReadOnly}
            isStateReadOnly={isStateReadOnly}
            isZipReadOnly={isZipReadOnly}
            isEdit={isEdit}
            isLoading={$loader.isRunHandler('update-address-place')}
            _core={_core}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default observer(AddPlaceModal)
