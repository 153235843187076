var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import { WithLoaderWrapper } from 'ziphy-web-shared/basic/lib/loaders';
import { Title } from 'ziphy-web-shared/basic/lib/simple';
import { trendsListConfig, trendsListConfigMap, trendsListConfigValues, } from '@library/trendsList/TrendsList.config';
import { getBMIMeasurement, measurementsResponseAdapter, } from '@library/trendsList/TrendsList.helpers';
import TrendsListChart from '@library/trendsList/trendsListChart/TrendsListChart';
import psService from '@ps/services/psService';
import styles from './TrendsList.module.scss';
const TrendsList = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const codes = _.map(trendsListConfigValues, (item) => item.code);
        const response = yield psService.getMeasurements({ codes: codes });
        if (!((_a = response.prepared) === null || _a === void 0 ? void 0 : _a.measurements)) {
            return;
        }
        const measurements = measurementsResponseAdapter((_b = response.prepared) === null || _b === void 0 ? void 0 : _b.measurements);
        // Sort by config sequence
        measurements.sort((a, b) => _.findIndex(trendsListConfigValues, (item) => item.code === a.code) -
            _.findIndex(trendsListConfigValues, (item) => item.code === b.code));
        // Adding BMI
        const height = measurements.findIndex((item) => item.code === trendsListConfig.bodyHeight.code);
        const weight = measurements.findIndex((item) => item.code === trendsListConfig.bodyWeight.code);
        if (height > -1 && weight > -1) {
            const bmi = getBMIMeasurement(measurements[height], measurements[weight]);
            measurements.splice(height, 0, bmi);
        }
        setData(measurements);
    }));
    return (_jsxs(WithLoaderWrapper, Object.assign({ isLoading: isLoading, visibleCondition: data.length > 0, noResults: null }, { children: [_jsx(Title, Object.assign({ size: "h3", className: "mb-20" }, { children: t('label.measures') })), _jsx("div", Object.assign({ className: styles.container }, { children: data.map((item) => (_jsx(TrendsListChart, { value: item, config: trendsListConfigMap.get(item.code), className: styles.chart }, item.code))) }))] })));
};
export default memo(TrendsList);
