import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from 'ziphy-web-shared/basic/lib/buttons';
import { useCodeResenderSeconds } from 'ziphy-web-shared/basic/lib/utilities';
import { $loader } from 'ziphy-web-shared/basic/utils';
import { SEND_CODE_LOADER } from '../CodeResender.constants';
const ResendButton = ({ initTime, onResend, className }) => {
    const { t } = useTranslation();
    const { seconds, inProgress } = useCodeResenderSeconds(initTime);
    return (_jsx("div", Object.assign({ className: classNames(className) }, { children: _jsx(Button, Object.assign({ mode: ['secondary', 'block'], onClick: onResend, isLoading: $loader.isRunHandler(SEND_CODE_LOADER), disabled: inProgress || $loader.isRunHandler(SEND_CODE_LOADER) }, { children: inProgress ? t('btn.resend_code_seconds', { seconds: seconds }) : t('btn.resend_code') })) })));
};
export default observer(ResendButton);
