import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { $auth } from 'ziphy-web-shared/basic/entities/auth';
import { $windowSize } from 'ziphy-web-shared/basic/utils';
import { $router } from 'ziphy-web-shared/basic/utils/router';
import QuickLinks from '@nedoShared/entityViews/other/quickLinks/QuickLinks';
import Toolbar from '@nedoShared/features/layouts/toolbar/Toolbar';
import LoginModal from '@nedoShared/modules/auth/Login.modal';
import BookingHome from '@booking/pages/bookingHome/BookingHome';
import { $modal } from '@store';
import mainConfig from '@config/main';
import { routeActions } from '@config/routeActions';
import styles from './HomePage.module.scss';
const HomePage = ({ registration = false }) => {
    const { invitationCode } = useParams();
    useEffect(() => {
        if (!registration)
            return;
        if ($auth.isLogged) {
            $router.executeAutoTarget(routeActions.HOME());
        }
        else {
            $modal.add(LoginModal, {
                view: Boolean(invitationCode) ? 'with_code' : 'without_code',
                invitationCode,
                onLeave: () => $router.executeAutoTarget(routeActions.HOME()),
            });
        }
    }, [registration, invitationCode]);
    return (_jsxs("div", Object.assign({ className: "content" }, { children: [_jsx(Toolbar, { hasBurger: $windowSize.isMobileSize, className: styles.toolbar }), _jsx("div", Object.assign({ className: classNames('container', $windowSize.isMobileSize && 'p-0') }, { children: _jsx(BookingHome, { loaderMode: 'default' }) })), mainConfig.custom.homeQuickLinks && (_jsx(QuickLinks, { className: classNames('container', 'mt-30') }))] })));
};
export default observer(HomePage);
