const byPracticeConfig = {
    mdToolbox: {
        local: {
            any: {
                link: 'https://live.mdtoolbox.net/rx/ui/login.aspx',
            },
        },
        staging: {
            any: {
                link: 'https://live.mdtoolbox.net/rx/ui/login.aspx',
            },
        },
        production: {
            any: {
                link: 'https://live.mdtoolbox.net/rx/ui/login.aspx',
            },
        },
    },
    parachuteHealth: {
        local: {
            any: {
                link: 'https://dme.parachutehealth.com/users/log_in?redirect_to=https%3A%2F%2Fdme.parachutehealth.com%2Fu%2Ff%2F5GK-59S2U-4RP-HL%2Forders%2FQ97-7YSB-VQ-7I6T%2Fdelivery',
            },
        },
        staging: {
            '30_59_1_27_26_38_63_64_72_75': {
                link: 'https://dme.parachutehealth.com/users/log_in?redirect_to=https%3A%2F%2Fdme.parachutehealth.com%2Fu%2Ff%2F5GK-59S2U-4RP-HL%2Forders%2FQ97-7YSB-VQ-7I6T%2Fdelivery',
            },
        },
        production: {
            '14_25_1_5_8_11_12_13_27_29_32': {
                link: 'https://dme.parachutehealth.com/users/log_in?redirect_to=https%3A%2F%2Fdme.parachutehealth.com%2Fu%2Ff%2F5GK-59S2U-4RP-HL%2Forders%2FQ97-7YSB-VQ-7I6T%2Fdelivery',
            },
        },
    },
};
export default byPracticeConfig;
