var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Icon from 'ziphy-web-shared/basic/assets/images/Icon';
import { $paymentMethods, paymentMethodIsExpired, } from 'ziphy-web-shared/basic/entities/paymentMethods';
import { useLoader } from 'ziphy-web-shared/basic/hooks';
import { BubbleLoader } from 'ziphy-web-shared/basic/lib/loaders';
import PaymentMethodsIcon from '@nedoShared/features/paymentMethods/paymentMethodsController/paymentMethodsIcon/PaymentMethodsIcon';
import { $modal } from '@store';
import SelectPaymentMethodModal from '../selectPaymentMethodModal/SelectPaymentMethod.modal';
import styles from './PaymentMethodSelector.module.scss';
const PaymentMethodSelector = ({ selectedId, onChangeSelectedId }) => {
    const { t } = useTranslation();
    const { isLoading } = useLoader(() => __awaiter(void 0, void 0, void 0, function* () {
        const lastPaymentMethod = yield $paymentMethods.getLast();
        if (lastPaymentMethod && !paymentMethodIsExpired(lastPaymentMethod)) {
            onChangeSelectedId(lastPaymentMethod.id);
        }
    }));
    useEffect(() => {
        if (!isLoading && _.isEmpty($paymentMethods.getById(selectedId))) {
            onChangeSelectedId(null);
        }
    }, [isLoading, $paymentMethods.sortedItems.length]);
    const paymentMethod = $paymentMethods.getById(selectedId) || null;
    const openModal = () => {
        $modal.add(SelectPaymentMethodModal, {
            selectedId: selectedId,
            onSelectId: onChangeSelectedId,
        });
    };
    return (_jsxs("div", Object.assign({ className: styles.container, onClick: openModal }, { children: [_jsx("div", Object.assign({ className: styles.label }, { children: t('label.payment_method') })), _jsx("div", Object.assign({ className: styles.cardNumber }, { children: isLoading ? (_jsx("div", Object.assign({ className: styles.bubbleLoader }, { children: _jsx(BubbleLoader, {}) }))) : paymentMethod ? (_jsxs(_Fragment, { children: [_jsx(PaymentMethodsIcon, { brand: paymentMethod.brand, className: styles.icon }), "*", paymentMethod.last4] })) : (t('label.none')) })), _jsx(Icon, { type: 'edit', className: styles.editIcon })] })));
};
export default observer(PaymentMethodSelector);
