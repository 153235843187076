import { $accountsGlobalStorage, Accounts } from 'ziphy-web-shared/basic/entities/accounts';
import { $addressesGlobalStorage, Addresses } from 'ziphy-web-shared/basic/entities/addresses';
import { $auth, Auth } from 'ziphy-web-shared/basic/entities/auth';
import { Calls } from 'ziphy-web-shared/basic/entities/calls';
import { ChatMessages } from 'ziphy-web-shared/basic/entities/chatMessages';
import { CodingsSearch } from 'ziphy-web-shared/basic/entities/codingsSearch';
import { $contactsGlobalStorage, Contacts } from 'ziphy-web-shared/basic/entities/contacts';
import { CoSign } from 'ziphy-web-shared/basic/entities/coSign';
import { Dev } from 'ziphy-web-shared/basic/entities/dev';
import { Documents } from 'ziphy-web-shared/basic/entities/documents';
import { DocumentTemplates } from 'ziphy-web-shared/basic/entities/documentTemplates';
import { Encounters } from 'ziphy-web-shared/basic/entities/encounters';
import { Events } from 'ziphy-web-shared/basic/entities/events';
import { InsuranceProviders } from 'ziphy-web-shared/basic/entities/insuranceProviders';
import { $insurancesGlobalStorage, Insurances } from 'ziphy-web-shared/basic/entities/insurances';
import { Medications } from 'ziphy-web-shared/basic/entities/medical/medications';
import { Notes } from 'ziphy-web-shared/basic/entities/notes';
import { $officesGlobalStorage, Offices } from 'ziphy-web-shared/basic/entities/offices';
import { $patientsGlobalStorage, Patients } from 'ziphy-web-shared/basic/entities/patients';
import { $paymentMethodsGlobalStorage, PaymentMethods, } from 'ziphy-web-shared/basic/entities/paymentMethods';
import { Places } from 'ziphy-web-shared/basic/entities/places';
import { $practicesGlobalStorage, Practices } from 'ziphy-web-shared/basic/entities/practices';
import { PracticeServices } from 'ziphy-web-shared/basic/entities/practiceServices';
import { PsPatients } from 'ziphy-web-shared/basic/entities/psPatients';
import { Roles } from 'ziphy-web-shared/basic/entities/roles';
import { Schedules } from 'ziphy-web-shared/basic/entities/schedules';
import { Services } from 'ziphy-web-shared/basic/entities/services';
import { Sessions } from 'ziphy-web-shared/basic/entities/sessions';
import { Settings } from 'ziphy-web-shared/basic/entities/settings';
import { SsQuestions } from 'ziphy-web-shared/basic/entities/ssQuestions';
import { Symptoms } from 'ziphy-web-shared/basic/entities/symptoms';
import { Users } from 'ziphy-web-shared/basic/entities/users';
import { Vouchers } from 'ziphy-web-shared/basic/entities/vouchers';
import { MyackApi } from './myack/api';
export { myackAxiosInstance } from './myack/api';
export * from './api.types';
export class Api extends MyackApi {
}
export let apiService;
export let accountsService;
export let addressesService;
export let authService;
export let callsService;
export let codingsSearch;
export let cosignService;
export let chatMessagesService;
export let contactsService;
export let documentsService;
export let documentTemplatesService;
export let encountersService;
export let eventsService;
export let insuranceProvidersService;
export let insurancesService;
export let notesService;
export let medicationsService;
export let officesService;
export let patientsService;
export let paymentMethodsService;
export let placesService;
export let practicesService;
export let practiceServicesService;
export let psPatientsService;
export let rolesService;
export let schedulesService;
export let servicesService;
export let settingsService;
export let sessionsService;
export let ssQuestionsService;
export let devService;
export let symptomsService;
export let usersService;
export let vouchersService;
const storageManager = {
    patient: [$patientsGlobalStorage],
    patients: [$patientsGlobalStorage],
    practice: [$practicesGlobalStorage],
    practices: [$practicesGlobalStorage],
    account: [$accountsGlobalStorage],
    accounts: [$accountsGlobalStorage],
    insurance: [$insurancesGlobalStorage],
    insurances: [$insurancesGlobalStorage],
    paymentMethod: [$paymentMethodsGlobalStorage],
    paymentMethods: [$paymentMethodsGlobalStorage],
    contact: [$contactsGlobalStorage],
    contacts: [$contactsGlobalStorage],
    address: [$addressesGlobalStorage],
    addresses: [$addressesGlobalStorage],
    office: [$officesGlobalStorage],
    offices: [$officesGlobalStorage],
};
export const initServices = (props) => {
    const initData = {
        baseUrl: props.urls.baseUrl,
        storageManager: props.storageManager || storageManager,
        getAccessToken: $auth.getAccessToken.bind($auth),
    };
    apiService = new Api(initData);
    accountsService = new Accounts(initData);
    addressesService = new Addresses(initData);
    authService = new Auth(initData);
    callsService = new Calls(initData);
    codingsSearch = new CodingsSearch(initData);
    cosignService = new CoSign(initData);
    chatMessagesService = new ChatMessages(initData);
    contactsService = new Contacts(initData);
    devService = new Dev(initData);
    documentsService = new Documents(initData);
    documentTemplatesService = new DocumentTemplates(initData);
    encountersService = new Encounters(initData);
    eventsService = new Events(initData);
    insuranceProvidersService = new InsuranceProviders(initData);
    insurancesService = new Insurances(initData);
    notesService = new Notes(initData);
    medicationsService = new Medications(initData);
    officesService = new Offices(initData);
    patientsService = new Patients(initData);
    paymentMethodsService = new PaymentMethods(initData);
    placesService = new Places(initData);
    practicesService = new Practices(initData);
    practiceServicesService = new PracticeServices(initData);
    psPatientsService = new PsPatients(initData);
    rolesService = new Roles(initData);
    schedulesService = new Schedules(initData);
    servicesService = new Services(initData);
    settingsService = new Settings(initData);
    sessionsService = new Sessions(initData);
    ssQuestionsService = new SsQuestions(initData);
    symptomsService = new Symptoms(initData);
    usersService = new Users(initData);
    vouchersService = new Vouchers(initData);
};
