import React from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import { $appts } from '@store'

const ApptOfficeNote = ({ byRoute = undefined }) => {
  const { t } = useTranslation()

  const { fullAppt } = byRoute || $appts.byRoute
  const description = _.get(fullAppt, ['appointment', 'description'], null)

  if (!description) {
    return <></>
  }

  return (
    <div className="appt_note">
      <div className="alert alert--warning">
        <div className="alert-title">{t('appt.label.note_title')}</div>
        {description}
      </div>
    </div>
  )
}

export default observer(ApptOfficeNote)
