import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import moment from 'moment-timezone';
import NotificationsButton from '../NotificationsButton';
import styles from './NotificationsListItem.module.scss';
const NotificationsListItem = ({ notice, isOther, isOpen }) => {
    var _a, _b;
    const ifFunction = (value) => (typeof value === 'function' ? value() : value);
    const actions = notice.viewed
        ? []
        : (_a = notice === null || notice === void 0 ? void 0 : notice.actions) === null || _a === void 0 ? void 0 : _a.filter((x) => !['view_appointment'].includes(x.type));
    return (_jsxs("div", Object.assign({ className: classNames(styles.item, notice.viewed && styles.itemViewed, isOther && styles.itemOther) }, { children: [notice.isActual && (_jsx("div", { className: classNames(styles.redMark, isOpen && styles.redMarkVisible) })), _jsx("div", Object.assign({ className: styles.title }, { children: ifFunction(notice.title) })), _jsx("div", Object.assign({ className: classNames(notice.isActual && 'font-weight-700') }, { children: ifFunction(notice.body) })), _jsxs("div", Object.assign({ className: classNames(styles.itemButtons, ((_b = notice.actions) === null || _b === void 0 ? void 0 : _b.length) === 0 && 'mt-0') }, { children: [actions === null || actions === void 0 ? void 0 : actions.map((btn, index) => (_jsx(NotificationsButton, { notice: notice, btn: btn }, index))), _jsx("div", Object.assign({ className: styles.itemDate }, { children: moment(notice.created).fromNow() }))] }))] }), notice.id));
};
export default observer(NotificationsListItem);
