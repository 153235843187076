import React from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { getUserText, isEqualIds } from 'ziphy-web-shared/basic/helpers'
import { $windowSize } from 'ziphy-web-shared/basic/utils'
import { $router } from 'ziphy-web-shared/basic/utils/router'

import TabsBar from '@library/widgets/tabsBar/TabsBar'

import { routeActions } from '@config/routeActions'

const PatientsList = ({ encounterId, appointment, patients }) => {
  const { t } = useTranslation()

  const activePatientId = Object.values(patients).find((x) =>
    isEqualIds(x.activeEncounter, encounterId),
  )?.id

  const preparedList = Object.values(patients).map((x) => ({
    id: x.id,
    text: `${getUserText(x, ['fullName', 'genderShort'])} ${getUserText(x, ['yo'])}`,
  }))

  function getApptLink(patientId) {
    return routeActions.APPT({
      appointmentId: appointment.id,
      encounterId: patients[patientId].activeEncounter,
    })
  }

  return (
    <div className={classNames($windowSize.isMobileSize ? 'mb-20' : 'mb-10')}>
      <TabsBar
        mode={['secondary-dark', 'block']}
        modeSelected={['black']}
        items={preparedList}
        selected={activePatientId}
        autoTarget={getApptLink}
        moreButtonText={'label.all_patients'}
        moreModalParams={{
          title: t('label.patients'),
          items: preparedList,
          searchBar: true,
          searchMap: ['text'],
          allowUnselect: false,
          searchLabel: t('label.search_patient'),
          value: [activePatientId],
          onChange: (valuesList) => {
            const value = valuesList[0]
            $router.executeAutoTarget(getApptLink(value))
          },
        }}
      />
    </div>
  )
}

export default observer(PatientsList)
