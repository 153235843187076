var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { $addressesContexts, ENUMS_ADDRESS_LOCAL_TYPE, } from 'ziphy-web-shared/basic/entities/addresses';
import { getEnumObjectValue } from 'ziphy-web-shared/basic/helpers';
import { PatientInfoBox } from 'ziphy-web-shared/basic/lib/simple';
import { $loader, $windowSize } from 'ziphy-web-shared/basic/utils';
import Badge from '@library/ui/badge/Badge';
import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import { $modal } from '@store';
import AddAddressModal from '../addAddressModal/AddAddress.modal';
import ChooseAddressTypeModal from '../addAddressModal/ChooseAddressType.modal';
const AddressList = ({ patient, isModal, items, className }) => {
    const { t } = useTranslation();
    const $controller = $addressesContexts.get(patient.id);
    function handleDelete(e, item) {
        e.stopPropagation();
        confirmAction('customAlert', {
            data: {
                title: t('confirmator.addresses.delete.title'),
                message: t('confirmator.addresses.delete.message'),
            },
            callback: $loader.registerHandler('rm-address', () => __awaiter(this, void 0, void 0, function* () {
                yield $controller.delete({ id: item.id });
            })),
        });
    }
    return (_jsxs("div", Object.assign({ className: classNames('row row--gutters-5 row--mb-10', className) }, { children: [items.map((x) => {
                let sameAsResidential = false;
                const residentialAddress = _.find(items, (x) => x.localType === 'residential');
                if (x.localType !== 'residential' && residentialAddress) {
                    const fieldsToCompare = ['city', 'postalCode', 'line'];
                    if (_.every(fieldsToCompare, (key) => _.isEqual(x[key], residentialAddress[key]))) {
                        sameAsResidential = true;
                    }
                }
                return (_jsx("div", Object.assign({ className: "col-12" }, { children: _jsx(PatientInfoBox, { onClick: () => {
                            $modal.add(AddAddressModal, {
                                patientId: patient.id,
                                address: x,
                                duplOfResidential: sameAsResidential,
                            });
                        }, value: x.asStringLine, label: x.asStringCityStateZip, secondaryValue: _jsx(Badge, Object.assign({ type: [x.localType !== 'residential' ? 'dark-gray-fill' : 'primary-fill', 'sm'] }, { children: getEnumObjectValue(x.localType, ENUMS_ADDRESS_LOCAL_TYPE).value })), mode: ['hiddenRightElement'], rightElement: {
                            onDelete: !$windowSize.isMobileSize
                                ? (e) => handleDelete(e, x)
                                : null,
                        } }) }), x.id));
            }), $controller.addressesLimit > 0 && (_jsx("div", Object.assign({ className: "col-12" }, { children: _jsx(ButtonLegacy, { mode: "create", className: "btn-block", label: t('add_address.title'), action: () => $modal.add(ChooseAddressTypeModal, {
                        patientId: patient.id,
                        existingAddresses: items,
                    }) }) })))] })));
};
export default observer(AddressList);
