var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useRef } from 'react';
import _ from 'lodash';
import { getInitConfig } from 'ziphy-web-shared';
import { apiService } from 'ziphy-web-shared/basic/api';
import { prepareResponseData } from 'ziphy-web-shared/basic/api/myack/api.helpers';
import { $loader, globalLoaderHandlers, WebSocketPollingListener, } from 'ziphy-web-shared/basic/utils';
export const useEventsListener = ({ isEnabled }) => {
    const listener = useRef(null);
    const buffer = useRef(new Map());
    const triggerDebounced = useRef(_.debounce(() => {
        buffer.current.forEach((messages, appointmentId) => {
            const eventTypes = new Set(messages.map((x) => x.event.event));
            $loader.simpleTrigger(globalLoaderHandlers.EVENT, {
                appointmentId,
                eventTypes,
                isChatMessage: eventTypes.has('chat_message_received'),
                events: messages.map((x) => x.event),
                notifications: messages.map((x) => x.notifications).flat(),
            });
        });
        buffer.current.clear();
    }, 300)).current;
    useEffect(() => {
        if (!listener.current) {
            listener.current = new WebSocketPollingListener({
                wsUrl: `${getInitConfig().apiService.urls.hooksUrl}/events/listen`,
                getToken: apiService.getAccessToken,
                onEvent: (message) => {
                    var _a;
                    message = prepareResponseData(message);
                    const appointmentId = (_a = message.event) === null || _a === void 0 ? void 0 : _a.appointmentId;
                    if (!buffer.current.has(appointmentId))
                        buffer.current.set(appointmentId, []);
                    buffer.current.get(appointmentId).push(message);
                    triggerDebounced();
                },
                onPolling: () => __awaiter(void 0, void 0, void 0, function* () {
                    // todo: generate IMessage eventsService.list() and notificationsService.list(eventsIds)
                }),
            });
        }
        if (isEnabled) {
            listener.current.start();
        }
        else {
            listener.current.stop();
        }
        return () => {
            var _a;
            (_a = listener.current) === null || _a === void 0 ? void 0 : _a.stop();
            triggerDebounced.cancel();
        };
    }, [isEnabled, triggerDebounced]);
};
