import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useParsedParams } from 'ziphy-web-shared/basic/hooks';
import Toolbar from '@nedoShared/features/layouts/toolbar/Toolbar';
import { usePsPatientLink } from '@ps/hooks';
import { $psHistory } from '@store';
import { routeActions } from '@config/routeActions';
const PatientToolbar = ({ hasVitals }) => {
    const { patientId } = useParsedParams();
    const psPatientLink = usePsPatientLink();
    const isOutlet = $psHistory.category || hasVitals;
    return (_jsx(Toolbar, { goBackProps: {
            autoTarget: isOutlet
                ? psPatientLink({ categoryId: null })
                : routeActions.PATIENT({ patientId }),
        }, useBackToUrl: !isOutlet }));
};
export default observer(PatientToolbar);
