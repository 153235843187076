import _ from 'lodash';
export const composeLine = ({ lineOne, lineTwo, }) => {
    let result = [];
    if (lineOne) {
        result.push(lineOne);
        if (lineTwo) {
            result.push(lineTwo);
        }
    }
    else {
        result.push('');
    }
    return result;
};
export const translatePlaceToAddress = (place) => {
    let result = {};
    const line = [];
    let lineOne = '';
    let buildingStreetLine = '';
    if (place.building) {
        buildingStreetLine = place.building;
    }
    if (place.street) {
        buildingStreetLine += _.isEmpty(buildingStreetLine) ? place.street : ` ${place.street}`;
    }
    lineOne = buildingStreetLine;
    let floorAptLine = '';
    if (place.floor) {
        floorAptLine = place.floor;
    }
    if (place.apartment) {
        floorAptLine += _.isEmpty(floorAptLine) ? place.apartment : ` ${place.apartment}`;
    }
    if (floorAptLine) {
        lineOne += `, ${floorAptLine}`;
    }
    line.push(lineOne);
    if (place.directions) {
        line.push(place.directions);
    }
    result = {
        city: place.city,
        country: place.country,
        line,
        postalCode: place.zip,
        state: place.state,
    };
    return result;
};
