var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { $addressesContexts } from 'ziphy-web-shared/basic/entities/addresses';
import { useForm } from 'ziphy-web-shared/basic/lib/forms';
import { Input, Switch } from 'ziphy-web-shared/basic/lib/forms/fields';
import { $loader } from 'ziphy-web-shared/basic/utils';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import AddressAutocomplete from '@nedoShared/features/addresses/addressController/addressAutocomplete/AddressAutocomplete';
import { composeLine } from './AddAddress.helpers';
import styles from './AddAddress.module.scss';
const AddAddressModal = ({ patientId, address, residentialAddress, duplOfResidential, _core, }) => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation();
    const $controller = $addressesContexts.get(patientId);
    const isEdit = Boolean(address === null || address === void 0 ? void 0 : address.id);
    const [formIsDisabled, setFormIsDisabled] = useState(isEdit);
    const { form, useFormOnChange } = useForm({
        sameAsResidential: {
            value: duplOfResidential !== null && duplOfResidential !== void 0 ? duplOfResidential : false,
        },
        lineOne: {
            value: address.asStringLineOne,
            rules: ['required'],
        },
        lineTwo: {
            value: address.asStringLineTwo,
        },
        country: {
            value: (_a = address.country) !== null && _a !== void 0 ? _a : 'us',
            rules: ['required'],
        },
        city: {
            value: (_b = address.city) !== null && _b !== void 0 ? _b : '',
            rules: ['required'],
        },
        state: {
            value: (_c = address.state) !== null && _c !== void 0 ? _c : '',
            rules: ['required'],
        },
        postalCode: {
            value: (_d = address.postalCode) !== null && _d !== void 0 ? _d : '',
            output: 'emptyNull',
            rules: [
                {
                    name: 'required',
                    // @ts-ignore
                    condition: (value, rule, rules, selfForm) => selfForm.fields.country.value !== 'il', // for Israel postal codes are optional
                },
            ],
        },
        type: {
            value: address.type,
        },
        use: {
            value: address.use,
        },
    });
    const { sameAsResidential, lineOne, lineTwo, country, city, state, postalCode } = form.fields;
    useFormOnChange(() => {
        var _a, _b, _c, _d, _e, _f;
        if (!isEdit) {
            if (sameAsResidential.value) {
                city.setValue(residentialAddress.city);
                country.setValue(residentialAddress.country);
                state.setValue(residentialAddress.state);
                postalCode.setValue(residentialAddress.postalCode);
                lineOne.setValue(residentialAddress.asStringLineOne);
                lineTwo.setValue(residentialAddress.asStringLineTwo);
                setFormIsDisabled(true);
            }
            else {
                city.setValue((_a = address.city) !== null && _a !== void 0 ? _a : '');
                country.setValue((_b = address.country) !== null && _b !== void 0 ? _b : 'us');
                state.setValue((_c = address.state) !== null && _c !== void 0 ? _c : '');
                postalCode.setValue((_d = address.postalCode) !== null && _d !== void 0 ? _d : '');
                lineOne.setValue((_e = address.asStringLineOne) !== null && _e !== void 0 ? _e : '');
                lineTwo.setValue((_f = address.asStringLineTwo) !== null && _f !== void 0 ? _f : '');
                setFormIsDisabled(false);
            }
        }
    }, [sameAsResidential.value]);
    const handleSubmit = $loader.registerHandler('add-address', (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        const formIsValid = form.validateAll().isValid;
        if (form.isChanged && formIsValid) {
            let result = form.values;
            const requestParams = _.omit(result, [
                'lineOne',
                'lineTwo',
                'sameAsResidential',
                'postalCode',
            ]);
            requestParams.line = composeLine(result);
            requestParams['postalCode|strict'] = result.postalCode;
            requestParams.country = requestParams.country.toUpperCase();
            const response = yield $controller.create(requestParams);
            if (!response.error) {
                _core.onHide();
            }
        }
    }));
    function handleDelete(e, item) {
        e.stopPropagation();
        confirmAction('customAlert', {
            data: {
                title: t('confirmator.addresses.delete.title'),
                message: t('confirmator.addresses.delete.message'),
            },
            callback: $loader.registerHandler('rm-address', () => __awaiter(this, void 0, void 0, function* () {
                const response = yield $controller.delete({ id: item.id });
                if (!response.error) {
                    _core.onHide();
                }
            })),
        });
    }
    return (_jsxs(Modal, Object.assign({ className: classNames(styles.addAddress, $loader.isRunHandler('select-suggestion') && styles.blocked), size: "xl", centered: true }, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: () => _core.onHide(false) }) })), _jsx(Modal.Header, Object.assign({ className: "text-lg-center" }, { children: t(isEdit
                    ? `add_address.modal.title.edit_address.${address.localType}`
                    : `add_address.modal.title.add_address.${address.localType}`) })), _jsx(Modal.Body, { children: _jsx("form", { children: _jsxs("div", Object.assign({ className: "row row--gutters-5" }, { children: [(residentialAddress || duplOfResidential) && (_jsx("div", Object.assign({ className: "col-12 mb-10" }, { children: _jsx(Switch, { field: sameAsResidential, label: t('add_address.modal.label.same_as_residential'), asBox: true, isDisabled: isEdit }) }))), _jsx("div", Object.assign({ className: "col-12 mb-10" }, { children: _jsx(AddressAutocomplete, { isDisabled: formIsDisabled, initLineValue: lineOne.value, onChangeLine: lineOne.onChange, isInvalid: lineOne.isInvalid, label: t('address.label.address_line_one'), placeholder: t('add_address.placeholder.line_one'), country: (_e = country.value) === null || _e === void 0 ? void 0 : _e.toLowerCase(), onChangeCountry: (newValue) => country.onChange(_.isArray(newValue) ? newValue[0] : newValue), onSelectSuggestion: (suggestion) => {
                                        var _a;
                                        city.setValue(suggestion.city);
                                        country.setValue((_a = suggestion.country) === null || _a === void 0 ? void 0 : _a.toLowerCase());
                                        state.setValue(suggestion.state);
                                        postalCode.setValue(suggestion.postalCode);
                                        lineOne.setValue(suggestion.line[0]);
                                        if (suggestion.line.length > 1) {
                                            lineTwo.setValue(suggestion.line[1]);
                                        }
                                    } }) })), _jsx("div", Object.assign({ className: "col-12 mb-10" }, { children: _jsx(Input, { mode: 'lg', field: lineTwo, readonly: formIsDisabled, label: t('label.line_two') }) })), _jsx("div", Object.assign({ className: "col-12 col-lg-4 mb-10 mb-lg-0" }, { children: _jsx(Input, { mode: 'lg', field: city, readonly: formIsDisabled, label: t('label.city') }) })), _jsx("div", Object.assign({ className: "col-6 col-lg-4" }, { children: _jsx(Input, { mode: 'lg', field: state, readonly: formIsDisabled, label: t('label.state') }) })), _jsx("div", Object.assign({ className: "col-6 col-lg-4" }, { children: _jsx(Input, { mode: 'lg', field: postalCode, readonly: formIsDisabled, label: t('label.zip') }) }))] })) }) }), _jsx(Modal.Footer, { children: _jsxs("div", Object.assign({ className: styles.btns }, { children: [isEdit ? (_jsx(ButtonLegacy, { mode: ['secondary-danger'], label: t('btn.delete'), action: (e) => handleDelete(e, address), isLoading: $loader.isRunHandler('rm-address') })) : (_jsx(ButtonLegacy, { mode: ['primary'], label: t('btn.submit'), action: handleSubmit, isLoading: $loader.isRunHandler('add-address') })), _jsx(ButtonLegacy, { mode: ['secondary'], label: t('btn.cancel'), action: _core.onHide })] })) })] })));
};
AddAddressModal.displayName = 'AddAddressModal';
export default observer(AddAddressModal);
