import React from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy'

import { extendPlace } from '@helpers/addressHelper'

import SelectAddressMap from '../../../../pages/bookingHome/selectAddress/selectAddressMap/SelectAddressMap'
import styles from './SelectAddressDesktop.module.scss'

const SelectAddressDesktop = ({
  placeDetails,
  isDetailedPlace,
  isCollapsedMap,
  onClickBook,
  onClickChange,
}) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.container, { [styles.containerCollapsed]: isCollapsedMap })}>
      <div className={styles.map}>
        <SelectAddressMap coords={placeDetails.coords} isCollapsed={isCollapsedMap} />
      </div>
      {isDetailedPlace && (
        <div className={classNames(styles.content)}>
          <div className={styles.address}>
            <div className={styles.addressLabel}>
              {isCollapsedMap ? t('book_review.address') : t('book_review.book_for')}
            </div>
            <div className={styles.addressValue}>
              {extendPlace(placeDetails).buildingStreetCity()}
              {!isCollapsedMap && '?'}
            </div>
          </div>
          <div className={styles.buttons}>
            {!isCollapsedMap && (
              <ButtonLegacy
                mode="primary"
                action={onClickBook}
                label={`${t('btn.yes')}, ${t('btn.book_here')}`}
              />
            )}
            <ButtonLegacy
              mode="white"
              action={onClickChange}
              label={
                isCollapsedMap
                  ? t('btn.change_address')
                  : `${t('btn.no')}, ${t('btn.change_address')}`
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(SelectAddressDesktop)
