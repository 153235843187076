import React, { createElement, Fragment, useEffect } from 'react'

import { nativeScrollTo } from 'ziphy-web-shared/basic/helpers'
import { $router } from 'ziphy-web-shared/basic/utils/router'

import { config, TOS_GENERAL } from '@library/plugins/termsOfService/Tos.config'

export const TosText = ({ type = TOS_GENERAL, json, extractRef, onlyScroll = false }) => {
  const rules = config[type]['rules']({ onlyScroll })

  useEffect(() => nativeScrollTo($router.searchParams.get(type)), [])

  return createElement(
    'div',
    {},
    json.map((el, index) => {
      return (
        <Fragment key={index}>
          {window.ziphyTosRenderJSON({
            json: el,
            rules,
            extractData: extractRef.current,
            createElement,
          })}
        </Fragment>
      )
    }),
  )
}
