var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { $insurancesContexts, ENUMS_INSURANCE_POLICY_HOLDERS, ENUMS_INSURANCE_TYPES, } from 'ziphy-web-shared/basic/entities/insurances';
import { getEnumObjectValue, isAbsoluteEmpty } from 'ziphy-web-shared/basic/helpers';
import { useForm } from 'ziphy-web-shared/basic/lib/forms';
import { Input, Switch } from 'ziphy-web-shared/basic/lib/forms/fields';
import MultiSelect from 'ziphy-web-shared/basic/lib/forms/fields/multiSelect/MultiSelect';
import { $loader } from 'ziphy-web-shared/basic/utils';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import AddInsuranceTypeaheadProvider from './fields/AddInsuranceProvider';
const AddInsuranceModal = ({ patientId, initValue, _core }) => {
    var _a;
    const { t } = useTranslation();
    const $controller = $insurancesContexts.get(patientId);
    const { form, useFormOnChange } = useForm({
        type: {
            value: initValue === null || initValue === void 0 ? void 0 : initValue.type,
            output: ['string'],
            rules: ['required'],
        },
        providerName: {
            value: initValue === null || initValue === void 0 ? void 0 : initValue.providerName,
            output: ['string', 'emptyNull'],
            rules: [
                (value, rule, rules, { values }) => {
                    const type = values.type;
                    return isAbsoluteEmpty(value) ? type !== 'medical' : true;
                },
            ],
        },
        providerId: {
            value: initValue === null || initValue === void 0 ? void 0 : initValue.providerId,
            output: ['strict', 'string', 'emptyNull'],
        },
        memberId: {
            value: initValue === null || initValue === void 0 ? void 0 : initValue.memberId,
            output: ['strict', 'string'],
            rules: ['required'],
        },
        groupId: {
            value: initValue === null || initValue === void 0 ? void 0 : initValue.groupId,
            output: ['strict', 'string', 'emptyNull'],
        },
        memberFirstName: {
            value: initValue === null || initValue === void 0 ? void 0 : initValue.memberFirstName,
            output: ['string'],
            rules: ['required'],
        },
        memberLastName: {
            value: initValue === null || initValue === void 0 ? void 0 : initValue.memberLastName,
            output: ['string'],
            rules: ['required'],
        },
        policyHolder: {
            value: initValue === null || initValue === void 0 ? void 0 : initValue.policyHolder,
            output: ['string'],
            rules: ['required'],
        },
        isPrimary: {
            value: (initValue === null || initValue === void 0 ? void 0 : initValue.isPrimary) || false,
        },
    });
    const { type, memberFirstName, memberLastName, memberId, policyHolder, providerName, providerId, groupId, isPrimary, } = form.fields;
    useFormOnChange(() => {
        var _a, _b;
        if (((_a = type.value) === null || _a === void 0 ? void 0 : _a[0]) === 'medicare' && ((_b = policyHolder.value) === null || _b === void 0 ? void 0 : _b[0])) {
            policyHolder.onChange([ENUMS_INSURANCE_POLICY_HOLDERS.self.id]);
        }
    }, [type.value], 0);
    const insuranceTypes = Object.values(ENUMS_INSURANCE_TYPES).map((x) => ({
        id: x.id,
        text: t(x.tKey),
    }));
    const insurancePolicyHolders = Object.values(ENUMS_INSURANCE_POLICY_HOLDERS).map((x) => {
        var _a;
        return ({
            id: x.id,
            text: t(x.tKey),
            isDisabled: ((_a = type.value) === null || _a === void 0 ? void 0 : _a[0]) === 'medicare' && x.id !== 'self',
        });
    });
    const handleSubmit = $loader.registerHandler('create-insurance', (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        const formIsValid = form.validateAll().isValid;
        if (form.isChanged && formIsValid) {
            let result = form.values;
            if (isAbsoluteEmpty(result.providerName)) {
                result.providerName = result.type;
            }
            const response = yield $controller.create(result);
            if (!response.error) {
                _core.onHide();
            }
        }
    }));
    return (_jsxs(Modal, Object.assign({ centered: true, scrollable: true, className: "add-insurance-modal" }, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: _core.onHide }) })), _jsx(Modal.Header, { children: t('label.add_insurance') }), _jsx(Modal.Body, { children: _jsxs("form", Object.assign({ id: "add-insurance-form", onSubmit: handleSubmit, className: "mb--10" }, { children: [_jsx(MultiSelect, { label: t('insurance.label.type'), items: insuranceTypes, field: type, mode: "lg" }), _jsx(AddInsuranceTypeaheadProvider, { providerName: providerName, providerId: providerId, className: "mt-10" }), _jsxs("div", Object.assign({ className: "row row--gutters-5" }, { children: [_jsx("div", Object.assign({ className: "col-12 col-md-6" }, { children: _jsx(Input, { className: "mt-10", label: getEnumObjectValue((_a = type.value) === null || _a === void 0 ? void 0 : _a[0], ENUMS_INSURANCE_TYPES).labelMemberId ||
                                            t('insurance.label.member_id'), field: memberId, mode: "lg" }) })), _jsx("div", Object.assign({ className: "col-12 col-md-6" }, { children: _jsx(Input, { className: "mt-10", label: t('insurance.label.group_id'), field: groupId, mode: "lg" }) }))] })), _jsx(Input, { className: "mt-10", label: t('insurance.label.member_first_name'), field: memberFirstName, mode: "lg" }), _jsx(Input, { className: "mt-10", label: t('insurance.label.member_last_name'), field: memberLastName, mode: "lg" }), _jsx(MultiSelect, { label: t('insurance.label.policy_holder'), items: insurancePolicyHolders, field: policyHolder, className: 'mt-10', mode: "lg" }), _jsx(Switch, { field: isPrimary, label: t('insurance.label.use_as_primary'), asBox: true, className: 'mt-30' })] })) }), _jsxs(Modal.Footer, { children: [_jsx(ButtonLegacy, Object.assign({ mode: "primary", form: "add-insurance-form", className: "mb-10 btn-block", isLoading: $loader.isRunHandler('create-insurance') }, { children: t('btn.submit') })), _jsx(ButtonLegacy, Object.assign({ mode: "secondary", className: "btn-block", action: () => _core.onHide() }, { children: t('btn.cancel') }))] })] })));
};
AddInsuranceModal.displayName = 'AddInsuranceModal';
export default observer(AddInsuranceModal);
