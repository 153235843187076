var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import moment from 'moment-timezone';
import { isStaffApp } from 'ziphy-web-shared/basic/helpers';
import { usePaginationInfinite } from 'ziphy-web-shared/basic/hooks';
import { Loader } from 'ziphy-web-shared/basic/lib/loaders';
import { PageNoResults } from 'ziphy-web-shared/basic/lib/simple';
import clientService from '@services/client';
import ApptsListPageItem from '@nedoShared/entityViews/appointments/apptsListItem/ApptsListItem';
const ApptsTabHistory = ({ practiceId, dateFrom, dateTo }) => {
    const { ref, isLoading, data } = usePaginationInfinite({
        idKeyPath: 'appointment.id',
        fetchData: ({ limit, offset }) => __awaiter(void 0, void 0, void 0, function* () {
            if (!dateFrom) {
                dateFrom = moment.utc().subtract(1, 'years').format('YYYY-MM-DDTHH:mm:ss');
            }
            const response = yield clientService.getAppointments({
                type: 'past',
                practiceId,
                fromDate: dateFrom,
                toDate: dateTo,
                limit,
                offset,
            });
            return response.prepared;
        }),
    }, [practiceId, dateFrom, dateTo]);
    return (_jsxs(_Fragment, { children: [data === null || data === void 0 ? void 0 : data.map((x) => _jsx(ApptsListPageItem, { fullAppt: x }, x.appointment.id)), isLoading ? (_jsx(Loader, { mode: ['h108', 'bgGray'], className: 'mt-10' })) : (!(data === null || data === void 0 ? void 0 : data.length) && (_jsx(PageNoResults, { children: practiceId || dateFrom || dateTo ? (_jsx(Trans, { i18nKey: "appts.empty.filtered" })) : (_jsx(Trans, { i18nKey: isStaffApp() ? 'appts.empty.current_staff' : 'appts.empty.current_client', components: [_jsx("br", {})] })) }))), _jsx("div", { ref: ref })] }));
};
export default observer(ApptsTabHistory);
