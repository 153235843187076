import { useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';
import Form from './Form';
export const useForm = (params, { isReadyForInit = true, key = 'default' } = {}) => {
    const formsRef = useRef({});
    const initializedRef = useRef({});
    if (!formsRef.current[key]) {
        formsRef.current[key] = Form.create();
        initializedRef.current[key] = isReadyForInit;
    }
    if (!initializedRef.current[key] && isReadyForInit) {
        formsRef.current[key] = Form.create();
        initializedRef.current[key] = true;
    }
    formsRef.current[key].register(params);
    const currentForm = formsRef.current[key];
    const useFormOnChange = (callback, deps = [], delay = 400) => {
        const fn = useCallback(_.debounce(callback, delay), []);
        useEffect(fn, deps);
    };
    const current = currentForm;
    return {
        form: current,
        values: current.values,
        fields: current.fields,
        groups: current.groups,
        useFormOnChange,
        params,
    };
};
