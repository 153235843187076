import React from 'react'

import { observer } from 'mobx-react-lite'

import { $windowSize } from 'ziphy-web-shared/basic/utils'

import SelectModal from '@library/select/Select.modal'
import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator'
import TabsBarDesktop from '@library/widgets/tabsBar/TabsBarDesktop'
import TabsBarMobile from '@library/widgets/tabsBar/TabsBarMobile'

import { $modal } from '@store'

const TabsBar = ({
  mode,
  modeSelected,
  items,
  selected,
  onChange,
  autoTarget,
  selectAll,
  moreButtonText,
  moreModalParams = {},
}) => {
  const checkAuth = (props, e) => confirmAction('checkAuth', props, e)

  function handleOpenListModal(e) {
    const callback = () => {
      $modal.add(SelectModal, moreModalParams)
    }
    checkAuth({ callback }, e)
  }

  return (
    <>
      {$windowSize.isMobileSize ? (
        <TabsBarMobile
          items={items}
          selected={selected}
          onChange={onChange}
          autoTarget={autoTarget}
          moreModalParams={handleOpenListModal}
        />
      ) : (
        <TabsBarDesktop
          mode={mode}
          modeSelected={modeSelected}
          items={items}
          selected={selected}
          selectAll={selectAll}
          onChange={onChange}
          autoTarget={autoTarget}
          moreButtonText={moreButtonText}
          moreModalParams={handleOpenListModal}
        />
      )}
    </>
  )
}

export default observer(TabsBar)
