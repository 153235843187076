import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { getUserText } from 'ziphy-web-shared/basic/helpers';
import DtlGroup from '@library/ui/dtl/DtlGroup';
import DtlItem from '@library/ui/dtl/DtlItem';
const CocPatientDetails = ({ patient, className }) => {
    const { t } = useTranslation();
    return (_jsxs(DtlGroup, Object.assign({ className: classNames('row row--mb-20', className) }, { children: [_jsx(DtlItem, Object.assign({ className: "col-12 col-lg-3", label: t('label.name') }, { children: getUserText(patient, ['fullName']) })), _jsx(DtlItem, Object.assign({ className: "col-12 col-lg-3", label: t('label.sex_assigned_at_birth') }, { children: getUserText(patient, ['gender']) })), _jsx(DtlItem, Object.assign({ className: "col-12 col-lg-3", label: t('label.date_of_birth') }, { children: getUserText(patient, ['dob']) })), _jsx(DtlItem, Object.assign({ className: "col-12 col-lg-3", label: t('label.referral_partner_code') }, { children: patient.code }))] })));
};
export default CocPatientDetails;
