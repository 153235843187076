import axios from 'axios'
import withAxiosRetry from 'axios-retry'
import _ from 'lodash'
import { $awsCaptcha, showAlert } from 'ziphy-web-shared/basic/lib/utilities'
import { setRG } from 'ziphy-web-shared/basic/utils/analytics'

import {
  checkResponseErrors,
  convertKeysToSnake,
  getRequestParams,
  getUnAuthResponse,
  prepareRequestData,
  prepareResponseData,
  withPreparedResponse,
} from './api.helpers'

export const myackAxiosInstance = axios.create()

withAxiosRetry(myackAxiosInstance, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000
  },
  retryCondition: (error) => {
    return error.code === 'ERR_NETWORK'
  },
})

const responseCache = new Map()
const pendingRequests = new Map()

export class MyackApi {
  apiVersion = [3, 0]
  apiVersionV3 = [3, 0]

  baseUrl = ''
  storageManager
  getAccessToken // $auth.getAccessToken

  constructor(props) {
    this.baseUrl = props.baseUrl ?? this.baseUrl
    this.storageManager = props.storageManager ?? this.storageManager
    this.getAccessToken = props.getAccessToken ?? this.getAccessToken
  }

  async fetch(method = '', payload = {}, options = {}) {
    const {
      accessToken,
      skipAlert = false,
      prepareResult = true,
      prepareResultParams,
      useStorageManager = true,
      deDuplication = true,
      cacheTime = 0, // seconds
      apiVersion = this.apiVersion,
      addition = '',
    } = options

    const requestParams = getRequestParams(method, payload, accessToken, apiVersion)
    const requestKey = JSON.stringify(_.omit(requestParams, ['id', 'meta.access_token']))

    responseCache.forEach((cached, key) => {
      const now = Date.now() / 1000
      if (key === requestKey) {
        if (now - cached.time >= cacheTime) responseCache.delete(key)
      } else {
        if (now >= cached.expiry) responseCache.delete(key)
      }
    })
    if (cacheTime > 0 && responseCache.has(requestKey)) {
      return responseCache.get(requestKey).response
    }

    if (deDuplication && pendingRequests.has(requestKey)) {
      return _.cloneDeep(await pendingRequests.get(requestKey))
    }

    const requestPromise = (async () => {
      let url = this.baseUrl
      url += '?' + [method, addition].filter((x) => x).join('-')

      let response = {}

      if (
        [
          //
          'user.auth.request_code',
          'user.auth.onboard',
          'test2.user.auth.request_code',
        ].includes(method)
      ) {
        const res = await $awsCaptcha.request({ url, requestParams })

        if (res.response) {
          response = res.response
        } else if (res.error) {
          if (res.error.code) {
            response.error = { code: res.error.code, message: res.error.message }
          } else {
            response.error = { code: 'error.auth.high_rate_captcha', message: '' }
          }
        }
      } else {
        try {
          const res = await myackAxiosInstance.post(url, requestParams)
          response = res.data || {}
        } catch (e) {
          response.error = {
            code: e.code || 'error.catch.internal',
            message: e.message,
          }
        }
      }

      const error = checkResponseErrors(requestParams, response, skipAlert)

      if (error.analyticsMessage) {
        setRG('send', {
          error: error.analyticsMessage,
          customData: { request: requestParams, response: response },
        })
      }

      response = withPreparedResponse(response, prepareResult, prepareResultParams)

      if (useStorageManager && response.prepared && this.storageManager) {
        Object.entries(response.prepared).forEach(([key, value]) => {
          this.storageManager[key]?.forEach((x) => x.UPDATE(value))
        })
      }

      return response
    })()

    if (deDuplication) pendingRequests.set(requestKey, requestPromise)
    const result = await requestPromise
    if (deDuplication) pendingRequests.delete(requestKey)

    if (!result.error && (cacheTime > 0 || responseCache.has(requestKey))) {
      const now = Date.now() / 1000
      responseCache.set(requestKey, {
        time: now,
        expiry: now + cacheTime,
        response: _.cloneDeep({ ...result, isFromCache: true }),
      })
    }

    return result
  }

  async fetchV3(method = '', payload = {}, options = {}) {
    return this.fetch(method, payload, { ...options, apiVersion: this.apiVersionV3 })
  }

  async fetchBatch(requestsData = [], options = {}) {
    const {
      accessToken,
      skipAlert = false,
      prepareResult = true,
      prepareResultParams,
      useStorageManager = true,
      apiVersion = this.apiVersion,
    } = options

    if (!requestsData.length) {
      return {}
    }

    const batchParams = []
    let methods = []

    requestsData.forEach(({ method, params }) => {
      batchParams.push(getRequestParams(method, params, accessToken, apiVersion))
      methods.push(method)
    })

    let url = this.baseUrl
    url += '?batch__' + _.uniq(methods).join(',')

    const res = await myackAxiosInstance.post(url, batchParams)
    let result = res.data || []

    result.forEach((response, index) => {
      const requestParams = batchParams[index]
      const requestDataItem = requestsData[index]

      const skip = _.isBoolean(requestDataItem.skipAlert) ? requestDataItem.skipAlert : skipAlert
      const error = checkResponseErrors(requestParams, response, skip)

      if (error.analyticsMessage) {
        setRG('send', {
          error: error.analyticsMessage,
          customData: { batchIndex: index, request: requestParams, response: response },
        })
      }

      response = withPreparedResponse(response, prepareResult, prepareResultParams)

      if (useStorageManager && response.prepared && this.storageManager) {
        Object.entries(response.prepared).forEach(([key, value]) => {
          this.storageManager[key]?.(value)
        })
      }

      result[index] = response
    })

    return result
  }

  async fetchLogged(method, payload, options) {
    const accessToken = await this.getAccessToken(options?.roleId)

    if (!accessToken) {
      return getUnAuthResponse(method, options.apiVersion || this.apiVersion)
    }

    return await this.fetch(method, payload, { ...options, accessToken })
  }

  async fetchLoggedV3(method, payload, options) {
    return this.fetchLogged(method, payload, { ...options, apiVersion: this.apiVersionV3 })
  }

  async fetchLoggedBatch(requestsData, options) {
    const accessToken = await this.getAccessToken(options?.roleId)

    if (!accessToken) {
      return requestsData.map((x) => {
        return getUnAuthResponse(x.method, options.apiVersion || this.apiVersion)
      })
    }

    return await this.fetchBatch(requestsData, { ...options, accessToken })
  }

  async fetchLoggedBatchV3(requestsData, options) {
    return this.fetchLoggedBatch(requestsData, { ...options, apiVersion: this.apiVersionV3 })
  }

  async fetchUniversal(url, payload = {}, options = {}) {
    const {
      method = 'post',
      skipAlert = false,
      prepareResult = true,
      prepareResultParams,
      convertKeys = true,
      ...axiosConfig
    } = options

    if (convertKeys) {
      payload = convertKeysToSnake(payload)
    }

    const requestData = {
      ...prepareRequestData(url, payload),
    }

    let responseData = {}

    try {
      const response = await myackAxiosInstance({ method, url, data: requestData, ...axiosConfig })
      responseData = response.data
    } catch (err) {
      responseData = err.response?.data || err
    }

    if (responseData.detail) {
      const detail = responseData.detail

      let messages = []
      let message = ''

      if (_.isString(detail)) {
        messages.push(detail)
      } else if (_.isObject(detail)) {
        _.forEach(detail, (x) => {
          let tmp = []
          tmp.push(_.capitalize(x.msg))

          if (_.isArray(x.loc)) {
            tmp.push(x.loc.filter((x) => x !== 'body').join('.'))
          }

          messages.push(tmp.join(': '))
        })

        message = messages.join(', ')
      }

      if (!skipAlert) {
        showAlert.error(message, { isApiMessage: true })
      }

      setRG('send', {
        error: message,
        customData: { request: requestData, response: responseData },
      })

      responseData.error = {
        detail: responseData.detail,
        message: message,
      }
    }

    let result = {}

    if (responseData.error) {
      result.error = responseData.error
    } else if (!_.isEmpty(responseData)) {
      result.prepared = prepareResult
        ? prepareResponseData(responseData, prepareResultParams)
        : responseData
    }

    if (!result.hasOwnProperty('prepared')) {
      result.prepared = null
    }

    return result
  }
}
