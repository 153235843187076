import React, { useCallback } from 'react'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import _ from 'lodash'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { documentsService } from 'ziphy-web-shared/basic/api'
import { $auth } from 'ziphy-web-shared/basic/entities/auth'
import { DropDown } from 'ziphy-web-shared/basic/lib/functional'
import { Title } from 'ziphy-web-shared/basic/lib/simple'

import Document from '@library/document/Document'
import { useDocumentUploader } from '@library/plugins/documentUploader/documentUploader.hooks'

import { $appts, $modal } from '@store'

import { apptDocumentsFormList } from '@config/apptDocumentsFormsList'
import mainConfig from '@config/main'

import PlusSvg from '@assets/svg/Plus'
import UploadSvg from '@assets/svg/Upload'

import styles from './ApptDocuments.module.scss'
import FillOutFormsModal from './ApptFillOutForms.modal'

const ApptDocuments = ({ activePatient }) => {
  const env = process.env.REACT_APP_ENV

  const { fullAppt, actions } = $appts.byRoute
  const { appointment } = fullAppt
  const { encounterId } = useParams()
  const { t } = useTranslation()

  const { documents, DocumentUploader } = useDocumentUploader({
    patientId: activePatient.userId,
    encounterId: encounterId,
    baseFileName: [
      activePatient.firstName,
      activePatient.lastName,
      moment(activePatient.dob).format('YYYYMMDD'),
    ].join('_'),
  })

  const fillOutForms = apptDocumentsFormList.filter((item) => {
    let groupByEnv = item[env]
    if (!groupByEnv) return false

    let targetFormsList = {}

    Object.entries(groupByEnv).forEach(([ids, value]) => {
      ids.split('_').forEach((id) => {
        targetFormsList[id] = value
      })
    })

    return (
      targetFormsList[appointment.practiceId] ||
      targetFormsList.any ||
      _.includes(
        $auth.availablePractices.find((x) => x.id === appointment.practiceId)?.documentTemplateIds,
        targetFormsList.byPracticeConfig?.documentTemplateId,
      )
    )
  })
  const documentTemplates = $appts.documentTemplates[encounterId]

  const onClickExternalForm = useCallback(
    ({ type }) =>
      async () => {
        const tokenRes = await documentsService.getPatientAccessToken({ encounterId })
        const template = _.find(documentTemplates, (x) => x.type === type)

        if (template) {
          const targetUrl = mainConfig.web.documentsUrl + template.link
          const queryParams = queryString.stringify({
            access_token: tokenRes.prepared?.accessToken,
            encounter_id: encounterId,
            referrer_url: encodeURIComponent(window.location.href),
            role: mainConfig.appType,
          })

          window.location.href = [targetUrl, queryParams].join('?')
        }
      },
    [appointment.id, encounterId, window.location.href, mainConfig.appType], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const openFillOutFormsModal = useCallback(() => {
    $modal.add(FillOutFormsModal, { encounterId, practiceId: appointment.practiceId })
  }, [encounterId, appointment.practiceId]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!documentTemplates?.length && !actions.documents) {
    return null
  }

  return (
    <div className={styles.container} id="appt_documents">
      <Title size={'h3'} className={styles.title}>
        {t('label.documents_and_forms')}
      </Title>
      <div className={styles.documentsContainer}>
        {actions.viewForms &&
          documentTemplates?.map((item, index) => {
            if (item.type === 'patient_intake') {
              return (
                <Document
                  key={index}
                  className={styles.document}
                  title={item.name}
                  status={
                    !item.documentStatus
                      ? t('forms.status.incomplete')
                      : item.documentStatus === 'submitted'
                        ? t('forms.status.submitted', { date: '' })
                        : t(`forms.status.${item.documentStatus}`)
                  }
                  type={
                    !item.documentStatus || item.documentStatus === 'draft' ? 'warning' : 'default'
                  }
                  clickable={!!actions.fillIntake}
                  onClick={onClickExternalForm({ type: item.type })}
                />
              )
            }

            return (
              <Document
                key={index}
                title={item.name}
                className={styles.document}
                status={
                  item.isAccepted
                    ? t('forms.status.submitted', { date: '' })
                    : t('forms.status.incomplete')
                }
                type={item.isAccepted ? 'default' : 'warning'}
                clickable={!!actions.fillForms && !item.isAccepted}
                onClick={onClickExternalForm({ type: item.type })}
              />
            )
          })}

        {actions.documents && (
          <>
            {$auth.availablePractices.find((x) => x.id === appointment.practiceId) &&
              fillOutForms.length > 0 && (
                <Document
                  className={styles.document}
                  icon={<PlusSvg />}
                  status={t('label.fill_out_forms')}
                  onClick={openFillOutFormsModal}
                />
              )}

            <DocumentUploader>
              <Document
                className={styles.document}
                icon={<UploadSvg />}
                status={t('label.upload_document')}
              />
            </DocumentUploader>

            {documents.map((item, index) => {
              const document = (
                <Document
                  className={styles.document}
                  title={item.type.label}
                  status={item.statusText}
                  type={item.hasError ? 'error' : 'default'}
                  loading={item.isLoading}
                />
              )

              if (item.actions) {
                return (
                  <DropDown
                    key={index}
                    renderButton={({ ref, onToggle }) => (
                      <div ref={ref} onClick={onToggle} className={styles.error}>
                        {document}
                      </div>
                    )}
                    items={item.actions.map((x) => ({ ...x, onClick: x.action }))}
                  />
                )
              }
              return <React.Fragment key={index}>{document}</React.Fragment>
            })}
          </>
        )}
      </div>
    </div>
  )
}

export default observer(ApptDocuments)
