var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Title } from 'ziphy-web-shared/basic/lib/simple';
import Modal from '@library/modal/Modal';
import ModalToolbarBtn from '@library/modal/toolbar/ModalToolbarBtn';
import { confirmAction } from '@library/utils/actionConfirmator/ActionConfirmator';
import ButtonLegacy from '@nedoShared/basic/lib/simple/button/ButtonLegacy';
import { customExecute } from '@helpers/other';
import { customActions } from '@config/customActions';
import mainConfig from '@config/main';
import { $configure } from '../configure.store';
import styles from './ConfigurePreview.module.scss';
const ConfigurePreviewModal = ({ _core }) => {
    const { t } = useTranslation();
    function handleSubmit() {
        $configure.toNext();
        _core.onHide();
    }
    function handleCancel() {
        confirmAction('customAlert', {
            data: {
                title: t('confirmator.logout.title'),
                message: t('confirmator.logout.message'),
            },
            callback: () => __awaiter(this, void 0, void 0, function* () {
                yield customExecute(customActions.HANDLE_REJECT_PAPERWORK());
                _core.onHide();
            }),
        });
    }
    return (_jsxs(Modal, Object.assign({ centered: true, scrollable: true, closeOnOverlay: false }, { children: [_jsx("div", Object.assign({ className: "modal-toolbar" }, { children: _jsx(ModalToolbarBtn, { type: "close", action: handleCancel }) })), _jsx(Modal.Header, { children: _jsx(Title, Object.assign({ size: 'h2', className: "text-lg-center" }, { children: _jsx(Trans, { i18nKey: "configure_preview.title", components: [_jsx("br", { className: "d-none d-lg-block" })] }) })) }), _jsxs(Modal.Body, { children: [_jsx("p", { children: t('configure_preview.description') }), $configure.requiredSteps.map((step, index) => (_jsxs("p", Object.assign({ className: !step.required && styles.completed }, { children: [index + 1, ".", ' ', t('configure_preview.steps.' + _.snakeCase(step.name), {
                                appName: mainConfig.appName,
                            }), index === $configure.requiredSteps.length - 1 ? '.' : ';'] }), index)))] }), _jsx(Modal.Footer, { children: _jsx(ButtonLegacy, Object.assign({ mode: ['primary', 'block'], action: handleSubmit }, { children: t('configure_preview.btn.start') })) })] })));
};
ConfigurePreviewModal.displayName = 'ConfigurePreviewModal';
export default observer(ConfigurePreviewModal);
