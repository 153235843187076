import { useParsedParams } from 'ziphy-web-shared/basic/hooks';
import { $router } from 'ziphy-web-shared/basic/utils/router';
import { routeActions } from '@config/routeActions';
export const usePsPatientLink = () => {
    const { patientId: _patientId, practiceId: _practiceId, categoryId: _categoryId, } = useParsedParams();
    let backToUrl = $router.searchParams.get('backToUrl');
    if (Array.isArray(backToUrl)) {
        backToUrl = backToUrl.join(',');
    }
    return ({ patientId, practiceId, categoryId, saveBackUrl = true, }) => routeActions.PS_PATIENT({
        patientId: patientId || _patientId,
        practiceId: practiceId || _practiceId,
        categoryId: categoryId === null ? undefined : categoryId || _categoryId,
    }, Object.assign({}, (saveBackUrl && backToUrl && { backToUrl })));
};
