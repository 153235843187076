import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Input } from 'ziphy-web-shared/basic/lib/forms/fields';
import { VERIFY_CODE } from '../CodeResender.constants';
import styles from './CodeInput.module.scss';
const CodeInput = ({ code, messageType, label, placeholder, maxLength, mode, className, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: className }, { children: [messageType === 'text' && (_jsx("div", Object.assign({ className: classNames(styles.text, 'mb-10') }, { children: _jsx(Trans, { i18nKey: "text.auth.code_sent", components: [_jsx("br", {})] }) }))), messageType === 'voice' && (_jsx("div", Object.assign({ className: classNames(styles.text, 'mb-10') }, { children: _jsx(Trans, { i18nKey: "text.auth.code_sent", components: [_jsx("br", {})] }) }))), _jsx(Input, { field: code, label: label || t('label.code'), maxLength: maxLength || VERIFY_CODE.maxLength, inputMode: "numeric", 
                // @ts-ignore
                autoComplete: "one-time-code", pattern: VERIFY_CODE.pattern, autoFocus: true, placeholder: placeholder || t('label.code'), mode: mode || 'noLabel' })] })));
};
export default observer(CodeInput);
