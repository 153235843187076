import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import helpers from '@library/trendsList/trendsListChart/TrendsListChart.helpers';
import styles from './TrendsListChart.module.scss';
const TrendsListChart = ({ value, config, className }) => {
    const { t } = useTranslation();
    const labels = useMemo(() => {
        const stringDates = value.items.flatMap((item) => item.map((item) => item.date));
        const dates = stringDates.map((date) => new Date(date).getTime());
        const minDate = Math.min(...dates);
        const maxDate = Math.max(...dates);
        return helpers.getRangeOfDates(minDate, maxDate, 4);
    }, [value]);
    return (_jsxs("div", Object.assign({ className: classNames(styles.container, className) }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: t(config.label) })), _jsx("div", Object.assign({ className: styles.chart }, { children: _jsx(Chart, { type: 'line', data: {
                        labels: labels,
                        datasets: value.items.map((item, index) => {
                            var _a, _b, _c, _d;
                            return ({
                                data: item.map((item) => ({ y: item.value, x: item.date })),
                                fill: true,
                                borderColor: (_a = config.chart[index]) === null || _a === void 0 ? void 0 : _a.lineColor,
                                backgroundColor: (_b = config.chart[index]) === null || _b === void 0 ? void 0 : _b.backgroundColor,
                                // When there is only one point
                                pointRadius: item.length < 2 ? 3 : 0,
                                pointBackgroundColor: item.length < 2 ? (_c = config.chart[index]) === null || _c === void 0 ? void 0 : _c.lineColor : '#fff',
                                pointHoverBorderColor: item.length < 2 ? (_d = config.chart[index]) === null || _d === void 0 ? void 0 : _d.lineColor : undefined,
                            });
                        }),
                    }, options: {
                        elements: {
                            line: {
                                borderWidth: 3,
                                tension: 0.5,
                                cubicInterpolationMode: 'monotone',
                            },
                        },
                        plugins: {
                            legend: { display: false },
                            tooltip: { enabled: false },
                        },
                        scales: {
                            x: {
                                type: 'time',
                                time: {
                                    unit: 'millisecond',
                                    displayFormats: { millisecond: 'MM/YYYY' },
                                },
                                ticks: {
                                    display: true,
                                    source: 'labels',
                                    color: 'rgba(117, 117, 117, 1)',
                                    font: { size: 12 },
                                },
                                grid: { display: false, drawBorder: false },
                            },
                            y: {
                                grid: { drawBorder: false },
                                ticks: {
                                    count: 4,
                                    callback: (label) => Math.round(+label * 10) / 10,
                                    color: 'rgba(117, 117, 117, 1)',
                                    font: { size: 12 },
                                },
                            },
                        },
                    } }) }))] })));
};
export default TrendsListChart;
